import React, { useContext, useState, useEffect } from 'react'
import { GlobalContext, sort } from 'Utils'
import { get, isEmpty } from 'lodash'
import { SaturationTable, SaturationLegend, ReportWrapper } from 'Components'
import styled from '@emotion/styled'
import { GET_SATURATION_TABLE_STRUCTURE, GET_SATURATION_TABLE_PDVS } from 'Queries'
import { useLazyQuery } from '@apollo/client'
import { Spin } from 'antd'


export const SaturationReport = ({
  codDistribuidor,
  codObjetivo,
  year,
}) => {

  const context = useContext(GlobalContext)

  const [filter, setFilter] = useState({})
  const [gruposSat, setGruposSat] = useState([])
  const [listadoPdvs, setListadoPdvs] = useState([])
  const [empleados, setEmpleados] = useState([])
  const [empleadosFiltrados, setEmpleadosFiltrados] = useState([])
  const [niveles, setNiveles] = useState([])
  const [searchTerm, setSearchTerm] = useState(undefined)

  const [refresh, setRefresh] = useState(false)

  const [totalElements, setTotalElements] = useState(0)
  const [{ sortBy, sortDir, page }, setSort] = useState({
    sortBy: 'countGroup',
    sortDir: 'desc',
    page: 1
  })

  const variables = {
    id: {
      codDistribuidor,
      ano: year,
      codObjetivo,
    },
    codEmpleado: filter.empleado ? filter.empleado : get(context, 'currentUser.codEmpleado'),
    nivel: filter.empleado ? filter.nivel : get(context, 'currentUser.profile.id'),
    size: 100,
    searchTerm: searchTerm !== undefined ? searchTerm : '',
    countGroup: filter.nGroups !== undefined ? parseInt(filter.nGroups.slice(3)) : null,
    status: filter.minOk !== undefined ? (filter.minOk === "N" ? false : true) : null,
  }

  const [getPdvsList, { loading: loadingPdvs }] = useLazyQuery(GET_SATURATION_TABLE_PDVS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getTablaObjetivoSat }) => {
      if (refresh) {
        setRefresh(false);
        const newList = getTablaObjetivoSat.content.map(pdv => {
          return {
            pdvId: pdv.pdvId,
            pdv: pdv.pdvName,
            nameKey: `key_${pdv.pdvName}_${pdv.pdvId}`,
            groupsId: pdv.groupsId,
            countGroup: pdv.groupsId.length,
            success: pdv.success,
          }
        })
        setTotalElements(getTablaObjetivoSat.pagination.totalElements)
        setListadoPdvs(newList)
        context.resetKeepAliveTimer()
      }
    },
  })

  const [getEmpleadosAndGroups, { loading }] = useLazyQuery(GET_SATURATION_TABLE_STRUCTURE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ tableListSatGroup }) => {
      if (isEmpty(empleados) && isEmpty(gruposSat)) {
        setEmpleados(tableListSatGroup.empleados)
        setGruposSat(tableListSatGroup.gruposSat)
        if (isEmpty(listadoPdvs)) {
          setRefresh(true);
          const sortObj = { sortBy, sortDir, page: 0 }
          getPdvsList({ variables: { ...variables, ...sortObj } })
        }
      }
    },
  })

  const orderList = (sorter, current) => {
    setRefresh(true);
    setSort({
      sortBy: sorter.field,
      // sortDir: sortDir === 'asc' ? 'desc' : 'asc',
      sortDir: sorter.order === 'descend' ? 'desc' : 'asc',
      page: current
    })
    const sortObj = {
      sortBy: sorter.field,
      // sortDir: sortDir === 'asc' ? 'desc' : 'asc',
      sortDir: sorter.order === 'descend' ? 'desc' : 'asc',
      page: current - 1,
    }
    getPdvsList({ variables: { ...variables, ...sortObj } })
  }

  useEffect(() => {
    if (isEmpty(empleados)) {
      getEmpleadosAndGroups({
        variables: {
          id: {
            codDistribuidor: get(context, 'currentUser.codDistribuidor'),
            ano: year,
            codObjetivo,
          },
        }
      })
    }
  }, [empleados])

  useEffect(() => {
    const levels = [...new Map(empleados.map(emp => emp.nivelEmpleado).map(item =>
      [item["id"], item])).values()];

    if (!niveles.length && levels.length) {
      const niveles = sort(levels, 'desc')
      setNiveles(niveles)
    }
  })

  useEffect(() => {
    if (filter.nivel) {
      const employees = empleados.filter(emp => emp.nivelEmpleado.id === filter.nivel)
      setEmpleadosFiltrados(sort(employees, 'nombreEmpleado'))
    }
  }, [filter.nivel])

  const handleOnChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (!isEmpty(gruposSat)) {
      const timeoutId = setTimeout(() => {
        setRefresh(true);
        const sortObj = { sortBy, sortDir, page: 0 }
        getPdvsList({ variables: { ...variables, ...sortObj } })
      }, 1500)
      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!isEmpty(gruposSat)) {
      setRefresh(true);
      const sortObj = { sortBy, sortDir, page: 0 }
      getPdvsList({ variables: { ...variables, ...sortObj } })
    }
  }, [filter.empleado, filter.minOk, filter.nGroups])

  return (
    <ReportWrapper reportHeight={170}>
      <FollowUpLegendWrapper>
        <SaturationLegend
          filter={filter}
          empleados={empleadosFiltrados}
          niveles={niveles}
          handleOnChange={handleOnChange}
          gruposSat={gruposSat}
          setFilter={newFilter => {
            setFilter({ ...filter, ...newFilter })
          }}
          clearFilter={() => {
            setSearchTerm('');
            setFilter({});
          }}
          isLoading={loading || loadingPdvs}
        />
      </FollowUpLegendWrapper>
      <Spin spinning={loading || loadingPdvs}>
        <SaturationTable
          headerList={gruposSat}
          dataList={listadoPdvs}
          orderList={orderList}
          totalElements={totalElements}
          sortBy={sortBy}
          sortDir={sortDir}
          pager={loadingPdvs ? 1 : page}
          isLoading={loading || loadingPdvs}
        />
      </Spin>

    </ReportWrapper>
  )
}

const FollowUpLegendWrapper = styled.div`
  margin-bottom: 10px;
`
