import React from 'react'

export const GlobalContext = React.createContext()
export const GlobalContextProvider = GlobalContext.Provider
export const GlobalContextConsumer = GlobalContext.Consumer

export const DashboardContext = React.createContext()
export const DashboardContextProvider = DashboardContext.Provider
export const DashboardContextConsumer = DashboardContext.Consumer

export const ObjectiveContext = React.createContext({
  objective: {},
  setObjective: noop => noop,
})
export const ObjectiveContextProvider = ObjectiveContext.Provider
export const ObjectiveContextConsumer = ObjectiveContext.Consumer

export default GlobalContext
