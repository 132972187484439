import React from 'react'
import styled from '@emotion/styled'

export const ReportWrapper = ({ reportHeight, children }) => {
  return (
    <Wrapper reportHeight={reportHeight}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${props => props.reportHeight}px);
  overflow-y: auto;
  padding: 10px 20px;
`
