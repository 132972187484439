import React from "react";

import { RadioGroup, WarningMessage, ModalSectionLabel } from "Components";
import { colors } from "Utils";

import { Form, Checkbox as AntCheckbox, Select, Alert, Row, Col } from "antd";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";

export const ModalFormWhatToMeasure = ({
    measure,
    salesType = [],
    whatMeasureFormOptions,
    whatSalesTypeFormOptions = [],
    providerOptions,
    specialistOptions,
    onChangeMeasure,
    onChangeSalesType,
    showWarning,
    emptySalesType,
    paraEspecialistas,
    setParaEspecialistas,
    ocultarNiveles,
    setOcultarNiveles,
    hasProvider,
    setHasProvider,
    hasCanal,
    setHasCanal,
    paraProveedor,
    setParaProveedor,
    specialistID,
    setSpecialistID,
    withException,
    setWithException,
    paraCanal,
    setParaCanal,
    canalOptions,
    tipoValorRef
}) => {
    const showAlert = (type) => {
        return (
            <Alert
                style={{ marginTop: 5 }}
                message={`Este distribuidor no tiene ${type} disponibles`}
                type="warning"
                showIcon
            />
        );
    };
    const filteredOptions = hasCanal
        ? whatMeasureFormOptions.filter(
              (option) => option.id !== "A" && option.id !== "S"
          )
        : whatMeasureFormOptions;

    return (
        <Form>
            <Row style={{ marginBottom: 10 }}>
                <Col span={11} style={{ borderRight: "solid 1px lightgray" }}>
                    <ModalSectionLabel>Tipo de medida</ModalSectionLabel>
                    <RadioGroup
                        options={filteredOptions}
                        value={measure}
                        onChange={(e) => {
                            if (e.target.value === "A") {
                                emptySalesType();
                            }
                            onChangeMeasure(e.target.value);
                        }}
                        label={(option) => (
                            <span>
                                {option.desc} ({option.unidad})
                            </span>
                        )}
                    />

                    <ModalSectionLabel>Tipo de facturación</ModalSectionLabel>
                    {whatSalesTypeFormOptions.map((option) => (
                        <BillingCheckbox
                            key={option.id}
                            value={option.id}
                            disabled={measure === "A"}
                            checked={salesType.indexOf(option.id) > -1}
                            onChange={onChangeSalesType}
                        >
                            {option.desc}
                        </BillingCheckbox>
                    ))}
                </Col>
                <Col span={12} offset={1}>
                    <ModalSectionLabel>
                        Aplicación y Visibilidad
                    </ModalSectionLabel>
                    {!hasCanal && (
                        <>
                            <Checkbox
                                disabled={isEmpty(specialistOptions)}
                                checked={paraEspecialistas}
                                onChange={() =>
                                    setParaEspecialistas(!paraEspecialistas)
                                }
                            >
                                <span
                                    style={{
                                        fontSize: 16,
                                        color: "#555",
                                        paddingLeft: 8,
                                    }}
                                >
                                    Aplica a especialistas
                                    <br />
                                    <SmallExplanation>
                                        Este objetivo también aplicará a
                                        especialistas
                                    </SmallExplanation>
                                    {measure === "S" &&
                                        isEmpty(specialistOptions) &&
                                        showAlert("especialistas")}
                                </span>
                            </Checkbox>
                            {measure === "S" && paraEspecialistas && (
                                <Select
                                    style={{ marginTop: -20, marginBottom: 35 }}
                                    mode="default"
                                    placeholder="Especialista"
                                    value={specialistID}
                                    onChange={(value) => setSpecialistID(value)}
                                    showArrow={true}
                                >
                                    {specialistOptions.map(
                                        ({
                                            codMiembro,
                                            nombre,
                                            categorias,
                                        }) => (
                                            <Select.Option
                                                key={codMiembro}
                                                value={codMiembro}
                                            >
                                                {nombre}
                                                <span
                                                    style={{ paddingLeft: 10 }}
                                                >
                                                    {isEmpty(categorias)
                                                        ? ""
                                                        : " (" +
                                                          categorias.join(
                                                              ", "
                                                          ) +
                                                          ")"}
                                                </span>
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            )}
                        </>
                    )}

                    {measure !== "A" && !hasCanal && (
                        <Checkbox
                            checked={ocultarNiveles}
                            onChange={() => setOcultarNiveles(!ocultarNiveles)}
                        >
                            <span
                                style={{
                                    fontSize: 16,
                                    color: "#555",
                                    paddingLeft: 8,
                                }}
                            >
                                Objetivo de seguimiento gerencial
                                <br />
                                <SmallExplanation>
                                    Este objetivo sólo será visible por gerente
                                    y jefe de ventas
                                </SmallExplanation>
                            </span>
                        </Checkbox>
                    )}

                    <Checkbox
                        disabled={isEmpty(providerOptions)}
                        checked={hasProvider}
                        onChange={() => setHasProvider(!hasProvider)}
                    >
                        <span
                            style={{
                                fontSize: 16,
                                color: "#555",
                                paddingLeft: 8,
                            }}
                        >
                            Compartir con proveedor
                            <br />
                            <SmallExplanation>
                                Este objetivo aplicará al proveedor seleccionado
                            </SmallExplanation>
                            {isEmpty(providerOptions) &&
                                showAlert("proveedores")}
                        </span>
                    </Checkbox>

                    {hasProvider && (
                        <Select
                            style={{ marginBottom: 35 }}
                            mode="default"
                            placeholder="Proveedores"
                            value={paraProveedor}
                            onChange={(value) => setParaProveedor(value)}
                            showArrow={true}
                        >
                            {providerOptions.map(
                                ({ codigoProveedor, nombre }) => {
                                    if (codigoProveedor !== undefined) {
                                        return (
                                            <Select.Option
                                                key={codigoProveedor}
                                                value={codigoProveedor}
                                            >
                                                {codigoProveedor} - {nombre}
                                            </Select.Option>
                                        );
                                    }
                                }
                            )}
                        </Select>
                    )}

                    <br />
                    {measure !== "A" && measure !== "S" && !hasCanal && (
                        <Row>
                            <Col span={2}>
                                <Checkbox
                                    checked={withException}
                                    onChange={() =>
                                        setWithException(!withException)
                                    }
                                />
                            </Col>
                            <Col
                                span={20}
                                style={{
                                    fontSize: 16,
                                    paddingLeft: 3,
                                    paddingBottom: 20,
                                    color: "#555",
                                }}
                            >
                                Con excepciones: seleccionar clientes que no
                                compren ciertos artículos
                            </Col>
                        </Row>
                    )}
                    {measure !== "A" &&
                        measure !== "S" &&
                        !paraEspecialistas &&
                        !ocultarNiveles &&
                        !withException && tipoValorRef.id !== 'IA' && (
                            <>
                                <Checkbox
                                    disabled={isEmpty(canalOptions)}
                                    checked={hasCanal}
                                    onChange={() => {
                                        setHasCanal(!hasCanal);
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: 16,
                                            color: "#555",
                                            paddingLeft: 8,
                                        }}
                                    >
                                        Canales
                                        <br />
                                        {isEmpty(canalOptions) &&
                                            showAlert("canales")}
                                    </span>
                                </Checkbox>
                                {hasCanal && (
                                    <Select
                                        style={{
                                            marginBottom: 35,
                                            overflowY: "auto",
                                        }}
                                        mode="multiple"
                                        placeholder="Canales"
                                        value={paraCanal}
                                        onChange={(value) => {
                                            setParaCanal(value);
                                        }}
                                        dropdownMatchSelectWidth={false}
                                        dropdownStyle={{
                                            maxHeight: "auto",
                                            overflowY: "hidden",
                                        }}
                                        showArrow={true}
                                    >
                                        {canalOptions.map(
                                            ({ codigoCanal, descripcion }) => {
                                                if (codigoCanal !== undefined) {
                                                    return (
                                                        <Select.Option
                                                            key={codigoCanal}
                                                            value={codigoCanal}
                                                        >
                                                            {codigoCanal} - {" "}
                                                            {descripcion}
                                                        </Select.Option>
                                                    );
                                                }
                                            }
                                        )}
                                    </Select>
                                )}
                            </>
                        )}
                </Col>
            </Row>

            {showWarning && (
                <WarningMessage sWidth={"400px"}>
                    <span>
                        Si se modifican estos valores se{" "}
                        <strong>eliminarán los ajustes del objetivo</strong>
                    </span>
                </WarningMessage>
            )}
        </Form>
    );
};

const BillingCheckbox = styled(AntCheckbox)`
    display: flex;
    font-size: 14px;
    line-height: 2.5;
    color: grey;
    + .ant-checkbox-wrapper {
        margin-left: 0;
    }
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
        padding-right: 18px;
        padding-left: 18px;
    }
`;
const SmallExplanation = styled.small`
    font-size: 14px;
    color: ${colors.warmGrey};
    padding-left: 35px;
    display: block;
    white-space: normal;
`;
const Checkbox = styled(AntCheckbox)`
    && {
        margin: 0;
    }
`;
