import styled from '@emotion/styled'
import { colors } from 'Utils'

export const ObjectivePageTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.11;
  letter-spacing: -0.27px;
  color: ${colors.black};
  margin-bottom: 22px;
`
