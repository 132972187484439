import gql from 'graphql-tag'

const EMPLOYEE_FRAGMENT = gql`
  fragment employeeFragment on Jerarquia {
    miembro {
      nombre
      codPadre
      codMiembro
      codComercial
      nivel
      categorias
      email
      telefono
      miembrosDisponibles {
        codMiembro
        nombre
      }
      activo
      lastLogin
      username
      copiaGerente
    }
  }
`
const COMERCIAL_FRAGMENT = gql`
  fragment comercialFragment on Comercial {
    codComercial
    nombreComercial
  }
`

export const HIERARCHY_FRAGMENT = gql`
  fragment hierarchyFragment on JerarquiaComercial {
    jerarquia {
      ...employeeFragment
      hijos {
        ...employeeFragment
        hijos {
          ...employeeFragment
          hijos {
            ...employeeFragment
          }
        }
      }
    }
    comercialesSinAsignar {
      ...comercialFragment
    }
    comercialesActivos {
      ...comercialFragment
    }
  }
  ${EMPLOYEE_FRAGMENT}
  ${COMERCIAL_FRAGMENT}
`
