const months = [
  'Ene.',
  'Feb.',
  'Mar.',
  'Abr.',
  'May.',
  'Jun.',
  'Jul.',
  'Ago.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
]

const objetivoAjusteValor = {
  titulo: 'AA.',
  signo: false,
  valor: 200,
  unidad: '%',
}

const objetivoAjusteMatrizCelda = {
  // # Mes: Enero - 0, Febrero - 1, ...
  mes: 0,
  // # Valor: valorRef
  valorRef: objetivoAjusteValor,

  // # Valor: inc en valor absoluto sobre porcentaje
  inc: objetivoAjusteValor,

  // # Valor: aa (año anterior) or ma (mes anterior)
  pa: objetivoAjusteValor,

  // # Valor: total = valorRef + AA
  total: objetivoAjusteValor,
}

const objetivoAjusteMatrizFila = {
  comercial: {
    codMiembro: 64,
    titulo: 'María Barroso Rodríguez',
    subtitulo: null,
  },
  celdas: new Array(12).fill(objetivoAjusteMatrizCelda),
}

const objetivoAjusteTotales = {
  // # Suma por filas/columnas de valores de referencia ajustados por mes - Cantidad
  valorRef: objetivoAjusteValor,

  // # Suma por filas/columnas de valores de referencia ajustados por mes - %
  inc: objetivoAjusteValor,

  // # Suma por filas/columnas de AA por mes
  pa: objetivoAjusteValor,

  // # Suma por filas/columnas de AA (si aplica) + TotValorRefs por mes
  total: objetivoAjusteValor,
}

export const adjustmentsMockGen = {
  data: {
    ajuste: {
      cabeceraMeses: months,
      // Valor ajuste por comercial y mes
      valoresCeldaPorComercialMes: new Array(4).fill(objetivoAjusteMatrizFila),
      cabeceraTotal: 'Ene. - Dic.',
      // Totales por fila
      valoresTotalPorComercial: new Array(4).fill(objetivoAjusteTotales),
      // Totales por columna
      valoresTotalPorMes: new Array(12).fill(objetivoAjusteTotales),
    },
  },
}

export const adjustmentsMock = {
  data: {
    ajuste: {
      cabeceraMeses: [
        'Ene.',
        'Feb.',
        'Mar.',
        'Abr.',
        'May.',
        'Jun.',
        'Jul.',
        'Ago.',
        'Sep.',
        'Oct.',
        'Nov.',
        'Dec.',
      ],
      valoresCeldaPorComercialMes: [
        {
          comercial: {
            codMiembro: 64,
            titulo: 'María Barroso Rodríguez Rodríguez Rodríguez',
            subtitulo: null,
          },
          // "comercial": {
          //   "codMiembro": null,
          //   "titulo": "Objetivo conjunto",
          //   "subtitulo": "20 comerciales"
          // },
          celdas: [
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
          ],
        },
        {
          comercial: {
            codMiembro: 64,
            titulo: 'María Barroso Rodríguez',
            subtitulo: null,
          },
          celdas: [
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
          ],
        },
        {
          comercial: {
            codMiembro: 64,
            titulo: 'María Barroso Rodríguez',
            subtitulo: null,
          },
          celdas: [
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
          ],
        },
        {
          comercial: {
            codMiembro: 64,
            titulo: 'María Barroso Rodríguez',
            subtitulo: null,
          },
          celdas: [
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
            {
              mes: 0,
              valorRef: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              inc: null,
              pa: {
                titulo: 'AA.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
              total: {
                titulo: 'Tot.',
                signo: false,
                valor: 200,
                unidad: '%',
              },
            },
          ],
        },
      ],
      cabeceraTotal: 'Ene. - Dic.',
      // cabeceraTotal: null,
      valoresTotalPorComercial: [
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 10000,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'Tot.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 10000,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'Tot.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'Tot.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'Tot.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
      ],
      valoresTotalPorMes: [
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
        {
          valorRef: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          inc: null,
          pa: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
          total: {
            titulo: 'AA.',
            signo: false,
            valor: 200,
            unidad: '%',
          },
        },
      ],
    },
  },
}
