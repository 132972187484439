import React from 'react'

import { Modal, ModalTitle, ModalSubtitle } from './styled'
import { TableHeader } from './TableHeader'
import { TableRow } from './TableRow'
import { cloneDeep } from 'lodash'

export const IncentiveMatrixModal = ({
  show,
  title,
  subtitle,
  dataSource,
  onCancel,
  editIncentivesMatrix,
  deleteIncentivesMatrix,
  addNewRow,
  removeNewRow,
  onRowEdit,
  editedRow,
}) => {
  let localDataSource = cloneDeep(dataSource)
  const linealRow = dataSource.filter(row => row.lineal)

  localDataSource.map((row, index) => {
    row.min = (index > 0 && dataSource[index - 1].desde) || 0
    row.max =
      (index < dataSource.length - 1 && dataSource[index + 1].desde) ||
      Number.POSITIVE_INFINITY
    row.minRetribution = (index > 0 && dataSource[index - 1].valor) || 0
    row.maxRetribution =
      (index < dataSource.length - 1 && dataSource[index + 1].valor) ||
      (row.limite && Number.POSITIVE_INFINITY) ||
      100
  })

  if (linealRow.length < 1) {
    localDataSource.splice(localDataSource.length - 1, 0, {
      desde: null,
      hasta: null,
      limite: false,
      lineal: true,
      orden: localDataSource.length - 1,
      secuencia: null,
      valor: null,
    })
  }

  return (
    <Modal
      title={
        <>
          <ModalTitle>{title}</ModalTitle>
          <ModalSubtitle>{subtitle}</ModalSubtitle>
        </>
      }
      visible={show}
      okText="Cerrar"
      width="700px"
      centered
      onCancel={() => onCancel()}
      onOk={() => onCancel()}
      cancelButtonProps={{
        style: { display: 'none' },
      }}
    >
      <TableHeader />
      {localDataSource.map((row, index) => {
        return (
          <TableRow
            editIncentivesMatrix={editIncentivesMatrix}
            deleteIncentivesMatrix={deleteIncentivesMatrix}
            addNewRow={addNewRow}
            removeNewRow={removeNewRow}
            key={index}
            index={index}
            {...row}
            onRowEdit={onRowEdit}
            editedRow={editedRow}
          />
        )
      })}
    </Modal>
  )
}
