import React from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { colors } from 'Utils'
import styled from '@emotion/styled'
import {
  CellTitle,
  CellDescription,
  HeaderCellWrapper,
  CellWrapper,
} from 'Components'

export const TotalRowEmployees = ({
  title,
  dataSource,
  showDetails,
  setShowDetails,
  isPercentage,
}) => {

  return (
    <Inner>
      <DetailSwitch onClick={() => setShowDetails(!showDetails)}>
        <LeftOutlined
          style={{ margin: 'auto', fontSize: 10 }}
          rotate={showDetails ? 0 : 180}
        ></LeftOutlined>
      </DetailSwitch>
      <HeaderCellWrapper>{title}</HeaderCellWrapper>
      {dataSource.map((value, index) => {
        return (
          <CellWrapper key={index} isPercentage={isPercentage}>
            <CellTitle dataSource={value.valorRef} />
            <CellDescription dataSource={value.pa} />
            <CellDescription dataSource={value.inc} />
            <CellDescription dataSource={value.total} />
          </CellWrapper>
        )
      })}
    </Inner>
  )
}

const Inner = styled.div({
  position: 'relative',
  width: 'min-content',
})
const DetailSwitch = styled.div({
  position: 'absolute',
  color: 'white',
  zIndex: 1,
  display: 'flex',
  top: 19 - 9,
  left: 90 + 9,
  width: 18,
  height: 18,
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
  borderRadius: '100%',
  background: colors.trueGreen,
  '&:hover': {
    cursor: 'pointer',
  },
})
