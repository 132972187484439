import React from 'react'
import { PageHeader as AntPageHeader } from 'antd'
import styled from '@emotion/styled'

export const DetailsPageTitleSection = ({
  onBack,
  title,
  subtitle,
  children,
}) => {
  return (
    <Wrapper>
      <PageHeader onBack={onBack} title={title} subTitle={subtitle || ''} />
      {children}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  padding: 10px 0px 0 25px;
`
const PageHeader = styled(AntPageHeader)`
  display: flex;
  width: calc(100% - 150px);
  background: transparent;
  padding: 0;
  .ant-page-header-back {
    margin: 5px 30px 10px 0;
  }
  .ant-page-header-heading-title {
    float: none;
    font-family: MyriadPro;
    font-size: 24px;
    color: #000;
    font-weight: normal;
  }
  .ant-page-header-heading-sub-title {
    float: none;
    font-size: 16px;
    color: #008200;
    text-transform: uppercase;
  }
  .ant-page-header-content,
  .ant-page-header-content-view {
    vertical-align: top;
    display: inline-block;
    padding-top: 0;
    padding-left: 55px;
    h3 {
      margin-bottom: 1px;
    }
  }
  .ant-divider-vertical {
    display: none;
  }
`
