import React, { useState, useEffect } from 'react'

import { ObjectivePannelCell, Modal, ModalSectionLabel, WarningMessage } from 'Components'
import { colors, formatNumberWithThousands, formatNumber } from 'Utils'

import { Input as AntInput, Checkbox as AntCheckbox } from 'antd'
import styled from '@emotion/styled'
import { isEmpty } from 'lodash'

export const ObjectivePannel = ({
  dataSource = {},
  originalRefValue,
  refValueType,
  onChange,
  adjusted,
  isTotal,
}) => {
  const valorRef = dataSource.valorRef || []
  const valorRefDetalle = dataSource.valorRefDetalle || []
  const valorPersonalizado = dataSource.valorPersonalizado || []
  const valorPersonalizadoDetalle = dataSource.valorPersonalizadoDetalle || []

  const valorRefValue = (!isEmpty(valorRef) && valorRef[0].valor.valor) || 0

  const [showModal, setShowModal] = useState(false)
  const [checked, setChecked] = useState(false)
  const [refValue, setRefValue] = useState(valorRefValue)

  useEffect(() => {
    if (adjusted) setRefValue('')
  }, [adjusted])

  useEffect(() => {
    setRefValue(valorRefValue)
  }, [valorRefValue])

  return (
    <Wrapper>
      <PannelInner>
        <ObjectivePannelCell
          dataSource={valorRef}
          title={valorRef.length > 0 ? valorRef[0].valor.titulo : ''}
          onEdit={() => setShowModal(true)}
        ></ObjectivePannelCell>
        <ObjectivePannelCell
          dataSource={valorRefDetalle}
          title="Resumen total"
          showBorder={true}
        ></ObjectivePannelCell>
        <ObjectivePannelCell
          dataSource={isTotal ? valorPersonalizado : valorPersonalizado.concat(valorPersonalizadoDetalle)}
          title={valorPersonalizado.length > 0 ? valorPersonalizado[0].valor.titulo : ''}
          backgroundColor="#e2f0c8"
        ></ObjectivePannelCell>
      </PannelInner>

      <Modal
        width="377px"
        visible={showModal}
        title="Editar valor referencia"
        okText="ACTUALIZAR"
        cancelText="CANCELAR"
        okButtonProps={{
          disabled:
            ((valorRefValue ===
              parseFloat(refValue.toString().replace(',', '.')) &&
              !adjusted) ||
              isNaN(parseFloat(refValue))) &&
            !checked,
        }}
        onCancel={() => {
          setShowModal(false)
          setChecked(false)
          if (adjusted) {
            setRefValue('')
          } else {
            setRefValue(valorRefValue)
          }
        }}
        onOk={() => {
          if (checked) {
            onChange(originalRefValue)
            setRefValue(originalRefValue)
          } else {
            onChange(refValue)
          }
          setShowModal(false)
          setChecked(false)
        }}
      >
        <ModalSectionLabel>Valor de referencia</ModalSectionLabel>
        <Input
          value={refValue}
          onChange={e =>
            setRefValue(
              formatNumber(e.target.value, refValueType === 'porcentaje'),
            )
          }
          disabled={checked}
        />
        {originalRefValue !== valorRefValue && (
          <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
            Restablecer valor de referencia original (
            {formatNumberWithThousands(originalRefValue)})
          </Checkbox>
        )}
        <WarningMessage>
          <span>
            Si actualizas el valor de referencia{' '}
            <strong>los ajustes que hayas hecho se perderán</strong>
          </span>
        </WarningMessage>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: fit-content;
  padding-right: 30px;
`

const Input = styled(AntInput)`
  margin-bottom: 8px;
`

const PannelInner = styled.div`
  max-height: 128px;
  white-space: nowrap;
  width: fit-content;
  box-shadow: 0 4px 12px 0 rgba(200, 198, 198, 0.5);
  background-color: ${colors.white};
  > :first-of-type {
    width: 295px;
  }
`
const Checkbox = styled(AntCheckbox)`
  display: block;
  font-size: 14px;
  line-height: 2.25;
  color: #686c71;
  margin: 0 0 8px 0;
  + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`
