import React, { Fragment } from 'react'

import {
  ModalSectionLabel,
  RadioGroup,
  YearPicker,
  WarningMessage,
} from 'Components'
import { dateIconStyle } from 'Utils';

import { Select, DatePicker } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled'
import moment from 'moment'

const { Option } = Select

export const ModalFormWhenToMeasure = ({
  options,
  showWhenCalendar,
  setShowWhenCalendar,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  whenOptionValue,
  setWhenOptionValue,
  whenValue,
  setWhenValue,
  year,
  setYear,
  showWarning,
  calendarWarnings,
  medidaId,
}) => {
  const {
    objetivoCuandoEvaluar = [],
    objetivoCuandoEvaluarFinPeriodo = [],
    objetivoCuandoOpcionesPeriodosVigencia = [],
  } = options

  let localWhenValue = whenValue === null ? null : whenValue !== 'M' ? 'F' : 'M';

  const disabledDate = current => {
    const currentYear = moment(`${year}/01/01`)
    return (
      current && (current < currentYear.startOf('year') || currentYear.endOf('year') < current)
    )
  }

  const editMoment = () => {
    const newStart = moment(startDate).format('MM/DD')
    const newEnd = moment(endDate).format('MM/DD')
    return [moment(`${year}/${newStart}`, 'YYYY/MM/DD'), moment(`${year}/${newEnd}`, 'YYYY/MM/DD')]
  }

  const checkFP = () => {
    if (whenValue === 'F') {
      setWhenValue('A')
    }
    return whenValue
  }

  const mainView = (
    <Fragment>
      <ModalSectionLabel>Periodo de vigencia de objetivo</ModalSectionLabel>
      <Select
        allowClear
        size="default"
        placeholder="Seleccionar periodo"
        onChange={value => {
          if (value === 0) {
            setShowWhenCalendar(true)
          } else {
            setWhenOptionValue(value)
          }
        }}
        style={{ width: '100%' }}
        showArrow={true}
        value={whenOptionValue}
      >
        {options &&
          objetivoCuandoOpcionesPeriodosVigencia.map((option, index) => {
            return (
              <Option key={index + 1} value={index + 1}>
                {option.desc}
              </Option>
            )
          })}
        <Option key={0} value={0}>
          Personalizar...
        </Option>
      </Select>
      <ModalSectionLabel>Evaluación del objetivo</ModalSectionLabel>
      <RadioGroup
        options={objetivoCuandoEvaluar}
        onChange={e => setWhenValue(e.target.value)}
        value={localWhenValue}
        label={option => <span>{option.desc}</span>}
      ></RadioGroup>

      {(whenValue !== null && whenValue !== 'M' && medidaId === 'N') && (
        <>
          <ModalSectionLabel>¿Cómo quieres evaluarlo?</ModalSectionLabel>
          <RadioGroup
            options={objetivoCuandoEvaluarFinPeriodo}
            onChange={e => setWhenValue(e.target.value)}
            value={checkFP()}
            label={option => <span>{option.desc}</span>}
          ></RadioGroup>
        </>)}

      {showWarning && (
        <WarningMessage>
          <span>
            Si se modifican estos valores se{' '}
            <strong>eliminarán los ajustes e incentivos del objetivo</strong>
          </span>
        </WarningMessage>
      )}
      {!!calendarWarnings.length && (
        <WarningMessage>
          <span>
            {`Es necesario calendario ${calendarWarnings
              .map((ano, i) => `${i ? ' y ' : ''}del año ${ano}`)
              .join(' ')}. `}
          </span>
          <strong>Revise y edite los calendarios</strong>
        </WarningMessage>
      )}
    </Fragment>
  )

  const setMyDay = (mom) => {
    let d = new Date(Date.UTC(mom.format('YYYY'), parseInt(mom.format('MM')) - 1, mom.format('DD'), 0, 0, 0));
    return d.getTime();
  }

  const calendarView = (
    <Fragment>
      <CenterPicker>
        <YearPicker
          year={year}
          minYear={moment().year() - 10}
          onIncrement={() => {
            setYear(year + 1)
            setStartDate(startDate + 31557600000)
            setEndDate(endDate + 31557600000)
          }}
          onDecrement={() => {
            setYear(year - 1)
            setStartDate(startDate - 31557600000)
            setEndDate(endDate - 31557600000)
          }}
        ></YearPicker>
      </CenterPicker>
      <StyledDatePicker
        size="large"
        value={editMoment()}
        disabledDate={disabledDate}
        onChange={(val) => {
          setStartDate(setMyDay(val[0]))
          setEndDate(setMyDay(val[1]))
        }}
        style={{ width: '100%' }}
        superPrevIcon={<DoubleLeftOutlined style={dateIconStyle} />}
        prevIcon={<LeftOutlined style={dateIconStyle} />}
        nextIcon={<RightOutlined style={dateIconStyle} />}
        superNextIcon={<DoubleRightOutlined style={dateIconStyle} />}
      />
    </Fragment>
  )
  return <Fragment>{showWhenCalendar ? calendarView : mainView}</Fragment>
}

const CenterPicker = styled.div`
  display: flex;
  justify-content: center;
`

const StyledDatePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  .ant-picker-input {
    padding-left: 35px;
  }
`