import React, { useContext } from 'react'
import { ReportEmbed } from 'Components'
import { GlobalContext } from 'Utils'
import { get } from 'lodash'

export const ReportsEvolution = ({ url, dashboardId }) => {

  const context = useContext(GlobalContext)
  const codDistribuidor = get(context, 'currentUser.codDistribuidor')

  return (
    <ReportEmbed idReport="evolution" currentUrl={url} dashboardId={dashboardId} params={{ codDistribuidor: codDistribuidor }}/>
  )
}
