import React from 'react'
import { Alert as AntAlert } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { colors } from 'Utils'

export const ObjectiveWarning = () => {
  return (
    <Alert
      message={
        <AlertMessage>
          El <strong>valor de referencia</strong> del objetivo ha sido{' '}
          <strong>ajustado</strong>
        </AlertMessage>
      }
      type="warning"
      showIcon
      icon={<StyledIcon style={{ color: '#686c71' }} />}
    />
  )
}

const Alert = styled(AntAlert)`
  border-radius: 5px;
  background-color: #ffe16a;
  padding: 7px;
  border: none;
  color: #686c71;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 18px;
`
const StyledIcon = styled(ExclamationCircleOutlined)`
  position: initial;
  top: initial;
  left: initial;
  vertical-align: middle;
  color: ${colors.white};
`

const AlertMessage = styled.span`
  padding-left: 5px;
  line-height: 0.94;
  letter-spacing: 0.29px;
`
