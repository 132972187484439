import React from 'react'
import {
  ObjectiveBoxPercentual,
  ObjectiveBoxNumeric,
  ObjectiveBoxIncentive,
  ObjectiveBoxOpen,
  ObjectiveBoxEuroType,
} from 'Components'
import { colors, months, colorTypeMap } from 'Utils'

import styled from '@emotion/styled'

export const ObjectiveMonthlyBox = ({
  kpi,
  year,
  onClick = null,
  isNotClickable,
}) => {
  let { diasLaborablesRestantes, idMedida = 'V', mes, inctvMinCaja, objetivoPendiente } = kpi

  const title = mes && `${months[mes - 1]} ${year}`
  const moveIncs = idMedida !== 'N' && idMedida !== 'S' && idMedida !== 'A' && !(diasLaborablesRestantes > 0 && objetivoPendiente > 0)

  const subtitleDays =
    (diasLaborablesRestantes &&
      diasLaborablesRestantes > 0 &&
      (diasLaborablesRestantes !== 1
        ? `Quedan ${diasLaborablesRestantes} días`
        : `Queda ${diasLaborablesRestantes} día`)) ||
    ''

  return (
    <Inner
      borderColor={colorTypeMap[idMedida]}
      onClick={onClick}
      isNotClickable={isNotClickable}
    >
      <FlexBox>
        <Title>{title}</Title>
        <Subtitle>{subtitleDays}</Subtitle>
      </FlexBox>
      {(idMedida === 'N' || idMedida === 'S') ? (
        <ObjectiveBoxNumeric kpi={kpi} />
      ) : idMedida === 'A' ? (
        <ObjectiveBoxOpen kpi={kpi} />
      ) : inctvMinCaja !== null ? (
        <ObjectiveBoxEuroType kpi={kpi} />
      ) : (
        <ObjectiveBoxPercentual kpi={kpi} />
      )}
      <IncentivePos moveIncs={moveIncs} >
        <ObjectiveBoxIncentive kpi={kpi} />
      </IncentivePos>
    </Inner>
  )
}

const Inner = styled.div`
  border-top: 4px solid ${props => props.borderColor};
  display: inline-block;
  width: 330px;
  height: 245px;
  margin: 0 25px 40px 25px;
  padding: 15px 20px;
  box-shadow: 0 4px 12px 0 rgba(200, 198, 198, 0.5);
  cursor: 'pointer';
  pointer-events: ${({ isNotClickable }) => (isNotClickable ? 'none' : 'auto')};
  position: relative;
`

const FlexBox = styled.span`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: flex-end;
`
const Title = styled.span`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.29px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.trueGreen};
`
const Subtitle = styled.span`
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: var(--black);
`

const IncentivePos = styled.div`
  position: absolute;
  bottom: ${props => (props.moveIncs ? '34' : '10')}px;
  right: 20px;
`