import React, { useEffect, useRef, useState, useContext } from 'react'
import { AutoComplete, Input } from 'antd'
import Icon from '@ant-design/icons'
import { ReactComponent as EditSvg } from 'Assets/icons/edit-icon.svg'
import { colors, GlobalContext } from 'Utils'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'
import { GET_LABELS } from 'Queries/ObjectiveDefinition'
import { isEmpty } from 'lodash'

export const ObjectiveTag = ({
  name,
  readOnly,
  update,
  onlyEditing = false,
  onBlur,
  ...props
}) => {
  const {
    currentUser: { codDistribuidor },
    isDev,
  } = useContext(GlobalContext)
  const [prevName, setPrevName] = useState(name)
  const [tagName, setTag] = useState(name)
  const [editing, setEdition] = useState(onlyEditing)
  const [tagsList, setTagsList] = useState([])
  const [tagsFiltered, filterTags] = useState([])
  const inputRef = useRef(null)

  const { data, refetch } = useQuery(GET_LABELS, {
    skip: isDev || readOnly,
    variables: { codDistribuidor },
  })

  useEffect(() => {
    if (editing && !onlyEditing) {
      inputRef.current.focus()
    }
  }, [editing])

  useEffect(() => {
    if (data && data.etiquetasDisponibles && isEmpty(tagsFiltered)) {
      setTagsList(data.etiquetasDisponibles.map((tag) => {
        return { value: tag, label: tag }
      }))
    }
  }, [data])

  useEffect(() => {
    const changeTag = async () => {
      try {
        if (!editing && tagName !== name) {
          await update(tagName)
          setPrevName(tagName)
        }
      } catch {
        setTag(prevName)
      }
    }
    changeTag()
  }, [editing, tagName])

  const toggleEdition = () => {
    !onlyEditing && !readOnly && setEdition(!editing)
  }

  const onSearchTag = text => {
    setTag(text)
    text.length > 0
      ? filterTags(
        tagsList.filter(
          tag => tag.value.includes(text)
        ),
      )
      : filterTags([])
  }

  const onSelectTag = tag => {
    tag = typeof tag === 'object' ? tag.currentTarget.value : tag
    toggleEdition()
    setTag(tag)
  }

  const updateTag = async () => {
    try {
      if (onBlur) {
        await onBlur(tagName)
        if (!isDev) refetch()
      } else {
        onSelectTag(tagName)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onFocus = () => {
    if (!isDev && refetch) refetch()
  }

  const EditIcon = (props) => <Icon component={EditSvg} {...props} />;

  return editing ? (
    <StyledAutoComplete
      defaultValue={tagName}
      options={tagsFiltered}
      onSearch={onSearchTag}
      onSelect={onSelectTag}
      onBlur={updateTag}
      onFocus={onFocus}
      ref={inputRef}
      {...props}
    >
      <Input.Search onPressEnter={onSelectTag} />
    </StyledAutoComplete>
  ) : (
    <Wrapper readOnly={readOnly}>
      <Name onClick={toggleEdition}>{tagName}</Name>
      {!readOnly && (
        <EditIcon style={{ fontSize: 16, color: colors.fernGreen }} />
      )}
    </Wrapper>
  )
}

const Name = styled.div`
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
`

const StyledAutoComplete = styled(AutoComplete)`
  width: 330px;
  font-size: 20px;
  input,
  input:focus {
    font-size: 16px;
    border: none;
    box-shadow: none;
  }
  span > i,
  span > i:hover {
    font-size: 16px;
    cursor: text;
    color: ${colors.fernGreen};
  }
  .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    min-width: 80px !important;
  }
  .ant-input {
    margin: 1px 0 auto 10px;
  }
  .ant-input-group .ant-input {
    width: 238px !important;
}
`

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: baseline;
  flex: 1;
  .anticon {
    opacity: 0.2;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    cursor: ${({ readOnly }) => (readOnly ? 'auto' : 'pointer')};
    .anticon {
      opacity: 1;
    }
  }
`
