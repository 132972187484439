import React from 'react'
import { Tree as AntTree } from 'antd'
import { ProviderRow } from 'Components'
import styled from '@emotion/styled'
import { colors, sort } from 'Utils'

export const ProviderTree = ({ dataSource, comercials, codDistribuidor, actions }) => {

  const newComercials = comercials.map(com => { return { codComercial: com.codComercial, codEmpleado: com.codEmpleado, nombre: com.nombre } })

  // Pintar comercial

  const generateComercialTree = (comercialesVisibles, comercials, actions, key) => {
    const nodeKey = `${key}-${comercials.codComercial}`
    const newComercial = {
      title: <ProviderRow key={nodeKey}
        dataSource={{ id: comercials.codComercial, nombre: comercials.nombre, nivel: 'comercial', comercialesVisibles }}
        actions={actions}
        nodeKey={nodeKey}
      />,
      key: nodeKey,
      style: { background: colors.white, borderBottom: `solid 1px ${colors.whiteTwo}`, width: '100%' },
    }

    return newComercial;
  }

  // Pintar Usuario HESA

  const generateHesaTree = (ds, comercials, actions, key) => {
    const nodeKey = `${key}-${ds.codEmpleado}`;
    const newEmployee = {
      title: <ProviderRow key={nodeKey}
        dataSource={{
          id: ds.codEmpleado,
          nombre: ds.nombreEmpleado,
          nivel: 'comercial_hesa',
          activo: ds.activo,
          email: ds.email,
          telefono: ds.telefono,
          lastLogin: ds.ultimoAcceso,
          username: ds.username,
          comercialesVisibles: ds.comercialesVisibles,
          comercials,
        }}
        actions={actions}
        nodeKey={nodeKey}
      />,
      key: nodeKey,
      style: { background: '#e4e4e4', borderBottom: `solid 1px ${colors.whiteTwo}`, width: '100%' },
      children: comercials && sort(comercials, 'codComercial').map(comercial => generateComercialTree(ds.comercialesVisibles, comercial, actions, nodeKey))
    }

    return newEmployee;
  }

  // Pintar proveedor

  const generateProviderTree = (ds, comercials, actions, key) => {
    let newTree = []
    ds.forEach((prv, index) => {
      const nodeKey = `${key}-${prv.codProveedor ? prv.codProveedor : ('xxxxx' + index)}`;
      const deletable = (!prv.usuariosHesa || (prv.usuariosHesa && prv.usuariosHesa.length < 1)) &&
        (!prv.infoObjetivos || (prv.infoObjetivos && prv.infoObjetivos.length < 1))
      const newProvider = {
        title: <ProviderRow key={nodeKey}
          dataSource={{ id: prv.codProveedor, nombre: prv.nombre, nivel: 'proveedor', infoObjetivos: prv.infoObjetivos, deletable }}
          actions={actions}
          nodeKey={nodeKey}
        />,
        key: nodeKey,
        style: { background: colors.trueGreen, width: '100%' },
        children: prv.usuariosHesa && sort(prv.usuariosHesa, 'codEmpleado').map(hijo => generateHesaTree(hijo, comercials, actions, nodeKey)),
      }

      newTree.push(newProvider);
    });

    return newTree;
  }

  return ((dataSource && (
    <Inner>
      <StyledTree
        defaultExpandAll
        treeData={generateProviderTree(
          dataSource,
          newComercials,
          actions,
          codDistribuidor)}
      />
    </Inner>
  )) ||
    null)
}

const StyledTree = styled(AntTree)`
  .ant-tree-switcher-icon {
    padding: 20px 10px;
  }
  .ant-tree-node-content-wrapper {
    width: 100%
  }
  .ant-tree-node-content-wrapper:hover {
    background-color: unset;
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: unset;
  }
`

const Inner = styled.div`
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
`
