import React from 'react'
import { SectionTitle } from 'Components'
import { includes } from 'lodash'

import styled from '@emotion/styled'
import { colors } from 'Utils'

const daysTildes = { miercoles: 'miércoles', sabado: 'sábado' }

export const DayPicker = ({
  days,
  disabled,
  onSelectDay,
  currentSelection,
  isYearPast,
}) => {
  return (
    <Wrapper isYearPast={isYearPast}>
      <SectionTitle>Días de preventa</SectionTitle>
      <Days>
        {days.map(day => {
          return (
            <Day
              disabled={disabled}
              key={day}
              onClick={disabled ? null : () => onSelectDay(day)}
              isPresale={
                includes(currentSelection, day) && <span>PREVENTA</span>
              }
            >
              <DayLabel>{daysTildes[day] || day}</DayLabel>
            </Day>
          )
        })}
      </Days>
    </Wrapper>
  )
}

const Wrapper = styled.div(({ isYearPast }) => ({
  opacity: isYearPast ? 0.3 : 1,
  pointerEvents: isYearPast ? 'none' : 'auto',
  width: 658,
  padding: 20,
  borderRadius: 4,
  boxShadow: '0 4px 7px 0 rgba(0, 0, 0, 0.1)',
  border: 'solid 1px rgba(0, 130, 0, 0.13)',
  backgroundColor: 'white',
}))

const Days = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const Day = styled.div(({ isPresale, disabled }) => ({
  flexGrow: 1,
  flexBasis: 100 / 7,
  borderRadius: 6,
  margin: 3,
  border: isPresale
    ? '#d3e4cb 1px solid'
    : `${disabled ? colors.greyish : colors.warmGrey} 1px solid`,
  background: isPresale ? '#d3e4cb' : 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 35,
  cursor: disabled ? 'not-allowed' : 'pointer',
  color: disabled ? colors.greyish : colors.greyishBrown,
}))

const DayLabel = styled.label({
  pointerEvents: 'none',
  fontSize: 12,
  display: 'block',
  margin: 0,
  padding: 0,
  color: 'inherit',
  textTransform: 'capitalize',
})
