import React, { Fragment } from 'react'
import { Modal, WarningMessage } from 'Components'
import styled from '@emotion/styled'

export const OptionGroupButton = ({
  label,
  sublabel,
  blocked,
  formView,
  modalProps,
  onOpen,
  ...rest
}) => {
  return !blocked ? (
    <Fragment>
      <Modal {...modalProps} width={`${modalProps.width || 440}px`}>
        {formView && formView()}
      </Modal>
      <Wrapper {...rest}>
        <OptionGroupLabel {...rest} onClick={onOpen}>
          {label}
        </OptionGroupLabel>
        <OptionsGroupSpacer></OptionsGroupSpacer>
        <OptionGroupSelection {...rest}>{sublabel}</OptionGroupSelection>
      </Wrapper>
    </Fragment>
  ) : (
    <Fragment>
      <Modal {...modalProps} width={`${modalProps.width || 440}px`}>
        {formView && formView()}
      </Modal>
      <Wrapper {...rest}>
        <OptionGroupLabel {...rest} onClick={onOpen}>
          {label}
        </OptionGroupLabel>
        <OptionsGroupSpacer></OptionsGroupSpacer>
        <Warning>
          <WarningMessage>
            No existe ningún especialista para el conjunto de categorías seleccionadas
          </WarningMessage>
        </Warning>
      </Wrapper>
    </Fragment>
  )
}

const Wrapper = styled.button(props => ({
  width: 772,
  height: 132,
  cursor: props.disabled ? 'default' : 'pointer',
  marginBottom: 20,
  borderRadius: 9,
  backgroundOrigin: 'border-box',
  border: props.selected ? 'solid 1px transparent' : 'solid 0px transparent',
  boxShadow: props.selected
    ? '2px 1000px 1px #fff inset'
    : '0 3px 5px 0 rgba(1, 102, 1, 0.47)',
  backgroundImage: props.selected
    ? 'linear-gradient(to left, #7ab800, #008200)'
    : props.disabled
      ? 'linear-gradient(293deg, #7ab80080, #00820080), linear-gradient(to bottom, rgba(0, 184, 0, 0.2), rgba(0, 130, 0, 0.2))'
      : 'linear-gradient(293deg, #7ab800, #008200), linear-gradient(to bottom, rgba(0, 184, 0, 0.2), rgba(0, 130, 0, 0.2))',
  outline: 'none',
}))

const OptionGroupLabel = styled.h3(props => ({
  fontSize: 28,
  textAlign: 'center',
  color: props.selected ? '#008200' : 'white',
}))

const OptionGroupSelection = styled.p(props => ({
  fontSize: 14,
  fontWeight: 600,
  color: props.selected ? '#008200' : 'white',
}))

const OptionsGroupSpacer = styled.div({
  height: 1,
  width: 427,
  margin: '0 auto 15px',
  background: '#7ab800',
})

const Warning = styled.div`
  width: 50%;
  margin: auto;
  > div {
    line-height: 1.2;
  }
`
