import React, { useState } from 'react'
import { Checkbox, HorizontalLine } from './shared'
import { concat, without } from 'lodash'
import styled from '@emotion/styled'
import { Input } from 'antd'

// Implement search
export const ComercialList = ({
  dataSource = [],
  selectedComercials,
  setSelectedComercials,
}) => {
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const comercialIds = dataSource.map(({ codEmpleado }) => codEmpleado)
  const allChecked = selectedComercials.length === dataSource.length

  const onSelectedCommercials = async selectedComercials => {
    setLoading(true)
    await setSelectedComercials(selectedComercials)
    setLoading(false)
  }

  return (
    <>
      <InputWrapper>
        <Input
          size="large"
          placeholder="Buscar comerciales"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </InputWrapper>
      {!dataSource.length ? (
        <p>No existen comerciales para seleccionar</p>
      ) : (
        <>
          <Checkbox
            checked={allChecked}
            style={{ display: 'flex' }}
            disabled={loading}
            onChange={() => {
              onSelectedCommercials(allChecked ? [] : [...comercialIds])
            }}
          >
            Seleccionar todos los comerciales
          </Checkbox>
          <HorizontalLine />
          <CheckboxList>
            {dataSource
              .filter(
                ({ nombreEmpleado: nombre }) =>
                  nombre.toLowerCase().indexOf(search) > -1 || search === '',
              )
              .map(({ codEmpleado, nombreEmpleado }, index) => {
                const checked = selectedComercials.indexOf(codEmpleado) > -1
                return (
                  <Checkbox
                    key={index}
                    style={{ display: 'flex' }}
                    checked={checked}
                    disabled={loading}
                    onChange={() => {
                      onSelectedCommercials(
                        checked
                          ? without(selectedComercials, codEmpleado)
                          : concat(selectedComercials, codEmpleado),
                      )
                    }}
                  >
                    {nombreEmpleado}
                  </Checkbox>
                )
              })}
          </CheckboxList>
        </>
      )}
    </>
  )
}

const InputWrapper = styled.div`
  margin-bottom: 15px;
`
const CheckboxList = styled.div`
  height: calc(100vh - 305px);
  overflow: auto;
  > label:first-of-type {
    margin-top: 14px;
  }
`
