import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Checkbox as AntCheckbox } from 'antd'

export const ObjectiveTitle = styled.span`
  font-size: 18px;
  line-height: 23px;
  color: ${colors.black};
`

export const ObjectiveSubtitle = styled.div`
  font-size: 14px;
  color: #757575;
`

export const ObjectiveDetail = styled.div`
  margin-bottom: 9px;
`

export const ObjectiveDetailSubtitle = styled.div`
  width: 100%;
  border-bottom: solid 1px #dedede;
  margin-bottom: 6px;
`

export const ObjectiveDetailLabel = styled.span`
  vertical-align: top;
  display: inline-block;
  min-width: 88px;
  font-size: 16px;
  line-height: 0.94;
  letter-spacing: 0.29px;
  color: ${colors.warmGrey};
`
export const ObjectiveDetailDescription = styled.div`
  width: calc(100% - 88px);
  vertical-align: top;
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.29px;
  color: #2b2b2b;
`

export const ObjectiveDetailCategories = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.black};
  margin-bottom: 12px;
`

export const DescriptionInner = styled.div`
  margin-bottom: 12px;
  line-height: 18px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
`

export const Breadcrumb = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.24px;
  color: ${colors.black};
  margin-bottom: 10px;
`

export const Checkbox = styled(AntCheckbox)`
  display: flex;
  font-size: 16px;
  line-height: 2.25;
  color: #686c71;
  padding-left: 20px;
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-inner::after {
    top: 45%;
    left: 30%;
  }
  + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`
export const HorizontalLine = styled.hr`
  border-top: none;
  border-color: #979797;
  margin-bottom: 0px;
`
export const CategoryList = styled.div``

export const Category = styled.div`
  height: 35px;
  background-color: ${props => (props.enabled ? '#008200' : '#eaeaea')};
  font-size: 16px;
  font-weight: 600;
  line-height: 0.94;
  letter-spacing: 0.29px;
  color: ${props => (props.enabled ? '#ffffff' : '#b3b3b3')};
  line-height: 38px;
  padding: 0 20px;
  margin-bottom: 1px;
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
`

export const BreadcrumbHome = styled.span`
  color: ${colors.trueGreen};
  cursor: pointer;
`

export const DraftSpan = styled.span`
  font-size: 15px;
  font-weight: 900;
  margin-right: 5px;
`

export const IconTag = styled.span`
  border: ${props => props.borderColor};;
  border-width: thin;
  border-radius: 7px;
  padding: 3px;
  border-style: solid;
  float: right;
  margin-right: 40px;
  margin-top: 7px;
`
