export const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

export const days = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];

export const colorList = [
  '#A5243E',
  '#5C8121',
  '#0267C2',
  '#FE960B',
  '#683B34',
  '#E393DD',
  '#747774',
  '#E0C133',
  '#2C6F6C',
  '#C62503',
];

// Filters arrays for KPIs and Summary table

export const agrupacionList = [
  { id: undefined, desc: '...' },
  { id: 'C', desc: 'Comercial' },
  { id: 'D', desc: 'Distribuidor' }
];

export const estadoList = [
  { id: undefined, desc: '...' },
  { id: 'ACTIVO', desc: 'Activo' },
  { id: 'VENCIDO', desc: 'Vencido' }
];

export const periodoList = [
  { id: undefined, desc: '...' },
  { id: 'F', desc: 'Fin de Período' },
  { id: 'M', desc: 'Mensual' }
];

export const minOkList = [
  { id: undefined, desc: '...' },
  { id: 'Y', desc: 'Conseguido' },
  { id: 'N', desc: 'No conseguido' }
];

// Arrays for KPIs

export const colorTypeMap = {
  V: '#02B698',
  U: '#14926B',
  F: '#AAD922',
  N: '#F6871D',
  B: '#744E39',
  M: '#109710',
  A: '#379CD8',
  S: '#6D5189',
  X: '#ADADAD',
};

export const unitTypeMap = {
  V: 'l.',
  U: 'uds.',
  F: 'eur.',
  N: 'PdVs',
  B: 'uds.',
  M: 'eur.',
  S: 'PdVs',
};

export const iconTypeMap = {
  V: 'drop',
  U: 'box',
  F: 'euro',
  N: 'bar',
  B: 'equivalentPackage',
  M: 'margin',
  A: 'hand',
  S: 'saturacion'

};

// Arrays for summary table

export const headerObj = [
  { label: 'Objetivo', key: 'desc' },
  { label: 'Nivel', key: 'rolEmpleado' },
  { label: 'Comercial', key: 'nombreEmpleado' },
];

export const headerCom = [
  { label: 'Comercial', key: 'nombreEmpleado' },
  { label: 'Nivel', key: 'rolEmpleado' },
  { label: 'Objetivo', key: 'desc' },
];

export const headerCommon = [
  { label: 'Tipo de Objetivo', key: 'objetivoUnidad' },
  { label: 'Fecha Periodo', key: 'periodo' },
  { label: 'Cantidad Objetivo', key: 'objAbs' },
  { label: 'Cantidad Actual', key: 'objetivoRealDia' },
];

export const headerNotHesa = [
  { label: '% Cumplimiento', key: 'porcCumplimientoMedidaRealDia' },
  { label: 'Incentivo Teórico', key: 'incentivoTeorico' },
  { label: 'Incentivo Real', key: 'incentivoDia' },
  { label: '% Incentivo', key: 'porcIncentivo' },
];

export const headerState = [
  { label: 'Estado', key: 'estado' },
];
