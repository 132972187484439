import styled from '@emotion/styled'
import { colors } from 'Utils'

export const SectionTitle = styled.h4({
  fontSize: 21,
})

export const PageTitle = styled.div(({ centered, objtxt }) => ({
  fontSize: 24,
  color: colors.black,
  textTransform: 'uppercase',
  marginBottom: objtxt && 16,
  margin: !objtxt && 16,
  textAlign: centered ? 'center' : 'left',
  width: centered ? '100%' : 'auto',
}))
