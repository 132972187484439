import React, { useRef, useState, useEffect } from 'react'

import { ObjectiveBoxQuery } from 'Components'
import { colors } from 'Utils'

import styled from '@emotion/styled'

export const ObjectiveBoxGroup = ({
  dataSource,
  title,
  isLoading,
  startPoint,
  elementsToLoad,
  setElementsToLoad,
  ...props
}) => {
  const [hasChildren, setHasChildren] = useState(true)
  const containerRef = useRef(null)
  const [isPainted, setIsPainted] = useState(false)
  const [goNext, setGoNext] = useState(true)

  useEffect(() => {
    const { current } = containerRef
    setHasChildren(isLoading || !!current.children.length)
  })

  const numberOfElements = elementsToLoad
    ? Math.max(0, elementsToLoad - startPoint)
    : undefined

  return (
    <div>
      {(isPainted && hasChildren) && <TabPaneSubtitle>{title}</TabPaneSubtitle>}
      <FlexContiner ref={containerRef}>
        {dataSource.slice(0, numberOfElements).map((objective, index) => {
          if (goNext) setGoNext(false)
          while (!goNext) {
            return (
              <ObjectiveBoxQuery
                onCompleted={() =>
                  setElementsToLoad(prevElementsToLoad => prevElementsToLoad + 1)
                }
                key={index}
                num={numberOfElements}
                setIsPainted={setIsPainted}
                isPainted={isPainted}
                objective={objective}
                goNext={goNext}
                setGoNext={setGoNext}
                {...props}
              />
            )
          }
        })}
      </FlexContiner>
    </div>
  )
}

const TabPaneSubtitle = styled.div`
  font-size: 24px;
  color: ${colors.fernGreen};
  border-bottom: solid 1px #c3c3c3;
  margin: 16px 0;
`

const FlexContiner = styled.div`
  margin: 0 -25px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`
