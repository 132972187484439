import React from 'react'
import styled from '@emotion/styled'
import { colors, formatNumberWithThousands } from 'Utils'
import { isEmpty } from 'lodash'

export const ObjectivePannelCell = ({
  dataSource = [],
  backgroundColor = '#ffffff',
  showBorder = false,
  title,
  onEdit,
}) => {
  if (isEmpty(dataSource)) return null
  return (
    <PannelCellInner backgroundColor={backgroundColor} showBorder={showBorder}>
      <PannelCellHeader>
        <Title>{title}</Title>
        {onEdit && <EditButton onClick={onEdit}>Editar</EditButton>}
      </PannelCellHeader>
      <PannelCellBody>
        {dataSource.map(({ valor, subtitulo1, subtitulo2 }, index) => (
          <CellValueInner key={index}>
            <CellValue>{`${
              valor.signo && valor.valor > 0 ? '+' : ''
            }${formatNumberWithThousands(valor.valor)}${
              valor.unidad
            }`}</CellValue>
            <CellDetails>{subtitulo1}</CellDetails>
            <CellDetails>{subtitulo2}</CellDetails>
          </CellValueInner>
        ))}
      </PannelCellBody>
    </PannelCellInner>
  )
}

const PannelCellInner = styled.div`
  display: inline-block;
  padding: 12px 20px;
  background-color: ${props => props.backgroundColor};
  border-left: ${props =>
    props.showBorder ? `6px solid ${colors.trueGreen}` : 'none'};
`

const EditButton = styled.span`
  margin-left: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.36;
  color: ${colors.trueGreen};
`

const PannelCellHeader = styled.div``
const PannelCellBody = styled.div`
  margin-top: 4px;
  > :first-of-type {
    padding-left: 0;
  }
  > :last-child {
    border-right: none;
  }
`

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: ${colors.black};
`

const CellValue = styled.div`
  font-size: 24px;
  line-height: 33px;
  font-weight: 600;
  letter-spacing: 0.44px;
  color: ${colors.pine};
`

const CellDetails = styled.div`
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.29px;
  color: #555555;
`

const CellValueInner = styled.div`
  display: inline-block;
  border-right: 2px solid ${colors.trueGreen};
  padding: 0 20px;
  min-width: 180px;
`
