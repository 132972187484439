import React from 'react'
import styled from '@emotion/styled'
import { Modal as AntModal } from 'antd'
import { colors } from 'Utils'
import { ModalTitle } from './'

export const Modal = ({
  title,
  subtitle,
  children,
  width,
  cancelText,
  BttnProps,
  ...props
}) => {
  return (
    <ModalWrapper
      width={width || '460px'}
      cancelText={cancelText || 'Cancelar'}
      cancelButtonProps={{ type: 'link', visible: 0 }}
      closable={false}
      centered
      title={<ModalTitle title={title} subtitle={subtitle} />}
      okButtonProps={BttnProps || undefined}
      {...props}
    >
      {children}
    </ModalWrapper>
  )
}

const ModalWrapper = styled(AntModal)`
  .ant-btn.ant-btn-primary {
    font-size: 12px;
    line-height: 1.83;
    color: ${colors.white};
    text-transform: uppercase;
    font-weight: 600;
  }
  .ant-btn.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.5);
  }

  .ant-modal-body {
    padding: 12px 32px 32px;
    overflow: auto;
  }
  .ant-modal-header {
    padding: 24px 32px 16px;
  }
  .ant-modal-footer {
    padding: 10px 32px;

    .ant-btn.ant-btn-link {
      font-size: 12px;
      line-height: 1.83;
      color: #008200;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
`
