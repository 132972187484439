import React from 'react'

import { rounded } from 'Utils'

import styled from '@emotion/styled'

export const ActionPlanTable = ({
  dataSource,
  rowHeaders,
  headers,
  header = true,
  backgroundColors,
  boldRows,
}) => {
  return (
    <>
      <ColHeaderWrapper header={header}>
        {rowHeaders.map((name, i) => (
          <ColHeader
            color={backgroundColors[i]}
            bold={boldRows.indexOf(i) > -1}
          >
            {name}
          </ColHeader>
        ))}
      </ColHeaderWrapper>
      <TableWrapper>
        {header && (
          <TableHeaderRow>
            {headers.map(name => (
              <HeaderCel>{name}</HeaderCel>
            ))}
          </TableHeaderRow>
        )}
        {dataSource.map((value, i) => {
          delete value.__typename
          return (
            <TableRow color={backgroundColors[i]}>
              {Object.keys(value).map(key => (
                <Cel negative={value[key] < 0}>
                  {key.indexOf('porc') > -1
                    ? rounded(value[key], true) + '%'
                    : rounded(value[key], true)}
                </Cel>
              ))}
            </TableRow>
          )
        })}
      </TableWrapper>
    </>
  )
}

const ColHeader = styled.div`
  font-size: 16px;
  font-weight: ${props => (props.bold ? 600 : 'normal')};
  padding-left: 22px;
  color: #555555;
  height: 40px;
  line-height: 42px;
  background-color: ${props => props.color || 'white'};
  border-bottom: solid 1px #c6cc2d;
`
const ColHeaderWrapper = styled.div`
  display: inline-block;
  margin-top: ${props => (props.header ? 36 : 0)}px;
  width: 276px;
  min-width: 276px;
  box-shadow: 0 4px 12px 0 rgba(200, 198, 198, 0.5);
  > :last-child {
    border-bottom: solid 1px #7ab800;
  }
`
const TableWrapper = styled.div`
  display: inline-block;
  flex-grow: 1;
  box-shadow: 0 4px 12px 0 rgba(200, 198, 198, 0.5);
  background-color: var(--white);
  > :last-child {
    border-bottom: solid 1px #7ab800;
  }
`
const TableRow = styled.div`
  padding-right: 60px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.color || 'white'};
  border-bottom: solid 1px #c6cc2d;
  height: 40px;
  line-height: 42px;
`
const TableHeaderRow = styled.div`
  line-height: 36px;
  height: 36px;
  padding-right: 60px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  background-color: white;
`
const Cel = styled.span`
  font-size: 16px;
  color: ${props => (props.negative ? 'red' : '#686c71')};
  text-align: right;
  width: 107px;
`

const HeaderCel = styled(Cel)`
  white-space: nowrap;
  font-weight: 600;
  color: var(--pine);
`
