import React, { useContext, useState } from 'react'

import { ObjectiveTag } from 'Components'
import { UPDATE_OBJECTIVE_NAME, UPDATE_OPEN_OBJECTIVE_NAME, OBJECTIVE, OPEN_OBJECTIVE } from 'Queries'
import {
  GlobalContext,
  ObjectiveContext,
  getMutationVars,
  colors,
  getObjectiveIdVars,
} from 'Utils'

import { Input as AntInput } from 'antd'
import Icon from '@ant-design/icons'
import styled from '@emotion/styled'
import { get } from 'lodash'
import { useMutation } from '@apollo/client'

import { ReactComponent as EditSvg } from 'Assets/icons/edit-icon.svg'

export const ObjectiveNameAndTag = ({ objective }) => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const [nombre, setName] = useState(get(objective, 'nombre') || '')

  const [updateName] = useMutation(
    get(objective, 'medida.id') === 'A' ? UPDATE_OPEN_OBJECTIVE_NAME : UPDATE_OBJECTIVE_NAME,
    getMutationVars(getObjectiveIdVars(context), get(objective, 'medida.id') === 'A' ? OPEN_OBJECTIVE : OBJECTIVE, {
      onCompleted: ({ objective }) => {
        context.setObjective(objective)
        context.resetKeepAliveTimer()
      },
    }),
  )

  const EditIcon = (props) => <Icon component={EditSvg} {...props} />;

  return (
    <Wrapper>
      <Field>
        <Input
          placeholder="Introduzca nombre de objetivo a crear"
          value={nombre}
          onChange={e => setName(e.target.value.toString().substr(0, 64).replaceAll('\'', '´'))}
          onBlur={() =>
            updateName({
              variables: { etiqueta: context.objective.etiqueta, nombre },
            })
          }
        ></Input>
        <EditIcon style={{ fontSize: 16, color: colors.fernGreen }} />
      </Field>
      <Field>
        <ObjectiveTag
          name={objective.etiqueta}
          placeholder="Escriba o busque etiqueta"
          onChange={
            e => 
             {
              let rename = e.toString().replaceAll('\'', '´')
              objective.etiqueta = rename
              return updateName({ variables: { etiqueta: rename, nombre } })
            }
            
          }
          onlyEditing
          onBlur={ (etiqueta) => 
            {
              let rename = etiqueta.toString().replaceAll('\'', '´')
              objective.etiqueta = rename
              return updateName({ variables: { etiqueta: rename, nombre } })
            }
          }
        />
      </Field>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  position: 'relative',
  top: -35,
  width: 'auto',
  height: 80,
  padding: '15px 15px 0 15px',
  borderRadius: 18,
  boxShadow: '0 2px 17px 0 rgba(0, 0, 0, 0.19)',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
})
const Field = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .ant-input {
    color: #205527;
    text-align: center;
    margin: auto 10px;

    &:focus {
      box-shadow: 0 0 0 2px rgba(70, 122, 60, 0.2);
    }
  }
  .ant-select-selection__placeholder {
    font-size: 16px;
    text-align: center;
    margin-top: -6px;
  }
`
const Input = styled(AntInput)({
  border: 'none',
  width: 480,
  margin: 'auto 10px',
  fontSize: 24,
  textAlign: 'center',
})
