import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'
import { WarningMessage } from 'Components'

export const showDeleteConfirm = (name, onOk, isHesa = false) => {
  Modal.confirm({
    title: <ConfirmTitle>Borrar perfil</ConfirmTitle>,
    content: (
      <div>
        <p>El perfil de <b>{name}</b> será borrado de tu {isHesa ? 'jerarquía de proveedores' : 'jerarquía comercial'} </p>
        {!isHesa && (<WarningMessage>
          <span>
            Cualquier variación en la estructura de la jerarquía afectará a los
            objetivos existentes
          </span>
        </WarningMessage>)}
      </div>
    ),
    icon: null,
    centered: true,
    okText: 'BORRAR',
    okButtonProps: {
      type: 'danger',
    },
    cancelText: 'CANCELAR',
    cancelButtonProps: {
      type: 'link',
    },
    onOk,
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
