import gql from 'graphql-tag'

export const INFO_HESA_FRAGMENT = gql`
  fragment infoHesaFragment on InfoHesa {
      isHesa
      nivel
      codEmpleado
      codDistribuidor
      nombre
  }
`

export const GET_KPI_HESA_DEALERS = gql`
  query obtenerDistribuidores($codEmpleadoHesa: Long!) {
    InfoHesa: obtenerDistribuidores(codEmpleadoHesa: $codEmpleadoHesa) {
      ...infoHesaFragment
    }
  }
  ${INFO_HESA_FRAGMENT}
`


export const CLEAN_INFO_HESA_CACHE = gql`
  query limpiarCacheInfoHesa {
    limpiarCacheInfoHesa
  }
`

export const SAVE_INFO_HESA = gql`
  query saveInfoHesa(
    $codigoEmpleadoHesa: Long!
    $infoHesa: InfoHesaInput!
  ) {
    InfoHesa: saveInfoHesa(
      codigoEmpleadoHesa: $codigoEmpleadoHesa
      infoHesa: $infoHesa
    ) {
      ...infoHesaFragment
    }
  }
  ${INFO_HESA_FRAGMENT}
`
