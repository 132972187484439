import React from 'react'
import styled from '@emotion/styled'
import { Alert, Empty as AntdEmpty } from 'antd'
import { Spinner } from 'Components'
import { colors } from 'Utils'

export const ResourceWrapper = ({
  resource: { data, loading, error },
  isLoading = false,
  noData = 'No hay datos',
  children,
  noManage,
}) => {
  if (noManage) return children
  if (loading) {
    return <Spinner />
  }
  if (error) {
    const message = error.message || 'Algo ha fallado.'
    return <Alert message="Error" description={message} type="error" showIcon />
  }
  if (!data || Object.keys(data).length < 1) {
    return (noData && <Empty description={noData} />) || null
  }
  return (
    <>
      {isLoading ? <Spinner style={{ marginTop: '-35px' }} /> : null}
      {children}
    </>
  )
}

const Empty = styled(AntdEmpty)`
  margin: 50px;
  font-size: 24px;
  color: ${colors.whiteTwo};
`
