import React, { useEffect, useContext, useState } from 'react'

import { ResourceWrapper } from 'Components'
import { ObjectiveLayout } from 'Fragments'
import { CREATE_DRAFT_OBJECTIVE, COPY_OBJECTIVE } from 'Queries'
import { GlobalContext, ObjectiveContextProvider } from 'Utils'

import { get, isNil } from 'lodash'
import { useMutation } from '@apollo/client'

export const ObjectiveNew = ({
  children,
  navigate,
  type,
  year,
  id: idObjetivo,
}) => {
  const [objective, setObjective] = useState({})
  const [hasArticles, setHasArticles] = useState(true)
  const [hasExceptions, setHasExceptions] = useState(true)
  const [hasClients, setHasClients] = useState(true)
  const [recalculating, setRecalculating] = useState(false)
  const [{ anyo, id }, setURLparams] = useState({ anyo: year, id: idObjetivo })
  const context = useContext(GlobalContext)
  const codDistribuidor = get(context, 'currentUser.codDistribuidor')

  const updateObjective = objective =>
    setObjective(({ hasArticles, hasExceptions, hasClients, recalculating }) => ({
      ...objective,
      hasArticles,
      hasExceptions,
      hasClients,
      recalculating,
    }))

  const onCompleted = ({ objective }) => setObjective(objective)

  const [createEmptyObjective, emptyResource] = useMutation(
    CREATE_DRAFT_OBJECTIVE,
    {
      variables: {
        codDistribuidor,
      },
      onCompleted,
    },
  )

  const [copyObjective, copyResource] = useMutation(COPY_OBJECTIVE, {
    variables: {
      id: {
        codDistribuidor,
        ano: anyo,
        codObjetivo: id,
      },
    },
    onCompleted,
  })

  useEffect(() => {
    switch (type) {
      case 'new':
        createEmptyObjective()
        break
      case 'copy':
        copyObjective()
        break

      default:
        break
    }
  }, [])

  useEffect(() => {
    setObjective(prevObjective => ({
      ...prevObjective,
      hasArticles,
      hasExceptions,
      hasClients,
      recalculating,
    }))
  }, [hasArticles, hasExceptions, hasClients, recalculating])

  useEffect(() => {
    if (anyo !== year && type === 'edit')
      navigate(`/objective/edit/${id}/${anyo}/definition`)
  }, [anyo, id])

  let resource =
    (emptyResource.data && emptyResource) ||
    (copyResource.data && copyResource) ||
    {}

  if (type === 'edit') {
    resource = {
      data: {
        objective: {
          ano: anyo,
          codObjetivo: id,
        },
      },
    }
  }

  const ano = get(resource, 'data.objective.ano')
  const codObjetivo = get(resource, 'data.objective.codObjetivo')

  return (
    <ObjectiveContextProvider
      value={{
        objective,
        setObjective: updateObjective,
        setHasArticles,
        setHasExceptions,
        setHasClients,
        setRecalculating,
        changeURLParams: setURLparams,
      }}
    >
      <ResourceWrapper resource={resource}>
        {!isNil(ano) && !isNil(codObjetivo) && (
          <ObjectiveLayout
            ano={ano}
            codObjetivo={codObjetivo}
            navigate={navigate}
            type={type}
            objective={objective}
          >
            {children}
          </ObjectiveLayout>
        )}
      </ResourceWrapper>
    </ObjectiveContextProvider>
  )
}
