import React from 'react'
import { Row, Col } from 'antd'
import { Icon } from '@ant-design/compatible'
import { Button } from 'Components'
import moment from 'moment'
import { formatNumberWithThousands, months, rounded, plurals } from 'Utils'
import { CSVLink } from "react-csv";
import styled from '@emotion/styled'
import { headerCom, headerCommon, headerNotHesa, headerObj, headerState } from './commonArrays'

const setUpArray = (aL, filter) => {
  let fullList = [];

  for (let i = 0; i < aL.length; i++) {
    for (let j = 0; j < aL[i].empleadosGlobal.length; j++) {

      const isMensual = aL[i].empleadosGlobal[j].cuandoEvaluar.id === 'M';
      const emplMedida = aL[i].empleadosGlobal[j].idMedida
      const objAbs = isMensual ?
        aL[i].empleadosGlobal[j].objetivoMes :
        aL[i].empleadosGlobal[j].objetivoFP;

      let rowDates = [aL[i].empleadosGlobal[j].fechaDesde, aL[i].empleadosGlobal[j].fechaHasta]
      if (isMensual) {
        rowDates = buildMonthDates(aL[i].empleadosGlobal[j].mes, aL[i].empleadosGlobal[j].fechaDesde, aL[i].empleadosGlobal[j].fechaHasta)
      }

      const newRow = {
        desc: isMensual ? `${aL[i].desc} [${months[aL[i].empleadosGlobal[j].mes - 1]}]` : aL[i].desc,
        estado: aL[i].estado,
        mes: aL[i].empleadosGlobal[j].mes,
        codEmpleado: aL[i].empleadosGlobal[j].codEmpleado,
        rolEmpleado: aL[i].empleadosGlobal[j].nivelEmpleado.id,
        nombreEmpleado: aL[i].empleadosGlobal[j].nombreEmpleado,
        objetivoUnidad: aL[i].withException ? `${aL[i].empleadosGlobal[j].objetivoUnidad} c/excepciones` :
          aL[i].inctvMinCaja !== null ? `€/${plurals(aL[i].empleadosGlobal[j].objetivoUnidad)}` :
            emplMedida === 'S' ? 'Saturación' : aL[i].empleadosGlobal[j].objetivoUnidad,


        periodo: `${moment(rowDates[0]).format('DD/MM/YY')} - ${moment(rowDates[1]).format('DD/MM/YY')}`,
        objAbs: emplMedida === 'A' ? '-' : rounded(objAbs),
        objetivoRealDia: emplMedida === 'A' ? '-' : rounded(aL[i].empleadosGlobal[j].objetivoRealDia),
        porcCumplimientoMedidaRealDia: (emplMedida === 'A' && aL[i].empleadosGlobal[j].nivelEmpleado.id === 'GE') ? '-' :
          `${formatNumberWithThousands(Number(aL[i].empleadosGlobal[j].porcCumplimientoMedidaRealDia).toFixed(1), true)}%`,
        // `${rounded(aL[i].empleadosGlobal[j].porcCumplimientoMedidaRealDia, false, 1)}%`,
        incentivoTeorico: aL[i].inctvMinCaja !== null ? '-' : rounded(aL[i].empleadosGlobal[j].incentivoTeorico),
        incentivoDia: rounded(aL[i].empleadosGlobal[j].incentivoDia),
        porcIncentivo: aL[i].inctvMinCaja !== null ? '-' : `${rounded((retVal(aL[i].empleadosGlobal[j].incentivoDia, 0, false) * 100) / retVal(aL[i].empleadosGlobal[j].incentivoTeorico === 0 ? 1 : aL[i].empleadosGlobal[j].incentivoTeorico, 1, false), 1)}%`,
        distribucionValorRef: aL[i].empleadosGlobal[j].distribucionValorRef,
        idMedida: emplMedida,
        idCuandoEvaluar: aL[i].empleadosGlobal[j].cuandoEvaluar.id,
        idObj: `${aL[i].codObjetivo}.${aL[i].empleadosGlobal[j].codEmpleado}.${j}`,
      };

      const filterOK = checkFilters(newRow, rowDates[0], rowDates[1], filter)

      if (filterOK) {
        fullList.push(newRow)
      }
    }
  }
  return fullList.sort(compare);
}

export const groupListBy = (list, value, filter = {}) => {
  let fullList = setUpArray(list, filter);

  const listByValue = fullList.reduce(function (hash, todo) {
    if (!hash.hasOwnProperty(todo[value])) hash[todo[value]] = [];
    hash[todo[value]].push(todo);
    return hash;
  }, {});

  const groupedByTodo = Object.keys(listByValue).map(function (key) {
    return listByValue[key];
  });

  return groupedByTodo;
}

function compare(a, b) {
  let newA = a.porcCumplimientoMedidaRealDia.replace('.', '').replace(',', '.')
  let newB = b.porcCumplimientoMedidaRealDia.replace('.', '').replace(',', '.')

  if (parseInt(newA) > parseInt(newB)) {
    return -1;
  }
  if (parseInt(newA) < parseInt(newB)) {
    return 1;
  }
  return 0;
}

function retVal(value, res, format = true) {
  if (value === null) {
    return res;
  }
  if (format) {
    return formatNumberWithThousands(value, 1);
  }
  return parseFloat(value).toFixed(parseFloat(value) % 1 !== 0 ? 2 : 0);
}


const buildMonthDates = (mes, desde, hasta) => {
  const year = moment(desde).year();
  let newDates = []

  if (moment(desde).month() === (mes - 1)) {
    newDates[0] = desde
    newDates[1] = moment(`${year}/${mes}/01`).endOf('month')
  } else if (moment(hasta).month() === (mes - 1)) {
    newDates[0] = moment(`${year}/${mes}/01`).startOf('month')
    newDates[1] = hasta
  } else {
    newDates[0] = moment(`${year}/${mes}/01`).startOf('month')
    newDates[1] = moment(`${year}/${mes}/01`).endOf('month')
  }

  return newDates
}

const checkFilters = (row, desde, hasta, { tipo, cuando, fechas, idMedida, empleado, estado }) => {
  const currentMonth = moment().month();

  return row &&
    (!estado || estado === row.estado) &&
    (!cuando || cuando === row.idCuandoEvaluar) &&
    (!tipo || tipo === row.distribucionValorRef.id) &&
    ((!fechas || !fechas.length ||
      (moment(desde).isBefore(fechas[1]) && moment(hasta).isAfter(fechas[0]))) &&
      (moment(desde).year() < moment().year() || !(row.idCuandoEvaluar === 'M' && currentMonth < moment(desde).month())) // Si el obj es mensual no se muestren meses superiores si es este año 
      // !(row.idCuandoEvaluar === 'M' && currentMonth < moment(desde).month())
    ) &&
    (!empleado || row.codEmpleado === empleado) &&
    (!idMedida || !idMedida.length || idMedida.includes(row.idMedida))
  // (row.idMedida === 'A' || (row.idMedida !== 'A' && (row.rolEmpleado !== 'JV' && row.rolEmpleado !== 'JE')))
}


const printLoop = (body) => {
  let fullBody = [];
  let newRow;

  for (let i = 0; i < body.length; i++) {
    for (let j = 0; j < body[i].length; j++) {
      newRow = body[i][j];
      fullBody.push(newRow)
    }
  }
  return fullBody
}

export const getEmpleadosAndNiveles = (kpis, nivel) =>
  kpis.reduce(
    (result, kpi) => {
      kpi.empleadosGlobal.forEach(empleado => {
        if (!nivel || nivel === empleado.nivelEmpleado.id) {
          result.empleadosGlobal[empleado.codEmpleado] = empleado
          result.niveles[empleado.nivelEmpleado.id] = empleado.nivelEmpleado
        }
      })
      return result
    },
    { empleadosGlobal: {}, niveles: {} },
  )

export const summaryHeader = (type, isHesa) => {
  let header = []

  if (type === 'comerciales') {
    header = headerCom
  } else {
    header = headerObj
  }
  header = [...header, ...headerCommon]
  if (!isHesa) { header = [...header, ...headerNotHesa] }
  header = [...header, ...headerState]

  return header;
}

export const DownloadButton = ({ header, body, loading, setPauser, setStopper, stopper, isShort = false, remaining = 0, call = () => { } }) => (
  <Row>
    <Col span={3} style={{ position: "relative", margin: isShort ? '10px 0 0 -30px' : '35px 0 -5px 20px' }}>
      <CSVLink
        headers={header}
        data={printLoop(body)}
        filename={"Objetives.csv"}
        className="btn btn-primary"
        target="_blank"
        separator={";"}
      >
        <Button disabled={body.length === 0} loading={loading} type="primary" style={{ marginLeft: "42px", borderRadius: isShort ? "5px" : "0px 5px 5px 0px" }} >
          Descargar CSV
        </Button>
      </CSVLink>
      {!isShort && (
        <StyledBtn onClick={
          () => {
            if (stopper && remaining > 0) {
              setPauser(false)
              call()
            }
            setStopper(!stopper)
          }}
          disabled={(loading && stopper) || remaining <= 0}
        >
          <Icon type={stopper ? "caret-right" : "pause"} style={{ fontSize: '17px' }} />
        </StyledBtn>
      )}
    </Col>
  </Row >
)

const StyledBtn = styled.button`
  position: absolute;
  height: 40px;
  top: 0;
  background: white;
  color: darkgray;
  font-size: 20px;
  border-color: lightgray;
  border-width: 1px;
  padding: 4px 12px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  &:disabled {
    color: lightgray;
    background: #f3f3f3;
    border-color: #e9e9e9;
    cursor: not-allowed;
  }
`;
