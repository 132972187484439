export const objectivesListMock = {
  data: {
    objetivos: [
      {
        codObjetivo: 0,
        ano: 2020,
        draft: false,
        nombre: 'Objetivo 2020',
        etiqueta: 'Nuevos Objetivos',
        idDesc: '2020-0',
        ajustado: false,
        comoDesc: 'Aumenta 1.000 unidades en total. Aplica a cada comercial.',
        modifEn: 1580120045224,
        medida: {
          id: 'U',
          desc: 'Unidades',
          unidad: 'unidades',
          __typename: 'ObjetivoQueMedida',
        },
        tiposEntrega: [
          {
            id: 0,
            __typename: 'ObjetivoQueTipoEntrega',
          },
        ],
        tipoValorRef: {
          id: 'T',
          desc: 'Cantidad total',
          __typename: 'ObjetivoComoTipoValorRef',
        },
        valorRef: {
          valor: 1000,
          tipoDeUnidad: 'cantidad',
          __typename: 'ObjetivoComoValorRef',
        },
        distribucionValorRef: {
          id: 'C',
          desc: 'A cada comercial',
          nota:
            'El valor de referencia se aplicará de manera individual a cada comercial asociado al objetivo',
          __typename: 'ObjetivoComoDistribucionValorRef',
        },
        cuandoEvaluar: {
          id: 'F',
          desc: 'Al final del objetivo',
          __typename: 'ObjetivoCuandoEvaluar',
        },
        periodoVigencia: {
          fechaInicio: 1577836800000,
          fechaFin: 1609372800000,
          __typename: 'ObjetivoCuandoPeriodoVigencia',
        },
        categoriasSeleccionadasArticulo: {
          categorias: [
            {
              categoria: {
                id: 'FML',
                desc: 'Familia',
                __typename: 'ObjetivoCategoria',
              },
              // valores: [],
              valores: [
                {
                  nombre: 'Amarillo',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'Cerveza',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'Ahtanum',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
              ],
              __typename: 'ObjetivoCategoriaValores',
            },
          ],
          excepciones: [
            {
              codDistribuidor: 7103803,
              codigo: '5675',
              desc: '15 CL. DESPERADOS F. P. 24 UD.',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '5730',
              desc: 'AFFLIGEM DUBBEL 75 CL. 12 UNID',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '5741',
              desc: 'AFFLIGEM TRIPEL 75 CL. 12 UNID',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '9279',
              desc: 'AFFLIGEM BLOND 30 CL. FP 24 UD',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '5474',
              desc: '25 CL. DESPERADOS F.P. 24 UNID',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '5729',
              desc: 'AFFLIGEM BLOND 75 CL. 12 UD.',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '9280',
              desc: 'AFFLIGEM DUBBLEL 30 CL. FP 24U',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '9281',
              desc: 'AFFLIGEM TRIPEL 30 CL. FP 24U',
              __typename: 'Excepcion',
            },
          ],
        },
        categoriasSeleccionadasCliente: {
          categorias: [
            {
              categoria: {
                id: 'FML',
                desc: 'Familia',
                __typename: 'ObjetivoCategoria',
              },
              // valores: [],
              valores: [
                {
                  nombre: 'Amarillo',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'Cerveza',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'Ahtanum',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
                {
                  nombre: 'This is another article',
                  seleccionado: true,
                  activado: true,
                  __typename: 'ObjetivoCategoriaValor',
                },
              ],
              __typename: 'ObjetivoCategoriaValores',
            },
          ],
          excepciones: [
            {
              codDistribuidor: 7103803,
              codigo: '5675',
              desc: '15 CL. DESPERADOS F. P. 24 UD.',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '5730',
              desc: 'AFFLIGEM DUBBEL 75 CL. 12 UNID',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '5741',
              desc: 'AFFLIGEM TRIPEL 75 CL. 12 UNID',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '9279',
              desc: 'AFFLIGEM BLOND 30 CL. FP 24 UD',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '5474',
              desc: '25 CL. DESPERADOS F.P. 24 UNID',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '5729',
              desc: 'AFFLIGEM BLOND 75 CL. 12 UD.',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '9280',
              desc: 'AFFLIGEM DUBBLEL 30 CL. FP 24U',
              __typename: 'Excepcion',
            },
            {
              codDistribuidor: 7103803,
              codigo: '9281',
              desc: 'AFFLIGEM TRIPEL 30 CL. FP 24U',
              __typename: 'Excepcion',
            },
          ],
        },
        comercialesSeleccionados: [7351, 7501, 7101],
        vigencia: 'activos',
        ajustadoIncentivo: true,
        __typename: 'Objetivo',
      },
    ],
  },
}
