export const mapeoObjetivoSACRes = {
  data: {
    mapeoObjetivoSAC: {
      idMedida: 'V',
      urlGerencia:
        'https://heineken-es.eu1.sapanalytics.cloud/sap/fpa/ui/tenants/002/bo/story/123BBAEEC4F3814485C13D4FACD2F335?mode=embed&pageBar=disable&v01Model=t.5:C5qi0cuzvodxdbf9uzkgiy04cg&v01Par=IP_ANO&v01Val=2019&v02Par=IP_CODIGO_OBJETIVO&v02Val=44&v03Par=IP_MES&v03Val=10&v04Model=t.5:Cc030t5n4ceg0c3phtz4ev0r28&v04Par=IP_ANO&v04Val=2019&v05Par=IP_CODIGO_OBJETIVO&v05Val=44&v06Par=IP_MES&v06Val=10&v07Model=t.5:Cj7olafgmzbn5t81wimdutedc&v07Par=IP_ANO&v07Val=2019&v08Par=IP_CODIGO_OBJETIVO&v08Val=44&v09Par=IP_MES&v09Val=10',
      urlComercial: null,
      urlIncentivo:
        'https://heineken-es.eu1.sapanalytics.cloud/sap/fpa/ui/tenants/002/bo/story/123BBAEEC4F3814485C13D4FACD2F335?mode=embed&pageBar=disable&v01Model=t.5:C5qi0cuzvodxdbf9uzkgiy04cg&v01Par=IP_ANO&v01Val=2019&v02Par=IP_CODIGO_OBJETIVO&v02Val=44&v03Par=IP_MES&v03Val=10&v04Model=t.5:Cc030t5n4ceg0c3phtz4ev0r28&v04Par=IP_ANO&v04Val=2019&v05Par=IP_CODIGO_OBJETIVO&v05Val=44&v06Par=IP_MES&v06Val=10&v07Model=t.5:Cj7olafgmzbn5t81wimdutedc&v07Par=IP_ANO&v07Val=2019&v08Par=IP_CODIGO_OBJETIVO&v08Val=44&v09Par=IP_MES&v09Val=10',
      nombre: 'VOLUMEN: Incremental % sobre AA por Mes Por distribuidor',
      fechaDesde: 1548979200000,
      fechaHasta: 1577750400000,
      comerciales: [
        { codComercial: 0, nombreComercial: 'Todos', __typename: 'Comercial' },
        {
          codComercial: 9237759,
          nombreComercial: 'Jose Manuel Puche',
          __typename: 'Comercial',
        },
        {
          codComercial: 9237292,
          nombreComercial: 'José Mantero',
          __typename: 'Comercial',
        },
        {
          codComercial: 9237657,
          nombreComercial: 'Pepe Rivera',
          __typename: 'Comercial',
        },
      ],
      pdvSituacion: [
        { id: '0', desc: 'Todos', __typename: 'PdvSituacion' },
        { id: 'C', desc: 'PdV crecen', __typename: 'PdvSituacion' },
        { id: 'M', desc: 'Pdv mantienen', __typename: 'PdvSituacion' },
        { id: 'D', desc: 'Pdv decrecen', __typename: 'PdvSituacion' },
        { id: 'N', desc: 'Pdv nuevos', __typename: 'PdvSituacion' },
        { id: 'P', desc: 'Pdv perdidos', __typename: 'PdvSituacion' },
        { id: 'NC', desc: 'No cliente', __typename: 'PdvSituacion' },
      ],
      __typename: 'MapeoObjetivoSAC',
    },
  },
}

export const planAccionPdvResumenRes = {
  data: {
    planAccionPdvResumen: {
      medida: {
        id: 'V',
        desc: 'Volumen',
        unidad: 'litros',
        __typename: 'ObjetivoQueMedida',
      },
      tablaResumenMedida: [
        {
          act: 0,
          aa: 0,
          dif: 0,
          porcDif: 0,
          __typename: 'PlanAccionResumenMedida',
        },
        {
          act: 0,
          aa: 0,
          dif: 0,
          porcDif: 0,
          __typename: 'PlanAccionResumenMedida',
        },
        {
          act: 0,
          aa: 0,
          dif: 0,
          porcDif: 0,
          __typename: 'PlanAccionResumenMedida',
        },
        {
          act: 0,
          aa: 0,
          dif: 0,
          porcDif: 0,
          __typename: 'PlanAccionResumenMedida',
        },
      ],
      tablaResumenMedidaPdvSituacion: [
        {
          pdv: 1,
          medidaActDifAA: 1180647.6500000001,
          porcVarUnidad: 11.501696495085675,
          __typename: 'PlanAccionResumenMedidaPdvSituacion',
        },
        {
          pdv: 0,
          medidaActDifAA: 0,
          porcVarUnidad: 0,
          __typename: 'PlanAccionResumenMedidaPdvSituacion',
        },
        {
          pdv: 2,
          medidaActDifAA: -27522.749999999993,
          porcVarUnidad: -0.268122599668173,
          __typename: 'PlanAccionResumenMedidaPdvSituacion',
        },
        {
          pdv: -1,
          medidaActDifAA: 1153124.9000000001,
          porcVarUnidad: 11.233573895417502,
          __typename: 'PlanAccionResumenMedidaPdvSituacion',
        },
        {
          pdv: 0,
          medidaActDifAA: 0,
          porcVarUnidad: 0,
          __typename: 'PlanAccionResumenMedidaPdvSituacion',
        },
        {
          pdv: 0,
          medidaActDifAA: 0,
          porcVarUnidad: 0,
          __typename: 'PlanAccionResumenMedidaPdvSituacion',
        },
        {
          pdv: 0,
          medidaActDifAA: 0,
          porcVarUnidad: 0,
          __typename: 'PlanAccionResumenMedidaPdvSituacion',
        },
      ],
      __typename: 'PlanAccionPdvResumen',
    },
  },
}

export const PlanAccionPdvDetallePageRes = {
  data: {
    planAccionPdvDetallePage: {
      content: [
        {
          codDistribuidor: null,
          codPtoVenta: '1007444',
          nombre: 'BAR VIRGINIA',
          poblacion: 'CADIZ',
          razonSocial: null,
          pdvSituacion: null,
          rutaPrev: {
            id: '337211',
            desc: null,
            distribuidor: null,
            __typename: 'Ruta',
          },
          valorMedidaDia: 1059142,
          valorMedidaAA: 58301,
          difMedida: 1000841,
          __typename: 'PlanAccionPdvDetalle',
        },
        {
          codDistribuidor: null,
          codPtoVenta: '1007446',
          nombre: 'ASADOR DE POLLOS SERRANA 2',
          poblacion: 'JEREZ DE LA FRONTERA',
          razonSocial: null,
          pdvSituacion: null,
          rutaPrev: {
            id: '337211',
            desc: null,
            distribuidor: null,
            __typename: 'Ruta',
          },
          valorMedidaDia: 17640,
          valorMedidaAA: 29098,
          difMedida: -11458,
          __typename: 'PlanAccionPdvDetalle',
        },
        {
          codDistribuidor: null,
          codPtoVenta: '1007445',
          nombre: 'ALIMENTACION DIARIO DE CADIZ',
          poblacion: 'CADIZ',
          razonSocial: null,
          pdvSituacion: null,
          rutaPrev: {
            id: '337211',
            desc: null,
            distribuidor: null,
            __typename: 'Ruta',
          },
          valorMedidaDia: 17280,
          valorMedidaAA: 29290,
          difMedida: -12010,
          __typename: 'PlanAccionPdvDetalle',
        },
        {
          codDistribuidor: null,
          codPtoVenta: '1007453',
          nombre: 'EL LAGAR DEL TIO PARRILLA',
          poblacion: 'JEREZ DE LA FRONTERA',
          razonSocial: null,
          pdvSituacion: null,
          rutaPrev: {
            id: '337211',
            desc: null,
            distribuidor: null,
            __typename: 'Ruta',
          },
          valorMedidaDia: 17730,
          valorMedidaAA: 29849,
          difMedida: -12119,
          __typename: 'PlanAccionPdvDetalle',
        },
        {
          codDistribuidor: null,
          codPtoVenta: '1007441',
          nombre: 'ELEFANTE AZUL',
          poblacion: 'CADIZ',
          razonSocial: null,
          pdvSituacion: null,
          rutaPrev: {
            id: '337211',
            desc: null,
            distribuidor: null,
            __typename: 'Ruta',
          },
          valorMedidaDia: 18316,
          valorMedidaAA: 30597,
          difMedida: -12281,
          __typename: 'PlanAccionPdvDetalle',
        },
        {
          codDistribuidor: null,
          codPtoVenta: '1007449',
          nombre: 'BAR BIENESTAR',
          poblacion: 'JEREZ DE LA FRONTERA',
          razonSocial: null,
          pdvSituacion: null,
          rutaPrev: {
            id: '337211',
            desc: null,
            distribuidor: null,
            __typename: 'Ruta',
          },
          valorMedidaDia: 17671,
          valorMedidaAA: 29956,
          difMedida: -12285,
          __typename: 'PlanAccionPdvDetalle',
        },
      ],
      pagination: {
        numberOfElements: 6,
        totalElements: 6,
        __typename: 'Pagination',
      },
      __typename: 'PlanAccionPdvDetallePage',
    },
  },
}
