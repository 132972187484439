import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'

export let modalNotification = null

export const showNotificationsModal = notifications => {
  modalNotification = Modal.info()
  modalNotification.update({
    title: <ConfirmTitle>Avisos importantes</ConfirmTitle>,
    content: (
      <ul>
        {notifications.map(({ desc, context, url }, i) => {
          const anyo = (context && context.ano) || ''

          return (
            <li
              key={i}
              dangerouslySetInnerHTML={{
                __html: `<a href="#${`${url}/${anyo}`}">${desc}${anyo}</a>`,
              }}
            />
          )
        })}
      </ul>
    ),
    icon: null,
    centered: true,
    okText: 'CERRAR',
    okButtonProps: {
      type: 'danger',
    },
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
