import React from 'react'

import {
  ObjectiveBoxPercentual,
  ObjectiveBoxNumeric,
  ObjectiveBoxIncentive,
  ObjectiveBoxOpen,
  ObjectiveBoxEuroType,
} from 'Components'
import { colors, colorTypeMap } from 'Utils'

import { Icon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import moment from 'moment'

export const ObjectiveBox = ({ kpi, isNotClickable, onClick = null, comercialView, isHesa }) => {
  const {
    cuandoEvaluar,
    desc,
    fechaDesde,
    fechaHasta,
    diasLaborablesRestantes,
    distribucionValorRef,
    idMedida = 'X',
    inctvMinCaja,
    paraProveedor,
    objetivoPendiente,
    canales
  } = kpi

  const subtitleDays =
    (diasLaborablesRestantes &&
      diasLaborablesRestantes > 0 &&
      (diasLaborablesRestantes !== 1
        ? `(quedan ${diasLaborablesRestantes} días)`
        : `(queda ${diasLaborablesRestantes} día)`)) ||
    ''

  const subtitle =
    fechaDesde &&
    fechaHasta &&
    `${moment(fechaDesde).format('DD MMM YY')} - ${moment(fechaHasta).format(
      'DD MMM YY',
    )} ${subtitleDays || ''}`

  const moveIncs = idMedida !== 'N' && idMedida !== 'S' && idMedida !== 'A' && !(diasLaborablesRestantes > 0 && objetivoPendiente > 0)

  return (
    <Inner
      borderColor={colorTypeMap[idMedida]}
      onClick={onClick}
      isNotClickable={isNotClickable}
      idMedida={idMedida}
    >
      <Title>
        <span style={{ maxWidth: '260px' }} >{desc}</span>
        <Cuando>{cuandoEvaluar.id !== 'M' ? 'FP' : cuandoEvaluar.id} {canales.length > 0 ? 'CH': ''}</Cuando>
      </Title>
      <Subtitle>
        <span>{subtitle}</span>
        <IconSpan>
          <Icon type="share-alt" theme="outlined" style={{ marginRight: 5, visibility: !paraProveedor && 'hidden' }} />
          <Icon type="team" theme="outlined" style={{ visibility: distribucionValorRef.id !== 'D' && 'hidden' }} />
        </IconSpan>
      </Subtitle>
      {(idMedida === 'N' || idMedida === 'S') ? (
        <ObjectiveBoxNumeric kpi={kpi} />
      ) : idMedida === 'A' ? (
        <ObjectiveBoxOpen kpi={kpi} comercialView={comercialView} />
      ) : inctvMinCaja !== null ? (
        <ObjectiveBoxEuroType kpi={kpi} />
      ) : (
        <ObjectiveBoxPercentual kpi={kpi} isHesa={isHesa} />
      )}
      {!isHesa && <IncentivePos moveIncs={moveIncs} >
        <ObjectiveBoxIncentive kpi={kpi} />
      </IncentivePos>}
    </Inner>
  )
}

const Inner = styled.div`
  border-top: 4px solid ${props => props.borderColor};
  display: inline-block;
  width: 330px;
  height: 280px;
  margin: 0 25px 40px 25px;
  padding: 15px 20px;
  box-shadow: 0 4px 12px 0 rgba(200, 198, 198, 0.5);
  cursor: 'pointer';
  pointer-events: ${({ isNotClickable }) => (isNotClickable ? 'none' : 'auto')};
  position: relative;
`

const Title = styled.div`
  font-size: 16.5px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.29px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.trueGreen};
  
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 5px;
`

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.25px;
  color: #757575;
  margin-bottom: 5px
`

const IncentivePos = styled.div`
  position: absolute;
  bottom: ${props => (props.moveIncs ? '34' : '10')}px;
  right: 20px;
`

const Cuando = styled.span`
  margin-bottom: 5px;
  color: ${colors.purpleGrey};
  font-size: 16px;
  font-weight: bold;
  background: #F9FAF9;
  padding-left: 8px;
`

const IconSpan = styled.span`
float: right;
font-size: 17px;
color: ${colors.pine};
`
