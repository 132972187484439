import React from 'react'
import { Icon as AntIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import moment from 'moment'
import { colors } from 'Utils'

export const YearPicker = ({
  disabled,
  onIncrement,
  onDecrement,
  minYear,
  maxYear,
  year = moment().year(),
}) => {
  return (
    <Wrapper>
      <Controls>
        <Icon
          disabled={disabled || (minYear && year <= minYear)}
          type="left"
          onClick={onDecrement}
        />
        <YearLabel>{year}</YearLabel>
        <Icon
          disabled={disabled || (maxYear && year >= maxYear)}
          type="right"
          onClick={onIncrement}
        />
      </Controls>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
`

const Controls = styled.div({
  display: 'flex',
  color: 'black',
  alignItems: 'center',
})

const Icon = styled(AntIcon)(({ disabled }) => ({
  opacity: disabled ? 0.4 : 1,
  pointerEvents: disabled ? 'none' : 'all',
  fontSize: 19,
  margin: '0 10px',
}))

const YearLabel = styled.label`
  font-size: 19px;
  font-weight: 600;
  line-height: 0.79;
  letter-spacing: 0.35px;
  color: ${colors.black};
`
