import React from 'react'
import styled from '@emotion/styled'

export const FormLabel = ({ title, optional, optionalText = 'opcional' }) => (
  <>
    <LabelTitle>{title}</LabelTitle>
    {optional && <LabelOptional> ({optionalText})</LabelOptional>}
  </>
)

const LabelTitle = styled.span`
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #050606;
`
const LabelOptional = styled.span`
  padding-left: 5px;
  font-size: 12px;
  letter-spacing: 0.25px;
  color: #9b9b9b;
  vertical-align: middle;
`
