import styled from '@emotion/styled'

export const Tag = styled.span`
  display: inline-block;
  min-height: 40px;
  border-radius: 46px;
  border: solid 1px rgba(86, 153, 255, 0.14);
  font-size: 14px;
  color: #575757;
  padding: 8px 22px;
  margin: 7px 7px;
`
