import React, { useContext, useState, useEffect } from 'react'

import {
  ObjectiveCategoryList,
  ObjectiveCategoryValueList,
  ObjectiveLeftCol,
  ObjectiveRightCol,
  SelectionTag,
  TagContainer,
  WarningMessage,
} from 'Components'
import {
  GET_OBJECTIVE_ARTICLE_CATEGORIES,
  GET_OBJECTIVE_CATEGORY_ARTICLE_VALUES,
  UPDATE_OBJECTIVE_ARTICLE_CATEGORIES,
  UPDATE_OBJECTIVE_ARTICLE_EXCEPTIONS,
  OBJECTIVE,
  GET_ARTICLES,
  GET_AVAILABLE_SALESMEN,
} from 'Queries'
import { ArticlesTable } from 'Fragments'
import {
  GlobalContext,
  ObjectiveContext,
  getMutationVars,
  getObjectiveIdVars,
  removeValueFromCategories,
  removeCategoryFromCategories,
  addValueToCategories,
  addCategoryToCategories,
  categoriesCount,
} from 'Utils'

import styled from '@emotion/styled'
import { get, isEmpty, without, concat, cloneDeep } from 'lodash'
import { useMutation, useLazyQuery } from '@apollo/client'

export const ArticlesTab = ({
  objective,
  objGrupoId,
}) => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { resetKeepAliveTimer, setObjective, setHasArticles, setHasExceptions, empleados, setEmpleados } = context

  const selectedCategories = objGrupoId === 'A' ? (get(objective, 'categoriasSeleccionadasArticulo.categorias') || [])
    : (get(objective, 'categoriasSeleccionadasExcepciones.categorias') || [])

  const exceptions = objGrupoId === 'A' ? (get(objective, 'categoriasSeleccionadasArticulo.excepciones') || [])
    : (get(objective, 'categoriasSeleccionadasExcepciones.excepciones') || [])

  const paraProveedor = get(objective, 'paraProveedor') || ''
  const paraEspecialistas = get(objective, 'paraEspecialistas') || false
  const [extraExceptionHash, setExtraExceptionHash] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [numArticles, setArticles] = useState(0)
  const [articleList, setArticleList] = useState([])

  useEffect(() => {
    if (numArticles - exceptions.length > 0) {
      getSalesmen()
      if (objGrupoId === 'A') {
        setHasArticles(true)
      } else {
        setHasExceptions(true)
      }
    } else {
      if (objGrupoId === 'A') {
        setHasArticles(false)
      } else {
        setHasExceptions(false)
      }
    }
  }, [numArticles, exceptions])

  const onCompleted = ({ objective }) => {
    let newObjective = objective
    newObjective.exceptionsPassed = objGrupoId === 'E' ? true : false;
    setObjective(newObjective)
    resetKeepAliveTimer()
  }

  const [selectCategory] = useMutation(
    UPDATE_OBJECTIVE_ARTICLE_CATEGORIES,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  )

  const [getSalesmen, salesmenResource] = useLazyQuery(GET_AVAILABLE_SALESMEN, {
    fetchPolicy: 'network-only',
    skip: !objective.paraEspecialistas,
    variables: { ...getObjectiveIdVars(context) },
  })

  const newEmpleados = get(salesmenResource, 'data.empleados')

  useEffect(() => {
    if (JSON.stringify(empleados) !== JSON.stringify(newEmpleados)) setEmpleados(newEmpleados)
  })

  const updateSelectedCategory = selectedCategories => {
    // formatear valoresSeleccionados para enviarlos únicamente con el número para que devuelva todo correcto
    let formattedCategories = cloneDeep(selectedCategories);
    if (formattedCategories.length) {
      formattedCategories.forEach((category, indexCat) => {
        if (category.idCategoria === 'PRO') {
          formattedCategories[indexCat].valoresSeleccionados.forEach((value, indexVal) => {
            formattedCategories[indexCat].valoresSeleccionados[indexVal] = value.split("###")[0].trim();
          })
        }
      })
    }
    if ((objective.paraProveedor !== null && objective.paraProveedor !== '') && formattedCategories.find(cats => cats.idCategoria === 'PRO') === undefined) {
      formattedCategories.push({ idCategoria: "PRO", valoresSeleccionados: [objective.paraProveedor] })
    }
    selectCategory({
      variables: {
        selectedCategories: formattedCategories,
        objGrupoId,
      },
    })
  }

  const removeCategoryValue = (id, value) => {
    return updateSelectedCategory(removeValueFromCategories(selectedCategories, id, value))
  }

  const removeAllCategoryValues = id =>
    updateSelectedCategory(removeCategoryFromCategories(selectedCategories, id))

  const removeAllCategories = () => updateSelectedCategory([])

  const addCategoryValue = (id, value) =>
    updateSelectedCategory(addValueToCategories(selectedCategories, id, value))

  const addAllCategoryValues = (id, values) =>
    updateSelectedCategory(
      addCategoryToCategories(selectedCategories, id, values),
    )

  const removeAllExceptions = () => updateSelectedException(true)

  const addAllExceptions = () => updateSelectedException(false)

  const updateSelectedException = (selected) => {
    let newList = []

    if (!selected) newList = articleList.map(art => art.codArticulo)

    return setExceptions({
      variables: {
        excepcionesSeleccionadas: selected
          ? []
          : newList,
        objGrupoId,
      },
    }).then(() => {
      if (selected) setExtraExceptionHash(extraExceptionHash + '#')
    })
  }

  const [setExceptions] = useMutation(
    UPDATE_OBJECTIVE_ARTICLE_EXCEPTIONS,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  )

  const selectionIds = exceptions.map(exception => exception.codigo)

  const onSelectionChange = (record, selected) => {
    return setExceptions({
      variables: {
        excepcionesSeleccionadas: selected
          ? without(selectionIds, record.codArticulo)
          : concat(selectionIds, record.codArticulo),
        objGrupoId,
      },
    }).then(() => {
      if (selected) setExtraExceptionHash(extraExceptionHash + '#')
    })
  }

  const selectedCategorieHash =
    selectedCategories
      .map(
        ({ categoria, valores }) =>
          categoria.id + valores.map(({ nombre }) => nombre).join(''),
      )
      .join('') + extraExceptionHash

  const setExceptionTab = (val) => {
    let newObjective = objective
    newObjective.exceptionsPassed = val;
    setObjective(newObjective)
    resetKeepAliveTimer()
  }

  return (
    <Total>
      <ObjectiveLeftCol>
        {!selectedCategory && (
          <ObjectiveCategoryList
            onChange={setSelectedCategory}
            query={GET_OBJECTIVE_ARTICLE_CATEGORIES}
          />
        )}

        {selectedCategory && (
          <ObjectiveCategoryValueList
            selectedCategory={selectedCategory}
            selectedCategories={selectedCategories}
            setSelectedCategory={setSelectedCategory}
            query={GET_OBJECTIVE_CATEGORY_ARTICLE_VALUES}
            addCategoryValue={addCategoryValue}
            removeCategoryValue={removeCategoryValue}
            removeAllCategoryValues={removeAllCategoryValues}
            addAllCategoryValues={addAllCategoryValues}
            selectedCategorieHash={selectedCategorieHash}
            objGrupoId={objGrupoId}
            paraProveedor={objective.paraProveedor}
          />
        )}
      </ObjectiveLeftCol>
      <ObjectiveRightCol>
        {objective.paraEspecialistas && newEmpleados && !newEmpleados.length && (
          <Warning>
            <WarningMessage>
              No existe ningún especialista para el conjunto de categorías seleccionadas
            </WarningMessage>
          </Warning>
        )}
        <TagContainer
          title={`Categorías seleccionadas (${categoriesCount(selectedCategories)})`}
          deleteAll={!isEmpty(selectedCategories)}
          onDeleteAll={removeAllCategories}
        >
          {isEmpty(selectedCategories) ? (
            <SelectionTag warning={true}>Seleccione al menos una categoria</SelectionTag>
          ) : (
            selectedCategories.map(category =>
              category.valores.map(value => (
                <SelectionTag
                  key={category.categoria.id + '_' + value.nombre}
                  isProvider={objective.paraProveedor === value.nombre.split("###")[0]}
                  onDelete={() =>
                    removeCategoryValue(category.categoria.id, value.nombre)
                  }
                >
                  {value.nombre.split("###")[0]} {value.nombre.split("###")[1]}
                </SelectionTag>
              )),
            )
          )}
        </TagContainer>
        <TagContainer
          title={`Deseleccionados (${exceptions.length})`}
          deleteAll={!isEmpty(exceptions)}
          onDeleteAll={removeAllExceptions}
        >
          {!isEmpty(exceptions) &&
            exceptions.map(({ desc, codigo }) => (
              <SelectionTag
                key={codigo}
                isException
                onDelete={() =>
                  onSelectionChange({ codArticulo: codigo }, true)
                }
              >
                {desc}
              </SelectionTag>
            ))}
        </TagContainer>
        <ArticlesTable
          query={GET_ARTICLES}
          objectiveId={getObjectiveIdVars(context)}
          selectedCategorieHash={selectedCategorieHash}
          onSelectionChange={onSelectionChange}
          selectionIds={selectionIds}
          showSearch={true}
          sortBy="nombre"
          sortDir="asc"
          showDate={false}
          expandable={true}
          searchPlaceholder="Buscar en artículos seleccionados"
          onQueryCompleted={setArticles}
          exceptions={exceptions}
          removeAllExceptions={removeAllExceptions}
          addAllExceptions={addAllExceptions}
          numArticles={numArticles}
          articleList={articleList}
          setArticleList={setArticleList}
          objGrupoId={objGrupoId}
          setExceptionTab={setExceptionTab}
          showWarning={(objective.paraProveedor !== null && paraProveedor !== '') && paraEspecialistas}
        />
      </ObjectiveRightCol>
    </Total>
  )
}

const Warning = styled.div`
  float: right;
  width: 350px;
  margin-top: 24px;
  > div {
    line - height: 1.2;
  }
`

const Total = styled.div`
margin-top: -25px;
`

