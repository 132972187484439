import React from 'react'
import styled from '@emotion/styled'

export const CalendarDay = ({
  cellDate,
  isHoliday,
  isPresale,
  isOutOfCurrentMonth,
  isToday,
  isYearPast,
  legend,
  onClick,
}) => {
  return (
    <DayWrapper
      isHoliday={isHoliday}
      isPresale={isPresale}
      isToday={isToday}
      isYearPast={isYearPast}
      isOutOfCurrentMonth={isOutOfCurrentMonth}
      legend={legend}
    >
      <DayInner
        isHoliday={isHoliday}
        isPresale={isPresale}
        isToday={isToday}
        onClick={onClick}
      >
        {isHoliday && <HolidayLine />}
        <DayNumber>{!legend && cellDate.format('D')}</DayNumber>
      </DayInner>
    </DayWrapper>
  )
}

const calendarDaySize = 35

const DayWrapper = styled.div(
  ({ isPresale, isHoliday, isOutOfCurrentMonth, isYearPast, legend }) => ({
    display: 'flex',
    justifyContent: 'center',
    opacity: isOutOfCurrentMonth ? 0.3 : 1,
    cursor: isYearPast || legend ? 'default' : 'pointer',
    alignItems: 'center',
    overflow: 'hidden',
    width: calendarDaySize,
    height: calendarDaySize,
    border: '0.5px solid #f6f6f6',
    color: isHoliday ? 'white' : 'auto',
    backgroundColor: isHoliday ? '#204a8a' : isPresale ? '#d3e4cb' : 'white',
  }),
)

const DayInner = styled.div(({ isToday }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  width: calendarDaySize,
  height: calendarDaySize,
  border: isToday ? 'solid 2px #ef6a6e' : 'none',
}))

const DayNumber = styled.div({})

const HolidayLine = styled.div({
  width: calendarDaySize + 100,
  height: 0,
  border: '1px solid #ef6a6e',
  transform: 'rotate(-45deg)',
  position: 'absolute',
  top: '50%',
})
