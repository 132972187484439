import React, { useContext, useState } from 'react'

import { Row, PageTitle } from 'Components'
import { GET_CURRENT_USER, SET_CONSENT, CHANGE_FUERZA_CONSENTS } from 'Queries'
import { GlobalContext, colors } from 'Utils'

import { Switch } from 'antd'
import styled from '@emotion/styled'
import { useQuery, useMutation } from '@apollo/client'
import { get } from 'lodash'

export const FuerzaConsents = () => {
  const context = useContext(GlobalContext)
  const [respuesta, setRespuesta] = useState(get(context, 'currentUser.respuestaFuerzaBar'))
  const [callUser, setCallUser] = useState(false)

  const resource = useQuery(GET_CURRENT_USER, {
    skip: !callUser,
    fetchPolicy: 'network-only',
    variables: { tipo: 0 },
    onCompleted: () => {
      setRespuesta(true)
      setCallUser(false)
      context.resetKeepAliveTimer()
    }
  })

  const [changeConsents] = useMutation(CHANGE_FUERZA_CONSENTS, {
    onCompleted: (cambiarConsentimientoFuerzaBar) => {
      if (cambiarConsentimientoFuerzaBar) {
        setRespuesta(!respuesta)
        resource.refetch({})
        context.resetKeepAliveTimer()
      }
    },
  })

  const [setConsent] = useMutation(SET_CONSENT)

  const refetchUser = () => {
    resource.refetch({})
  }

  return (
    <>
      <Row type="flex" justify="space-between">
        <PageTitle>Permisos de sincronización con FUERZABAR</PageTitle>
      </Row>
      <div style={{ padding: 20, height: 'calc(100vh - 150px)', overflowY: 'auto' }}>
        Si aceptas, tu catálogo de artículos de <AppName>Clave</AppName> y los artículos que añadas en el futuro se sincronizarán en <AppName>FUERZABAR</AppName>.
        <br />
        <Switch
          style={{ margin: 10 }}
          checkedChildren="ACEPTAR Y SINCRONIZAR"
          unCheckedChildren="RECHAZAR Y NO SINCRONIZAR"
          checked={respuesta === null ? false : respuesta}
          onClick={c => {
            if (respuesta === null) {
              setCallUser(true)
              setConsent({ variables: { tipo: 1, respuesta: true } }).then(() => {
                refetchUser()
              })
            } else {
              changeConsents({ variables: { respuesta: c } });
            }
          }}
        />
      </div>
    </>
  )
}

const AppName = styled.span`
  font-weight: bold;
  color: ${colors.fernGreen};
`