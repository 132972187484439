import React, { useContext } from 'react'

import { ReportEmbed } from 'Components'
import { GlobalContext } from 'Utils'

import { Tabs as AntTabs } from 'antd'
import styled from '@emotion/styled'
import { get } from 'lodash'

export const ReportsDataQuality = ({ url, dashboardIdClients, dashboardIdArticles }) => {

  const context = useContext(GlobalContext)
  const codDistribuidor = get(context, 'currentUser.codDistribuidor')

  return (
    <StyledTabs defaultActiveKey="clients" >
      <AntTabs.TabPane tab="Clientes" key="clients">
        <ReportEmbed
          idReport="qualityClient"
          currentUrl={url}
          dashboardId={dashboardIdClients}
          hSize={190}
          params={{ codDistribuidor: codDistribuidor }}
        />
      </AntTabs.TabPane>
      <AntTabs.TabPane tab="Artículos" key="articles">
        <ReportEmbed idReport="qualityArticles" currentUrl={url} dashboardId={dashboardIdArticles} hSize={190} params={{ codDistribuidor: codDistribuidor }}/>
      </AntTabs.TabPane>
    </StyledTabs>
  )
}

const StyledTabs = styled(AntTabs)`
  .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-left: 15px;
  }
  .ant-tabs-tab {
    padding: 10px 15px;
    font-size: 17px;
  }
`