import gql from 'graphql-tag'
export const GET_ARTICLES = gql`
  query getArticles(
    $codDistribuidor: Long!
    $page: Int
    $size: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: SortDir
    $idObjetivo: ObjetivoIdInput
    $objGrupoId: String
    $idCodSatGroup: Int
  ) {
    articuloPage(
      codDistribuidor: $codDistribuidor
      page: $page
      size: $size
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      idObjetivo: $idObjetivo
      objGrupoId: $objGrupoId
      idCodSatGroup: $idCodSatGroup
    ) {
      content {
        nombre
        codDistribuidor
        codArticulo
        familia
        marca
        modifEn
        key: codArticulo
      }
      pagination {
        numberOfElements
        totalElements
      }
    }
  }
`

export const GET_ARTICLE = gql`
  query getArticle($codArticulo: String!, $codDistribuidor: Long!) {
    articulo(codArticulo: $codArticulo, codDistribuidor: $codDistribuidor) {
      codDistribuidor
      codArticulo
      nombre
      familia
      marca
      formato
      agrupacionFormato
      modifEn
      codProveedor
      nombreProveedor
      codProductoProv
      agrupFormato
      agrupMarca
      peso
      margen
      fechaMargen
      descuento
      fechaDescuento
      coste
      importeImpuestoEspecialAlcoholes
      gravamenPuntoVenta
      tipoIva
      capacidadL
      capacidadKg
      segmentaciones {
        id
        desc
      }
      bultoEquivalente
    }
  }
`
