import React, { useState } from 'react'

import { ArticlesTab } from 'Components'
import { colors, ObjectiveContextConsumer } from 'Utils'

import { Tabs as AntTabs } from 'antd'
import { Icon } from '@ant-design/compatible'
import styled from '@emotion/styled'

const { TabPane } = AntTabs

export const ObjectiveArticlesAndExceptions = () => (
  <ObjectiveContextConsumer>
    {({ objective }) =>
      !Number.isFinite(objective.codObjetivo) ? null : (
        <Articles objective={objective} />
      )
    }
  </ObjectiveContextConsumer>
)

const Articles = ({ objective }) => {

  const [position, setPosition] = useState("1")

  return (
    <Total>
      <StyledTabs size="small" defaultActiveKey={position} type="card" onChange={e => setPosition(e.toString())}>
        <TabPane
          tab={
            <>
              <TabText> Quiero que se vendan estos artículos </TabText>
              <Circle position={position === "1"}>{position === "1" ? '2.0' : <Icon style={{ margin: '0 2px' }} type="check" />}</Circle>
            </>
          }
          key="1"
        >
          {position === "1" && (
            <ArticlesTab
              objective={objective}
              objGrupoId={"A"}
            />
          )}
        </TabPane>
        <TabPane
          tab={
            <>
              <TabText> Donde NO se compren estos </TabText>
              <Circle position={position === "2"}>2.1</Circle>
            </>
          }
          key="2"
        >
          {position === "2" && (
            <ArticlesTab
              objective={objective}
              objGrupoId={"E"}
            />
          )}
        </TabPane>
      </StyledTabs>
    </Total>
  )
}

const StyledTabs = styled(AntTabs)`
  .ant-tabs-nav {
    margin: 0 0 0 399px;
    // border-bottom: 1px solid #b1b1b1 !important;    
    z-index: 1000;
    width: 650px;
  }
  .ant-tabs-tab {
    background: #ffffff !important;
    border-color: #b1b1b1 !important;
  }
  .ant-tabs-tab-active {
    background: #f9faf9 !important;
    border-color: #b1b1b1 !important;
    border-bottom: transparent !important;
  }
`

const Total = styled.div`
  padding-top: 7px;
  width: 100vw;
  margin: 0;
 `

const TabText = styled.span`
  font-size: 17px;
  font-weight: 600;
  padding-left: 10px;
`

const Circle = styled.span(({ position }) => ({
  color: position ? 'white' : colors.trueGreen,
  background: position ? colors.trueGreen : 'transparent',
  fontWeight: position ? '500' : '600',
  border: `1px solid ${colors.trueGreen}`,
  borderRadius: 15,
  padding: 3,
  marginLeft: 10,
}))
