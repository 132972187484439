import React, { useState, useCallback, useEffect, useContext } from 'react'

import { ResourceWrapper, ReportWrapper } from 'Components'
import { ActionPlanTables, PdvTable } from 'Fragments'
import { ACTION_PLAN, CODIGOS_POSTALES } from 'Queries'
import { GlobalContext, checkRolePermit } from 'Utils'

import { Select, DatePicker, Tabs, Radio as AntRadio } from 'antd'
import styled from '@emotion/styled'
import { useQuery, useLazyQuery } from '@apollo/client'
import { get, isEmpty } from 'lodash'
import moment from 'moment'

const { TabPane } = Tabs

const dateFormat = 'YYYY/MM/DD'

const pdvEstado = [
  {
    id: '0',
    desc: 'Todos',
  },
  {
    id: 'A',
    desc: 'Alta',
  },
  {
    id: 'B',
    desc: 'Baja',
  },
]

const mesesAnteriores = [
  {
    id: 1,
    desc: 'Mes anterior',
  },
  {
    id: 2,
    desc: 'Anteriores 2 meses',
  },
  {
    id: 3,
    desc: 'Anteriores 3 meses',
  },
]

const optionsDropwdown = (options, props = { id: 'id', desc: 'desc' }) =>
  options && options.length
    ? options.map(option => (
      <Select.Option key={option[props.id]} value={option[props.id]}>
        {option[props.desc]}
      </Select.Option>
    ))
    : []

export const ActionPlan = ({
  codDistribuidor,
  idObjetivo,
  fechaDesde,
  fechaHasta,
  comerciales,
  pdvSituacion,
  idMedida,
  month,
  year,
}) => {
  const context = useContext(GlobalContext)
  const { isDev } = context
  const roles = get(context, 'currentUser.roles')

  const hasSeguimientoObjetivoPlanAccionResumen = checkRolePermit(
    roles,
    'SeguimientoObjetivoPlanAccionResumen',
  )
  const hasSeguimientoObjetivoPlanAccionPdvs =
    isDev || checkRolePermit(roles, 'SeguimientoObjetivoPlanAccionPdvs')

  // if a user doesn't have permission to see the action plan summary show pdv table
  let defaultShowPdv = hasSeguimientoObjetivoPlanAccionResumen ? false : true

  const [selectedEmpleado, setSelectedEmpleado] = useState(0)
  const [selectedSituation, setSelectedSituation] = useState('D')
  const [selectedEstado, setSelectedEstado] = useState('A')
  const [selectedMesesAnteriores, setSelectedMesesAnteriores] = useState(3)
  const [matObjetive, setMatObjetive] = useState('S')
  const [codPostal, setCodPostal] = useState([])
  const [showPdv, setShowPdv] = useState(defaultShowPdv)
  const [showPdvOp, setShowPdvOp] = useState(false)

  const variables = {
    idObjetivo,
    codPostal,
  }

  const [getActionPlanQuery, resource] = useLazyQuery(ACTION_PLAN, {
    fetchPolicy: 'network-only',
    skip: isDev,
    variables,
  })
  const getActionPlan = useCallback(getActionPlanQuery, [])

  const planAccionPdvResumen = get(resource, 'data.planAccionPdvResumen')

  useEffect(() => {
    if (idMedida === 'N') {
      setSelectedSituation('P')
    }
  }, [idMedida])

  const objectiveStart = moment(fechaDesde, 'x')
    .utc()
    .startOf('day')
  const objectiveEnd = moment(fechaHasta, 'x')
    .utc()
    .endOf('day')

  const monthStart = moment
    .utc(`${year}-${month || objectiveStart.month() + 1}-01`, 'YYYY-MM-DD')
    .startOf('month')
  const monthEnd = moment
    .utc(`${year}-${month || objectiveEnd.month() + 1}-01`, 'YYYY-MM-DD')
    .endOf('month')

  const serverDate = moment(context.currentUser.currentTime)
    .utc()
    .endOf('day')

  const start = moment.max(monthStart, objectiveStart)
  const end = moment.min(monthEnd, objectiveEnd, serverDate)

  const showDatePicker = start.isValid() && end.isValid()

  const [period, setPerdiod] = useState([start, end])

  useEffect(() => {
    let start = period[0]
    let end = period[1] || period[0]

    if (start.isValid() && end.isValid()) {
      if (!showPdv) {
        getActionPlan({
          variables: {
            fechaDesde: start.utc().format('x'),
            fechaHasta: end.utc().format('x'),
            codEmpleado: selectedEmpleado || 0,
          },
        })
      }
    }
  }, [period, selectedEmpleado, getActionPlan, showPdv])

  const getCodPostales = useQuery(CODIGOS_POSTALES, {
    variables: { codDistribuidor },
    onCompleted: context.resetKeepAliveTimer,
  })

  const codigosPostales = (getCodPostales.data && getCodPostales.data.codigosPostales) ?
    getCodPostales.data.codigosPostales : []

  const renderContent = () => (
    <ReportWrapper reportHeight={205}>
      <ControlsWrapper showPdvOp={showPdvOp}>
        <Select
          value={codPostal.length === 0 ? ['Todos'] : codPostal}
          mode="multiple"
          allowClear
          placeholder="Código Postal"
          onChange={cps => {
            setCodPostal(cps.filter(cp => cp !== 'Todos'));
          }}
          maxTagCount={2}
          optionLabelProp="label"
          size="large"
          style={{ width: 300 }}
          showArrow={true}
        >
          {codigosPostales.map(cp => {
            return (
              <Select.Option value={cp} label={cp}>
                <div> {cp} </div>
              </Select.Option>
            )
          })}
        </Select>
        {showPdvOp && (
          <Select
            mode="default"
            size="large"
            placeholder="Compraron en"
            value={selectedMesesAnteriores}
            onChange={setSelectedMesesAnteriores}
            style={{ width: 180 }}
            showArrow={true}
          >
            {optionsDropwdown(mesesAnteriores)}
          </Select>
        )}
        {showPdv && (
          <>
            <Select
              mode="default"
              size="large"
              placeholder="Estado"
              value={selectedEstado}
              onChange={setSelectedEstado}
              style={{ width: 80 }}
              showArrow={true}
            >
              {optionsDropwdown(pdvEstado)}
            </Select>
            {!showPdvOp && (
              <Select
                mode="default"
                size="large"
                placeholder="Selección de situación"
                value={selectedSituation}
                onChange={setSelectedSituation}
                style={{ width: 155 }}
                showArrow={true}
              >
                {optionsDropwdown(pdvSituacion)}
              </Select>
            )}
          </>
        )}
        {hasSeguimientoObjetivoPlanAccionResumen && !isEmpty(comerciales) && (
          <Select
            mode="default"
            size="large"
            value={selectedEmpleado}
            onChange={id => setSelectedEmpleado(id)}
            style={{ width: 280 }}
            showArrow={true}
          >
            {optionsDropwdown(comerciales, {
              id: 'codEmpleado',
              desc: 'nombreEmpleado',
            })}
            {/* {comerciales.map(({ codComercial: id, nombreComercial }) => (
              <Select.Option key={id} value={id}>
                {nombreComercial}
              </Select.Option>
            ))} */}
          </Select>
        )}
        {showDatePicker && (
          <DatePicker.RangePicker
            allowClear={false}
            disabledDate={current => {
              return (
                current && (current < objectiveStart || objectiveEnd < current)
              )
            }}
            onCalendarChange={selection => {
              if (selection.length > 1) setPerdiod(selection)
            }}
            size="large"
            value={period}
            format={dateFormat}
            style={{ width: '260px' }}
          />
        )}
      </ControlsWrapper>
      {showPdvOp && (
        <div
          style={{ textAlign: "left", paddingBottom: 15 }}
        >
          <AntRadio.Group
            onChange={e => {
              setMatObjetive(e.target.value)
            }}
            value={matObjetive}
          >
            <StyledRadio value={'S'}>PdVs CON productos del Objetivo</StyledRadio>
            <StyledRadio value={'N'}>PdVs SIN productos del Objetivo</StyledRadio>
          </AntRadio.Group>
        </div>
      )}
      {showPdv ? (
        hasSeguimientoObjetivoPlanAccionPdvs && (
          <PdvTable
            queryExtraParams={{
              ...variables,
              fechaDesde: moment.utc(period[0]).format('x'),
              fechaHasta: moment.utc(period[1]).format('x'),
              codEmpleado: selectedEmpleado,
              pdvSituacionId: showPdvOp ? undefined : selectedSituation,
              numeroMesesAnteriores: showPdvOp
                ? selectedMesesAnteriores
                : undefined,
              situacionId: selectedEstado,
              matObjetivo: showPdvOp
                ? (matObjetive === 'S' ? true : false)
                : undefined,
              codPostal,
            }}
            idMedida={idMedida}
            sortBy={showPdvOp ? 'calcMedida' : 'difMedida'}
            sortDir={showPdvOp ? 'desc' : 'asc'}
          />
        )
      ) : (
        <ResourceWrapper resource={resource}>
          {hasSeguimientoObjetivoPlanAccionResumen && planAccionPdvResumen && (
            <ActionPlanTables
              planAccionPdvResumen={planAccionPdvResumen}
              idMedida={idMedida}
            />
          )}
        </ResourceWrapper>
      )}
    </ReportWrapper>
  )

  const manageTabs = key => {
    if (key === '3') {
      setShowPdvOp(true)
      setShowPdv(true)
    } else if (key === '2') {
      setShowPdvOp(false)
      setShowPdv(true)
    } else {
      setShowPdvOp(false)
      setShowPdv(false)
    }
  }

  return (
    <Inner>
      <Tabs size="small" type="card" onTabClick={manageTabs}>
        {hasSeguimientoObjetivoPlanAccionResumen && (
          <TabPane tab={`Plan de Acción`} key="1" />
        )}
        <TabPane tab={`Listado de PDVs`} key="2" />
        <TabPane tab={`PDVs ExCompradores`} key="3" />
      </Tabs>
      {renderContent()}
    </Inner>
  )
}

const Inner = styled.div`
  min-width: 776px;
  margin: auto;
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: #f9faf9;
    border-bottom: 1px solid #f9faf9;
    font-weight: bold;
  }
`
export const StyledRadio = styled(AntRadio)`
  display: block;
  font-size: 14px;
  line-height: 2.5;
  color: grey;
  + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-wrapper + span,
  .ant-checkbox + span {
    padding-right: 18px;
    padding-left: 18px;
  }
`
const ControlsWrapper = styled.div`
  ${({ showPdvOp }) => (showPdvOp &&
    ' min-width: 1035px; '
  )}
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
  > :first-child {
    margin-left: 0;
  }
`
