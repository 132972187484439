import React from 'react'
import { Table, articleColumns, dateColumn } from 'Components'
import { TableQuery } from 'Fragments'

export const ArticlesTable = ({
  showDate = true,
  expandable = false,
  showSearch = true,
  onSelectionChange = null,
  selectionIds = [],
  objectiveId = null,
  selectedCategorieHash = '',
  onQueryCompleted = null,
  onRow,
  sortBy,
  sortDir,
  querySeachParams,
  query,
  searchPlaceholder,
  exceptions,
  removeAllExceptions,
  addAllExceptions,
  numArticles,
  articleList,
  setArticleList,
  showWarning,
  objGrupoId = 'A',
  setExceptionTab,
  type = 'art',
  idCodSatGroup = undefined,
}) => {
  const sortDirMap = {
    asc: 'ascend',
    desc: 'descend',
  }

  const columns = showDate ?
    [...articleColumns(onSelectionChange, exceptions, removeAllExceptions, addAllExceptions, articleList.length), dateColumn]
    : articleColumns(onSelectionChange, exceptions, removeAllExceptions, addAllExceptions, articleList.length)

  const columnsWithSort = columns.map(column => {
    return column.dataIndex !== sortBy
      ? column
      : { ...column, defaultSortOrder: sortDirMap[sortDir] }
  })

  return (
    <TableQuery
      showSearch={showSearch}
      searchPlaceholder={searchPlaceholder}
      querySeachParams={querySeachParams}
      // onSelectionChange={onSelectionChange}
      selectionIds={selectionIds}
      title="Artículos"
      expandable={expandable}
      query={query}
      objectiveId={objectiveId}
      selectedCategorieHash={selectedCategorieHash}
      queryVariables={{
        sortBy,
        sortDir,
      }}
      queryPath={'articuloPage'}
      idKey={'codArticulo'}
      onQueryCompleted={onQueryCompleted}
      contentList={articleList}
      setContentList={setArticleList}
      showWarning={showWarning}
      idCodSatGroup={idCodSatGroup}
      objGrupoId={objGrupoId}
      setExceptionTab={setExceptionTab}
    >
      <Table
        columns={columnsWithSort}
        locale={{ emptyText: 'No hay artículos para la búsqueda' }}
        onRow={onRow}
      />
    </TableQuery>
  )
}
