import gql from 'graphql-tag'

export const GET_ACTIVITIES = gql`
  query getActivities(
    $codDistribuidor: Long!
    $page: Int
    $size: Int
    $sortBy: String
    $sortDir: SortDir
  ) {
    actividadPage(
      codDistribuidor: $codDistribuidor
      page: $page
      size: $size
      sortBy: $sortBy
      sortDir: $sortDir
    ) {
      content {
        fecha
        secuencia
        tipo
        fase
        resultado
        error
        invalidated
        invalidationTime
        numRegs
        fechaIni
        fechaFin
      }
      pagination {
        numberOfElements
        totalElements
      }
    }
  }
`

// export const GET_LAST_ACTIVITY = gql`
//   query getLastActivity($codDistribuidor: Long!) {
//     fechaUltimaActividad(codDistribuidor: $codDistribuidor)
//   }
// `

export const GET_LAST_ACTIVITY = gql`
  query getLastActivity($codDistribuidor: Long!) {
    ultimaActividad(codDistribuidor: $codDistribuidor) {
      fecha
      tipo
      fase
      resultado
      error
      invalidated
      invalidationTime
      numRegs
      fechaIni
      fechaFin
    }
  }
`
