import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { Button, Icon } from 'Components'
import { Select } from 'antd'
import { agrupacionList, colors, periodoList } from 'Utils'

const toggleFilter = (filter, element) =>
  filter && filter.length
    ? filter.includes(element)
      ? filter.filter(elem => elem !== element)
      : [...filter, element]
    : [element]

export const FollowUpLegend = ({
  filter,
  filter: { tipo, cuando, idMedida, empleado, nivel },
  empleados,
  niveles,
  setFilter,
  clearFilter,
  isLoading,
  isDisabled,
}) => {

  const [ipadResolution, setIpadResolution] = useState()
  const [showThisLabel, setShowThisLabel] = useState('');
  const [disableInput, setDisableInput] = useState(true);
  const [showSec, setShowSec] = useState(5);

  useEffect(() => {
    setTimeout(() => {
      updateResolution()
    }, 100)
    window.addEventListener('resize', updateResolution)
    return () => {
      window.removeEventListener('resize', updateResolution)
    }
  }, [])

  useEffect(() => {
    const timer =
      showSec > 0 && setInterval(() => setShowSec(showSec - 1), 1000);
    if (showSec === 0) {
      setDisableInput(false);
    }
    if (isLoading || isDisabled) { setShowSec(5) }
    return () => clearInterval(timer);
  }, [showSec]);

  const updateResolution = () => {
    setIpadResolution(document.querySelector('#contentWidth').offsetWidth < 965)
  }

  const overShow = (tipo) => {
    setShowThisLabel(tipo);
  }

  return (
    <>
      <IconsWrapper>
        <IconFilter
          disabled={disableInput}
          selected={idMedida && idMedida.includes('S')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'S') })}
          onMouseOver={() => overShow('S')}
        >
          <Icon type="saturacion" size={30} />
          {(showThisLabel === 'S') && <Label>Saturación</Label>}
        </IconFilter>
        <IconFilter
          disabled={disableInput}
          selected={idMedida && idMedida.includes('A')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'A') })}
          onMouseOver={() => overShow('A')}
        >
          <Icon type="hand" size={26} />
          {(showThisLabel === 'A') && <Label>Obj. Libre</Label>}
        </IconFilter>
        <IconFilter
          disabled={disableInput}
          selected={idMedida && idMedida.includes('B')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'B') })}
          onMouseOver={() => overShow('B')}
        >
          <Icon type="equivalentPackage" size={32} />
          {(showThisLabel === 'B') && <Label>Bulto Eq.</Label>}
        </IconFilter>
        <IconFilter
          disabled={disableInput}
          selected={idMedida && idMedida.includes('F')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'F') })}
          onMouseOver={() => overShow('F')}
        >
          <Icon type="euro" size={32} />
          {(showThisLabel === 'F') && <Label>Facturación</Label>}
        </IconFilter>
        <IconFilter
          disabled={disableInput}
          selected={idMedida && idMedida.includes('M')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'M') })}
          onMouseOver={() => overShow('M')}
        >
          <Icon type="margin" size={32} />
          {(showThisLabel === 'M') && <Label>Margen</Label>}
        </IconFilter>
        <IconFilter
          disabled={disableInput}
          selected={idMedida && idMedida.includes('N')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'N') })}
          onMouseOver={() => overShow('N')}
        >
          <Icon type="bar" size={32} />
          {(showThisLabel === 'N') && <Label>Numérica</Label>}
        </IconFilter>
        <IconFilter
          disabled={disableInput}
          selected={idMedida && idMedida.includes('U')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'U') })}
          onMouseOver={() => overShow('U')}
        >
          <Icon type="box" size={32} />
          {(showThisLabel === 'U') && <Label>Unidades</Label>}
        </IconFilter>
        <IconFilter
          disabled={disableInput}
          selected={idMedida && idMedida.includes('V')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'V') })}
          onMouseOver={() => overShow('V')}
        >
          <Icon type="drop" size={32} />
          {(showThisLabel === 'V') && <Label>Volumen</Label>}
        </IconFilter>
      </IconsWrapper>
      <FilterWrapper>
        <Select
          disabled={disableInput}
          mode="default"
          size="small"
          placeholder="Rol"
          value={niveles.length ? nivel : null}
          onChange={nivel => setFilter({ nivel, empleado: undefined })}
          style={
            ipadResolution
              ? { width: 140, minWidth: 120 }
              : { width: 180, minWidth: 120 }
          }
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {niveles.map(({ id, desc }) => (
            <Select.Option key={id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={disableInput || !nivel}
          mode="default"
          size="small"
          placeholder="Empleado"
          value={empleados.length ? empleado : null}
          onChange={empleado => setFilter({ empleado })}
          style={
            ipadResolution
              ? { width: 170, minWidth: 120 }
              : { width: 200, minWidth: 120 }
          }
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {empleados.map(({ codEmpleado, nombreEmpleado }) => (
            <Select.Option key={codEmpleado} value={codEmpleado}>
              {nombreEmpleado}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={disableInput}
          mode="default"
          size="small"
          placeholder="Agrupación"
          value={tipo}
          onChange={tipo => setFilter({ tipo })}
          style={
            ipadResolution
              ? { width: 150, minWidth: 120 }
              : { width: 180, minWidth: 120 }
          }
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {agrupacionList.map(({ id, desc }) => (
            <Select.Option key={id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={disableInput}
          mode="default"
          size="small"
          placeholder="Tipo período"
          value={cuando}
          onChange={cuando => setFilter({ cuando })}
          style={
            ipadResolution
              ? { width: 140, minWidth: 120 }
              : { width: 180, minWidth: 120 }
          }
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {periodoList.map(({ id, desc }) => (
            <Select.Option key={id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <Button
          disabled={
            !Object.values(filter).some(theFilter =>
              Array.isArray(theFilter) ? theFilter.length : theFilter,
            )
          }
          type="link"
          size="large"
          onClick={clearFilter}
        >
          Resetear filtros
        </Button>
      </FilterWrapper>
    </>
  )
}

const IconFilter = styled.div`
  border: ${({ selected }) =>
    selected ? `1px dashed ${colors.nastyGreen}` : 'none'};
  border-radius: 5px;
  padding: 3px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled && '40%')};
  &:hover {
    cursor: pointer;
  }
`
const Label = styled.span`
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${colors.black};
  margin: 0 10px 0 6px;
`
const IconsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  right: 20px;
  top: 10px;
  min-width: 500px;
  > div {
    margin: 0 5px;
  }
`

const FilterWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  right: 0px;
  top: 65px;
  > div {
    margin: 0 5px;
  }
`