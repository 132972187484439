import { findIndex, cloneDeep, uniq, concat } from 'lodash'

export const removeValueFromCategories = (categories, id, value) =>
  cloneDeep(categories).map(category => ({
    idCategoria: category.categoria.id,
    valoresSeleccionados: category.valores
      .filter(
        values =>
          (category.categoria.id !== id && true) || values.nombre.split("###")[0] !== value.split("###")[0],
      )
      .map(values => values.nombre),
  }))

export const removeCategoryFromCategories = (categories, id) =>
  cloneDeep(categories)
    .filter(category => category.categoria.id !== id)
    .map(category => ({
      idCategoria: category.categoria.id,
      valoresSeleccionados: category.valores.map(values => values.nombre),
    }))

export const addValueToCategories = (categories, id, value) => {
  const newCategories = cloneDeep(categories).map(category => ({
    idCategoria: category.categoria.id,
    valoresSeleccionados: category.valores.map(values => values.nombre),
  }))
  const index = findIndex(newCategories, o => o.idCategoria === id)
  if (index >= 0) {
    const values = uniq(
      concat(newCategories[index].valoresSeleccionados || [], value),
    )
    newCategories[index].valoresSeleccionados = values
  } else {
    newCategories.push({
      idCategoria: id,
      valoresSeleccionados: [value],
    })
  }
  return newCategories
}

export const addCategoryToCategories = (categories, id, values) => {
  const newCategories = cloneDeep(categories).map(category => ({
    idCategoria: category.categoria.id,
    valoresSeleccionados: category.valores.map(values => values.nombre),
  }))

  const index = findIndex(newCategories, o => o.idCategoria === id)
  if (index >= 0) {
    newCategories[index].valoresSeleccionados = values
  } else {
    newCategories.push({
      idCategoria: id,
      valoresSeleccionados: values,
    })
  }
  return newCategories
}


export const addValueToCategoriesExtended = (categories, selectedCategory, value) => {
  const newCategories = cloneDeep(categories).map(category => ({
    categoria: {
      id: category.categoria.id,
      desc: category.categoria.desc
    },
    valores: category.valores.map(values => {
      return {
        nombre: values.nombre,
        seleccionado: values.seleccionado,
        activado: values.activado
      }
    }),
  }))
  const index = findIndex(newCategories, o => o.categoria.id === selectedCategory.id)
  if (index >= 0) {
    const values = uniq(
      concat(newCategories[index].valores || [], { nombre: value, seleccionado: true, activado: true }),
    )
    newCategories[index].valores = values
  } else {
    newCategories.push({
      categoria: {
        id: selectedCategory.id,
        desc: selectedCategory.desc
      },
      valores: [{ nombre: value, seleccionado: true, activado: true }],
    })
  }
  return newCategories
}