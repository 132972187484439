export const currentUserRes = {
  data: {
    currentUser: {
      codDistribuidor: 337213,
      name: 'Distribuidor 01 Gerente 01',
      roles: [
        {
          name: 'SeguimientoObjetivo',
          read: true,
          write: false,
          __typename: 'Role',
        },
        {
          name: 'InformesIncentivosPorComercial',
          read: true,
          write: false,
          __typename: 'Role',
        },
        {
          name: 'JerarquiaComercial',
          read: true,
          write: true,
          __typename: 'Role',
        },
        {
          name: 'SeguimientoObjetivoPlanAccionResumen',
          read: true,
          write: false,
          __typename: 'Role',
        },
        { name: 'Calendario', read: true, write: true, __typename: 'Role' },
        {
          name: 'SeguimientoObjetivoPlanAccionPdvs',
          read: false,
          write: false,
          __typename: 'Role',
        },
        {
          name: 'SeguimientoObjetivoGerenciaComercialSAC',
          read: true,
          write: false,
          __typename: 'Role',
        },
        {
          name: 'RegistroActividad',
          read: true,
          write: false,
          __typename: 'Role',
        },
        {
          name: 'RegistroActividadUltimaActividad',
          read: true,
          write: false,
          __typename: 'Role',
        },
        {
          name: 'GestionObjetivo',
          read: true,
          write: true,
          __typename: 'Role',
        },
      ],
      __typename: 'User',
    },
  },
}
