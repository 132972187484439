import React from 'react'
import { colors } from 'Utils'
import styled from '@emotion/styled'
import { ReactComponent as CloseCircle } from 'Assets/icons/close-circle.svg'
import { ReactComponent as CloseCircleRed } from 'Assets/icons/close-circle-red.svg'
import { ReactComponent as LockCircle } from 'Assets/icons/lock-circle.svg'
import { Popover } from 'antd'

export const SelectionTag = ({ children, onDelete, isException, isProvider = false, warning = false }) => (
  <SelectionTagInner hasDelete={onDelete && true} isException={isException} warning={warning}>
    {children}
    {(!isProvider && onDelete) ? (
      <IconWrapper onClick={onDelete}>
        {isException ? <CloseCircleRed /> : <CloseCircle />}
      </IconWrapper>
    ) : (isProvider) ? (
      <IconWrapper>
        <Popover
          content={(<> Esta categoría no se puede excluir. Deseleccione la opción <b>"Compartir con proveedor"</b> si quiere eliminarlo.</>)}
        >
          <LockCircle />
        </Popover>

      </IconWrapper>
    ) : null}
  </SelectionTagInner>
)

const SelectionTagInner = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.29px;
  color: ${props =>
    props.warning ? '#bb7c00' : props.isException ? colors.redCruz : colors.white};
  background-color: ${props =>
    props.warning ? '#fdea86' : props.isException ? colors.ligthRed : colors.trueGreen};
  position: relative;
  padding: ${props =>
    props.hasDelete ? '5px 30px 5px 12px' : '5px 12px 5px 12px'};
  border-radius: 20px;
  margin: 0 10px 8px;
`

const IconWrapper = styled.span`
  position: absolute;
  width: 18px;
  right: 6px;
  top: 3px;
  cursor: pointer;
`
