import gql from 'graphql-tag'

export const GET_CLIENTS = gql`
  query GetClients(
    $codDistribuidor: Long!
    $page: Int
    $size: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: SortDir
    $idObjetivo: ObjetivoIdInput
  ) {
    clientePage(
      codDistribuidor: $codDistribuidor
      page: $page
      size: $size
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      idObjetivo: $idObjetivo
    ) {
      content {
        nombre
        tipoCliente
        direccion
        modifEn
        codCliente
        codDistribuidor
        key: cif
      }
      pagination {
        numberOfElements
        totalElements
      }
    }
  }
`

export const GET_CLIENT = gql`
  query getClient($codCliente: String!, $codDistribuidor: Long!) {
    cliente(codCliente: $codCliente, codDistribuidor: $codDistribuidor) {
      nombre
      direccion
      codPostal
      poblacion
      fechaAlta
      cif
      email
      telefono
      telefonoDos
      codCliente
      codPtoVenta
      razonSocial
      situacion
      longitud
      latitud
      codComercialPrev
      nombreComercialPrev
      frecPrev
      frecReparto
      frecTelev
      deudaViva
      viaPago
      diasCredito
      rutaPrev {
        desc
        id
      }
      rutaReparto {
        desc
        id
      }
      rutaTelev {
        desc
        id
      }
      segmentaciones {
        desc
        id
      }
      segmentacionesHk {
        desc
        id
      }
    }
  }
`
