import React from 'react'

import { Icon } from 'Components'
import { formatNumberWithThousands, colors, iconTypeMap } from 'Utils'

import { Icon as AntIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'

export const ObjectiveBoxNumeric = ({ kpi }) => {
  const {
    objetivoPendiente,
    objetivoAbsTotal,
    objetivoAbs,
    objetivoAbsMes,
    objetivoUnidad,
    cuandoEvaluar,
    diasLaborablesRestantes,
    idMedida,
    withException,
  } = kpi

  const unit = 'PdVs'
  const icon = iconTypeMap[idMedida]

  const superado = objetivoPendiente <= 0
  const objAbs = cuandoEvaluar.id === 'M' && idMedida === 'N' ? objetivoAbs :
    cuandoEvaluar.id === 'M' && idMedida === 'S' ? objetivoAbsMes :
      objetivoAbsTotal;

  return (
    <>
      <LFooter>
        <FooterInfo>Objetivo </FooterInfo>
        {formatNumberWithThousands(objAbs)} {objetivoUnidad}
      </LFooter>
      <br />
      <Inner>
        <Footer>
          <div>
            <KpiInfo>
              {superado
                ? 'Superado por'
                : diasLaborablesRestantes > 0
                  ? 'Quedan'
                  : 'No superado por'}
            </KpiInfo>
            <Kpi success={superado}>
              {formatNumberWithThousands(Math.abs(objetivoPendiente))} {unit}
            </Kpi>
          </div>
          <div style={{ position: 'relative', marginTop: '10px' }}>
            <Icon type={icon} size={60} />
            {withException && (
              <AntIcon
                type="stop"
                style={{ position: "absolute", top: 45, right: 40, fontSize: 22 }}
                theme="twoTone"
                twoToneColor={colors.scarlet}
              />
            )}
            {(cuandoEvaluar.id !== 'M' && idMedida === 'N') &&
              <FpInfo>
                {cuandoEvaluar.id === 'A' ? 'A' : 'P'}
              </FpInfo>
            }
          </div>
        </Footer>
      </Inner>
      <RFooter>
        <FooterInfo>Actual </FooterInfo>
        {formatNumberWithThousands(objAbs - objetivoPendiente)} {objetivoUnidad}
      </RFooter>
      {/* {(diasLaborablesRestantes <= 0 || objetivoPendiente === 0) && (
        <div style={{ position: "relative" }}>
          <CircleStamp completed={superado} isNumeric={true} />
        </div>
      )} */}
    </>
  )
}

const Inner = styled.div`
  margin-top: 28px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: right;
  align-self: flex-end;
`

const Kpi = styled.div`
  font-size: 35px;
  font-weight: 600;
  line-height: 0.7;
  letter-spacing: 0.76px;
  text-align: left;
  color: ${props => (props.success ? `${colors.trueGreen}` : `${colors.redCruz}`)};
`
const KpiInfo = styled.div`
  font-size: 24px;
  font-weight: normal;
  line-height: 1.7;
  text-align: right;
  color: var(--black);
`
const FpInfo = styled.div`
position: absolute;
bottom: -7px;
right: 0px;
font-size: 19px;
color: #686464;
font-weight: bold;
text-shadow: 2px 0 0 #f9faf9, -2px 0 0 #f9faf9, 0 2px 0 #f9faf9, 0 -2px 0 #f9faf9, 1px 1px #f9faf9, -1px -1px 0 #f9faf9, 1px -1px 0 #f9faf9, -1px 1px 0 #f9faf9; 
`
const LFooter = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: var(--black);
  float: left;
  max-height: 20px;
  overflow: hidden;
`

const RFooter = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: var(--black);
  float: right;
  max-height: 20px;
  overflow: hidden;
  text-align: right;
  margin-top: 9px;
`

const FooterInfo = styled.span`
  font-size: 15px;
  line-height: 0.94;
  letter-spacing: 0.2px;
  font-weight: normal;
  color: var(--warm-grey);
`
