import React from 'react'
import { colors, dateFilter, formatNumberWithThousands } from 'Utils'
import styled from '@emotion/styled'
import { Switch as AntSwitch } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { showErrorNotification } from 'Components'

const totalSwitch = (exceptions, removeAll, addAll, numArt) => {
  return (
    <StyledTotalSwitch
      unCheckedChildren={<CheckOutlined style={{ color: colors.ligthGreen, fontSize: "10px" }} />}
      checkedChildren={(numArt - exceptions.length) !== 0 ? "" :
        <CloseOutlined style={{ color: colors.ligthRed, fontSize: "10px" }} />}
      checked={exceptions.length !== 0}
      onClick={c => c ? addAll() : removeAll()}
    ></StyledTotalSwitch>
  )
}

export const articleColumns = (onSelectionChange, exceptions, removeAll, addAll, numArt) => [
  {
    title: totalSwitch(exceptions, removeAll, addAll, numArt),
    dataIndex: 'Excep',
    key: 'Excep',
    width: 60,
    render: (text, row) => {
      return (
        <StyledRowSwitch
          unCheckedChildren={<CheckOutlined style={{ color: colors.trueGreen, fontSize: "10px" }} />}
          checkedChildren={<CloseOutlined style={{ color: colors.redCruz, fontSize: "10px" }} />}
          checked={exceptions.find(el => el.codigo === row.codArticulo) !== undefined}
          onChange={(c) =>
            (exceptions.length >= 100 && c) ? showErrorNotification('No se permiten más de 100 Deselecciones en los artículos de un objetivo') : onSelectionChange({ codArticulo: row.codArticulo }, !c)
          }
        ></StyledRowSwitch>
      )
    },
  },
  {
    title: 'Código',
    dataIndex: 'codArticulo',
    key: 'codArticulo',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 100,
  },
  {
    title: 'Nombre',
    dataIndex: 'nombre',
    key: 'nombre',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 376,
  },
  {
    title: 'Familia',
    dataIndex: 'familia',
    key: 'familia',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 100,
  },
  {
    title: 'Marca',
    dataIndex: 'marca',
    key: 'marca',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 100,
  },
]

export const clientColumns = (onSelectionChange, exceptions, removeAll, addAll, numCli) => [
  {
    title: totalSwitch(exceptions, removeAll, addAll, numCli),
    dataIndex: 'Excep',
    key: 'Excep',
    width: 60,
    render: (text, row) => {
      return (
        <StyledRowSwitch
          unCheckedChildren={<CheckOutlined style={{ color: colors.trueGreen, fontSize: "10px" }} />}
          checkedChildren={<CloseOutlined style={{ color: colors.redCruz, fontSize: "10px" }} />}
          checked={exceptions.find(el => el.codigo === row.codCliente) !== undefined}
          onChange={(c) =>
            (exceptions.length >= 100 && c) ? showErrorNotification('No se permiten más de 100 Deselecciones en los clientes de un objetivo') : onSelectionChange({ codCliente: row.codCliente }, !c)
          }
        ></StyledRowSwitch>
      )
    },
  },
  {
    title: 'Nombre del cliente',
    dataIndex: 'nombre',
    key: 'nombre',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 150,
  },
  {
    title: 'Código del cliente',
    dataIndex: 'codCliente',
    key: 'codCliente',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 120,
  },
  {
    title: 'Tipo',
    dataIndex: 'tipoCliente',
    key: 'tipoEstablec.id',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 180,
  },
  {
    title: 'Dirección',
    dataIndex: 'direccion',
    key: 'direccion',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 180,
  },
]

export const dateColumn = {
  title: 'Modificado',
  dataIndex: 'modifEn',
  key: 'modifEn',
  render: value => {
    return <span>{dateFilter(value)}</span>
  },
  sorter: true,
  sortDirections: ['ascend', 'descend', 'ascend'],
  width: 100,
}

const mapPdvSituation = {
  C: 'Crecen',
  M: 'Mantienen',
  D: 'Decrecen',
  N: 'Nuevos',
  P: 'Perdidos',
  NC: 'No compradores',
}

export const pdvColumn = (measure, measureOp) =>
  [
    {
      title: `Punto de venta${measure ? '' : ' oportunidad'}`,
      dataIndex: 'codPtoVenta',
      key: 'codPtoVenta',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 130,
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Población',
      dataIndex: 'poblacion',
      key: 'poblacion',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    measure && {
      title: 'Situación',
      dataIndex: 'pdvSituacion',
      key: 'pdvSituacion',
      render: value => {
        return <Cell>{mapPdvSituation[value]}</Cell>
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 110,
    },
    // {
    //   title: 'Estado',
    //   dataIndex: 'situacion',
    //   key: 'pdvEstado',
    //   sorter: true,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   width: 100,
    // },
    /*{
      title: 'Ruta preventa',
      dataIndex: 'rutaPrev',
      key: 'rutaPrev',
      render: (value) => {
        const obj = {
          children: value,
        };
        return obj;
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 110,
    },*/
    {
      title: 'Ruta preventa',
      dataIndex: 'rutaPrev',
      key: 'rutaPrev',
      align: 'center',
      render: (value) => {
        const obj = {
          children: value.desc,
        };
        return obj;
      },
    },
    measure && {
      title: measure.act,
      dataIndex: 'valorMedidaDia',
      key: 'valorMedidaDia',
      render: value => {
        return (
          <Cell negative={value < 0}>
            {formatNumberWithThousands(value, 0)}
          </Cell>
        )
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 85,
    },
    measure && {
      title: measure.aa,
      dataIndex: 'valorMedidaAA',
      key: 'valorMedidaAA',
      render: value => {
        return (
          <Cell negative={value < 0}>
            {formatNumberWithThousands(value, 0)}
          </Cell>
        )
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 85,
    },
    measure && {
      title: 'Dif',
      dataIndex: 'difMedida',
      key: 'difMedida',
      render: value => {
        return (
          <Cell negative={value < 0}>
            {formatNumberWithThousands(value, 0)}
          </Cell>
        )
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 85,
    },
    !measure && {
      title: measureOp,
      dataIndex: 'calcMedida',
      key: 'calcMedida',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 120,
      render: value => {
        return (
          <Cell negative={value < 0}>
            {formatNumberWithThousands(value, 0)}
          </Cell>
        )
      },
    },
  ].filter(col => col)

const Cell = styled.span`
  text-align: right;
  color: ${props => (props.negative ? 'rgba(255,0,0,.6)' : 'rgba(0,0,0,.6)')};
`

const StyledTotalSwitch = styled(AntSwitch)`
  background: ${props => (
    (props.checked && props.checkedChildren === "") ? colors.warmGrey :
      (props.checked && props.checkedChildren !== "") ? colors.redCruz :
        colors.trueGreen
  )};
`

const StyledRowSwitch = styled(AntSwitch)`
  background: ${props => (props.checked ? colors.ligthRed : colors.ligthGreen)};
`
