import React, { useState } from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'

export const ExceptionsEllipse = ({ children, collapsed, fontInherit = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  return (
    <ExceptionsInner fontInherit={fontInherit}>
      <span>
        {isCollapsed && children.length > 50
          ? (children + '').slice(0, 50)
          : children}
      </span>
      {children.length > 50 && (
        <ExceptionsSeeMore onClick={() => setIsCollapsed(!isCollapsed)} fontInherit={fontInherit}>
          {isCollapsed ? '...Ver más' : '...Ver menos'}
        </ExceptionsSeeMore>
      )}
    </ExceptionsInner>
  )
}

const ExceptionsInner = styled.div`
  font-size: ${({ fontInherit }) => fontInherit ? 'inherit' : '16px'};
  line-height: 1.5;
  color: ${({ fontInherit }) => fontInherit ? 'inherit' : colors.black};
  margin-bottom: 22px;
`

export const ExceptionsLabel = styled.span`
  color: #757575;
`
const ExceptionsSeeMore = styled.span`
  font-size: ${({ fontInherit }) => fontInherit ? 'inherit' : '14px'};
  font-weight: 600;
  color: #008200;
  cursor: pointer;
  padding-left: 10px;
`
