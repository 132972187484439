export const ArticlesMockup = [
  {
    key: '1',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '24 May 2019',
  },
  {
    key: '2',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '3',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '4',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '5',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '6',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '7',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '8',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '9',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '10',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '11',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '12',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '13',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '14',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
  {
    key: '15',
    code: '1234567',
    name: 'Nombre del artículo',
    type: 'Cerveza',
    brand: 'Heineken',
    created: '23 May 2019',
  },
]
