import React, { Fragment, useState } from 'react'

import { Table as AntTable } from 'antd'
import { Spinner } from 'Components'
import { Waypoint } from 'react-waypoint'
import styled from '@emotion/styled'

let fetchingMore = false

export const Table = ({
  cursor = true,
  content,
  totalElements,
  onRow,
  fetchMore,
  updateQueryPath,
  onSort,
  searchTerm,
  sortBy,
  sortDir,
  rowSelection,
  ...props
}) => {
  let [pager, setPager] = useState(1)

  return (
    <Fragment>
      <StyledTable
        cursor={cursor && !rowSelection}
        onChange={(_, __, { field, order }) => {
          setPager(1)
          return onSort({
            sortBy: field,
            sortDir: order === 'ascend' ? 'asc' : 'desc',
          })
        }}
        rowSelection={rowSelection}
        dataSource={content}
        pagination={false}
        onRow={article => onRow && onRow(article, searchTerm, sortBy, sortDir)}
        rowKey={(_, index) => index}
        bordered
        {...props}
      ></StyledTable>
      {fetchingMore && <Spinner />}
      <Waypoint
        scrollableAncestor={rowSelection ? null : window}
        onEnter={async () => {
          if (content.length < totalElements) {
            fetchingMore = true
            fetchMore({
              variables: {
                page: pager,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev

                return Object.assign({}, prev, {
                  [updateQueryPath]: {
                    ...prev[updateQueryPath],
                    content: [
                      ...prev[updateQueryPath].content,
                      ...fetchMoreResult[updateQueryPath].content,
                    ],
                  },
                })
              },
            })
            setPager(pager + 1)
            fetchingMore = false
          }
        }}
      ></Waypoint>
    </Fragment>
  )
}

const StyledTable = styled(AntTable)(({ cursor }) => ({
  background: 'white',
  cursor: cursor ? 'pointer' : 'initial',
  '& .ant-table-thead > tr > th': {
    background: '#f2f2f2',
    fontFamily: 'MyriadPro-BoldCond',
    fontSize: 16,
  },
}))
