import React, { useContext } from 'react'

import { LastUpdate, SideMenu } from 'Components';
import { GlobalContext, checkRolePermits, } from 'Utils'

import { Layout } from 'antd';
import {
  AimOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled'
import { Link } from '@reach/router';
import { get } from 'lodash'

import starShape from 'Assets/images/MenuStarShape.svg'

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export const Sider = ({ collapsed, setCollapsed }) => {
  const context = useContext(GlobalContext)
  const roles = get(context, 'currentUser.roles')

  const items = [
    getItem('OBJETIVOS', '1', <AimOutlined />, [
      getItem(<Link to="/dashboard/objectives/follow-up/active">Seguimiento</Link>, '1.1'),
      checkRolePermits(roles, ['GestionObjetivo']) && getItem(<Link to="/dashboard/objectives/gestion">Gestión</Link>, '1.2'),
      getItem(<Link to="/dashboard/objectives/table-objetives/objetivos">Tabla objetivos</Link>, '1.3'),
    ]),
    checkRolePermits(roles, ['InformesDashboardsGdia', 'InformesDashboardsGmes', 'InformesDashboardsTanalisis']) &&
    getItem('CUADROS DE MANDO', '2', <LineChartOutlined />, [
      checkRolePermits(roles, ['InformesCalidad']) && getItem(<Link to="/dashboard/management-dashboard/data-quality">Calidad de datos</Link>, '2.1'),
      checkRolePermits(roles, ['InformesVentas']) && getItem(<Link to="/dashboard/management-dashboard/sales">Ventas</Link>, '2.2'),
      checkRolePermits(roles, ['InformesDashboardsGdia', 'InformesDashboardsGmes']) && getItem(<Link to="/dashboard/management-dashboard/evolution">Gráficos evolución</Link>, '2.3'),
      checkRolePermits(roles, ['InformesDashboardsTanalisis']) && getItem(<Link to="/dashboard/management-dashboard/table-analysis">Tablas de análisis</Link>, '2.4'),
    ]),
  ];

  return (
    <Wrapper>
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width="218"
        style={{
          paddingTop: 90,
          backgroundImage: `url(${starShape})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom 175px left',
          height: '100%',
          position: 'fixed',
          left: 0,
          backgroundColor: 'white',
          boxShadow: '0 4px 12px 0 rgba(200,198,198,0.5)',
        }}
      >
        <SideMenu defaultSelectedKeys={['1']} mode="inline" items={items} />
        <LastUpdate collapsed={collapsed}></LastUpdate>
      </Layout.Sider>
    </Wrapper>
  );
};

const Wrapper = styled.div({
  ' .ant-layout-sider-children': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  ' .ant-menu-inline': {
    borderRight: 'none',
  },
})
