import loginLogo from 'Assets/logo/logo-clave@2x.png';
import bgImage from 'Assets/images/bkgr_img2.jpg';
import bgImage2 from 'Assets/images/bkgr_img3.jpg';
import hkLogo from 'Assets/images/tenant_logo.png';
import azureLogo from 'Assets/images/azure-ad.png';

import { Auth, I18n } from 'aws-amplify';
import {
  Authenticator,
  Flex,
  Grid,
  Image,
  useTheme,
  View,
  Button,
  useAuthenticator,
  translations
} from "@aws-amplify/ui-react";
import styled from '@emotion/styled'
import { Row, Col, Button as AntButton } from 'antd'

I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabulariesForLanguage('es', {
  'Send code': 'Enviar código',
  'CUSTOM_AUTH is not enabled for the client.': 'La contraseña no puede estar vacía.',
  'Incorrect username or password.': 'Nombre de usuario o contraseña incorrecta.',
  '2 validation errors detected: Value at \'userAlias\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at \'userName\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+': 'Email mal formado.',
});

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          height={100}
          src={loginLogo}
        />
      </View>
    );
  },

  Footer() {
    return (
      <View textAlign="center" >
        <Image
          alt="Hk logo"
          height={55}
          src={hkLogo}
        />
      </View>
    );
  },
  SignIn: {
    Header() {
      return (
        <View textAlign="center">
          <ButtonAdd onClick={() => {
            Auth.federatedSignIn({ customProvider: "AzureAD-IdentityProvider" })
          }}>
            <Row>
              <Col span={3} offset={5}>
                <Image
                  alt="azure logo"
                  height={25}
                  src={azureLogo}
                />
              </Col>
              <Col style={{ paddingTop: 2 }}>
                Accede con Azure <span style={{ color: '#058844', fontWeight: 'bold' }}>Heineken</span>
              </Col>
            </Row>
          </ButtonAdd>
        </View>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();
      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Recuperar contraseña
          </Button>
        </View>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      label: 'E-mail',
      placeholder: 'E-mail',
    },
    password: {
      labelHidden: false,
      label: 'Contraseña',
      placeholder: 'Contraseña',
    },
  },
  resetPassword: {
    username: {
      labelHidden: true,
      placeholder: 'Introduzca su email:',
    },
  },
};

export function Login() {
  const { tokens } = useTheme();

  const hash = document.location.hash.replace('#', '')
  const isLogin = ['/login'].includes(hash)

  if (!isLogin) {
    window.location.href = '#/login'
  }
  const inputEmail = document.getElementById('amplify-id-:r1:');
  let email = inputEmail?.value?.trim();
  if (inputEmail) {
    inputEmail.value = email;
  }

  return (
    <Grid style={{ overflowY: 'hidden' }} templateColumns={{ base: "0.8fr .9fr 0.8fr" }}>
      <View height="100vh">
        <Image
          src={bgImage}
          width="100%"
          height="100%"
          objectFit="cover"
        />
      </View>
      <Flex
        backgroundColor={tokens.colors.background.secondary}
        justifyContent="center"
        style={{ zIndex: 100 }}
      >
        <Authenticator
          style={{ padding: 10 }}
          hideSignUp={true}
          formFields={formFields}
          components={components}
        >
          {({ signOut, user }) => (
            <main>
              <h1>Hello {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </Flex>
      <View height="100vh">
        <Image
          src={bgImage2}
          width="100%"
          height="100%"
          objectFit="cover"
        />
      </View>
    </Grid >
  );
}

const ButtonAdd = styled(AntButton)(() => ({
  width: '87%',
  height: 38,
  padding: 5,
  marginTop: 15,
  background: 'white',
  border: 'solid 1px #999999',
  borderRadius: 5
}))
