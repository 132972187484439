import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'

export const showNoUserCodeModal = callback => {
  Modal.warning({
    title: <ConfirmTitle>Usuario sin distribuidor asociado</ConfirmTitle>,
    content: `El usuario utilizado no tiene distribuidor asociado, por favor, contacte con soporte.`,
    centered: true,
    icon: null,
    okText: 'Continuar',
    onOk: callback,
    onCancel: callback,
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
