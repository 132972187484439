import React from 'react';
import ReactDOM from 'react-dom/client';
import '@aws-amplify/ui-react/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { client } from 'Utils';
import { ApolloProvider } from '@apollo/client';

import es_ES from 'antd/lib/locale-provider/es_ES';
import 'moment/locale/es';  // important!
import { ConfigProvider } from "antd";

Amplify.configure({
  aws_project_region: process.env.REACT_APP_ENV_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_ENV_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_ENV_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_ENV_AWS_USER_POOLS_WEB_CLIENT_ID,
  Auth: {
    region: process.env.REACT_APP_ENV_AWS_REGION,
    userPoolId: process.env.REACT_APP_ENV_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_ENV_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_ENV_AWS_DOMAIN,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.REACT_APP_SITE_URL,
    redirectSignOut: process.env.REACT_APP_SITE_URL,
    responseType: "code",
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AmplifyProvider>
    <Authenticator.Provider>
      <ApolloProvider client={client}>
        <ConfigProvider locale={es_ES}>
          <App />
        </ConfigProvider>
      </ApolloProvider>
    </Authenticator.Provider>
  </AmplifyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
