import React from 'react'

import { PageErrorWarning } from 'Components'
import { DashboardContextConsumer } from 'Utils'

export const OutOfService = props => (
  <DashboardContextConsumer>
    {dashboardProps => <OutOfAlert {...dashboardProps} {...props} />}
  </DashboardContextConsumer>
)

export const OutOfAlert = () => {
  return (PageErrorWarning('Funcionalidad temporalmente deshabilitada', 'Perdone las molestias'))
}
