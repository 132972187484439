import React from 'react'
import styled from '@emotion/styled'
import {
  OpUserInfoCell,
  OpDescriptionRow,
  OpenTable
} from 'Components'
import { colors } from 'Utils'

export const ObjectiveOpenAdjustmentTable = ({
  isCommon,
  dataSource,
  onChange,
  onChangeColumn,
  showPeriod,
  periodDate,
}) => {

  const {
    cabecera = [],
    valoresCeldaPorEmpleadoMes = [],
  } = dataSource

  return (
    <Wrapper>
      <OpAdjustesColumn shadow isAbsolut={showPeriod}>
        {valoresCeldaPorEmpleadoMes.map(({ empleado, nivelAnidacion }, index) => (
          <OpUserInfoCell
            key={`${empleado.codMiembro}_${index}`}
            empleado={empleado}
            nivel={nivelAnidacion}
          />
        ))}
      </OpAdjustesColumn >
      {!isCommon && (
        <OpAdjustesColumn isAbsolut={showPeriod}>
          <OpDescriptionRow
            title={'Descripción'}
            dataSource={valoresCeldaPorEmpleadoMes}
            onChange={onChange}
            showPeriod={showPeriod}
          />
        </OpAdjustesColumn>
      )}
      <OpAdjustesColumn shadow isAbsolut={showPeriod}>
        <OpenTable
          headerValues={cabecera}
          dataSource={valoresCeldaPorEmpleadoMes}
          onChange={onChange}
          onChangeColumn={onChangeColumn}
          isCommon={isCommon}
          showPeriod={showPeriod}
          periodDate={periodDate}
        />
      </OpAdjustesColumn>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  width: 'fit-content',
})

export const OpAdjustesColumn = styled.div(({ shadow, showDetails = true, isAbsolut }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  '>div': {
    boxShadow: shadow
      ? '1px 1px 20px 0 rgba(200, 198, 198, 0.5)'
      : showDetails
        ? `0px 0px 10px 1px ${colors.lime}`
        : 'none',
  },
  margin: '20px 0 20px 0',
  ':first-of-type': {
    height: 'fit-content',
    margin: isAbsolut ? '75px 0 20px 20px' : '56px 0 20px 20px',
  },
  ':last-child': {
    margin: '20px 30px 20px 0',
    width: 'min-content',
  },
}))
