import React, { useContext } from 'react'

import {
  OptionGroupButtonWhat,
  OptionGroupButtonHow,
  OptionGroupButtonWhen,
  ObjectiveNameAndTag,
} from 'Fragments'
import { GET_FORM_OPTIONS } from 'Queries'
import { GlobalContext, ObjectiveContextConsumer } from 'Utils'

import { Row, Spin } from 'antd'
import styled from '@emotion/styled'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'

export const ObjectiveDefinition = () => {
  const { resetKeepAliveTimer } = useContext(GlobalContext)
  const formOptionsResource = useQuery(GET_FORM_OPTIONS, {
    onCompleted: resetKeepAliveTimer,
  })

  if (!formOptionsResource.data) return null

  const whatFormOptions =
    get(formOptionsResource, 'data.objetivoQueMedidas') || []

  const whatSalesTypeFormOptions =
    get(formOptionsResource, 'data.objetivoQueTipoEntrega') || []

  const howFormOptions = {
    type: formOptionsResource.data.objetivoComoTipoValorRefs || [],
    distribution:
      formOptionsResource.data.objetivoComoDistribucionValorRefs || [],
  }
  const whenFormOptions = {
    objetivoCuandoEvaluar: formOptionsResource.data.objetivoCuandoEvaluar,
    objetivoCuandoEvaluarFinPeriodo: formOptionsResource.data.objetivoCuandoEvaluarFinPeriodo,
    objetivoCuandoOpcionesPeriodosVigencia:
      formOptionsResource.data.objetivoCuandoOpcionesPeriodosVigencia || [],
  }

  return (
    <ObjectiveContextConsumer>
      {({ objective }) => (
        <Inner>
          <Content>
            <Row type="flex" justify="center">
              <ObjectiveNameAndTag objective={objective} />
            </Row>
            <Row type="flex" justify="center">
              <OptionsGroupWrapper>
                <OptionGroupButtonWhat
                  whatFormOptions={whatFormOptions}
                  whatSalesTypeFormOptions={whatSalesTypeFormOptions}
                  objective={objective}
                />
                <OptionGroupButtonHow
                  formOptions={howFormOptions}
                  objective={objective}
                />
                <OptionGroupButtonWhen
                  formOptions={whenFormOptions}
                  objective={objective}
                />
              </OptionsGroupWrapper>
            </Row>
          </Content>
          {objective.recalculating && (
            <SpinWrapper>
              <Spin tipe="Recalculando..." />
            </SpinWrapper>
          )}
        </Inner>
      )}
    </ObjectiveContextConsumer>
  )
}

const Content = styled.div`
  margin: 20px;
`
const Inner = styled.div({
  width: '100%',
  height: 'calc(100vh - 180px)',
  position: 'relative',
  overflowY: 'auto',
})
const SpinWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justiy-content: center;
  background: #f5f5f5bb;
  .ant-spin-spinning {
    flex: 1;
  }
`
const OptionsGroupWrapper = styled.div({
  width: 772,
})
