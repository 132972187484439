import React, { useState, useEffect } from 'react'
import {
  TableRowInner,
  InputWrapper,
  Inner,
  Button,
  ErrorMessage,
} from './styled'
import { getRetributionValue } from './utils'

import { ReactComponent as RecicleBinSvg } from 'Assets/icons/recycle-bin-icon.svg'
import { ReactComponent as EditSvg } from 'Assets/icons/edit-icon.svg'
import { ReactComponent as AddGreenSvg } from 'Assets/icons/add-green-icon.svg'
import { ReactComponent as RemoveGreenSvg } from 'Assets/icons/remove-green-icon.svg'
import Icon from '@ant-design/icons'
import { CellInput } from 'Components'
import styled from '@emotion/styled'

export const TableRow = ({
  min,
  max,
  minRetribution,
  maxRetribution,
  desde,
  hasta,
  valor,
  lineal,
  limite,
  orden,
  secuencia,
  index,
  onRowEdit,
  editedRow,
  editIncentivesMatrix,
  deleteIncentivesMatrix,
  addNewRow,
  removeNewRow,
}) => {
  const isEdit = editedRow && editedRow === index
  const disabled = editedRow && editedRow !== index
  const addDisabled =
    disabled || (max - desde < 2 || maxRetribution - valor < 2)
  const isHastaEnabled = editedRow && editedRow - 1 === index

  const [newDesde, setNewDesde] = useState(desde)
  const [newHasta, setNewHasta] = useState(hasta)
  const [newRetribution, setNewRetribution] = useState(valor)

  const [desdeError, setDesdeError] = useState(null)
  const [hastaError, setHastaError] = useState(null)
  const [retributionError, setRetributionError] = useState(null)

  useEffect(() => {
    if (desde !== newDesde) setNewDesde(desde)
    if (valor !== newRetribution) setNewRetribution(valor)
  }, [desde, valor])

  const onSave = () => {
    if (min >= newDesde || newDesde >= max) {
      if (limite) {
        setDesdeError(`Introduce un número mayor que ${min}`)
      } else {
        setDesdeError(`Introduce un número válido [${min + 1}-${max - 1}]`)
      }
    }
    if (minRetribution >= newRetribution || newRetribution >= maxRetribution) {
      if (limite) {
        setRetributionError(`Introduce un número mayor que ${minRetribution}`)
      } else {
        setRetributionError(
          `Introduce un número válido [${minRetribution + 1}-${maxRetribution -
          1}]`,
        )
      }
    }

    if (lineal && newDesde >= newHasta) {
      setHastaError(`Introduce un número mayor que ${newDesde || min + 1}`)
    }

    if (
      min < newDesde &&
      newDesde < max &&
      minRetribution < newRetribution &&
      newRetribution < maxRetribution
    ) {
      editIncentivesMatrix({
        variables: {
          secuencia,
          desde: newDesde,
          hasta,
          valor: newRetribution,
          lineal,
        },
      }).then(a => {
        onRowEdit(null)
        setDesdeError(null)
        setRetributionError(null)
        setHastaError(null)
      })
    }
    if (lineal && min < newDesde && newDesde < newHasta) {
      editIncentivesMatrix({
        variables: {
          secuencia,
          desde: newDesde,
          hasta: newHasta,
          lineal,
        },
      }).then(a => {
        onRowEdit(null)
        setDesdeError(null)
        setRetributionError(null)
        setHastaError(null)
      })
    }
  }

  const onDeleteClick = () => {
    if (!disabled)
      deleteIncentivesMatrix({
        variables: { secuencia },
      })
  }
  const onEditClick = () => {
    if (!disabled) {
      onRowEdit(index)
    }
  }
  const onAddClick = () => {
    if (lineal) {
      setNewHasta(null)
      setNewDesde(null)
    }
    if (!addDisabled)
      addNewRow(orden, {
        desde: null,
        hasta: max,
        limite: false,
        lineal: lineal,
        valor: null,
      })
  }

  return (
    <TableRowInner>
      <TableRowInput
        isEdit={isEdit}
        value={desde}
        error={desdeError}
        onChange={value => {
          setNewDesde(parseInt(value))
          setDesdeError(null)
        }}
      />
      <TableRowInput
        isEdit={isEdit && lineal}
        value={
          (limite && 'Resto') || (!lineal && isHastaEnabled && '-%') || hasta
        }
        error={hastaError}
        onChange={value => {
          setNewHasta(parseInt(value))
          setHastaError(null)
        }}
      />

      <TableRowInput
        bold
        disabled={lineal && !secuencia}
        isEdit={isEdit && !lineal}
        value={getRetributionValue(valor, lineal)}
        error={retributionError}
        onChange={value => {
          setNewRetribution(parseInt(value))
          setRetributionError(null)
        }}
      />

      <Inner>
        {!isEdit && (
          <>
            {!limite && !lineal && desde > 0 && (
              <AntdIcon
                disabled={disabled}
                component={RecicleBinSvg}
                onClick={onDeleteClick}
              />
            )}
            {desde > 0 && (
              <AntdIcon
                disabled={disabled}
                component={EditSvg}
                onClick={onEditClick}
              />
            )}
            {!limite && (!lineal || (lineal && !secuencia)) && (
              <AntdIcon
                disabled={addDisabled}
                component={AddGreenSvg}
                onClick={onAddClick}
              />
            )}
            {!limite && lineal && secuencia && (
              <AntdIcon
                disabled={disabled}
                component={RemoveGreenSvg}
                onClick={onDeleteClick}
              />
            )}
          </>
        )}
        {isEdit && (
          <>
            <Button
              size="large"
              type="link"
              style={{ float: 'right' }}
              onClick={onSave}
            >
              Guardar
            </Button>
            <Button
              size="large"
              type="link"
              style={{ float: 'right' }}
              onClick={() => {
                onRowEdit(null)
                setDesdeError(null)
                setRetributionError(null)
                setHastaError(null)
                setNewHasta(hasta)
                setNewDesde(desde)
                setNewRetribution(valor)
                if (!secuencia) {
                  removeNewRow(index)
                }
              }}
            >
              Cancelar
            </Button>
          </>
        )}
      </Inner>
    </TableRowInner>
  )
}

const CellInputError = ({ error }) =>
  error ? <ErrorMessage>{error}</ErrorMessage> : null

const TableRowInput = ({ isEdit, value, error, onChange, bold, disabled }) => (
  <InputWrapper bold={bold}>
    {isEdit ? (
      <>
        <CellInput
          value={(parseInt(value) && value) || ''}
          percentage={false}
          suffix="%"
          onlyPositive={true}
          updateOnChange={true}
          onChange={onChange}
        />
        <CellInputError error={error} />
      </>
    ) : (
      <TableRowInputValue disabled={disabled}>
        {(parseInt(value) && value + '%') || value}
      </TableRowInputValue>
    )}
  </InputWrapper>
)

const TableRowInputValue = styled.span`
  color: ${props => (props.disabled ? '#c7c7c7' : 'inherit')};
`

const AntdIcon = styled(Icon)`
  opacity: ${props => (props.disabled ? 0.5 : 'initial')};

  &[tabindex] {
    cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  }
`
