import React, { useState, createRef } from 'react'

import { Icon } from 'Components'
import { formatNumberWithThousands, colors } from 'Utils'

import styled from '@emotion/styled'

export const ObjectiveBoxOpen = ({ kpi, comercialView }) => {
  const {
    description,
    porcCumplimientoMedidaRealDia,
    countCo,
    countJe,
  } = kpi

  const ref = createRef();
  const [showMore, setShowMore] = useState(false);

  const onClickMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <DescFooter
        ref={ref}
        onMouseEnter={onClickMore}
        onMouseLeave={onClickMore}
        showMore={showMore}
      >
        <FooterInfo>Descripción </FooterInfo>
        {(description === '' || description === null) ? 'No hay descripción' : description}
      </DescFooter>
      <br />
      <Inner showMore={showMore}>
        {comercialView ? (
          <ComFooter>
            <KpiInfo>
              Conseguido
            </KpiInfo>
            <Kpi percentage={porcCumplimientoMedidaRealDia}>
              <span>
                {formatNumberWithThousands(
                  Number(porcCumplimientoMedidaRealDia).toFixed(1), true
                )}
                <KpiPercent>%</KpiPercent>
              </span>
            </Kpi>
          </ComFooter>
        ) : (
          <GeFooter>
            <FooterInfo>Nº Jefes eq. / Especialistas </FooterInfo>
            {countJe}
            <br />
            <FooterInfo>Nº Comerciales </FooterInfo>
            {countCo}
          </GeFooter>
        )}
        <div style={{ marginTop: '5px', marginRight: '5px' }}>
          <Icon type="hand" size={55} />
        </div>
      </Inner>
      <br />
    </>
  )
}

const Inner = styled.div`
  display: flex;
  float: left;
  justify-content: space-between;
  width: 100%;
  align-self: flex-end;
  ${props => (props.showMore && 'margin-top: 40px;')}
`

const Kpi = styled.div`
  font-size: 38px;
  font-weight: 600;
  line-height: 0.7;
  letter-spacing: 0.76px;
  text-align: right;
  color: ${props => (props.percentage >= 100 ? `${colors.trueGreen}` : `${colors.redCruz}`)};
`
const KpiInfo = styled.div`
  font-size: 22px;
  font-weight: normal;
  line-height: 1.7;
  text-align: right;
  color: var(--black);
`

const DescFooter = styled.div`
  padding-right: 10px;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.36px;
  color: var(--black);
  float: left;
  max-width: 310px;
  cursor: 'pointer';
  min-height: 60px;
  ${props => (props.showMore ? `
  width: max-content;
  background: #f9faf9;
  position: absolute;
  ` : `
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  `)}
`

const GeFooter = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: var(--black);
  float: left;
  overflow: hidden;
  margin-top: 10px;
  width: 210px;
`

const ComFooter = styled.div`
  width: 210px;
  padding-right: 70px;
`

const FooterInfo = styled.span`
  font-size: 16px;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: 0.29px;
  font-weight: normal;
  color: var(--warm-grey);
`

const KpiPercent = styled.span`
  font-size: 15px;
`
