import React from 'react'
import { Row, Col } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'

export const WarningMessage = ({ children, sWidth }) => {
  return (
    <Inner sWidth={sWidth}>
      <Row>
        <Col span={4}>
          <WarningIcon />
        </Col>
        <Col span={20}>{children}</Col>
      </Row>
    </Inner>
  )
}

const Inner = styled.div`
  width: ${props => (props.sWidth !== undefined ? props.sWidth : '100%')};
  margin-left: ${props => (props.sWidth !== undefined && '150px')};
  min-height: 50px;
  border-radius: 8px;
  background-color: #ffe16a;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #686c71;
  padding: 9px 16px;
`

const WarningIcon = styled(WarningOutlined)`
  padding: 3px;
  color: #686c71;
  font-size: 23px;
`
