import React from 'react'
import {
  Dropdown as AntDropdown,
  Button as AntButton,
  Menu as AntMenu,
} from 'antd'
import { Icon as AntIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { isArray } from 'lodash'

const { Item } = AntMenu

export const MultipleButton = ({ children }) => {
  const menu = (
    <Menu>
      {(isArray(children) &&
        children.map((child, index) => <Item key={index}>{child}</Item>)) || (
          <Item>{children}</Item>
        )}
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button size="large">
        Crear objetivo
        <Icon type="down"></Icon>
      </Button>
    </Dropdown>
  )
}

const Menu = styled(AntMenu)({
  ' .ant-dropdown-menu-item:hover': {
    backgroundColor: 'white',
  },
})

const Dropdown = styled(AntDropdown)({})

const Button = styled(AntButton)({
  background: '#008200',
  fontSize: 16,
  lineHeight: 1.5,
  color: 'white',
  '&:hover': {
    background: '#008200',
    color: 'white',
  },
})

const Icon = styled(AntIcon)({})
