import React, { useContext, useState, useEffect } from 'react'

import {
  ObjectivePannel,
  ObjectiveAdjustmentTable,
  ResourceWrapper,
} from 'Components'
import {
  RECALCULATE_OBJECTIVE_ADJUSTS,
  UPDATE_OBJECTIVE_ADJUSTS,
  OBJECTIVE,
  OBJECTIVE_WITH_ADJUSTS,
  OBJECTIVE_HIERARCHICAL_ADJUSTS,
  UPDATE_OBJECTIVE_HOW_WITH_ADJUSTS_V2,
} from 'Queries'
import {
  colors,
  GlobalContext,
  ObjectiveContext,
  getMutationVars,
  getObjectiveIdVars,
} from 'Utils'
import { ObjectiveAdjustmentsMock } from 'Mockups'

import { Tabs, Alert } from 'antd'
import styled from '@emotion/styled'
import { useMutation, useLazyQuery } from '@apollo/client'
import { get, isNil } from 'lodash'
import moment from 'moment'

export const ObjectiveAdjustments = () => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { resetKeepAliveTimer, setObjective, isDev, isLoading, setIsLoading } = context

  const objective = isDev
    ? ObjectiveAdjustmentsMock.data.objective
    : context.objective

    const onCompletedHierarchicalAdjusts = (response) => {
      resetKeepAliveTimer()
      setObjective({...objective, ajusteJerarquico: response.objective.ajusteJerarquico})
      setIsLoading(false)
    }

  const onCompleted = ({ objective }) => {
    resetKeepAliveTimer()
    setObjective(objective)
    getHierarchicalAdjusts()
  }

  const [getHierarchicalAdjusts, hierarchicalAdjustsResource] = useLazyQuery(OBJECTIVE_HIERARCHICAL_ADJUSTS, {
    fetchPolicy: 'network-only',
    skip: isDev,
    ...getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: onCompletedHierarchicalAdjusts,
    }),
  })

  const [getObjective, queryResource] = useLazyQuery(OBJECTIVE_WITH_ADJUSTS, {
    fetchPolicy: 'network-only',
    skip: isDev,
    ...getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  })

  const [updateHow, mutacionHowResource] = useMutation(
    UPDATE_OBJECTIVE_HOW_WITH_ADJUSTS_V2,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  )

  const [recalculateAdjusts, mutacionResource] = useMutation(
    RECALCULATE_OBJECTIVE_ADJUSTS,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  )

  const [updateAdjustsMutacion] = useMutation(
    UPDATE_OBJECTIVE_ADJUSTS,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  )

  useEffect(() => {
    if (!isDev) {
      const hasYear = !isNil(get(objective, 'ano'))
      const hasAdjustments = !isNil(get(objective, 'ajuste'))
      if (hasYear) {
        if (!hasAdjustments) recalculateAdjusts()
      } else getObjective()
    }
    if (isLoading) { setIsLoading(false) }
  }, [])

  const [originalRefValue] = useState(get(objective, 'valorRef.valor'))
  const [refValueType] = useState(get(objective, 'valorRef.tipoDeUnidad'))
  const [mode, setMode] = useState('comercial')
  const distribucionValorRefId = get(objective, 'distribucionValorRef.id')
  const fechaInicio = get(objective, 'periodoVigencia.fechaInicio')
  const fechaFin = get(objective, 'periodoVigencia.fechaFin')
  const cuandoEvaluarId = get(objective, 'cuandoEvaluar.id')

  const isNumeric = get(objective, 'medida.id') === 'N'
  const showPeriod = ((get(objective, 'tipoValorRef.id') === 'E' || get(objective, 'medida.id') === 'S') && cuandoEvaluarId === 'F') ||
    (isNumeric && cuandoEvaluarId === 'A')

  const updateValorReferencia = value =>
    updateHow({
      variables: {
        tipoValorRefId: get(objective, 'tipoValorRef.id'),
        valorRef: {
          valor: value.toString().replace(',', '.'),
          tipoDeUnidad: get(objective, 'valorRef.tipoDeUnidad'),
        },
        distribucionValorRefId,
        recalcularAjustes: true,
        refAno: get(objective, 'refAno'),
        inctvMinCaja: get(objective, 'inctvMinCaja'),
        pagoIncentivo: get(objective, 'pagoIncentivo'),
        ocultarNiveles: get(objective, 'ocultarNiveles'),
        paraEspecialistas: get(objective, 'paraEspecialistas'),
        nArtGroup: get(objective, 'nArtGroup'),
        satEspecialista: get(objective, 'satEspecialista'),
      },
    })

  const updateAdjusts = (mes, codMiembro, valorRef) => {
    return updateAdjustsMutacion({
      variables: {
        mes,
        codMiembro,
        valorRef,
      },
    })
  }
  const tableData =
    mode === 'comercial' ? objective.ajuste : objective.ajusteJerarquico
  const pannelData = (tableData && tableData.panel) || null
  const adjusted = objective.ajustado

  const resource = {
    loading: false,
    error:
      queryResource.error ||
      mutacionHowResource.error ||
      hierarchicalAdjustsResource.error ||
      mutacionResource.error,
  }

  const monthPeriod = () => {
    if (fechaInicio && fechaFin) {
      const formatedFechaInicio = moment(fechaInicio).format('YYYY-MM-DD');
      const formatedFechaFin = moment(fechaFin).format('YYYY-MM-DD');

      const inicioMes = moment(fechaInicio).startOf('month').format('YYYY-MM-DD');
      const finMes = moment(fechaFin).endOf('month').format('YYYY-MM-DD');

      return formatedFechaInicio !== inicioMes || formatedFechaFin !== finMes;
    }

    return false
  }

  return (
    <Wrapper>
      <PageTitle>Ajuste del objetivo</PageTitle>
      <Inner>
        <ResourceWrapper resource={{ ...resource, data: objective }}>
          {pannelData && (
            <ObjectivePannel
              dataSource={pannelData}
              adjusted={adjusted}
              onChange={updateValorReferencia}
              originalRefValue={originalRefValue}
              refValueType={refValueType}
              isTotal={objective.tipoValorRef.id === 'T'}
            ></ObjectivePannel>
          )}
          <div style={{ marginTop: "15px", marginBottom: "5px" }}>
            {monthPeriod() &&
              (<Alert
                message="El periodo del objetivo no cubre meses completos, es posible que sea necesario hacer ajustes manuales"
                type="warning"
                showIcon
                closable
                style={{ margin: "5px 0px 10px 5px", width: "800px", fontSize: "16px" }}
              />)}
            {cuandoEvaluarId === 'P' &&
              (<Alert
                message="Las medias presentadas pueden variar a causa del redondeo de los valores"
                type="warning"
                showIcon
                closable
                style={{ margin: "5px 0px 0px 5px", width: "600px", fontSize: "16px" }}
              />)}
          </div>

          {distribucionValorRefId === 'D' ? (
            tableData && (
              <ObjectiveAdjustmentTable
                dataSource={tableData}
                onChange={updateAdjusts}
                showPeriod={showPeriod}
                periodDate={objective.periodoVigencia}
                isNumeric={isNumeric}
              />
            )
          ) : (
            <RightTabs activeKey={mode} onChange={setMode}>
              <Tabs.TabPane tab={`Vista Comercial`} key="comercial">
                {tableData && (
                  <ObjectiveAdjustmentTable
                    dataSource={tableData}
                    onChange={updateAdjusts}
                    showPeriod={showPeriod}
                    periodDate={objective.periodoVigencia}
                    isNumeric={isNumeric}
                  />
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab={`Vista Jerárquica`} key="jerarquico">
                {tableData && (
                  <ObjectiveAdjustmentTable
                    dataSource={tableData}
                    onChange={updateAdjusts}
                    hierarchied
                    showPeriod={showPeriod}
                    periodDate={objective.periodoVigencia}
                    isNumeric={isNumeric}
                  />
                )}
              </Tabs.TabPane>
            </RightTabs>
          )}
        </ResourceWrapper>
      </Inner>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`
const Inner = styled.div`
  width: 100%;
  height: calc(100vh - 225px);
  padding: 25px 30px;
  overflow: auto;
`
const PageTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.11;
  letter-spacing: -0.27px;
  color: ${colors.black};
  height: 43px;
  padding: 13px 0 0 20px;
  width: 100%;
  border-bottom: 1px solid #dfdfdf
`
const RightTabs = styled(Tabs)`
  text-align: right;
`
