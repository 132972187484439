import gql from 'graphql-tag'
import { HIERARCHY_FRAGMENT } from 'Queries/CommercialHierarchyFragments'

export const GET_COMMERCIAL_HIERARCHY = gql`
  query getCommercialHierarchy($codDistribuidor: Long!) {
    jerarquiaComercial(codDistribuidor: $codDistribuidor) {
      ...hierarchyFragment
    }
    categoriasEspecialistas(codDistribuidor: $codDistribuidor)
  }
  ${HIERARCHY_FRAGMENT}
`

export const DELETE_EMPLOYEE = gql`
  mutation deleteEmployee(
    $codDistribuidor: Long!
    $codEmpleado: Long!
    $codPadre: Long
    $level: String
  ) {
    jerarquiaComercial: eliminarEmpleado(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
      codPadre: $codPadre
      nivel: $level
    ) {
      ...hierarchyFragment
    }
  }
  ${HIERARCHY_FRAGMENT}
`

export const ADD_EMPLOYEE = gql`
  mutation crearEmpleado(
    $codDistribuidor: Long!
    $codPadre: Long
    $codEmpleado: Long
    $nombre: String
    $email: String
    $telefono: String
    $tipoPerfil: TipoPerfil!
    $categoriasEspecialistas: [String]
  ) {
    jerarquiaComercial: crearEmpleado(
      codDistribuidor: $codDistribuidor
      codPadre: $codPadre
      codEmpleado: $codEmpleado
      nombre: $nombre
      email: $email
      telefono: $telefono
      tipoPerfil: $tipoPerfil
      categoriasEspecialistas: $categoriasEspecialistas
    ) {
      ...hierarchyFragment
    }
  }
  ${HIERARCHY_FRAGMENT}
`

export const EDIT_EMPLOYEE = gql`
  mutation editarEmpleado(
    $codDistribuidor: Long!
    $codEmpleado: Long!
    $nombre: String!
    $email: String
    $telefono: String
    $categoriasEspecialistas: [String]
  ) {
    jerarquiaComercial: editarEmpleado(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
      nombre: $nombre
      email: $email
      telefono: $telefono
      categoriasEspecialistas: $categoriasEspecialistas
    ) {
      ...hierarchyFragment
    }
  }
  ${HIERARCHY_FRAGMENT}
`

export const MOVE_EMPLOYEE = gql`
  mutation moverEmpleado(
    $codDistribuidor: Long!
    $codEmpleado: Long!
    $codNuevoPadre: Long!
    $codPadre: Long!
  ) {
    jerarquiaComercial: moverEmpleado(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
      codNuevoPadre: $codNuevoPadre
      codPadre: $codPadre
    ) {
      ...hierarchyFragment
    }
  }
  ${HIERARCHY_FRAGMENT}
`

export const SIGN_UP_EMPLOYEE = gql`
  mutation signUpEmployee(
    $codDistribuidor: Long!
    $codEmpleado: Long!
    $nivel: String!
  ) {
    jerarquiaComercial: crearCuentaUsuario(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
      nivel: $nivel
    ) {
      ...hierarchyFragment
    }
  }
  ${HIERARCHY_FRAGMENT}
`

export const DROP_EMPLOYEE = gql`
  mutation dropEmployee(
    $codDistribuidor: Long!
    $codEmpleado: Long!
    $drop: Boolean!
  ) {
    jerarquiaComercial: eliminarCuentaUsuario(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
      forzarEliminar: $drop
    ) {
      ...hierarchyFragment
    }
  }
  ${HIERARCHY_FRAGMENT}
`

export const REACTIVATE_EMPLOYEE = gql`
  mutation reactivateEmployee($codDistribuidor: Long!, $codEmpleado: Long!) {
    jerarquiaComercial: reactivarCuentaUsuario(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
    ) {
      ...hierarchyFragment
    }
  }
  ${HIERARCHY_FRAGMENT}
`
