import React, { useEffect, useState } from 'react'
import { Collapse as AntCollapse } from 'antd'
import styled from '@emotion/styled'
import { formatNumberWithThousands, colors, levelColors } from 'Utils'
import { CellInput } from 'Components/AdjustmentTable'

const getMounthValue = (mounths, index) =>
  Object.keys(mounths).reduce((acc, key) => {
    return parseInt(mounths[key].mes) === index + 1
      ? parseInt(mounths[key].valor)
      : acc
  }, 0)

export const ComercialIncetiveTable = ({
  startMonth,
  endMonth,
  name,
  incentive,
  montly,
  nivel,
  onChange,
  onColapse,
  open,
}) => {
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    if (open) setIsOpen(true)
  }, [open])

  return (
    <Collapse
      expandIconPosition="end"
      onChange={() => {
        onColapse()
        setIsOpen(!isOpen)
      }}
      activeKey={isOpen ? ['1'] : []}
      nivel={nivel}
    >
      <Panel
        key="1"
        header={
          <Header>
            <Name>{name}</Name>
            <Incentive>
              {formatNumberWithThousands(parseInt(incentive))} €
            </Incentive>
          </Header>
        }
      >
        <MonthsWrapper>
          {months.map((month, index) => (
            <Month
              key={index}
              disabled={!(startMonth <= index && index <= endMonth)}
            >
              <div>{month}</div>
              {startMonth <= index && index <= endMonth && (
                <InputWrapper>
                  <CellInput
                    value={getMounthValue(montly, index)}
                    percentage={false}
                    onChange={value => onChange(index + 1, value)}
                  />
                </InputWrapper>
              )}
            </Month>
          ))}
        </MonthsWrapper>
      </Panel>
    </Collapse>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
`
const Name = styled.div`
  font-size: 18px;
  line-height: 23px;
  color: var(--black);
`
const Incentive = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: var(--pine);
`
const Panel = AntCollapse.Panel
const Collapse = styled(AntCollapse)`
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.09);
  margin-bottom: 19px;
  &.ant-collapse-icon-position-right > .ant-collapse-item {
    border-bottom: none;
    > .ant-collapse-header {
      padding: 20px 28px 12px;
    }
  }
  .ant-collapse-header {
    background-color: ${({ nivel }) => colors[levelColors[nivel]]};
    box-shadow: 0 2px 4px 0 rgba(233, 233, 233, 0.5);
    min-height: 52px;
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 10px 12px;
  }
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 28px;
  }
`
const MonthsWrapper = styled.div`
  display: flex;
  > *:last-child {
    border-right: none;
  }
`
const Month = styled.div`
  display: inline-block;
  text-align: center;
  min-width: 84px;
  min-height: 62px;
  font-size: 14px;
  line-height: 1.43;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  color: #555555;
  border-right: solid 1px #eaeaea;
`
const InputWrapper = styled.div`
  width: 64px;
  margin: 8px auto;
`
const months = [
  'Ene.',
  'Feb.',
  'Mar.',
  'Abr.',
  'May.',
  'Jun.',
  'Jul.',
  'Ago.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
]
