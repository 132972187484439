import React from 'react'
import { Steps } from 'antd'
import styled from '@emotion/styled'

const { Step: AntStep } = Steps

export const StepperHeader = ({
  stepsOptions,
  currentStep,
  onStepChange,
  objective,
}) => {
  return (
    <Steps current={Number(currentStep)} onChange={onStepChange}>
      {stepsOptions.map((step, index) => {
        const isDisabled =
          stepsOptions[step] &&
          stepsOptions[step].isValid &&
          !stepsOptions[step].isValid(objective)

        return (
          <Step key={index} title={step.title} disabled={isDisabled}></Step>
        )
      })}
    </Steps>
  )
}

const Step = styled(AntStep)(({ disabled }) => ({
  opacity: disabled ? 0.8 : 1,
}))
