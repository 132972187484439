import React, { useState } from 'react'
import { Form, Select } from 'antd'
import styled from '@emotion/styled'
import { Modal, WarningMessage } from 'Components'

import { isEmpty } from 'lodash'

export const AddProviderForm = ({ props, setProps }) => {
  const [codeToMove, setCodeToMove] = useState(null)

  const onSubmit = () => {
    const provider = props.proveedores.find(prv => prv.codigoProveedor === codeToMove)
    const variables = {
      codDistribuidor: props.codDistribuidor,
      codProveedor: provider.codigoProveedor,
      nombre: provider.nombre,
    }

    props.submit({ variables }).then(() => {
      setProps({ ...props, visible: false })
      setCodeToMove(null)
    })
  }

  const disableSubmit = isEmpty(codeToMove)

  return (
    <Modal
      title="Añadir proveedor"
      okButtonProps={{ disabled: disableSubmit }}
      visible={props.visible}
      onCancel={() => {
        setProps({
          visible: false,
        })
        setCodeToMove(null)
      }}
      onOk={onSubmit}
    >
      <Form layout="vertical">
        <FormItem label="Proveedores">
          <Select
            mode="default"
            allowClear
            size="default"
            value={codeToMove}
            onChange={val => setCodeToMove(val)}
            style={{ width: '100%' }}
            showArrow={true}
          >
            {!isEmpty(props.proveedores) &&
              props.proveedores.map(({ codigoProveedor, nombre }) => {
                if (codigoProveedor !== undefined) {
                  return (
                    <Select.Option key={codigoProveedor} value={codigoProveedor}>
                      {codigoProveedor} - {nombre}
                    </Select.Option>
                  )
                }
              })}
          </Select>
        </FormItem>
      </Form>
      {props.warning && (
        <WarningMessage>
          <span>
            Cualquier variación en la estructura de la jerarquía afectará a los
            objetivos existentes
          </span>
        </WarningMessage>
      )}
    </Modal>
  )
}

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    padding-bottom: 0px;
    margin-bottom: 24px;
  }
`
