import React from 'react'
import styled from '@emotion/styled'
import alert from './../../Assets/images/alerta.png';

import { Col, Row } from 'antd';

export const PageErrorWarning = (title = '', subtitle = '') => {

  return (
    <div style={{ with: "100%", marginTop: 100 }}>
      <ErrorWrapper>
        <Row>
          <Col span={7} >
            <img style={{ height: 150 }} src={alert} alt="alert" />
          </Col>
          <Col span={17} >
            <Title>
              {title}
            </Title>
            <p style={{ fontSize: 20 }}>
              {subtitle}
            </p>
          </Col>
        </Row>
      </ErrorWrapper>
    </div>
  )
}

const ErrorWrapper = styled.div`
  margin: auto;
  width: 600px;
  height : 170px;
  text-align: center;
`

const Title = styled.div`
  font-size: 27px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
`