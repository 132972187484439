import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Button, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons';

const destroyModal = () => {
  Modal.destroyAll();
}

export const showFuerzaBarModal = (onOk, onCancel) => {
  Modal.confirm({
    title: <ConfirmTitle>Todos tus productos disponibles en FUERZABAR</ConfirmTitle>,
    content: (<div>
      <div>
        Si aceptas, tu catálogo de artículos de  <AppName>Clave</AppName> y los artículos que añadas en el futuro se sincronizarán en <AppName>FUERZABAR</AppName>.
      </div>
      <Button type='link' icon={<CloseOutlined />} style={{ position: 'absolute', right: -20, top: 10, color: 'gray' }} onClick={destroyModal} />
    </div>),
    icon: null,
    centered: true,
    okText: 'ACEPTAR Y SINCRONIZAR',
    okButtonProps: {
      type: 'primary',
    },
    onOk: () => {
      destroyModal();
      onOk()
    },
    cancelText: 'RECHAZAR Y NO SINCRONIZAR',
    cancelButtonProps: {
      type: 'danger',
    },
    onCancel: () => {
      destroyModal();
      onCancel()
    },
    width: 500,
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`

const AppName = styled.span`
  font-weight: bold;
  color: ${colors.fernGreen};
`