import React from 'react'
import { Row as AntRow } from 'antd'
import styled from '@emotion/styled'
import moment from 'moment'
import {
  CellDescription,
  CellIncDescription,
  HeaderCellWrapper,
  CellWrapper,
  CellInput,
  CellTitle,
} from 'Components'

export const AdjustesTable = ({
  headerValues,
  dataSource,
  footerValues,
  condensed,
  onChange,
  hierarchied,
  periodDate,
  showPeriod,
  isNumeric,
  isPercentage,
}) => {

  const mesHasta = parseInt(moment(periodDate.fechaFin).format('MM'))
  const newDataSource = showPeriod ? dataSource.map(obj => ({ empleado: obj.empleado, celdas: obj.celdas.filter(cell => cell.mes === mesHasta) })) : dataSource;
  const newFooterValues = showPeriod ? footerValues.filter(cell => cell.mes === mesHasta) : footerValues;
  const periodInfo = `${moment(periodDate.fechaInicio).format('DD/MM/YY')} - ${moment(periodDate.fechaFin).format('DD/MM/YY')}`

  function setPercent(pa, newInc) {
    let newValue;

    if (pa !== 0) {
      const newPercent = (newInc * 100) / pa
      const with2Decimals = newPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      newValue = parseFloat(with2Decimals);
    } else {
      newValue = newInc * 100;
    }

    return newValue;
  }

  return (
    <Inner>
      <DataRow>
        {headerValues.map(mes => (
          <HeaderCellWrapper
            isDetail={!condensed}
            condensed={condensed}
            isAbsolut={showPeriod}
            key={mes}
          >
            {mes}
            {showPeriod && (
              <>
                <br />
                <PeriodInfo>
                  {periodInfo}
                </PeriodInfo>
              </>
            )}
          </HeaderCellWrapper>
        ))}
      </DataRow>
      {
        newDataSource.map(({ empleado: { codMiembro }, celdas }, i) => (
          <DataRow type="flex" key={i}>
            {celdas &&
              celdas.map(({ valorRef, mes, pa, inc, total }, index) => (
                <CellWrapper
                  isDetail={!condensed}
                  condensed={condensed}
                  isAbsolut={showPeriod}
                  isPercentage={isPercentage}
                  key={index}
                >
                  {hierarchied ? (
                    <CellTitle dataSource={{ ...valorRef }} />
                  ) : (!hierarchied && (isNumeric && isPercentage)) ? (
                    <CellInput
                      suffix={''}
                      value={inc.valor}
                      onChange={value => {
                        onChange(mes, codMiembro, setPercent(pa.valor, parseInt(value)))
                      }}
                      paValue={1}
                    ></CellInput>
                  ) : (
                    <CellInput
                      percentage={valorRef.unidad === '%'}
                      suffix={valorRef.unidad === '%' ? '%' : ''}
                      value={valorRef.valor}
                      onChange={value => onChange(mes, codMiembro, value)}
                    ></CellInput>
                  )
                  }
                  <CellDescription dataSource={pa} />
                  {(!hierarchied && (isNumeric && isPercentage)) ?
                    (<CellIncDescription dataSource={valorRef.valor} />) :
                    (<CellDescription dataSource={inc} />)
                  }
                  <CellDescription dataSource={total} />
                </CellWrapper>
              ))}
          </DataRow>
        ))
      }
      <DataRow>
        {!hierarchied &&
          newFooterValues &&
          newFooterValues.map((value, index) => (
            <CellWrapper
              shadow
              isDetail
              isFooter
              condensed={condensed}
              key={index}
              isAbsolut={showPeriod}
            >
              <CellDescription dataSource={value.valorRef} withDec={parseFloat(value.valorRef).toFixed(1) % 1 !== 0} />
              <CellDescription dataSource={value.pa} />
              <CellDescription dataSource={value.inc} />
              <strong>
                <CellDescription dataSource={value.total} />
              </strong>
            </CellWrapper>
          ))}
      </DataRow>
    </Inner>
  )
}

const Inner = styled.div({
  width: 'max-content',
})

const DataRow = styled(AntRow)({
  display: 'flex',
  backgroung: 'green',
  ' > :last-child': {
    marginRight: 0,
  },
})

const PeriodInfo = styled.span(props => {
  return {
    fontSize: 13,
    color: 'black'
  }
})
