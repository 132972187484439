import React, { useContext, useState } from 'react'

import {
  PageTitle,
  Objective,
  ResourceWrapper,
  NoDataForList,
  Spinner,
} from 'Components'
import { GET_OBJECTIVES } from 'Queries'
import { GlobalContext } from 'Utils'
import { objectivesListMock } from 'Mockups'

import { Tabs as AntTabs, Row, Button as AntButton, Tooltip } from 'antd'
import styled from '@emotion/styled'
import { get, groupBy, isEmpty } from 'lodash'
import { useQuery, useApolloClient } from '@apollo/client'

const { TabPane } = AntTabs

export const Objectives = ({ navigate }) => {
  const context = useContext(GlobalContext)
  const codDistribuidor = get(context, 'currentUser.codDistribuidor')
  const warnings = get(context, 'currentUser.avisos')
  const hasHierarchyWarn =
    warnings && warnings.some(({ tipo }) => tipo === 'jerarquia')

  const [isRefetch, setIsRefetch] = useState(false)

  const variables = {
    codDistribuidor,
  }

  let resource = useQuery(GET_OBJECTIVES, {
    skip: context.isDev,
    fetchPolicy: 'network-only',
    variables,
    onCompleted: () => {
      setIsRefetch(false);
      context.resetKeepAliveTimer();
    },
  })
  if (context.isDev) resource = objectivesListMock

  let objectives = get(resource, 'data.objetivos') || {}
  const groupedObjectives = groupBy(objectives, o => o.vigencia)

  const client = useApolloClient()

  client.addResolvers()

  const { activos = [], futuros = [], vencidos = [] } = groupedObjectives

  const vencidosPorAno = groupBy(vencidos, o => o.ano)
  const futurosPorAno = groupBy(futuros, o => o.ano)

  return (
    <>
      <Row justify="space-between" type="flex" >
        <PageTitle>Objetivos</PageTitle>
        {hasHierarchyWarn ? (
          <Tooltip
            placement="left"
            title={
              hasHierarchyWarn
                ? 'Debes crear antes una jerarquía comercial'
                : ''
            }
          >
            <ButtonDisabled size="large">Nuevo objetivo</ButtonDisabled>
          </Tooltip>
        ) : (
          <Button
            size="large"
            onClick={() => navigate('/objective/new/0/0/definition')}
          >
            Nuevo objetivo
          </Button>
        )}
      </Row>
      {isRefetch ? <Spinner /> : (
        <ResourceWrapper resource={resource}>
          <StyledTabs>
            <TabPane tab={`ACTIVOS (${activos.length})`} key="1">
              <ObjList>
                {activos.map((objective, index) => (
                  <Objective
                    key={'1' + index}
                    objective={objective}
                    codDistribuidor={codDistribuidor}
                    refetchObjectives={resource.refetch}
                    setIsRefetch={setIsRefetch}
                    navigate={navigate}
                  />
                ))}
                {isEmpty(activos) && (
                  <NoDataForList text="No hay objetivos que estén activos"></NoDataForList>
                )}
              </ObjList>
            </TabPane>
            <TabPane tab={`FUTUROS (${futuros.length})`} key="2">
              <ObjList>
                {Object.keys(futurosPorAno)
                  .sort((a, b) => a - b)
                  .map(key => (
                    <div key={key}>
                      <Year>{key}</Year>
                      {futurosPorAno[key] &&
                        futurosPorAno[key].map((objective, index) => (
                          <Objective
                            key={'1' + index}
                            objective={objective}
                            codDistribuidor={codDistribuidor}
                            refetchObjectives={resource.refetch}
                            setIsRefetch={setIsRefetch}
                            navigate={navigate}
                          />
                        ))}
                    </div>
                  ))}
                {isEmpty(futuros) && (
                  <NoDataForList text="No hay objetivos creados para el futuro"></NoDataForList>
                )}
              </ObjList>
            </TabPane>
            <TabPane tab={`VENCIDOS (${vencidos.length})`} key="3">
              <ObjList>
                {Object.keys(vencidosPorAno)
                  .sort((a, b) => b - a)
                  .map(key => (
                    <div key={key}>
                      <Year>{key}</Year>
                      {vencidosPorAno[key] &&
                        vencidosPorAno[key].map((objective, index) => (
                          <Objective
                            key={'1' + index}
                            objective={objective}
                            codDistribuidor={codDistribuidor}
                            refetchObjectives={resource.refetch}
                            setIsRefetch={setIsRefetch}
                            navigate={navigate}
                          />
                        ))}
                    </div>
                  ))}
                {isEmpty(vencidos) && (
                  <NoDataForList text="No hay objetivos que hayan vencido"></NoDataForList>
                )}
              </ObjList>
            </TabPane>
          </StyledTabs>
        </ResourceWrapper>
      )}
    </>
  )
}

const Button = styled(AntButton)`
  margin: 16px;
  background: #008200;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  &:hover: {
    background: #008200;
    color: white;
  }
`
const ButtonDisabled = styled(AntButton)`
  && {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    font-size: 16px;
    line-height: 1.5;
    &:hover: {
      cursor: not-allowed;
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
    }
  }
`
const Year = styled.div`
  padding: 0 0 16px 10px;
  font-size: 18px;
  font-weight: 600;
  color: var(--black);
`
const StyledTabs = styled(AntTabs)`
  .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-left: 15px;
  }
  .ant-tabs-tab {
    padding: 10px 15px;
    font-size: 15px;
  }
  .ant-tabs-nav {
    margin: 0;
  }
`

const ObjList = styled.div`
  padding: 16px;
  height: calc(100vh - 200px);
  overflow-y: auto;
`