import React from 'react'
import { formatNumberWithThousands } from 'Utils'
import styled from '@emotion/styled'

export const CellDescription = ({ dataSource, withDec = false }) => {
  if (!dataSource) return null
  const { valor, unidad } = dataSource

  return (
    <CellInfo>
      {`${dataSource.titulo} ${dataSource.signo ? '+' : ''
        }${formatNumberWithThousands(valor, withDec, 1)}`}
      {unidad || ''}
    </CellInfo>
  )
}

const CellInfo = styled.p({
  fontSize: 12,
  lineHeight: 1.25,
  margin: 0,
})
