import React, { useState, useEffect, useContext } from 'react'

import {
  ResourceWrapper,
  NoDataForList,
  FollowUpLegend,
  ObjectiveBoxGroup,
  YearPicker,
  ObjectiveTag,
  Spinner,
  showWarningNotification
} from 'Components'
import {
  OBJECTIVE_KPI,
  UPDATE_OBJECTIVE_KPI_TAGS,
  GET_KPI_HESA_DEALERS,
  CLEAN_INFO_HESA_CACHE,
  SAVE_INFO_HESA,
  GET_CURRENT_USER,
  CHECK_CALENDAR_WARNINGS
} from 'Queries'
import {
  GlobalContext,
  DashboardContextConsumer,
  checkRolePermit,
  sort,
  colors,
  checkLevel,
} from 'Utils'

import { Tabs as AntTabs, Empty, Select, Alert } from 'antd'
import styled from '@emotion/styled'
import { get, groupBy, isEmpty } from 'lodash'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import moment from 'moment'

const { TabPane } = AntTabs

const SetOfKPIs = ({ labels, readOnly, updateTags, variables, ...props }) => (
  <>
    {[...sort(Object.keys(labels).filter(label => !!label)), ''].map(
      (label, i, allLabels) =>
        labels[label] && (
          <ObjectiveBoxGroup
            key={label}
            dataSource={labels[label]}
            startPoint={
              i
                ? allLabels
                  .slice(0, i)
                  .map(label => labels[label].length)
                  .reduce((total, numItems) => total + numItems, 0)
                : 0
            }
            title={
              label ? (
                <ObjectiveTag
                  name={label}
                  readOnly={readOnly}
                  update={newLabel =>
                    updateTags({
                      variables: {
                        ...variables,
                        currentLabel: label,
                        newLabel,
                      },
                    })
                  }
                />
              ) : (
                'Sin etiquetar'
              )
            }
            {...props}
          />
        ),
    )}
  </>
)

const getEmpleadosAndNiveles = (kpis, nivel) =>
  kpis.reduce(
    (result, kpi) => {
      kpi.empleados.forEach(empleado => {
        if (!nivel || nivel === empleado.nivelEmpleado.id) {
          result.empleados[empleado.codEmpleado] = empleado
          result.niveles[empleado.nivelEmpleado.id] = empleado.nivelEmpleado
        }
      })
      return result
    },
    { empleados: {}, niveles: {} },
  )

export const ObjectivesFollowUp = props => (
  <DashboardContextConsumer>
    {dashboardProps => <ObjFollowUp {...dashboardProps} {...props} />}
  </DashboardContextConsumer>
)

export const ObjFollowUp = ({
  navigate,
  type,
  year: navYear,
  filter,
  setFilter,
}) => {
  const context = useContext(GlobalContext)
  const isDev = context.isDev

  const profileId = get(context, 'currentUser.profile.id') || null
  const isHesa = get(context, 'currentUser.infoHesa.isHesa') || false
  const [codDistribuidor, setCodDistribuidor] = useState(get(context, 'currentUser.infoHesa') !== null ?
    (get(context, 'currentUser.infoHesa.codDistribuidor') || null) : (get(context, 'currentUser.codDistribuidor') || null))
  const avisos = get(context, 'currentUser.avisos') || []
  const codEmpleado = get(context, 'currentUser.codEmpleado')
  const [codEmpleadoInfoHesa, setCodEmpleadoInfoHesa] = useState(get(context, 'currentUser.infoHesa.codEmpleado') || null)
  const [nivelInfoHesa, setNivelInfoHesa] = useState(checkLevel(get(context, 'currentUser.infoHesa.nivel')))

  const [cacheIsClean, setCacheIsClean] = useState(true)
  const [infoIsSaved, setInfoIsSaved] = useState(true)
  const [userIsSaved, setUserIsSaved] = useState(true)
  const [hesaSteps, setHesaSteps] = useState(isHesa && codDistribuidor !== null)

  const warnedYears = avisos
    .filter(({ tipo }) => tipo === 'calendario')
    .map(({ context }) => context.ano)
  const isWarnedByHierarchy = avisos.some(({ tipo }) => tipo === 'jerarquia')
  const isComercial = isDev ? false : !context.currentUser.display
  const currentYear = moment(context.currentUser.currentTime).year()
  const readOnly = !checkRolePermit(
    get(context, 'currentUser.roles'),
    'GestionObjetivo',
  )
  const [year, setYear] = useState(navYear ? Number(navYear) : currentYear)
  const [KPIsLoading, setLoading] = useState({})
  const [elementsToLoad, setElementsToLoad] = useState(3)
  const [empleados, setEmpleados] = useState([])
  const [niveles, setNiveles] = useState([])

  const [empleadosYniveles, setEmpleadosYniveles] = useState({ empleados: {}, niveles: {} })
  const [activeObjectives, setActiveObjectives] = useState([])
  const [finishedObjectives, setFinishedObjectives] = useState([])

  const canCallKpis = (profileId !== null && codDistribuidor !== null) || (isHesa && hesaSteps && codDistribuidor !== null)
  const variables = {
    codDistribuidor: codDistribuidor,
    nivel: isHesa ? nivelInfoHesa : filter.empleado ? filter.nivel : undefined,
    codEmpleado: isHesa ? codEmpleadoInfoHesa : filter.empleado,
  }


  // HESA services

  const hesaResource = useQuery(GET_KPI_HESA_DEALERS, {
    skip: !isHesa,
    fetchPolicy: 'network-only',
    variables: { codEmpleadoHesa: codEmpleado },
    onCompleted: context.resetKeepAliveTimer,
  })

  const [refershCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    skip: !isHesa,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (!userIsSaved) {
        setUserIsSaved(true)
        setHesaSteps(true)
        context.resetKeepAliveTimer()
      }
    },
  })

  const [saveInfoHesa] = useLazyQuery(SAVE_INFO_HESA, {
    skip: !isHesa,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (!infoIsSaved) {
        setInfoIsSaved(true)
        setUserIsSaved(false)
        refershCurrentUser({ variables: { tipo: 0 } })
      }
    },
  })

  const [cleanHesaCache] = useLazyQuery(CLEAN_INFO_HESA_CACHE, {
    skip: !isHesa,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const dealer = hesaDealers.find(dlr => dlr.codDistribuidor === codDistribuidor)
      dealer.nivel = dealer.nivel.toUpperCase()
      delete dealer.__typename
      if (!cacheIsClean) {
        setCacheIsClean(true)
        setInfoIsSaved(false)
        saveInfoHesa({
          variables: {
            codigoEmpleadoHesa: codEmpleado,
            infoHesa: dealer
          }
        })
      }
    },
  })

  const hesaDealers = hesaResource.data && hesaResource.data.InfoHesa ? hesaResource.data.InfoHesa : []

  // KPI services

  const activeObjectiveResource = useQuery(OBJECTIVE_KPI, {
    skip: isDev || !canCallKpis,
    fetchPolicy: 'network-only',
    variables,
    onCompleted: () => {
      context.resetKeepAliveTimer()
    },
  })

  const isWarnedCurrentYear = warnedYears.includes(year)
  const isWarnedPastYear = warnedYears.includes(year - 1)
  const finishedObjectiveResource = useQuery(OBJECTIVE_KPI, {
    skip: !canCallKpis || (isDev || (isWarnedCurrentYear || isWarnedPastYear)),
    fetchPolicy: 'network-only',
    variables: { ...variables, ano: year },
    onCompleted: () => {
      context.resetKeepAliveTimer()
    },
  })

  const [updateTags] = useMutation(UPDATE_OBJECTIVE_KPI_TAGS, {
    onCompleted: () => {
      activeObjectiveResource.refetch()
      finishedObjectiveResource.refetch()
    },
  })

  const checkCalendar = useQuery(CHECK_CALENDAR_WARNINGS, {
    skip: profileId !== 'GE',
    fetchPolicy: 'network-only',
    variables: {
      ano: year + 1,
    },
    onCompleted: ({ verificarCalendarioObjetivo: calendarWarnings }) => {
      const messageDay = moment(`01/12/${year}`, 'DD/MM/YYYY')
      if (!!calendarWarnings.length && (moment() >= messageDay)) {
        showWarningNotification('Recuerda que aún no ha rellenado el calendario laboral del próximo año')
      }
    },
  })

  useEffect(() => {
    setActiveObjectives(
      sort(get(activeObjectiveResource, 'data.objetivoKpi') || [], 'desc').filter(
        ({ empleados }) =>
          !filter.empleado ||
          empleados.some(({ codEmpleado }) => codEmpleado === filter.empleado),
      )
    )
    setFinishedObjectives(
      sort(get(finishedObjectiveResource, 'data.objetivoKpi') || [], 'desc').filter(
        ({ empleados }) =>
          !filter.empleado ||
          empleados.some(({ codEmpleado }) => codEmpleado === filter.empleado),
      )
    )
  }, [activeObjectiveResource, finishedObjectiveResource, filter])

  useEffect(() => {
    setEmpleadosYniveles(getEmpleadosAndNiveles(
      type === 'active' ? activeObjectives : finishedObjectives,
    ))
  }, [activeObjectives, finishedObjectives])

  useEffect(() => {
    if (canCallKpis && (!isWarnedCurrentYear || isWarnedPastYear)) {
      finishedObjectiveResource.refetch({
        ...variables,
        ano: year,
      })
    }
  }, [year])

  useEffect(() => {
    const employees = Object.values(empleadosYniveles.empleados)
    if (!empleados.length && employees.length) {
      const empleados = sort(employees, 'nombreEmpleado')
      setEmpleados(empleados)
    }
  }, [empleadosYniveles.empleados])

  useEffect(() => {
    const empleados = sort(
      Object.values(
        getEmpleadosAndNiveles(
          type === 'active' ? activeObjectives : finishedObjectives,
          filter.nivel,
        ).empleados,
      ),
      'nombreEmpleado',
    )
    setEmpleados(empleados)
  }, [filter.nivel])

  useEffect(() => {
    const levels = Object.values(empleadosYniveles.niveles)
    if (!niveles.length && levels.length) {
      const niveles = sort(levels, 'desc')
      setNiveles(niveles)
    }
  }, [empleadosYniveles.niveles])

  const activeLabels = groupBy(activeObjectives, o => o.etiqueta)
  const expiredLabels = groupBy(finishedObjectives, o => o.etiqueta)

  const updateKPILoading = (kpi, value) => {
    setLoading(prevKPIsLoading =>
      prevKPIsLoading[kpi] !== value
        ? { ...prevKPIsLoading, [kpi]: value }
        : prevKPIsLoading,
    )
  }

  const navigateByKPIs = (key, year) => {
    if (filter.nivel && !filter.empleado) { setFilter({ ...filter, nivel: undefined }) }
    setElementsToLoad(3)
    navigate(
      `/dashboard/objectives/follow-up/${key}${key === 'inactive' ? `/${year}` : ''
      }`,
    )
  }

  const KPIsLoadingValues = Object.values(KPIsLoading).filter(
    value => value !== null,
  )
  const isLoading =
    !isDev &&
    KPIsLoadingValues.length &&
    KPIsLoadingValues.some(isLoaded => !isLoaded)

  const hesaWarning = () => {
    return (
      <div style={{ with: "100%", marginTop: 70, textAlign: "center" }}>
        <div style={{ margin: 'auto', width: 650 }}>
          <Alert
            message="Debe seleccionar un distribuidor para poder ver los objetivos y navegar por la aplicación"
            type="warning"
            showIcon
            style={{ width: 650, fontSize: 16 }}
          />
        </div>
      </div>
    )
  }

  const queriesLoading = !userIsSaved || !infoIsSaved || !cacheIsClean || activeObjectiveResource.loading || finishedObjectiveResource.loading

  return (
    <>
      {isHesa &&
        <div style={{ padding: "15px 10px" }}>
          <span style={{ fontSize: 19, color: '#7c7d7c', padding: '5px 0 0 20px' }}>
            Distribuidor
          </span>
          <Select
            style={{ width: "330px", marginLeft: "15px" }}
            placeholder="Seleccione un distribuidor"
            value={codDistribuidor}
            disabled={isLoading || queriesLoading}
            onChange={value => {
              const dealer = hesaDealers.find(dlr => dlr.codDistribuidor === value)
              setHesaSteps(false)

              setCodDistribuidor(value)
              setCodEmpleadoInfoHesa(dealer.codEmpleado)
              setNivelInfoHesa('GE')
              setFilter({})
              setEmpleados([])
              setNiveles([])

              setCacheIsClean(false)
              cleanHesaCache()
            }}
          >
            {hesaDealers.map(({ codDistribuidor, nombre }) => (
              <Select.Option key={codDistribuidor} value={codDistribuidor}>
                {codDistribuidor} - {nombre}
              </Select.Option>
            ))}
          </Select>
        </div>
      }
      <div id="contentWidth" style={{ position: 'relative', minWidth: '830px' }}>
        {isWarnedByHierarchy ||
          warnedYears.includes(currentYear) ||
          warnedYears.includes(currentYear - 1) ? (
          <NoDataForList
            text={`Para poder realizar seguimiento 
          ${isComercial
                ? ' deberán estar configurados correctamente los calendarios y/o la jerarquía comercial'
                : ' resuelva antes los avisos'
              }
            `}
          />
        ) : (
          <>
            <FollowUpLegendWrapper>
              <FollowUpLegend
                filter={filter}
                empleados={empleados}
                niveles={niveles}
                setFilter={newFilter => {
                  setFilter({ ...filter, ...newFilter })
                  if (newFilter.empleado) setLoading({})
                  setElementsToLoad(newFilter.empleado ? 3 : undefined)
                }}
                clearFilter={() => {
                  setFilter({})
                  setLoading({})
                  setElementsToLoad(3)
                }}
                isLoading={isLoading || queriesLoading}
                isDisabled={!canCallKpis}
              />
            </FollowUpLegendWrapper>

            <FollowTabs
              activeKey={type}
              onTabClick={key => {
                navigateByKPIs(key, year)
              }}
            >
              <TabPane tab={`ACTIVOS (${activeObjectives.length})`} key="active">
                <TabDiv hesaTab={isHesa} >
                  {(isHesa && codDistribuidor === null) ? hesaWarning() :
                    !canCallKpis ? (<Spinner />) :
                      <ResourceWrapper
                        resource={activeObjectiveResource}
                        // isLoading={isLoading}
                        noManage={isDev}
                      >
                        <SetOfKPIs
                          labels={activeLabels}
                          updateTags={updateTags}
                          variables={variables}
                          filter={filter}
                          setLoading={updateKPILoading}
                          kpisLoading={KPIsLoading}
                          navigate={navigate}
                          readOnly={readOnly}
                          isLoading={isLoading}
                          elementsToLoad={elementsToLoad}
                          setElementsToLoad={setElementsToLoad}
                        />
                        {isEmpty(activeObjectives) && (
                          <NoKPIs description="No hay objetivos que estén activos" />
                        )}
                      </ResourceWrapper>
                  }
                </TabDiv>
              </TabPane>
              <TabPane
                tab={`VENCIDOS (${finishedObjectives.length})`}
                key="inactive"
              >
                <TabDiv hesaTab={isHesa} >
                  {(isHesa && codDistribuidor === null) ? hesaWarning() :
                    !canCallKpis ? (<Spinner />) :
                      <>
                        <YearPicker
                          year={year}
                          minYear={currentYear - 2}
                          maxYear={currentYear}
                          onIncrement={() => {
                            setYear(year + 1)
                            navigateByKPIs(type, year + 1)
                          }}
                          onDecrement={() => {
                            setYear(year - 1)
                            navigateByKPIs(type, year - 1)
                          }}
                        ></YearPicker>
                        <ResourceWrapper
                          resource={finishedObjectiveResource}
                          isDev={isDev}
                          // isLoading={isLoading}
                          noData={`No se ha completado el calendario de ${isWarnedCurrentYear
                            ? isWarnedPastYear
                              ? `${year} y ${year - 1}`
                              : year
                            : year - 1
                            }`}
                        >
                          {isEmpty(finishedObjectives) ? (
                            <NoKPIs description="No hay objetivos que hayan vencido" />
                          ) : (
                            <SetOfKPIs
                              labels={expiredLabels}
                              updateTags={updateTags}
                              variables={variables}
                              filter={filter}
                              setLoading={updateKPILoading}
                              kpisLoading={KPIsLoading}
                              navigate={navigate}
                              readOnly={readOnly}
                              isLoading={isLoading}
                              elementsToLoad={elementsToLoad}
                              setElementsToLoad={setElementsToLoad}
                            />
                          )}
                        </ResourceWrapper>
                      </>
                  }
                </TabDiv>
              </TabPane>
            </FollowTabs>
          </>
        )}
      </div>
    </>
  )
}

const FollowTabs = styled(AntTabs)`
  .ant-tabs-nav{
    margin: 15px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    z-index: 105;
    padding: 12px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
  }
`
const FollowUpLegendWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 100;
`
const NoKPIs = styled(Empty)`
  margin: 50px;
  font-size: 24px;
  color: ${colors.whiteTwo};
`
const TabDiv = styled.div`
  margin-top: 30px;  
  padding: 0 25px;
  height: calc(100vh - ${props => (props.hesaTab ? 250 : 185)}px);
  overflow-y: auto;
`