import gql from 'graphql-tag'

const OBJETIVO_AJUSTE_VALOR = gql`
  fragment objetivoAjusteValor on ObjetivoAjusteValor {
    titulo
    signo
    valor
    unidad
  }
`

const OBJETIVO_ABIERTO_VALOR = gql`
  fragment objetivoAbiertoValor on ObjetivoAbiertoValor {
    titulo
    signo
    valor
    unidad
  }
`

const OBJETIVO_AJUSTE_INDICADOR = gql`
  fragment objetivoAjusteIndicador on ObjetivoAjusteIndicador {
    valor {
      ...objetivoAjusteValor
    }
    subtitulo1
    subtitulo2
  }
  ${OBJETIVO_AJUSTE_VALOR}
`

const OBJETIVO_AJUSTE_MATRIZ_CELDA = gql`
  fragment objetivoAjusteMatrizCelda on ObjetivoAjusteMatrizCelda {
    mes
    valorRef {
      ...objetivoAjusteValor
    }
    inc {
      ...objetivoAjusteValor
    }
    pa {
      ...objetivoAjusteValor
    }
    total {
      ...objetivoAjusteValor
    }
  }
  ${OBJETIVO_AJUSTE_VALOR}
`

const OBJETIVO_OPEN_MATRIZ_CELDA = gql`
  fragment objetivoAbiertoMatrizCelda on ObjetivoAbiertoMatrizCelda {
    mes
    inctv {
      ...objetivoAbiertoValor
    }
    percentage {
      ...objetivoAbiertoValor
    }
    inctvTotal {
      ...objetivoAbiertoValor
    }
  }
  ${OBJETIVO_ABIERTO_VALOR}
`

export const OBJECTIVE_ADJUSTS_FRAGMENT = gql`
  fragment objectiveAdjustsFragment on ObjetivoAjuste {
    panel {
      valorRef {
        ...objetivoAjusteIndicador
      }
      valorRefDetalle {
        ...objetivoAjusteIndicador
      }
      valorPersonalizado {
        ...objetivoAjusteIndicador
      }
      valorPersonalizadoDetalle {
        ...objetivoAjusteIndicador
      }
    }
    cabeceraMeses
    valoresCeldaPorEmpleadoMes {
      orden
      nivelAnidacion
      empleado {
        codMiembro
        titulo
        subtitulo
      }
      celdas {
        ...objetivoAjusteMatrizCelda
      }
    }
    cabeceraTotal
    valoresTotalPorEmpleado {
      ...objetivoAjusteMatrizCelda
    }
    valoresTotalPorMes {
      ...objetivoAjusteMatrizCelda
    }
  }
  ${OBJETIVO_AJUSTE_INDICADOR}
  ${OBJETIVO_AJUSTE_MATRIZ_CELDA}
`

export const OPEN_OBJETIVE_FRAGMENT = gql`
  fragment objectiveOpenFragment on ObjetivoAbierto {
    cabecera
    cabeceraInctvTotal{
      total
      totalMeses {
        mes
        valor
      }
    }
    valoresCeldaPorEmpleadoMes {
      orden
      nivelAnidacion
      empleado {
        codMiembro
        titulo
        subtitulo
      }
      celdas {
        ...objetivoAbiertoMatrizCelda
      }
      description
    }
  }
  ${OBJETIVO_OPEN_MATRIZ_CELDA}
`


export const OBJECTIVE_FRAGMENT = gql`
  fragment objectiveFragment on Objetivo {
    codObjetivo
    ano
    draft
    nombre
    etiqueta
    idDesc
    ajustado
    comoDesc
    modifEn
    refAno
    estado
    medida {
      id
      desc
      unidad
    }
    tiposEntrega {
      id
    }
    tipoValorRef {
      id
      desc
    }
    valorRef {
      valor
      tipoDeUnidad
    }
    distribucionValorRef {
      id
      desc
      nota
    }
    cuandoEvaluar {
      id
      desc
    }
    periodoVigencia {
      fechaInicio
      fechaFin
    }
    categoriasSeleccionadasArticulo {
      categorias {
        categoria {
          id
          desc
        }
        valores {
          nombre
          seleccionado
          activado
          groupsId
        }
      }
      excepciones {
        codDistribuidor
        codigo
        desc
        groupsId
      }
      saturacion{
        id
        nombre
        hasArticles
      }
    }
    categoriasSeleccionadasExcepciones {
      categorias {
        categoria {
          id
          desc
        }
        valores {
          nombre
          seleccionado
          activado
        }
      }
      excepciones {
        codDistribuidor
        codigo
        desc
      }
    }
    categoriasSeleccionadasCliente {
      categorias {
        categoria {
          id
          desc
        }
        valores {
          nombre
          seleccionado
          activado
        }
      }
      excepciones {
        codDistribuidor
        codigo
        desc
      }
    }
    comercialesSeleccionados
    vigencia
    ajustado
    ajustadoIncentivo
    paraEspecialistas
    paraProveedor
    ocultarNiveles
    minEuroType
    inctvMinCaja
    pagoIncentivo
    nArtGroup
    satEspecialista
    validateMsg
    withException
    withExceptionDesc
    canales {
      codigoCanal
      descripcion
    }
  }
`
