import React, { useState, useEffect, useContext } from 'react'

import {
  PageTitle,
  StepperHeader,
  Button,
  Modal,
  ResourceWrapper,
} from 'Components'
import { ObjectiveSummary } from 'Fragments'
import {
  SAVE_DRAFT_OBJECTIVE,
  CHANGE_OBJECTIVE_STATE,
  OBJECTIVE,
  OBJECTIVE_WITH_DATA,
} from 'Queries'
import { GlobalContext, ObjectiveContext, getStepsOption } from 'Utils'

import { Layout as AntLayout, Row, Spin } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { get, isNil, findIndex } from 'lodash'
import { useMutation, useQuery } from '@apollo/client'

const mapTypeTitle = {
  new: 'Crear Objetivo',
  copy: 'Crear Objetivo',
  edit: 'Editar Objetivo',
}

const AntHeader = AntLayout.Header
const AntContent = AntLayout.Content
const AntFooter = AntLayout.Footer

const getActiveStepFromHash = (config, objective) => {
  const locationHash = document.location.hash.split('/').pop()
  let toIndex = findIndex(config, { route: locationHash })
  if (toIndex < 0) toIndex = 0
  if (toIndex === 0 || config[toIndex].isValid(objective)) {
    return toIndex
  } else {
    return 0
  }
}

export const ObjectiveLayout = ({
  children,
  navigate,
  ano,
  codObjetivo,
  type,
  objective,
}) => {

  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { resetKeepAliveTimer, setObjective, isDev, setIsLoading, isLoading, empleados, setEmpleados } = context
  const codDistribuidor = get(context, 'currentUser.codDistribuidor')
  const variables = {
    id: {
      codDistribuidor,
      ano: Number(ano),
      codObjetivo: Number(codObjetivo),
    },
  }

  const newVariables = {
    id: {
      codDistribuidor,
      ano: Number(ano),
      codObjetivo: Number(codObjetivo),
    },
    estado: "Draft"
  }

  const [activeStep, setActiveStep] = useState('')
  const [isNavigationPromptVisible, setIsNavigationPromptVisible] = useState(false,)
  const [isSAving, setIsSAving] = useState(false)

  const resource = useQuery(
    objective.isDraft ? OBJECTIVE : OBJECTIVE_WITH_DATA,
    {
      fetchPolicy: 'network-only',
      variables,
      onCompleted: ({ objective }) => {
        const medidaL = get(objective, 'medida.id') || ''
        const tipoValorRefL = get(objective, 'tipoValorRef.id') || ''
        const minEuroTypeL = get(objective, 'minEuroType') || false
        const withExceptionL = get(objective, 'withException') || false
        const stepsL = getStepsOption(medidaL, tipoValorRefL, minEuroTypeL, withExceptionL)

        setObjective(objective)
        let currentStep = getActiveStepFromHash(stepsL, objective)
        navigate(stepsL[currentStep].route)
        setActiveStep(currentStep)
        resetKeepAliveTimer()
      },
    },
  )

  const saveAndGoOut = () => {
    // saved objective unlock btn
    setIsLoading(false)
    setTimeout(() => {
      navigate('/dashboard/objectives/gestion')
      resetKeepAliveTimer()
    }, 100);
  }

  const [saveObjective] = useMutation(SAVE_DRAFT_OBJECTIVE, {
    variables: newVariables,
    onCompleted: () => { saveAndGoOut() },
  })

  const [ChangeObjectiveState] = useMutation(CHANGE_OBJECTIVE_STATE, {
    variables: newVariables,
    onCompleted: () => { saveAndGoOut() },
  })

  const medida = get(resource, 'data.objective.medida.id') || ''
  const tipoValorRef = get(resource, 'data.objective.tipoValorRef.id') || ''
  const minEuroType = get(objective, 'minEuroType') || false
  const withException = get(objective, 'withException') || false
  const steps = getStepsOption(medida, tipoValorRef, minEuroType, withException)

  const updateState = (state) => {
    newVariables.estado = state
    ChangeObjectiveState()
  }

  const saveAs = state => {
    newVariables.estado = state
    saveObjective()
  }

  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === steps.length - 1
  const showNextStep = () => {
    const currentStep = activeStep + 1
    navigate(steps[currentStep].route)
    setActiveStep(currentStep)
  }
  const showPreviousStep = () => {
    let currentStep = activeStep - 1
    navigate(steps[currentStep].route)
    setActiveStep(currentStep)
  }

  const hash = document.location.hash

  useEffect(() => {
    if (objective && !isNil(objective.draft)) {
      let currentStep = isDev
        ? activeStep
        : getActiveStepFromHash(steps, objective)
      navigate(steps[currentStep].route)
      if (currentStep !== activeStep) {
        setActiveStep(currentStep)
      }
    }
  }, [hash])

  let block = objective.paraEspecialistas && empleados && !empleados.length;

  const finButDisabled = isLoading || (
    ((medida !== 'A' && tipoValorRef !== 'E') && (!objective.hasClients || (isNil(objective.ajuste) || isNil(objective.incentivo)))) ||
    (medida === 'A' && isNil(objective.ajusteAbierto)) ||
    (tipoValorRef === 'E' && ((!minEuroType && !objective.hasClients) || (minEuroType && isNil(objective.ajuste))))
  );

  return (
    <ResourceWrapper resource={resource}>
      <Layout>
        <Modal
          visible={isNavigationPromptVisible}
          title="Salir de crear objetivo"
          okText="SALIR"
          onOk={() => {
            setEmpleados(null);
            setTimeout(() => {
              navigate('/dashboard/objectives/gestion');
            }, 100);
          }}
          BttnProps={{
            type: 'danger',
          }}
          onCancel={() => setIsNavigationPromptVisible(false)}
        >
          <p>Los cambios no se guardaran.</p>
        </Modal>
        <Header>
          <TitleAndBack>
            <ArrowLeftOutlined
              style={{
                fontSize: 24,
                marginRight: 10,
                position: 'relative',
                top: 4,
              }}
              onClick={() => {
                if (!block || (block && type === 'new')) {
                  if (type !== 'edit') {
                    setIsNavigationPromptVisible(true)
                  } else {
                    navigate('/dashboard/objectives/gestion')
                  }
                }
              }}
            />
            <PageTitle centered objtxt >{mapTypeTitle[type]}</PageTitle>
          </TitleAndBack>
          <StepperHeader
            stepsOptions={steps}
            objective={context.objective}
            onStepChange={step => {
              if (isDev || steps[step].isValid(objective)) {
                navigate(steps[step].route)
                setActiveStep(step)
              }
            }}
            currentStep={activeStep}
          ></StepperHeader>
        </Header>
        <Content>
          <Inner>
            {children}
            <ObjectiveSummary
              showAdjustmentsAndIncentives
              navigate={navigate}
              disabled={!steps[1].isValid(context.objective)}
              steps={steps}
              currentStep={activeStep}
            />
          </Inner>
        </Content>
        <Footer>
          <Row type="flex" justify="space-between">
            {!isFirstStep && (
              <Button type="link" onClick={showPreviousStep}>
                ANTERIOR
              </Button>
            )}
            {isFirstStep && <div></div>}
            <Row type="flex" justify="space-between">
              {(isLastStep && (objective.draft || objective.estado === 'Draft')) && (
                <Button
                  type="primary"
                  disabled={finButDisabled}
                  style={{ marginRight: "15px" }}
                  onClick={() => {
                    setIsSAving(true)
                    if (
                      objective &&
                      !isNil(objective.draft) &&
                      !objective.draft
                    ) {
                      updateState('Draft')
                    } else {
                      // save objective block button
                      setIsLoading(true);
                      saveAs('Draft')
                    }
                  }}
                >
                  GUARDAR BORRADOR
                </Button>
              )}
              {isLastStep ? (
                <Button
                  type="primary"
                  disabled={finButDisabled}
                  onClick={() => {
                    setIsSAving(true)
                    if (
                      objective &&
                      !isNil(objective.draft) &&
                      !objective.draft
                    ) {
                      updateState('Productivo')
                    } else {
                      // save objective block button
                      setIsLoading(true);
                      saveAs('Productivo')
                    }
                  }}
                >
                  {(objective.draft || objective.estado === 'Draft') ? 'PUBLICAR' : 'TERMINAR'}
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={
                    !steps[activeStep + 1].isValid(context.objective)
                  }
                  onClick={showNextStep}
                >
                  SIGUIENTE
                </Button>
              )}
            </Row>
          </Row>
        </Footer>
        {isSAving && (
          <div style={{ position: 'absolute', height: '100%', width: '100%', background: '#f9faf9', padding: 200 }}>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <Spin style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, margin: 'auto' }} />
            </div>
          </div>)}
      </Layout>
    </ResourceWrapper >
  )
}

const Layout = styled(AntLayout)`
  position: relative;
  height: 100%;  
  width: 100%;  
  background: none;
  .ant-layout-content {
    padding: 0;
  }
`
const Header = styled(AntHeader)({
  background: 'white',
  boxShadow: '0 2px 6px 0 rgba(117, 117, 117, 0.1)',
  zIndex: 1000,
  height: 125,
  position: 'fixed',
  width: '100%',
})
const Content = styled(AntContent)({
  background: '#F9FAF9',
  height: '100vh !important',
  width: '100vw !important',
  padding: '125px 0 56px 0 !important',
})
const Inner = styled.div({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
})

const Footer = styled(AntFooter)({
  background: 'white',
  boxShadow: '0 -6px 6px 0 rgba(117, 117, 117, 0.1)',
  zIndex: 1000,
  position: 'fixed',
  width: '100%',
  bottom: 0,
  padding: 8,
})
const TitleAndBack = styled.div({
  display: 'flex',
  alignItems: 'baseline',
})
