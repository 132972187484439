export const ObjectiveIncetiveMock = {
	"data": {
		"objective": {
			"codObjetivo": 20,
			"ano": 2020,
			"draft": false,
			"nombre": "VolTest",
			"etiqueta": "test",
			"idDesc": "2020-20",
			"ajustado": false,
			"comoDesc": "Aumenta 123% respecto el año anterior. Aplica a un grupo de comerciales en conjunto.",
			"modifEn": 1583939368544,
			"medida": {
				"id": "F",
				"desc": "Facturación",
				"unidad": "euros",
				"__typename": "ObjetivoQueMedida"
			},
			"tiposEntrega": [
				{
					"id": 0,
					"__typename": "ObjetivoQueTipoEntrega"
				}
			],
			"tipoValorRef": {
				"id": "IA",
				"desc": "Incremental respecto al año anterior",
				"__typename": "ObjetivoComoTipoValorRef"
			},
			"valorRef": {
				"valor": 123,
				"tipoDeUnidad": "porcentaje",
				"__typename": "ObjetivoComoValorRef"
			},
			"distribucionValorRef": {
				"id": "D",
				"desc": "En conjunto",
				"nota": "El valor de referencia aplicará para las ventas conjuntas de todos los comerciales asociados al objetivo",
				"__typename": "ObjetivoComoDistribucionValorRef"
			},
			"cuandoEvaluar": {
				"id": "M",
				"desc": "Cada mes hasta el final del objetivo",
				"__typename": "ObjetivoCuandoEvaluar"
			},
			"periodoVigencia": {
				"fechaInicio": 1577836800000,
				"fechaFin": 1609372800000,
				"__typename": "ObjetivoCuandoPeriodoVigencia"
			},
			"categoriasSeleccionadasArticulo": {
				"categorias": [],
				"excepciones": [],
				"__typename": "ObjetivoListaCategoriasConExcepciones"
			},
			"categoriasSeleccionadasCliente": {
				"categorias": [],
				"excepciones": [],
				"__typename": "ObjetivoListaCategoriasConExcepciones"
			},
			"comercialesSeleccionados": [
				9237292,
				9237999,
				9237759,
				452,
				453,
				9237657,
				10,
				11,
				9178594,
				9233267
			],
			"vigencia": "activos",
			"ajustadoIncentivo": false,
			"paraEspecialistas": false,
			"ocultarNiveles": false,
			"__typename": "Objetivo",
			"incentivo": {
				"cabeceraTotales": {
					"total": 0,
					"totalMeses": [
						{
							"mes": 1,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 2,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 3,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 4,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 5,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 6,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 7,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 8,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 9,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 10,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 11,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						},
						{
							"mes": 12,
							"valor": 0,
							"__typename": "ObjetivoIncentivoCelda"
						}
					],
					"__typename": "ObjetivoIncentivoTotal"
				},
				"empleados": [
					{
						"nivelAnidacion": 1,
						"empleado": {
							"codMiembro": 1351,
							"titulo": "Russell Reynolds",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 2,
						"empleado": {
							"codMiembro": 1401,
							"titulo": "Prueba Segunda",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 2,
						"empleado": {
							"codMiembro": 1201,
							"titulo": "fulano de Tal",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 9237999,
							"titulo": "Anxo Ocampo",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 9233267,
							"titulo": "Carlos Roldán",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 10,
							"titulo": "Hai Becker",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 9178594,
							"titulo": "Hector Andrés",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 9237759,
							"titulo": "Jose Manuel Puche",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 9237292,
							"titulo": "José Mantero",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 453,
							"titulo": "Juan Eugenio Hernández Expósito",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 11,
							"titulo": "Marcene Schuster",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 452,
							"titulo": "María Barroso Rodríguez",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					},
					{
						"nivelAnidacion": 3,
						"empleado": {
							"codMiembro": 9237657,
							"titulo": "Pepe Rivera",
							"__typename": "ObjetivoAjusteMiembro"
						},
						"total": 0,
						"celdas": [
							{
								"mes": 1,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 2,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 3,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 4,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 5,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 6,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 7,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 8,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 9,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 10,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 11,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							},
							{
								"mes": 12,
								"valor": 0,
								"__typename": "ObjetivoIncentivoCelda"
							}
						],
						"__typename": "ObjetivoIncentivoFila"
					}
				],
				"__typename": "ObjetivoIncentivo"
			},
			"incentivoMatrizCumplimiento": [
				{
					"desde": 0,
					"hasta": 95,
					"limite": false,
					"lineal": false,
					"orden": 1,
					"secuencia": 1,
					"valor": 0,
					"__typename": "ObjetivoIncentivoMatrizCumplimientoFila"
				},
				{
					"desde": 95,
					"hasta": 96,
					"limite": false,
					"lineal": false,
					"orden": 2,
					"secuencia": 2,
					"valor": 75,
					"__typename": "ObjetivoIncentivoMatrizCumplimientoFila"
				},
				{
					"desde": 96,
					"hasta": 97,
					"limite": false,
					"lineal": false,
					"orden": 3,
					"secuencia": 3,
					"valor": 80,
					"__typename": "ObjetivoIncentivoMatrizCumplimientoFila"
				},
				{
					"desde": 97,
					"hasta": 98,
					"limite": false,
					"lineal": false,
					"orden": 4,
					"secuencia": 4,
					"valor": 85,
					"__typename": "ObjetivoIncentivoMatrizCumplimientoFila"
				},
				{
					"desde": 98,
					"hasta": 99,
					"limite": false,
					"lineal": false,
					"orden": 5,
					"secuencia": 5,
					"valor": 90,
					"__typename": "ObjetivoIncentivoMatrizCumplimientoFila"
				},
				{
					"desde": 99,
					"hasta": 100,
					"limite": false,
					"lineal": false,
					"orden": 6,
					"secuencia": 6,
					"valor": 95,
					"__typename": "ObjetivoIncentivoMatrizCumplimientoFila"
				},
				{
					"desde": 100,
					"hasta": 120,
					"limite": false,
					"lineal": true,
					"orden": 7,
					"secuencia": 7,
					"valor": null,
					"__typename": "ObjetivoIncentivoMatrizCumplimientoFila"
				},
				{
					"desde": 120,
					"hasta": null,
					"limite": true,
					"lineal": false,
					"orden": 8,
					"secuencia": 8,
					"valor": 120,
					"__typename": "ObjetivoIncentivoMatrizCumplimientoFila"
				}
			]
		}
	}
}