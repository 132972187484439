import gql from 'graphql-tag'

export const ACTION_PLAN = gql`
  query actionPlan(
    $idObjetivo: ObjetivoIdInput!
    $fechaDesde: Long!
    $fechaHasta: Long!
    $codEmpleado: Long!
    $codPostal: [String]
  ) {
    planAccionPdvResumen(
      idObjetivo: $idObjetivo
      fechaDesde: $fechaDesde
      fechaHasta: $fechaHasta
      codEmpleado: $codEmpleado
      codPostal: $codPostal
    ) {
      tablaResumenMedida {
        act
        aa
        dif
        porcDif
      }
      tablaResumenMedidaPdvSituacion {
        pdv
        medidaActDifAA
        porcVarUnidad
      }
    }
  }
`

const PLAN_ACCION_PDV_DETALLE_FRAGMENT = gql`
  fragment planAccionPdvDetallePagination on PlanAccionPdvDetallePage {
    pagination {
      numberOfElements
      totalElements
    }
  }
  fragment planAccionPdvDetalleContent on PlanAccionPdvDetalle {
    # codDistribuidor
    codPtoVenta
    nombre
    poblacion
    # razonSocial
    # situacion
    rutaPrev {
      id
      desc
      distribuidor
    }
  }
`

export const ACTION_PLAN_PDV = gql`
  query actionPlanPdv(
    $idObjetivo: ObjetivoIdInput!
    $page: Int = 0
    $size: Int = 50
    $sortDir: SortDir = asc
    $searchTerm: String
    $sortBy: String
    $fechaDesde: Long
    $fechaHasta: Long
    $codEmpleado: Long
    $pdvSituacionId: String
    $situacionId: String
    $codPostal: [String]
  ) {
    planAccionPdvDetallePage(
      page: $page
      size: $size
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      idObjetivo: $idObjetivo
      fechaDesde: $fechaDesde
      fechaHasta: $fechaHasta
      codEmpleado: $codEmpleado
      pdvSituacionId: $pdvSituacionId
      situacionId: $situacionId
      codPostal: $codPostal
    ) {
      ...planAccionPdvDetallePagination
      content {
        ...planAccionPdvDetalleContent
        pdvSituacion
        valorMedidaDia
        valorMedidaAA
        difMedida
      }
    }
  }
  ${PLAN_ACCION_PDV_DETALLE_FRAGMENT}
`

export const ACTION_PLAN_PDV_OPORTUNIDAD = gql`
  query actionPlanPdvOportunidad(
    $idObjetivo: ObjetivoIdInput!
    $page: Int = 0
    $size: Int = 50
    $sortDir: SortDir = asc
    $searchTerm: String
    $sortBy: String
    $fechaDesde: Long
    $fechaHasta: Long
    $codEmpleado: Long
    $numeroMesesAnteriores: Int!
    $situacionId: String
    $matObjetivo: Boolean
    $codPostal: [String]
  ) {
    planAccionPdvOportunidadPage(
      page: $page
      size: $size
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      idObjetivo: $idObjetivo
      fechaDesde: $fechaDesde
      fechaHasta: $fechaHasta
      codEmpleado: $codEmpleado
      numeroMesesAnteriores: $numeroMesesAnteriores
      situacionId: $situacionId
      matObjetivo: $matObjetivo
      codPostal: $codPostal
    ) {
      ...planAccionPdvDetallePagination
      content {
        ...planAccionPdvDetalleContent
        calcMedida
      }
    }
  }
  ${PLAN_ACCION_PDV_DETALLE_FRAGMENT}
`

export const CODIGOS_POSTALES = gql`
  query codigosPostales( $codDistribuidor: Long! ) {
    codigosPostales( codDistribuidor: $codDistribuidor ) 
  }
`
