import React, { Fragment, useState, useContext, useEffect } from "react";

import { ExceptionsEllipse } from "Components";
import { GET_COMMERCIAL_HIERARCHY } from "Queries";
import {
    GlobalContext,
    ObjectiveContext,
    ObjectiveContextConsumer,
    colors,
    diveObj,
    findSalesMen,
    formatNumberWithThousands,
    sort,
} from "Utils";

import { Layout, Tooltip, Button } from "antd";
import {
    SolutionOutlined,
    FileTextOutlined,
    LeftOutlined,
    RightOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";
import moment from "moment";

const categoriasParser = ([categorias]) => (
    <ExceptionsEllipse collapsed fontInherit>
        {categorias.length
            ? categorias
                  .map(({ valores }) =>
                      valores.map(({ nombre }) => nombre).join(", ")
                  )
                  .join(", ")
            : "Todas"}
    </ExceptionsEllipse>
);

const excepcionesParser = ([excepciones]) => (
    <ExceptionsEllipse collapsed fontInherit>
        {excepciones.length
            ? excepciones.map(({ desc }) => desc).join(", ")
            : "Ninguna"}
    </ExceptionsEllipse>
);

const canalesParser = (canales) => (
    <ExceptionsEllipse collapsed fontInherit>
     {canales[0].length
        ? canales[0].map((canal) => canal.descripcion).join(", ")
        : "Ninguno"}
    </ExceptionsEllipse>
  );
const sections = [
    {
        order: 1,
        label: "Definición",
        values: [
            { label: "Nombre", fields: ["nombre"] },
            {
                label: "¿Qué medir?",
                fields: ["medida.desc", "withExceptionDesc"],
            },
            {
                label: "¿Cómo medirlo?",
                fields: ["comoDesc"],
            },
            {
                label: "¿Cuando?",
                fields: [
                    ["periodoVigencia.fechaInicio", "periodoVigencia.fechaFin"],
                    "cuandoEvaluar.desc",
                ],
            },
            {
                label: "Canales",
                fields: ["canales"],
                method: canalesParser,
            },
        ],
    },
];

const gerenciaArticulos = [
    {
        order: 2,
        label: "Artículos",
        values: [
            {
                label: "Categorías",
                fields: ["categoriasSeleccionadasArticulo.categorias"],
                method: categoriasParser,
            },
            {
                label: "Deseleccionados",
                fields: ["categoriasSeleccionadasArticulo.excepciones"],
                method: excepcionesParser,
            },
        ],
    },
];

const gerenciaExcepciones = [
    {
        order: 2,
        label: "Artículos y Excepciones",
        values: [
            {
                label: "Artículos (Categorías)",
                fields: ["categoriasSeleccionadasArticulo.categorias"],
                method: categoriasParser,
            },
            {
                label: "Artículos (Deseleccionados)",
                fields: ["categoriasSeleccionadasArticulo.excepciones"],
                method: excepcionesParser,
            },
            {
                label: "Excepciones (Categorías)",
                fields: ["categoriasSeleccionadasExcepciones.categorias"],
                method: categoriasParser,
            },
            {
                label: "Excepciones (Deseleccionados)",
                fields: ["categoriasSeleccionadasExcepciones.excepciones"],
                method: excepcionesParser,
            },
        ],
    },
];

const gerenciaClientes = [
    {
        order: 4,
        label: "Clientes",
        values: [
            {
                label: "Categorías",
                fields: ["categoriasSeleccionadasCliente.categorias"],
                method: categoriasParser,
            },
            {
                label: "Deseleccionados",
                fields: ["categoriasSeleccionadasCliente.excepciones"],
                method: excepcionesParser,
            },
        ],
    },
];

const gerenciaComerciales = [
    {
        order: 2,
        label: "Comerciales",
        values: [
            {
                fields: ["comercialesSeleccionados"],
                method: ([comerciales], jerarquia) => {
                    const totalComerciales = comerciales.length;
                    const listadoComerciales = comerciales
                        .map((comercial) => jerarquia[comercial])
                        .filter((comercial) => comercial)
                        .join(", ");
                    return totalComerciales ? (
                        totalComerciales < 10 ? (
                            listadoComerciales
                        ) : (
                            <Tooltip
                                placement="leftTop"
                                title={listadoComerciales}
                            >
                                <SolutionOutlined
                                    style={{ color: colors.trueGreen }}
                                />
                                {` ${totalComerciales} seleccionados`}
                            </Tooltip>
                        )
                    ) : (
                        "Ninguno seleccionado"
                    );
                },
            },
        ],
    },
];

const gerenciaAjustes = [
    {
        order: 5,
        label: "Ajustes",
        values: [
            {
                label: "Valor de referencia",
                fields: [
                    "ajuste.panel.valorRef.0.valor.valor + ajuste.panel.valorRef.0.valor.unidad",
                ],
                method: ([valor, unidad]) =>
                    valor && formatNumberWithThousands(valor) + unidad,
            },
            {
                label: "Valor personalizado",
                fields: [
                    "ajuste.panel.valorPersonalizado.0.valor.valor + ajuste.panel.valorPersonalizado.0.valor.unidad",
                ],
                method: ([valor, unidad]) =>
                    valor && formatNumberWithThousands(valor) + unidad,
            },
        ],
    },
];

const gerenciaAjustesAbierto = [
    {
        order: 5,
        label: "Ajustes/Incentivos",
        values: [
            {
                label: "Total",
                fields: ["ajusteAbierto.cabeceraInctvTotal.total"],
                method: ([total]) => formatNumberWithThousands(total) + " €",
            },
        ],
    },
];

const gerenciaIncentivo = [
    {
        order: 6,
        label: "Incentivos",
        values: [
            {
                label: "Total",
                fields: ["incentivo.cabeceraTotales.total"],
                method: ([total]) => formatNumberWithThousands(total) + " €",
            },
        ],
    },
];

let newSections = [
    ...sections,
    ...gerenciaArticulos,
    ...gerenciaClientes,
    ...gerenciaComerciales,
    ...gerenciaAjustes,
    ...gerenciaIncentivo,
];

const getSectionOption = (steps, objective, readOnly) => {
    newSections = [];

    if (objective && objective.medida && objective.medida.id === "A") {
        newSections = [...newSections, ...sections];

        if (!readOnly) {
            newSections = [...newSections, ...gerenciaComerciales];
        }

        newSections = [...newSections, ...gerenciaAjustesAbierto];
    } else {
        if (objective.withException) {
            newSections = [...newSections, ...gerenciaExcepciones];
        } else {
            newSections = [...newSections, ...sections, ...gerenciaArticulos];
        }

        newSections = [...newSections, ...gerenciaClientes];

        if (!readOnly) {
            newSections = [...newSections, ...gerenciaComerciales];

            if (steps.find((stp) => stp.title === "Ajustes") !== undefined) {
                newSections = [...newSections, ...gerenciaAjustes];
            }

            if (steps.find((stp) => stp.title === "Incentivos") !== undefined) {
                newSections = [...newSections, ...gerenciaIncentivo];
            }
        }
    }

    return newSections;
};

const renderSections = (
    objective,
    jerarquiaComercial,
    sections,
    steps,
    navigate,
    readOnly
) =>
    (jerarquiaComercial || readOnly) && (
        <Fragment>
            {sections.map(
                ({ label, values }, i) =>
                    (!steps || steps[i].isValid(objective)) &&
                    !!diveObj(objective, values[0].fields[0])[0] && (
                        <Section key={label}>
                            <Header>
                                {label}
                                {!!steps && (
                                    <Button
                                        type="link"
                                        onClick={() => navigate(steps[i].route)}
                                    >
                                        EDITAR
                                    </Button>
                                )}
                            </Header>
                            <Content>
                                {values.map(
                                    ({ label, fields, method }, i) =>
                                        !!diveObj(
                                            objective,
                                            Array.isArray(fields[0])
                                                ? fields[0][0]
                                                : fields[0]
                                        )[0] && (
                                            <Field key={i}>
                                                <FieldLabel>{label}</FieldLabel>
                                                <FieldValue>
                                                    {fields.map((field) => (
                                                        <div key={field}>
                                                            {!method &&
                                                            Array.isArray(field)
                                                                ? field.map(
                                                                      (
                                                                          subfield,
                                                                          i
                                                                      ) =>
                                                                          (i
                                                                              ? " - "
                                                                              : "") +
                                                                          moment(
                                                                              diveObj(
                                                                                  objective,
                                                                                  subfield
                                                                              )[0]
                                                                          ).format(
                                                                              "DD/MM/YYYY"
                                                                          )
                                                                  )
                                                                : method
                                                                ? method(
                                                                      diveObj(
                                                                          objective,
                                                                          field
                                                                      ),
                                                                      jerarquiaComercial
                                                                  )
                                                                : diveObj(
                                                                      objective,
                                                                      field
                                                                  )}
                                                        </div>
                                                    ))}
                                                </FieldValue>
                                            </Field>
                                        )
                                )}
                            </Content>
                        </Section>
                    )
            )}
        </Fragment>
    );

export const ObjectiveSummary = ({
    disabled,
    steps,
    currentStep,
    navigate,
    rightShift = 0,
    readOnly,
}) => {
    const context = useContext(GlobalContext);
    const {
        currentUser: { codDistribuidor },
    } = context;
    const {
        objective: { comercialesSeleccionados },
    } = useContext(ObjectiveContext);

    const [showSummary, setShowSummary] = useState(false);
    const [jerarquiaComercial, setJerarquiaComercial] = useState();
    context.setShowSummary = setShowSummary;

    const { data } = useQuery(GET_COMMERCIAL_HIERARCHY, {
        skip: !comercialesSeleccionados || readOnly,
        variables: {
            codDistribuidor,
        },
        onCompleted: context.resetKeepAliveTimer,
    });

    useEffect(() => {
        if (data && !jerarquiaComercial) {
            setJerarquiaComercial(
                findSalesMen(data.jerarquiaComercial.jerarquia).reduce(
                    (salesmen, { miembro: { nombre, codMiembro } }) => ({
                        ...salesmen,
                        [codMiembro]: nombre,
                    }),
                    {}
                )
            );
        }
    }, [data]);

    useEffect(() => {
        if (!showSummary && currentStep === 4) setShowSummary(true);
    }, [currentStep]);

    return (
        <div
            style={{
                position: "absolute",
                right: showSummary ? 0 : -19,
                top: !readOnly && 0,
            }}
        >
            <ObjectiveContextConsumer>
                {({ objective }) =>
                    Number.isFinite(objective.codObjetivo) && (
                        <Summary
                            readOnly={readOnly}
                            collapsed={!showSummary}
                            onCollapse={() => setShowSummary(!showSummary)}
                            collapsible
                            collapsedWidth={disabled ? 0 : 70}
                            width={260}
                            trigger={
                                <TriggerBtn disabled={disabled}>
                                    <Fragment>
                                        <LeftOutlined
                                            data-show={!showSummary}
                                        />
                                        <FileTextOutlined />
                                        <span data-show={showSummary}>
                                            {" "}
                                            Resumen
                                        </span>
                                        <RightOutlined
                                            data-show={showSummary}
                                        />
                                    </Fragment>
                                </TriggerBtn>
                            }
                            style={{
                                marginRight:
                                    rightShift || (showSummary ? 0 : -70),
                            }}
                            placed={!!rightShift ? 1 : 0}
                        >
                            <Sections readOnly={readOnly}>
                                {showSummary &&
                                    renderSections(
                                        objective,
                                        jerarquiaComercial,
                                        sort(
                                            getSectionOption(
                                                steps,
                                                objective,
                                                readOnly
                                            ),
                                            "order"
                                        ),
                                        steps,
                                        navigate,
                                        readOnly
                                    )}
                            </Sections>
                        </Summary>
                    )
                }
            </ObjectiveContextConsumer>
        </div>
    );
};

const Summary = styled(Layout.Sider)`
    box-shadow: 0 4px 12px 0 rgba(200, 198, 198, 0.5);
    height: calc(100vh - ${({ readOnly }) => (readOnly ? 55 : 180)}px);
    padding: 0;
    z-index: 100;
    .ant-layout-sider-trigger {
        background-color: ${colors.trueGreen};
        position: absolute;
        top: 0px;
        left: ${({ collapsed }) => (collapsed ? -89 : 0)}px;
    }
    &.ant-layout-sider-collapsed {
        flex: 0 0 ${({ placed }) => (placed === 1 ? 0 : 70)} !important;
        min-width: ${({ placed }) => (placed === 1 ? 0 : 70)} !important;
    }
`;
const TriggerBtn = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    font-size: 18px;
    [data-show="false"] {
        display: none;
    }
`;
const Sections = styled.div(({ readOnly }) => ({
    marginTop: 43,
    height: `calc(100vh - ${readOnly ? 126 : 226}px)`,
    overflow: "auto",
}));
const Section = styled.div`
    padding: 10px 0;
`;
const Header = styled.div`
    height: 40px;
    background-color: rgba(226, 240, 200, 0.3);
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: ${colors.pine};
    font-weight: bold;
    justify-content: space-between;
`;
const Content = styled.div`
    padding: 20px 20px 0;
`;
const Field = styled.div`
    margin: 0 0 10px;
`;
const FieldLabel = styled.div`
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
    margin-bottom: 0;
`;
const FieldValue = styled.div`
    font-size: 12px;
    p {
        margin: 0;
    }
`;
