export const ObjectiveAdjustmentsMock = {
  data: {
    objective: {
      codObjetivo: 9,
      ano: 2021,
      draft: false,
      nombre: 'va de bultos',
      etiqueta: 'camer',
      idDesc: '2021-9',
      ajustado: false,
      comoDesc:
        'Aumenta 5 pdvs respecto el año anterior. Aplica a cada comercial.',
      modifEn: 1583914650612,
      medida: {
        id: 'N',
        desc: 'Numérica',
        unidad: 'pdvs',
        __typename: 'ObjetivoQueMedida',
      },
      tiposEntrega: [
        {
          id: 0,
          __typename: 'ObjetivoQueTipoEntrega',
        },
        {
          id: 1,
          __typename: 'ObjetivoQueTipoEntrega',
        },
      ],
      tipoValorRef: {
        id: 'IA',
        desc: 'Incremental respecto al año anterior',
        __typename: 'ObjetivoComoTipoValorRef',
      },
      valorRef: {
        valor: 5,
        tipoDeUnidad: 'cantidad',
        __typename: 'ObjetivoComoValorRef',
      },
      distribucionValorRef: {
        id: 'C',
        desc: 'A cada comercial',
        nota:
          'El valor de referencia se aplicará de manera individual a cada comercial asociado al objetivo',
        __typename: 'ObjetivoComoDistribucionValorRef',
      },
      cuandoEvaluar: {
        id: 'F',
        desc: 'Al final del objetivo',
        __typename: 'ObjetivoCuandoEvaluar',
      },
      periodoVigencia: {
        fechaInicio: 1609459200000,
        fechaFin: 1640908800000,
        __typename: 'ObjetivoCuandoPeriodoVigencia',
      },
      categoriasSeleccionadasArticulo: {
        categorias: [],
        excepciones: [],
        __typename: 'ObjetivoListaCategoriasConExcepciones',
      },
      categoriasSeleccionadasCliente: {
        categorias: [
          {
            categoria: {
              id: 'ZIP',
              desc: 'Código Postal',
              __typename: 'ObjetivoCategoria',
            },
            valores: [
              {
                nombre: '11012',
                seleccionado: true,
                activado: true,
                __typename: 'ObjetivoCategoriaValor',
              },
            ],
            __typename: 'ObjetivoCategoriaValores',
          },
        ],
        excepciones: [],
        __typename: 'ObjetivoListaCategoriasConExcepciones',
      },
      comercialesSeleccionados: [
        9237292,
        9237999,
        9237759,
        452,
        453,
        9237657,
        10,
        11,
        9178594,
        9233267,
      ],
      vigencia: 'futuros',
      ajustadoIncentivo: false,
      paraEspecialistas: false,
      ocultarNiveles: false,
      __typename: 'Objetivo',
      ajuste: {
        panel: {
          valorRef: [
            {
              valor: {
                titulo: 'Valor referencia',
                signo: true,
                valor: 5,
                unidad: '',
                __typename: 'ObjetivoAjusteValor',
              },
              subtitulo1: 'pdvs resp. aa',
              subtitulo2: 'comercial/periodo',
              __typename: 'ObjetivoAjusteIndicador',
            },
          ],
          valorRefDetalle: [
            {
              valor: {
                titulo: '',
                signo: true,
                valor: 50,
                unidad: '',
                __typename: 'ObjetivoAjusteValor',
              },
              subtitulo1: 'pdvs resp. aa',
              subtitulo2: 'periodo',
              __typename: 'ObjetivoAjusteIndicador',
            },
            {
              valor: {
                titulo: '',
                signo: false,
                valor: 50,
                unidad: '',
                __typename: 'ObjetivoAjusteValor',
              },
              subtitulo1: 'pdvs',
              subtitulo2: 'periodo',
              __typename: 'ObjetivoAjusteIndicador',
            },
          ],
          valorPersonalizado: null,
          valorPersonalizadoDetalle: null,
          __typename: 'ObjetivoAjustePanel',
        },
        cabeceraMeses: [
          'ene.',
          'feb.',
          'mar.',
          'abr.',
          'may.',
          'jun.',
          'jul.',
          'ago.',
          'sep.',
          'oct.',
          'nov.',
          'dic.',
        ],
        valoresCeldaPorEmpleadoMes: [
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 9237999,
              titulo: 'Anxo Ocampo',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 9233267,
              titulo: 'Carlos Roldán',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 10,
              titulo: 'Hai Becker',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 9178594,
              titulo: 'Hector Andrés',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 9237759,
              titulo: 'Jose Manuel Puche',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 9237292,
              titulo: 'José Mantero',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 453,
              titulo: 'Juan Eugenio Hernández Expósito',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 11,
              titulo: 'Marcene Schuster',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 452,
              titulo: 'María Barroso Rodríguez',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 9237657,
              titulo: 'Pepe Rivera',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
        ],
        cabeceraTotal: 'ene.-dic.',
        valoresTotalPorEmpleado: [
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
        ],
        valoresTotalPorMes: [
          {
            mes: 1,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 2,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 3,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 4,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 5,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 6,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 7,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 8,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 9,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 10,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 11,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 12,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 50,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
        ],
        __typename: 'ObjetivoAjuste',
      },
      ajusteJerarquico: {
        panel: {
          valorRef: [
            {
              valor: {
                titulo: 'Valor referencia',
                signo: true,
                valor: 5,
                unidad: '',
                __typename: 'ObjetivoAjusteValor',
              },
              subtitulo1: 'pdvs resp. aa',
              subtitulo2: 'comercial/periodo',
              __typename: 'ObjetivoAjusteIndicador',
            },
          ],
          valorRefDetalle: [
            {
              valor: {
                titulo: '',
                signo: true,
                valor: 50,
                unidad: '',
                __typename: 'ObjetivoAjusteValor',
              },
              subtitulo1: 'pdvs resp. aa',
              subtitulo2: 'periodo',
              __typename: 'ObjetivoAjusteIndicador',
            },
            {
              valor: {
                titulo: '',
                signo: false,
                valor: 50,
                unidad: '',
                __typename: 'ObjetivoAjusteValor',
              },
              subtitulo1: 'pdvs',
              subtitulo2: 'periodo',
              __typename: 'ObjetivoAjusteIndicador',
            },
          ],
          valorPersonalizado: null,
          valorPersonalizadoDetalle: null,
          __typename: 'ObjetivoAjustePanel',
        },
        cabeceraMeses: [
          'ene.',
          'feb.',
          'mar.',
          'abr.',
          'may.',
          'jun.',
          'jul.',
          'ago.',
          'sep.',
          'oct.',
          'nov.',
          'dic.',
        ],
        valoresCeldaPorEmpleadoMes: [
          {
            orden: 0,
            nivelAnidacion: 0,
            empleado: {
              codMiembro: 1101,
              titulo: 'Distribuidor 01 Gerente 01',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 50,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 50,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 1,
            nivelAnidacion: 1,
            empleado: {
              codMiembro: 1101,
              titulo: 'Distribuidor 01 Gerente 01',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 50,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 50,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 2,
            nivelAnidacion: 2,
            empleado: {
              codMiembro: 1101,
              titulo: 'Distribuidor 01 Gerente 01',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 3,
            nivelAnidacion: 3,
            empleado: {
              codMiembro: 9233267,
              titulo: 'Carlos Roldán',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 4,
            nivelAnidacion: 2,
            empleado: {
              codMiembro: 1201,
              titulo: 'fulano de Tal',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 5,
            nivelAnidacion: 3,
            empleado: {
              codMiembro: 9237759,
              titulo: 'Jose Manuel Puche',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 6,
            nivelAnidacion: 1,
            empleado: {
              codMiembro: 1351,
              titulo: 'Russell Reynolds',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 20,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 20,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 7,
            nivelAnidacion: 2,
            empleado: {
              codMiembro: 1401,
              titulo: 'Prueba Segunda',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 15,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 15,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 8,
            nivelAnidacion: 3,
            empleado: {
              codMiembro: 10,
              titulo: 'Hai Becker',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 9,
            nivelAnidacion: 3,
            empleado: {
              codMiembro: 9178594,
              titulo: 'Hector Andrés',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
          {
            orden: 10,
            nivelAnidacion: 3,
            empleado: {
              codMiembro: 9237657,
              titulo: 'Pepe Rivera',
              subtitulo: '',
              __typename: 'ObjetivoAjusteMiembro',
            },
            celdas: [
              {
                mes: 1,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 2,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 3,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 4,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 5,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 6,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 7,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 8,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 9,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 10,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 11,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
              {
                mes: 12,
                valorRef: {
                  titulo: '',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                inc: null,
                pa: {
                  titulo: 'AA.',
                  signo: false,
                  valor: 0,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                total: {
                  titulo: 'Tot.',
                  signo: false,
                  valor: 5,
                  unidad: '',
                  __typename: 'ObjetivoAjusteValor',
                },
                __typename: 'ObjetivoAjusteMatrizCelda',
              },
            ],
            __typename: 'ObjetivoAjusteMatrizFila',
          },
        ],
        cabeceraTotal: 'ene.-dic.',
        valoresTotalPorEmpleado: [
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 50,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 50,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 50,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 50,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 20,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 20,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 15,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 15,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 0,
            valorRef: {
              titulo: '',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 5,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
        ],
        valoresTotalPorMes: [
          {
            mes: 1,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 2,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 3,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 4,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 5,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 6,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 7,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 8,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 9,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 10,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 11,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
          {
            mes: 12,
            valorRef: null,
            inc: null,
            pa: {
              titulo: 'AA.',
              signo: false,
              valor: 0,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            total: {
              titulo: 'Tot.',
              signo: false,
              valor: 50,
              unidad: '',
              __typename: 'ObjetivoAjusteValor',
            },
            __typename: 'ObjetivoAjusteMatrizCelda',
          },
        ],
        __typename: 'ObjetivoAjuste',
      },
    },
  },
}
