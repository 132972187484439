import React from 'react'
import { EmployeeActions } from 'Components'
import { Icon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { colors, getNameInitials } from 'Utils'
import { isEmpty } from 'lodash'
import moment from 'moment'

const employeeType = {
  gerente: 'Gerente',
  jefe_ventas: 'Jefe Ventas',
  especialista: 'Especialista',
  jefe_equipo: 'Jefe Equipo',
  comercial: 'Comercial',
}

export const EmployeeRow = ({ dataSource, actions }) => {
  const {
    nombre,
    nivel,
    categorias,
    codComercial,
    codMiembro,
    lastLogin,
    activo,
    username,
  } = dataSource
  const isComercial = nivel === 'comercial'
  const subtitle =
    employeeType[nivel] +
    (isEmpty(categorias) ? '' : ' (' + categorias.join(', ') + ')')

  return (
    <StyledWrapper>
      <Circle>{nombre && getNameInitials(nombre)}</Circle>
      <StyledRow>
        <Title codPadre={dataSource.codPadre}>
          <span>
            {codMiembro} {nombre}
            {username && <UserName>{username}</UserName>}
          </span>
          {activo && lastLogin && actions && (
            <LastLogin title="último acceso">
              <Icon type="api" />
              <span>{moment(lastLogin).format('DD/MM/YY - HH:mm')}h.</span>
            </LastLogin>
          )}
        </Title>
        <Subtitle codPadre={dataSource.codPadre}>
          {subtitle}
          {isComercial && <span> {codComercial}</span>}
        </Subtitle>
      </StyledRow>
      {actions && <EmployeeActions dataSource={dataSource} actions={actions} />}
    </StyledWrapper>
  )
}
const Circle = styled.span`
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin-top: 3px;
  align-self: flex-start;

  background-color: #7ab800;
  border-radius: 50%;
  display: inline-block;
  line-height: 36px;

  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.43px;
  text-align: center;
  color: ${colors.white};
  user-select: none;
`
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 0.94;
  letter-spacing: 0.29px;
  color: ${props => props.codPadre ? colors.blackTwo : colors.white};
  margin-bottom: 4px;
`
const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.07;
  letter-spacing: 0.25px;
  color: ${props => props.codPadre ? colors.warmGrey : colors.white};
  text-overflow: ellipsis;
  overflow: hidden;
`
const StyledWrapper = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 4px 4px 0 8px;
`
const StyledRow = styled.div`
  margin-left: 16px;
  width: calc(100% - 245px);
`
const LastLogin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 115px;
  margin: -3px 10px 0 0;
  font-size: 14px;
  color: ${colors.coolGrey};
  > span {
    font-size: 12px;
    font-style: oblique;
    padding-top: 3px;
  }
`
const UserName = styled.span`
  font-size: 12px;
  color: ${colors.coolGrey};
  margin-left: 5px;
`
