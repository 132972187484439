export const objectivesMock = {
  data: {
    objetivoKpiValores: [
      {
        codObjetivo: 16,
        desc: 'PruebaEtiquetas',
        fechaDesde: 1546300800000,
        fechaHasta: 1573344000000,
        diasLaborablesRestantes: 5,
        cuandoEvaluar: { id: 'M', desc: 'Mensual' },
        distribucionValorRef: { id: 'C' },
        idMedida: 'V',
        objetivoUnidad: 'litros',
        porcCumplimientoMedidaRealDia: -100,
        objetivoRealDia: 0,
        objetivoDia: 1160358.91,
        objetivoAbsTotal: 1813060.8,
        objetivoPendiente: 1813060,
        objetivoPorDiaPendiente: 201451.2,
        incentivoDia: 0,
        incentivoTeorico: 0,
      },
      {
        codObjetivo: 18,
        desc: 'Otro objectivo con Etiqueta',
        fechaDesde: 1575158400000,
        fechaHasta: 1577750400000,
        diasLaborablesRestantes: 9,
        cuandoEvaluar: { id: 'M', desc: 'Mensual' },
        distribucionValorRef: { id: 'D' },
        idMedida: 'U',
        objetivoUnidad: 'unidades',
        porcCumplimientoMedidaRealDia: -100,
        objetivoRealDia: 0,
        objetivoDia: 19200,
        objetivoAbsTotal: 30000,
        objetivoPendiente: 30000,
        objetivoPorDiaPendiente: 3333.33,
        incentivoDia: 0,
        incentivoTeorico: 1350,
      },
      {
        codObjetivo: 17,
        desc: 'Objetivo con Etiqueta',
        fechaDesde: 1575158400000,
        fechaHasta: 1577750400000,
        diasLaborablesRestantes: 9,
        cuandoEvaluar: { id: 'M', desc: 'Mensual' },
        distribucionValorRef: { id: 'D' },
        idMedida: 'V',
        objetivoUnidad: 'litros',
        porcCumplimientoMedidaRealDia: -100,
        objetivoRealDia: 0,
        objetivoDia: 32000,
        objetivoAbsTotal: 50000,
        objetivoPendiente: 50000,
        objetivoPorDiaPendiente: 5555.55,
        incentivoDia: 0,
        incentivoTeorico: 2200,
      },
      {
        codObjetivo: 27,
        desc: 'Objetivo con Etiqueta2',
        fechaDesde: 1575158400000,
        fechaHasta: 1577750400000,
        diasLaborablesRestantes: 9,
        cuandoEvaluar: { id: 'M', desc: 'Mensual' },
        distribucionValorRef: { id: 'D' },
        idMedida: 'V',
        objetivoUnidad: 'litros',
        porcCumplimientoMedidaRealDia: -100,
        objetivoRealDia: 0,
        objetivoDia: 32000,
        objetivoAbsTotal: 50000,
        objetivoPendiente: 50000,
        objetivoPorDiaPendiente: 5555.55,
        incentivoDia: 0,
        incentivoTeorico: 2200,
      },
      {
        codObjetivo: 28,
        desc: 'Objetivo con Etiqueta3',
        fechaDesde: 1575158400000,
        fechaHasta: 1577750400000,
        diasLaborablesRestantes: 9,
        cuandoEvaluar: { id: 'M', desc: 'Mensual' },
        distribucionValorRef: { id: 'D' },
        idMedida: 'V',
        objetivoUnidad: 'litros',
        porcCumplimientoMedidaRealDia: -100,
        objetivoRealDia: 0,
        objetivoDia: 32000,
        objetivoAbsTotal: 50000,
        objetivoPendiente: 50000,
        objetivoPorDiaPendiente: 5555.55,
        incentivoDia: 0,
        incentivoTeorico: 2200,
      },
      {
        codObjetivo: 29,
        desc: 'Objetivo con Etiqueta4',
        fechaDesde: 1575158400000,
        fechaHasta: 1577750400000,
        diasLaborablesRestantes: 9,
        cuandoEvaluar: { id: 'M', desc: 'Mensual' },
        distribucionValorRef: { id: 'D' },
        idMedida: 'V',
        objetivoUnidad: 'litros',
        porcCumplimientoMedidaRealDia: -100,
        objetivoRealDia: 0,
        objetivoDia: 32000,
        objetivoAbsTotal: 50000,
        objetivoPendiente: 50000,
        objetivoPorDiaPendiente: 5555.55,
        incentivoDia: 0,
        incentivoTeorico: 2200,
      },
      {
        codObjetivo: 0,
        desc: 'INCENTIVOS: VUF_COMERCIAL_MES',
        fechaDesde: 1546300800000,
        fechaHasta: 1577750400000,
        diasLaborablesRestantes: 9,
        cuandoEvaluar: { id: 'F', desc: 'Fin de período' },
        distribucionValorRef: { id: 'C' },
        idMedida: 'F',
        objetivoUnidad: 'euros',
        porcCumplimientoMedidaRealDia: 0,
        objetivoRealDia: 0,
        objetivoDia: 0,
        objetivoAbsTotal: 0,
        objetivoPendiente: 0,
        objetivoPorDiaPendiente: 0,
        incentivoDia: 0,
        incentivoTeorico: 0,
      },
      {
        codObjetivo: 9,
        desc: 'INCENTIVOS: VUF_COMERCIAL_MES',
        fechaDesde: 1546300800000,
        fechaHasta: 1573344000000,
        diasLaborablesRestantes: 7,
        cuandoEvaluar: { id: 'M', desc: 'Fin de período' },
        distribucionValorRef: { id: 'C' },
        idMedida: 'F',
        objetivoUnidad: 'euros',
        porcCumplimientoMedidaRealDia: 0,
        objetivoRealDia: 0,
        objetivoDia: 0,
        objetivoAbsTotal: 0,
        objetivoPendiente: 0,
        objetivoPorDiaPendiente: 0,
        incentivoDia: 0,
        incentivoTeorico: 0,
      },
      {
        codObjetivo: 60,
        desc: 'De bultos por la vida',
        fechaDesde: 1579474800000,
        fechaHasta: 1580425200000,
        diasLaborablesRestantes: 7,
        cuandoEvaluar: { id: 'M', desc: 'Fin de período' },
        distribucionValorRef: { id: 'C' },
        idMedida: 'B',
        objetivoUnidad: 'unidades',
        porcCumplimientoMedidaRealDia: 0,
        objetivoRealDia: 0,
        objetivoDia: 0,
        objetivoAbsTotal: 0,
        objetivoPendiente: 0,
        objetivoPorDiaPendiente: 0,
        incentivoDia: 0,
        incentivoTeorico: 0,
      },
      {
        codObjetivo: 70,
        desc: 'Margen terminal',
        fechaDesde: 1579474800000,
        fechaHasta: 1580425200000,
        diasLaborablesRestantes: 7,
        cuandoEvaluar: { id: 'M', desc: 'Fin de período' },
        distribucionValorRef: { id: 'C' },
        idMedida: 'M',
        objetivoUnidad: 'euros',
        porcCumplimientoMedidaRealDia: 0,
        objetivoRealDia: 0,
        objetivoDia: 0,
        objetivoAbsTotal: 0,
        objetivoPendiente: 0,
        objetivoPorDiaPendiente: 0,
        incentivoDia: 0,
        incentivoTeorico: 0,
      },
    ],
  },
}
