import React from 'react'
import { Spin, Row } from 'antd'
import styled from '@emotion/styled'

const Wrapper = styled.div({
  padding: 30,
})

export const Spinner = style => (
  <Row type="flex" justify="center" align="middle" style={style}>
    <Wrapper>
      <Spin></Spin>
    </Wrapper>
  </Row>
)
