import { colors } from 'Utils'
import styled from '@emotion/styled'

export const OpHeaderCellWrapper = styled.div(({ isDetail, isAbsolut }) => ({
  display: 'inline-block',
  userSelect: 'none',
  padding: isAbsolut ? '10px 5px 10px 10px' : !isDetail ? '10px 20px' : '10px 20px 10px 30px',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.25,
  color: colors.pine,
  width: !isDetail ? 240 : isAbsolut ? 160 : 140,
  boxShadow: isDetail ? 'none' : '0 4px 12px 0 rgba(200, 198, 198, 0.5)',
  height: isAbsolut ? 55 : 36,
  background: isDetail ? 'none' : '#e3f0c8',
  textAlign: isAbsolut && 'left'
}))
