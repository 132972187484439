import gql from 'graphql-tag'
import {
  OBJECTIVE_FRAGMENT,
  OPEN_OBJETIVE_FRAGMENT,
} from 'Queries/ObjectiveFragments'

export const OPEN_OBJECTIVE = gql`
  query objetivo($id: ObjetivoIdInput!) {
    objective: objetivo(id: $id) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const UPDATE_OPEN_OBJECTIVE_NAME = gql`
  mutation updateObjectiveName(
    $id: ObjetivoIdInput!
    $nombre: String!
    $etiqueta: String
  ) {
    objective: actualizarObjetivoNombre(
      id: $id
      nombre: $nombre
      etiqueta: $etiqueta
    ) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const RECALCULATE_OPEN_OBJETIVE = gql`
  mutation recalcularObjetivoAbierto($id: ObjetivoIdInput!) {
    objective: recalcularObjetivoAbierto(id: $id) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const UPDATE_OBJETIVE_OPEN_ADJUSTS = gql`
  mutation actualizarObjetivoAbiertoCelda(
    $id: ObjetivoIdInput!
    $mes: Int!
    $codigoEmpleado: Long!
    $description: String
    $incentivoValor: Int
    $percentage: Float
  ) {
    objective: actualizarObjetivoAbiertoCelda(
      id: $id
      mes: $mes
      codigoEmpleado: $codigoEmpleado
      description: $description
      incentivoValor: $incentivoValor
      percentage: $percentage
    ) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const UPDATE_OBJETIVE_OPEN_COLUMN_ADJUSTS = gql`
  mutation actualizarObjetivoAbiertoAplicarColum(
    $id: ObjetivoIdInput!
    $mes: Int!
    $percentage: Float
  ) {
    objective: actualizarObjetivoAbiertoAplicarColum(
      id: $id
      mes: $mes
      percentage: $percentage
    ) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const UPDATE_OBJETIVE_OPEN_ADJUSTS_ALL = gql`
  mutation actualizarObjetivoAbiertoAplicarTodo(
    $id: ObjetivoIdInput!
    $description: String
    $incentivoValor: Int
  ) {
    objective: actualizarObjetivoAbiertoAplicarTodo(
      id: $id
      description: $description
      incentivoValor: $incentivoValor
    ) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const ADD_SALESMEN_TO_OPEN_OBJECTIVE = gql`
  mutation asignSalemenToObjetive($id: ObjetivoIdInput!, $salesmen: [Long]) {
    objective: actualizarObjetivoComercialesSeleccionados(
      id: $id
      comercialesSeleccionados: $salesmen
    ) {
      ...objectiveFragment
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const UPDATE_OPEN_OBJECTIVE_WHEN = gql`
  mutation updateObjectiveWhen(
    $id: ObjetivoIdInput!
    $cuandoEvaluarId: String
    $fechaInicio: Long!
    $fechaFin: Long!
  ) {
    objective: actualizarObjetivoCuando(
      id: $id
      cuandoEvaluarId: $cuandoEvaluarId
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
    ) {
      ...objectiveFragment
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`