import React, { useState } from 'react'
import { Breadcrumb } from './shared'
import { Select, Modal, Col, Row, Input, Button, Spin, Popover } from 'antd';
import { Icon } from '@ant-design/compatible'
import { Icon as AntIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { colors } from 'Utils'

export const ObjectiveGroupList = ({
  showGroupList,
  setShowGroupList,
  groupList,
  groupName,
  setGroupName,
  groupIndex,
  deleteGroup,
  addGroup,
  changeGroup,
  updateGroup,
  editRow,
  setEditRow,
  showAlert,
  isLoading,
  isDisabled,
}) => {

  const [showModal, setShowModal] = useState(false)

  const position = groupList.findIndex(grp => grp.id === groupIndex)

  const modalTitle = () => {
    return (<>
      Editar grupos
      <Popover
        content={
          (<span>
            Sólo se pueden crear un máximo de 10  grupos por objectivo
          </span>)
        }
      >
        <InfoIcon type="info-circle" />
      </Popover>
    </>)
  }

  return (
    <>
      <Breadcrumb style={{ height: 30 }}>
        <TitleLeft>
          Grupos
          <Select
            disabled={isDisabled}
            style={{ width: 170, marginLeft: 10 }}
            onChange={value => {
              changeGroup(value)
            }}
            value={groupIndex}
          >
            {groupList.map(grp => (
              <Select.Option value={grp.id}>{grp.nombre}</Select.Option>
            ))}
          </Select>
          <EditIcon disabled={isDisabled} onClick={() => !isDisabled && setShowModal(true)} type="form" />
        </TitleLeft>
        {/* <TitleRight onClick={() => setShowGroupList(!showGroupList)}>
          ({groupList[position] !== undefined && groupList[position].articulos.length})
          <AntIcon style={{ fontSize: 'large' }} type={showGroupList ? "caret-up" : "caret-down"} />
        </TitleRight> */}
      </Breadcrumb>
      <hr />
      {/* {showGroupList && <div style={{ height: showAlert ? '73%' : '85%', overflowY: 'auto', paddingTop: 10, width: 335 }}>
        {groupList[position] !== undefined && groupList[position].articulos.map(item => (
          <ArticleLeft><b>{item.codigo}</b> - {item.desc}</ArticleLeft>
        ))}
      </div>} */}

      <Modal
        title={modalTitle()}
        visible={showModal}
        onCancel={() => { setShowModal(false); setEditRow(-1); }}
        footer={[
          <Button
            key="ok"
            disabled={isLoading}
            type="primary"
            onClick={() => { setShowModal(false); setEditRow(-1); }}
          >
            OK
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>

          {editRow === -1 && (<p>
            <Input.Group compact>
              <Input
                value={groupName}
                maxLength={50}
                style={{ width: 250 }}
                onChange={e => setGroupName(e.target.value)}
              />
              <Button type="secundary" disabled={groupName === '' || groupList.length === 10 || isLoading} onClick={() => addGroup()}>Añadir grupo</Button>
            </Input.Group>
          </p>)}
          {groupList.map(grp => (
            <Row style={{ margin: '9px 0px' }}>
              <Col span={20}>
                {editRow === grp.id ?
                  (<Input
                    value={groupName}
                    maxLength={50}
                    style={{ width: 250 }}
                    onChange={e => setGroupName(e.target.value)}
                    onBlur={e => {
                      if (groupName !== '') {
                        updateGroup(e.target.value);
                      }
                      setGroupName('');
                    }}
                  />) :
                  grp.nombre
                }
              </Col>
              <Col span={4}>
                <EditIcon
                  disabled={editRow === grp.id || isLoading}
                  type="edit"
                  onClick={() => { if (editRow !== grp.id && !isLoading) { setEditRow(grp.id); setGroupName(grp.nombre); } }}
                  style={{ marginRight: 15 }}
                />
                <DeleteIcon
                  disabled={editRow === grp.id || isLoading}
                  type="close"
                  onClick={() => { if (editRow !== grp.id && !isLoading) { deleteGroup(grp.id) } }}
                />
              </Col>
            </Row>
          ))}
        </Spin>
      </Modal>
    </>
  )
}

const TitleLeft = styled.p`
  float: left;
  text-align: left;
  width: calc(100vh - 250px);
`

const EditIcon = styled(AntIcon)`
  color: ${props => (props.disabled ? colors.warmGrey : colors.trueGreen)};
  font-size: large;
  text-align: right;
  margin-left: 5px;
`

const DeleteIcon = styled(AntIcon)`
  color: ${props => (props.disabled ? colors.warmGrey : colors.redCruz)};
  font-size: large;
  text-align: right;
`

const InfoIcon = styled(Icon)`
  vertical-align: middle;
  font-size: 12px;
  margin-left: 3px;
`