import React from 'react'
import { formatNumberWithThousands } from 'Utils'
import styled from '@emotion/styled'

export const CellIncDescription = ({ dataSource }) => {

  function roundPercent() {
    if (Math.floor((dataSource % 1) * 100) !== 0) {
      const trfms = dataSource.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return formatNumberWithThousands(trfms, parseFloat(trfms).toFixed(1) % 1 !== 0, 1)
    } else {
      return formatNumberWithThousands(Math.floor(dataSource))
    }
  }

  return (
    <CellInfo>
      {`Inc. ${dataSource === 0 ? '0' : roundPercent()} %`}
    </CellInfo>
  )
}

const CellInfo = styled.p({
  fontSize: 12,
  lineHeight: 1.25,
  margin: 0,
})
