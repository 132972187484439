import React from 'react'
import { Table, pdvColumn } from 'Components'
import { TableQuery } from 'Fragments'
import { ACTION_PLAN_PDV, ACTION_PLAN_PDV_OPORTUNIDAD } from 'Queries'

const measureMap = {
  V: {
    act: 'Vol Act',
    aa: 'Vol AA',
  },
  F: {
    act: 'Fct Act',
    aa: 'Fct AA',
  },
  U: {
    act: 'Uds Act',
    aa: 'Uds AA',
  },
  N: {
    act: 'Uds Act',
    aa: 'Uds AA',
  },
  M: {
    act: 'Mg Act',
    aa: 'Mg AA',
  },
  B: {
    act: 'BE Act',
    aa: 'BE AA',
  },
}

const measureOportunidades = {
  V: 'Volumen',
  F: 'Facturación',
  U: 'Unidades',
  N: 'Unidades',
  M: 'Margen',
  B: 'Bulto E.',
  A: 'Obj. Abierto',
}

export const PdvTable = ({
  expandable = false,
  showSearch = true,
  onSelectionChange = null,
  selectionIds = [],
  objectiveId = null,
  selectedCategorieHash = '',
  searchPlaceholder = 'Buscar puntos de venta',
  queryExtraParams = null,
  onRow,
  sortBy,
  sortDir,
  querySeachParams,
  idMedida,
}) => {
  const sortDirMap = {
    asc: 'ascend',
    desc: 'descend',
  }
  const isPdvOp = !queryExtraParams.pdvSituacionId
  const columns = pdvColumn(
    !isPdvOp ? measureMap[idMedida] : null,
    measureOportunidades[idMedida],
  )
  const columnsWithSort = columns.map(column => {
    return column.dataIndex !== sortBy
      ? column
      : {
        ...column,
        defaultSortOrder: sortDirMap[sortDir],
      }
  })

  return (
    <TableQuery
      showSearch={showSearch}
      searchPlaceholder={searchPlaceholder}
      querySeachParams={querySeachParams}
      onSelectionChange={onSelectionChange}
      selectionIds={selectionIds}
      expandable={expandable}
      query={isPdvOp ? ACTION_PLAN_PDV_OPORTUNIDAD : ACTION_PLAN_PDV}
      objectiveId={objectiveId}
      selectedCategorieHash={selectedCategorieHash}
      queryVariables={{
        sortBy,
        sortDir,
      }}
      queryPath={
        isPdvOp ? 'planAccionPdvOportunidadPage' : 'planAccionPdvDetallePage'
      }
      idKey={sortBy}
      queryExtraParams={queryExtraParams}
    >
      <Table
        columns={columnsWithSort}
        locale={{ emptyText: 'No hay pdv para la búsqueda' }}
        onRow={onRow}
        cursor={false}
      />
    </TableQuery>
  )
}
