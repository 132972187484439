import React from 'react'
import { Icon as AntIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { showCantDeleteConfirm, showDeleteConfirm } from 'Components'
import { ReactComponent as RecicleBinIcon } from 'Assets/icons/recycle-bin-icon.svg'
import { ReactComponent as EditIcon } from 'Assets/icons/edit-icon.svg'
import { ReactComponent as AddWhiteIcon } from 'Assets/icons/add-white-icon.svg'
import { ReactComponent as AddGreenIcon } from 'Assets/icons/add-green-icon.svg'
import { ReactComponent as DropdownTwoArrows } from 'Assets/icons/dropdown-two-arrows.svg'

export const EmployeeActions = ({ dataSource, actions }) => {
  const isGerente = dataSource.nivel === 'gerente'
  const isComercial = dataSource.nivel === 'comercial'
  const isSignedUp = dataSource.activo !== null

  const onDeleteOk = () => {
    return actions.delete({
      variables: {
        codEmpleado: dataSource.codMiembro,
        codPadre: dataSource.codPadre,
        level: dataSource.nivel,
      },
    })
  }

  const onDelete = event => {
    event.stopPropagation()

    if (dataSource.deletable) {
      showDeleteConfirm(dataSource.nombre, onDeleteOk)
    } else {
      showCantDeleteConfirm()
    }
  }

  const onMove = event => {
    event.stopPropagation()
    actions.showMoveModal({
      subtitle: dataSource.nombre,
      codEmpleado: dataSource.codMiembro,
      codPadre: dataSource.codPadre,
      availableEmployees: dataSource.miembrosDisponibles,
    })
  }

  const onEdit = event => {
    event.stopPropagation()

    let popupProps = {
      subtitle: 'Comercial',
      profileType: dataSource.nivel,
      copyable: false,
      name: dataSource.nombre,
      email: dataSource.email,
      copiaGerente: dataSource.copiaGerente,
      phone: dataSource.telefono,
      availableEmployees: dataSource.miembrosDisponibles,
      showProfileTypeSelection: false,
      readonlyName: false,
      showAddComercial: false,
      codPadre: null,
      codEmpleado: null,
      edit: true,
      activo: dataSource.activo,
    }
    if (dataSource.nivel === 'gerente')
      popupProps = { ...popupProps, subtitle: 'Gerente' }
    if (dataSource.nivel === 'jefe_ventas')
      popupProps = { ...popupProps, subtitle: 'Jefe de ventas' }
    if (dataSource.nivel === 'jefe_equipo')
      popupProps = { ...popupProps, subtitle: 'Jefe de equipo' }
    if (dataSource.nivel === 'especialista')
      popupProps = {
        ...popupProps,
        subtitle: 'Especialista',
        profileType: dataSource.nivel,
        categorias: dataSource.categorias,
      }
    if (dataSource.nivel === 'comercial')
      popupProps = { ...popupProps, readonlyName: true }

    actions.showEditPopup({
      ...popupProps,
      codEmpleado: dataSource.codMiembro,
    })
  }
  const onAdd = event => {
    event.stopPropagation()
    let popupProps = {
      subtitle: 'Comercial',
      copyable: false,
      profileType: 'comercial',
      name: '',
      email: '',
      phone: '',
      codEmpleado: null,
      nameCopy: dataSource.nombre,
      emailCopy: dataSource.email,
      phoneCopy: dataSource.telefono,
      codEmpleadoCopy: dataSource.codMiembro,
      availableEmployees: dataSource.miembrosDisponibles,
      showProfileTypeSelection: false,
      readonlyName: false,
      showAddComercial: false,
      codPadre: null,
    }
    if (dataSource.nivel === 'gerente')
      popupProps = {
        ...popupProps,
        subtitle: 'Jefe de ventas',
        profileType: 'jefe_ventas',
        copyable: true,
      }
    if (dataSource.nivel === 'jefe_ventas') {
      popupProps = {
        ...popupProps,
        subtitle: 'Jefe de equipo, especialista',
        profileType: null,
        copyable: true,
        showProfileTypeSelection: true,
      }
      if (dataSource.codMiembro !== dataSource.codPadre) {
        popupProps.copyable = false
      }
    }
    if (dataSource.nivel === 'jefe_equipo') {
      popupProps = {
        ...popupProps,
        parentName: dataSource.nombre,
        parentLevel: 'Jefe de equipo',
        showAddComercial: true,
      }
    }
    if (dataSource.nivel === 'especialista') {
      popupProps = {
        ...popupProps,
        parentName: dataSource.nombre,
        parentLevel: 'Especialista',
        showAddComercial: true,
      }
    }

    actions.showAddPopup({
      ...popupProps,
      parentCode: dataSource.codMiembro,
    })
  }

  return (
    <Inner>
      {isSignedUp && !(isGerente || dataSource.copiaGerente) && (
        <AntIcon
          type={dataSource.activo ? 'unlock' : 'lock'}
          title={
            dataSource.activo ? 'pulse para desactivar' : 'pulse para activar'
          }
          theme="twoTone"
          twoToneColor={dataSource.activo ? colors.fernGreen : colors.scarlet}
          onClick={e => {
            e.stopPropagation()
            actions.lockUnlock(dataSource)
          }}
        />
      )}
      <AntIcon component={RecicleBinIcon} style={{ color: dataSource.codPadre ? colors.fernGreen : colors.white }} onClick={onDelete} />
      <AntIcon component={EditIcon} style={{ color: dataSource.codPadre ? colors.fernGreen : colors.white }} onClick={onEdit} />
      {isComercial && (
        <AntIcon component={DropdownTwoArrows} onClick={onMove}></AntIcon>
      )}
      {!isComercial && (
        <AntIcon
          component={dataSource.codPadre ? AddGreenIcon : AddWhiteIcon}
          onClick={onAdd}
        />
      )}
    </Inner>
  )
}

const Inner = styled.div`
  width: 193px;
  text-align: right;
  .anticon {
    color: ${colors.fernGreen};
    padding: 0 16px;
    font-size: 16px;
  }
`
