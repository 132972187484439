import styled from '@emotion/styled'

export const ObjectiveRightCol = styled.div`
  display: inline-block;
  padding: 10px 15px 32px 30px;
  margin-top: 20px;
  min-width: 500px;
  height: calc(100vh - 225px);
  width: calc(100% - 400px);
  vertical-align: middle;
  overflow: auto;
`
