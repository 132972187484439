import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'

export const showAdjustmentsRecalcConf = callback => {
  Modal.confirm({
    title: <ConfirmTitle>Modificación de ajuste. ¡Cuidado!</ConfirmTitle>,
    content: `Tras el cambio a realizar, los valores de referencia serán reiniciados`,
    icon: null,
    centered: true,
    okText: 'CONTINUAR SIN REINICIAR',
    okButtonProps: {
      type: 'danger',
    },
    cancelText: 'CONTINUAR',
    cancelButtonProps: {
      type: 'link',
    },
    onOk: () => callback(true),
    onCancel: () => callback(false),
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
