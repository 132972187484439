import React from 'react'

import { Icon } from 'Components'
import { rounded, plurals, colors, unitTypeMap, iconTypeMap } from 'Utils'

import { Icon as AntIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'

export const ObjectiveBoxEuroType = ({ kpi }) => {
  const {
    objetivoAbsTotal,
    objetivoAbsMes,
    objetivoRealDia,
    objetivoPendiente,
    idMedida,
    cuandoEvaluar,
    objetivoUnidad,
    inctvMinCaja,
    withException
  } = kpi

  const unit = unitTypeMap[idMedida]
  const icon = iconTypeMap[idMedida]

  const superado = objetivoPendiente <= 0

  const objAbs = cuandoEvaluar.id === 'M' ? objetivoAbsMes : objetivoAbsTotal;

  return (
    <>
      <LFooter>
        <FooterInfo>Incentivo </FooterInfo>
        {inctvMinCaja} €/{plurals(objetivoUnidad)}
        {(objAbs !== null && objAbs > 0) && (<>
          <br />
          <FooterInfo>Objetivo </FooterInfo>
          {rounded(objAbs)} {objetivoUnidad}
        </>)}
      </LFooter>
      <br />
      <Inner>
        <Footer>
          <div>
            <KpiInfo>
              Conseguido
            </KpiInfo>
            <Kpi success={superado}>
              {rounded(objetivoRealDia, true)} {unit}
            </Kpi>
          </div>
          <div style={{ marginTop: '10px', position: 'relative' }}>
            <Icon type={icon} size={60} />
            <EuroIcon>
              €
            </EuroIcon>
            {withException && (
              <AntIcon
                type="stop"
                style={{ position: "absolute", top: 35, right: 35, fontSize: 22 }}
                theme="twoTone"
                twoToneColor={colors.scarlet}
              />
            )}
          </div>
        </Footer>
      </Inner>
      <br />
    </>
  )
}

const Inner = styled.div`
  margin-top: 28px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: right;
  align-self: flex-end;
`

const Kpi = styled.div`
  font-size: 35px;
  font-weight: 600;
  line-height: 0.7;
  letter-spacing: 0.76px;
  text-align: right;
  color: ${props => (props.success ? `${colors.trueGreen}` : `${colors.redCruz}`)};
`
const KpiInfo = styled.div`
  font-size: 24px;
  font-weight: normal;
  line-height: 1.7;
  padding: 0px 0px 5px 10px;
  color: var(--black);
`

const LFooter = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: var(--black);
  float: left;
  max-height: 45px;
  overflow: hidden;
`

const FooterInfo = styled.span`
  font-size: 15px;
  line-height: 0.94;
  letter-spacing: 0.2px;
  font-weight: normal;
  color: var(--warm-grey);
`

const EuroIcon = styled.span`
  position: absolute;
  top: -5px;
  right: 0px;
  font-size: 23px;
  color: goldenrod;
  font-weight: bold;
  text-shadow: 2px 0 0 #f9faf9, -2px 0 0 #f9faf9, 0 2px 0 #f9faf9, 0 -2px 0 #f9faf9, 1px 1px #f9faf9, -1px -1px 0 #f9faf9, 1px -1px 0 #f9faf9, -1px 1px 0 #f9faf9; 
`
