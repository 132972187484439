import React, { useState } from 'react'
import { colors } from 'Utils'
import styled from '@emotion/styled'
import { Icon } from '@ant-design/compatible'

export const TagContainer = ({ children, title, deleteAll, onDeleteAll }) => {
  const [expanded, setExpanded] = useState(true)

  return (

    <>
      <TitleBar>
        <Title onClick={() => setExpanded(!expanded)} >
          {title} {' '}
          <Icon
            style={{ fontSize: 12 }}
            type={expanded ? 'up' : 'down'}
          />
        </Title>
        <TitleButton style={{ marginLeft: 100 }} isdisabled={!deleteAll} onClick={() => { if (deleteAll) { onDeleteAll() } }}>Borrar todo</TitleButton>
      </TitleBar>
      <TagContainerInner>{expanded && children}</TagContainerInner>
    </>
  )
}

const TitleBar = styled.div`
  margin: 16px 0;
`

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.24px;
  display: inline-block;
  min-width: 250px;
  color: ${colors.black};
`
const TitleButton = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.24px;
  color: ${props => props.isdisabled === true ? colors.warmGrey : colors.trueGreen};
  cursor: ${props => props.isdisabled === true ? 'default' : 'pointer'};
`

const TagContainerInner = styled.div`
  margin-left: -10px;
  margin-right: -10px;
`
