import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'

export const confirmHesaSubmit = (onSubmit) => {
    Modal.confirm({
        title: <ConfirmTitle>Correo en uso</ConfirmTitle>,
        // icon: <ExclamationCircleOutlined />,
        content: 'Estas actualizando un usuario que ya existe en el sistema, ¿Deseas guardar los cambios?',
        onOk() { onSubmit() },
        onCancel() { },
    });
}

export const confirmHesaSignUp = (activo, altaBaja, onSignUp, num) => {
    Modal.confirm({
        title: <ConfirmTitle>Dar de {altaBaja} usuario</ConfirmTitle>,
        // icon: <ExclamationCircleOutlined />,
        content: (activo && num > 1) ? `Este usuario existe para ${num} proveedores, ¿desea darle de baja?` :
            `¿Desea dar de ${altaBaja} a este usuario?`,
        onOk() { onSignUp() },
        onCancel() { },
    });
}

export const hesaWarning = (codDistribuidor) => {
    Modal.warning({
        title: <ConfirmTitle>Usuario en uso</ConfirmTitle>,
        // icon: <ExclamationCircleOutlined />,
        content: `Este Email no puede ser utilizado para un comercial HESA, ya que se usa en la jerarquía comercial del distribuidor ${codDistribuidor}.`,
    });
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
