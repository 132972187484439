import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Button, Col, Row } from 'antd'
import { ClockCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { GET_LAST_ACTIVITY } from 'Queries'
import { useQuery } from '@apollo/client'
import { get, capitalize } from 'lodash'
import { Link } from 'Components'
import { phaseErrorToTextMap } from 'Pages/Activities'
import moment from 'moment'
import { GlobalContext, checkRolePermit, colors } from 'Utils'

export const LastUpdate = ({ collapsed }) => {
  const context = useContext(GlobalContext)
  const roles = get(context, 'currentUser.roles')
  const codDistribuidor = get(context, 'currentUser.codDistribuidor')

  const { loading, error, data } = useQuery(GET_LAST_ACTIVITY, {
    variables: {
      codDistribuidor,
    },
  })

  if (loading || error) return null

  const lastUpdateData = get(data, 'ultimaActividad')

  let calendarFormattedDate = null
  let hasError = null

  if (lastUpdateData) {
    var date = new Date();
    var offsetInHours = -(date.getTimezoneOffset() / 60);
    calendarFormattedDate = capitalize(
      moment(lastUpdateData.fecha).utcOffset(offsetInHours).calendar(null, {
        sameDay: '[Hoy, ]h:mm a',
        lastDay: '[Ayer, ]h:mm a',
        lastWeek: 'D MMM h:mm a',
        sameElse: 'D MMM h:mm a',
      }),
    )
  }

  if (lastUpdateData && lastUpdateData.resultado) {
    hasError = lastUpdateData.resultado !== 'ok'
  }

  return (
    <Wrapper collapsed={collapsed}>
      <Title>ÚLTIMA ACTUALIZACIÓN:</Title>
      <Row>
        <Col span={18}>
          <Time>{calendarFormattedDate}</Time>
        </Col>
        <Col>
          {hasError && (
            <ErrorIcon />
          )}
        </Col>
      </Row>

      {hasError && (
        <Error>
          {phaseErrorToTextMap[lastUpdateData.fase] ||
            phaseErrorToTextMap.default}
        </Error>
      )}
      {checkRolePermit(roles, 'RegistroActividad') && (
        <Link to="/dashboard/activities">
          <Button type="primary">
            <ClockCircleOutlined />
            Ver actividad
          </Button>
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div(({ collapsed }) => ({
  border: '1px dotted green',
  background: 'white',
  borderRadius: 4,
  padding: 20,
  margin: 20,
  opacity: collapsed ? 0 : 1,
  transition: collapsed ? 'opacity .05s' : 'opacity .05s .1s',
}))

const Title = styled.h3({
  fontFamily: 'MyriadPro-Cond',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#205527',
  margin: 0,
})

const Time = styled.label({
  fontSize: 12,
  color: '#205527',
  margin: '10px 0',
  display: 'block',
})

const Error = styled.label({
  fontSize: 11,
  color: colors.redCruz,
  display: 'block',
  marginBottom: 10,
})

const ErrorIcon = styled(WarningOutlined)`
  color: ${colors.redCruz};
  font-size: 14px;
  margin: 12px 0;
`
