import gql from 'graphql-tag'
import {
  OBJECTIVE_FRAGMENT,
  OBJECTIVE_ADJUSTS_FRAGMENT,
} from 'Queries/ObjectiveFragments'

export const GET_FORM_OPTIONS = gql`
  query getFormOptions {
    objetivoQueMedidas {
      id
      desc
      unidad
    }
    objetivoQueTipoEntrega {
      id
      desc
    }
    objetivoComoTipoValorRefs {
      id
      desc
    }
    objetivoComoDistribucionValorRefs {
      id
      desc
      nota
    }
    objetivoCuandoEvaluar {
      id
      desc
    }
    objetivoCuandoEvaluarFinPeriodo {
      id
      desc
    }
    objetivoCuandoOpcionesPeriodosVigencia {
      desc
      fechaFin
      fechaInicio
    }
  }
`

export const UPDATE_OBJECTIVE_NAME = gql`
  mutation updateObjectiveName(
    $id: ObjetivoIdInput!
    $nombre: String!
    $etiqueta: String
  ) {
    objective: actualizarObjetivoNombre(
      id: $id
      nombre: $nombre
      etiqueta: $etiqueta
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_WHAT = gql`
  mutation updateObjectiveWhat(
    $id: ObjetivoIdInput!
    $medida: String
    $tipoEntrega: [Int]
    $paraEspecialistas: Boolean
    $ocultarNiveles: Boolean
    $paraProveedor: String
    $conExcepciones: Boolean
    $satEspecialista: Long
    $canales: [String]
  ) {
    objective: actualizarObjetivoQue(
      id: $id
      medida: $medida
      tipoEntrega: $tipoEntrega
      paraEspecialistas: $paraEspecialistas
      ocultarNiveles: $ocultarNiveles
      paraProveedor: $paraProveedor
      conExcepciones: $conExcepciones
      satEspecialista: $satEspecialista
      canales: $canales
    ) {
      ...objectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`

export const UPDATE_OBJECTIVE_HOW = gql`
  mutation updateObjectiveHow(
    $id: ObjetivoIdInput!
    $tipoValorRefId: String!
    $valorRef: ObjetivoComoValorRefInput
    $distribucionValorRefId: String!
    $recalcularAjustes: Boolean!
    $refAno: Int
    $inctvMinCaja: Float
    $pagoIncentivo: Boolean
    $nArtGroup: Int
  ) {
    objective: actualizarObjetivoComo(
      id: $id
      tipoValorRefId: $tipoValorRefId
      valorRef: $valorRef
      distribucionValorRefId: $distribucionValorRefId
      recalcularAjustes: $recalcularAjustes
      refAno: $refAno
      inctvMinCaja: $inctvMinCaja
      pagoIncentivo: $pagoIncentivo
      nArtGroup: $nArtGroup
    ) {
      ...objectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`

export const UPDATE_OBJECTIVE_HOW_WITH_ADJUSTS = gql`
  mutation updateObjectiveHowWithAdjusts(
    $id: ObjetivoIdInput!
    $tipoValorRefId: String!
    $valorRef: ObjetivoComoValorRefInput
    $distribucionValorRefId: String!
    $recalcularAjustes: Boolean!
    $refAno: Int
    $inctvMinCaja: Float
    $pagoIncentivo: Boolean
    $nArtGroup: Int
  ) {
    objective: actualizarObjetivoComo(
      id: $id
      tipoValorRefId: $tipoValorRefId
      valorRef: $valorRef
      distribucionValorRefId: $distribucionValorRefId
      recalcularAjustes: $recalcularAjustes
      refAno: $refAno
      inctvMinCaja: $inctvMinCaja
      pagoIncentivo: $pagoIncentivo
      nArtGroup: $nArtGroup
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_HOW_WITH_ADJUSTS_V2 = gql`
  mutation updateObjectiveHowWithAdjusts(
    $id: ObjetivoIdInput!
    $tipoValorRefId: String!
    $valorRef: ObjetivoComoValorRefInput
    $distribucionValorRefId: String!
    $recalcularAjustes: Boolean!
    $refAno: Int
    $inctvMinCaja: Float
    $pagoIncentivo: Boolean
    $nArtGroup: Int
  ) {
    objective: actualizarObjetivoComo(
      id: $id
      tipoValorRefId: $tipoValorRefId
      valorRef: $valorRef
      distribucionValorRefId: $distribucionValorRefId
      recalcularAjustes: $recalcularAjustes
      refAno: $refAno
      inctvMinCaja: $inctvMinCaja
      pagoIncentivo: $pagoIncentivo
      nArtGroup: $nArtGroup
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_WHEN = gql`
  mutation updateObjectiveWhen(
    $id: ObjetivoIdInput!
    $cuandoEvaluarId: String
    $fechaInicio: Long!
    $fechaFin: Long!
  ) {
    objective: actualizarObjetivoCuando(
      id: $id
      cuandoEvaluarId: $cuandoEvaluarId
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const GET_LABELS = gql`
  query getLabels($codDistribuidor: Long!) {
    etiquetasDisponibles(codDistribuidor: $codDistribuidor)
  }
`

export const GET_AVAILABLE_SALESMEN = gql`
  query getAvailableSalesmen($id: ObjetivoIdInput!) {
    empleados: getComercialesDisponibles(id: $id) {
      codEmpleado
      nombreEmpleado
    }
  }
`

export const GET_SPECIALISTS = gql`
  query getEspecialistasDisponibles($codDistribuidor: Long) {
    obtenerEspecialistaInformacion(codDistribuidor: $codDistribuidor) {
      codDistribuidor
      codMiembro
      nombre
      categorias
    }
  }
`
