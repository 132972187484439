import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal as AntModal, Button as AntButton } from 'antd'

export const Modal = styled(AntModal)`
  width: 700px;
  .ant-modal-close-x {
    display: none;
  }
  .ant-btn.ant-btn-primary {
    font-size: 12px;
    line-height: 1.83;
    color: ${colors.white};
    text-transform: uppercase;
  }
`

export const ModalTitle = styled.div`
  font-size: 18px;
  color: var(--black);
  margin-bottom: 4px;
`
export const ModalSubtitle = styled.div`
  font-size: 14px;
  line-height: 1.07;
  letter-spacing: 0.25px;
  color: var(--warm-grey);
`
export const TableHeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: var(--black);
  margin-bottom: 10px;
  > * {
    display: inline-block;
    width: 300px;
  }
`
export const TableHeaderSubtitle = styled.div`
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.29px;
  color: #555555;
  margin-bottom: 10px;
  > * {
    display: inline-block;
    width: 150px;
  }
`

export const TableRowInner = styled.div`
  min-height: 54px;
  line-height: 54px;
  border-top: solid 1px #dfdfdf;
  color: var(--warm-grey);
  font-size: 16px;
  line-height: 0.94;
  letter-spacing: 0.29px;
`
export const InputWrapper = styled.div`
  display: inline-block;
  margin-top: 11px;
  margin-bottom: 10px;
  line-height: 33px;
  font-weight: ${props => (props.bold ? 600 : 'normal')};
  min-width: ${props => (props.bold ? 'inicial' : '150px')};
  vertical-align: top;
  > * {
    width: 100px;
  }
  > div {
    margin-bottom: 0;
  }
`

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: red;
  font-weight: normal;
  line-height: 12px;
  margin-top: 5px;
`

export const Inner = styled.div`
  display: block;
  float: right;
  line-height: 54px;
  .anticon {
    color: ${colors.fernGreen};
    padding: 0 11px;
    font-size: 16px;
  }
`

export const Button = styled(AntButton)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--true-green);
  text-transform: uppercase;
  text-align: center;
  line-height: 54px;
`
