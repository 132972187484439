import React from 'react'

import { Content } from 'Components'
import { Header } from 'Fragments'

import { Layout } from 'antd'

export const Cookies = () => (
  <Layout>
    <Header onlyLogo />
    <Content nosider={1} fullwidth={'A'}>
      <h1>Política de Cookies</h1>
      <p>&nbsp;</p>
      <p>
        <strong>Heineken España, S.A.</strong> (“<strong>Heineken</strong>”),
        con C.I.F. A-28006013 y situada en Avenida de Andalucía 1, 41007,
        Sevilla (España), utiliza cookies para ofrecerle una mejor experiencia
        online. Para poder hacer un uso completo de la página web y/o aplicación
        en la que está navegando (en adelante, de manera conjunta, el “
        <strong>Sitio Web</strong>”), su ordenador, tablet o smartphone deberá
        aceptar cookies. Creemos que es importante que sepa qué cookies utiliza
        nuestro Sitio Web y con qué fin ya que ello le ayudará a proteger su
        privacidad, al tiempo que le permitirá disfrutar de la mejor experiencia
        online.
      </p>
      <p>
        Heineken pretende que su experiencia e interacción online sean lo más
        informativa y provechosa posible. Para ello utilizamos cookies o
        técnicas similares. Si no desea aceptar cookies al usar este Sitio Web,
        no debe aceptar cookies a través del banner emergente o, posteriormente,
        puede optar por desactivar sus cookies (vea el apartado 3 más abajo para
        obtener información sobre cómo hacerlo), pero ello puede afectar la
        funcionalidad del Sitio Web.
      </p>
      <p>
        Puede cambiar sus preferencias en cualquier momento. En Configuración de
        Cookies encontrará una lista de todas las cookies que hay dentro de cada
        categoría (estrictamente necesarias, analíticas, redes sociales,
        publicidad dirigida). Tenga en cuenta que el uso de la Configuración de
        Cookies en nuestro Sitio Web no hará que se eliminen las cookies que ya
        se hayan establecido. Puede hacerlo a través de la configuración de su
        navegador web tal y como se describe a continuación, después de haber
        cambiado su Configuración de Cookies en nuestro Sitio Web.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong>¿Qué es una cookie?</strong>
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>Después de enviar el formulario sobre
        verificación de edad&nbsp; y entrar en el Sitio Web, ésta usará las
        llamadas «cookies».&nbsp;Las cookies son pequeños archivos de texto que
        almacenan pequeñas cantidades de información, que se descargan y se
        pueden almacenar en su dispositivo de usuario, por ejemplo, su ordenador
        (u otros dispositivos habilitados para Internet, como un smartphone o
        una tablet). En Heineken podemos utilizar técnicas similares, como
        píxeles, web beacons y huellas dactilares para dispositivos. Por razones
        de coherencia, todas estas técnicas combinadas se denominarán en lo
        sucesivo «cookies».
      </p>
      <p>
        Esta Política de Cookies le proporciona información sobre las cookies
        que utilizamos y por qué. En nuestra
        <a href="#/privacidad" target="_blank">
          <strong>
            <u> Política de Privacidad </u>
          </strong>
        </a>
        se incluyen todos los detalles de otra información que podemos recopilar
        y cómo podemos utilizar su información personal.
      </p>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          <strong>Las cookies usadas en EL SITIO WEB</strong>
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>Heineken utiliza tipos diferentes de cookies.
        Algunas de estas son cookies de sesión, que son temporales y nos
        permiten vincular su actividad durante una sesión del navegador. Las
        cookies de sesión se borran al cerrar el navegador. Otro tipo de cookies
        son las cookies persistentes, que permanecen en su dispositivo durante
        el período de tiempo especificado en la cookie. El Sitio Web utiliza
        cookies con los siguientes fines:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Cookies estrictamente necesarias</strong>
        </li>
      </ul>
      <p>
        Las cookies estrictamente necesarias son indispensables y le ayudan a
        navegar por el Sitio Web. Mejoran la facilidad de uso del Sitio Web a
        los visitantes. Estas cookies también contribuyen a reforzar la
        seguridad y funcionalidad básica del Sitio Web.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ul>
        <li>
          <strong>Cookies de rendimiento</strong>
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>Las cookies de rendimiento nos ayudan a entender
        el comportamiento&nbsp; de nuestros visitantes y el que hacen del Sitio
        Web de una forma global. Esto significa que podemos averiguar lo que
        funciona y lo que no, lo cual nos ayuda a mejorar continuamente el Sitio
        Web y a medir el grado de eficacia de nuestra publicidad y comunicación.
      </p>
      <p>
        Usamos cookies de Google Analytics para lograr una visión general de los
        hábitos y del volumen de visitantes, así como para mejorar la
        experiencia general al usar nuestro Sitio Web. Puede rechazar el uso de
        cookies seleccionando la configuración adecuada de su navegador, tal y
        como se describe posteriormente en el apartado 3 de la presente Política
        de Cookies. Además, puede impedir que Google recopile y utilice datos
        (cookies y dirección IP) descargando e instalando el complemento del
        navegador disponible en
        https://tools.google.com/dlpage/gaoptout?hl=en-GB#.
      </p>
      <p>
        Puede inhabilitar el uso de Google Analytics mediante el enlace de
        inhabilitar Google Analytics. Este enlace crea una cookie de exclusión
        voluntaria que impide el procesamiento posterior de sus datos. Para
        obtener más información sobre las cookies de Google Analytics, consulte
        las páginas de ayuda y la Política de Privacidad de Google.
      </p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          <strong>Controle la configuración de sus cookies </strong>
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>Una vez que usted nos haya dado su consentimiento
        para el uso de cookies, almacenaremos una cookie en su ordenador o
        dispositivo para recordarlo la próxima vez. Si en cualquier momento
        desea cancelar su consentimiento para el uso de cookies, tendrá que
        eliminar nuestras cookies utilizando la configuración de su navegador de
        Internet. Debe hacerlo a través de la configuración del navegador para
        cada uno de los navegadores que utilice. Tenga en cuenta que algunos de
        nuestros servicios no funcionarán si su navegador no acepta cookies. Sin
        embargo, puede aceptar cookies de sitios web específicos convirtiéndolos
        en «sitios web de confianza» en su navegador de Internet.
      </p>
      <p>
        Los siguientes enlaces pueden ayudarle a gestionar la configuración de
        sus cookies, o puede utilizar la opción «Ayuda» de su navegador de
        Internet para obtener más detalles.
      </p>
      <ul>
        <li>
          Internet Explorer:
          <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
            https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
          </a>
        </li>
        <li>
          Mozilla Firefox:
          <a href="http://support.mozilla.com/en-US/kb/Cookies">
            http://support.mozilla.com/en-US/kb/Cookies
          </a>
        </li>
        <li>
          Google Chrome:
          <a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&amp;answer=95647">
            http://www.google.com/support/chrome/bin/answer.py?hl=en&amp;answer=95647
          </a>
        </li>
        <li>
          Safari:
          <a href="http://support.apple.com/kb/PH5042">
            http://support.apple.com/kb/PH5042
          </a>
        </li>
        <li>
          Opera:
          <a href="http://www.opera.com/help/tutorials/security/privacy/">
            http://www.opera.com/help/tutorials/security/privacy/
          </a>
        </li>
        <li>
          Adobe (flash cookies):
          <a href="https://www.adobe.com/support/flash/downloads.html">
            https://www.adobe.com/support/flash/downloads.html
          </a>
        </li>
      </ul>
      <p>
        Para bloquear específicamente las cookies de Google Analytics, puede
        instalar el «Complemento de inhabilitación para navegadores de Google
        Analytics» facilitado por Google:
      </p>
      <p>
        <ul>
          <li>
            <a href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=es">
              https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=es
            </a>
          </li>
        </ul>
      </p>
      <p>&nbsp;</p>
      <ol start="4">
        <li>
          <strong>Resumen de cookies</strong>
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>A continuación aparece una lista completa de las
        cookies usadas en el Sitio Web:
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Cookies necesarias</strong>
      </p>
      <table>
        <tbody>
          <tr>
            <td width="20%">
              <strong>NOMBRE</strong>
            </td>
            <td width="20%">
              <strong>DURACIÓN PREDETERMINADA</strong>
            </td>
            <td width="60%">
              <strong>DESCRIPCIÓN</strong>
            </td>
          </tr>
          <tr>
            <td>Codigo Distribuidor</td>
            <td>Sesión</td>
            <td>
              Se envía junto al usuario información sobre el distribuidor al que
              pertenece. Y para esto se utiliza el código de
              distribuidor/concesionario. Este dato es equivalente al código que
              identifica a cada distribuidor en las transmisiones LIQUEN.
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Nivel Usuario</td>
            <td>Sesión</td>
            <td>
              Se envía juanto al usuario información sobre el nivel del usuario
              que accede a la aplicación. Si es gerente, jefe de ventas, jefe de
              equipo y/o comercial.
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <strong>Cookies de Rendimiento </strong>(ver apartado 3c de esta Póliza)
      </p>
      <table>
        <tbody>
          <tr>
            <td width="20%">
              <strong>NOMBRE</strong>
            </td>
            <td width="20%">
              <strong>DURACIÓN PREDETERMINADA</strong>
            </td>
            <td width="60%">
              <strong>DESCRIPCIÓN</strong>
            </td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>2 años</td>
            <td>Se usa para distinguir a los usuarios</td>
            <td></td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>24 horas</td>
            <td>Se usa para distinguir a los usuarios</td>
            <td></td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>1 minuto</td>
            <td>Se usa para limitar el porcentaje de solicitudes.</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          <strong>Observaciones finales</strong>
        </li>
      </ol>
      <p>
        <strong>&nbsp;</strong>Revisaremos esta Política de Cookies y la
        actualizaremos de vez en cuando. Cualquier cambio de esta Política de
        Cookies se publicará en nuestro Sitio Web y, en la medida en que sea
        razonablemente posible, se le comunicará a usted. Puede consultar este
        Sitio Web para obtener la última versión.
      </p>
      <p>
        Si tiene más preguntas y/o comentarios que hacer, por favor póngase en
        contacto en la dirección{' '}
        <a href="mailto:protecciondatos@heineken.es">
          protecciondatos@heineken.es
        </a>
        .
      </p>
    </Content>
  </Layout>
)
