import React from 'react'
import { Collapse as AntCollapse } from 'antd'
import styled from '@emotion/styled'

export const ColapsableText = ({ title, subtitle }) => (
  <Collapse expandIconPosition="end" bordered={false}>
    <AntCollapse.Panel header={title} key="1">
      <p>{subtitle}</p>
    </AntCollapse.Panel>
  </Collapse>
)

const Collapse = styled(AntCollapse)`
  background-color: initial;
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 12px;
    color: #008200;
    padding: 0 0 5px 0;
  }

  &.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: 175px;
    margin: -3px 0 0 0;
    font-size: 10px;
    right: initial;
  }

  &.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0;
    font-size: 12px;
    line-height: 1.42;
    color: #a4a4a4;
  }
  &.ant-collapse > .ant-collapse-item:last-child {
    border: none;
  }
`
