import React from 'react'
import styled from '@emotion/styled'

import circleYes from 'Assets/images/si-circulo.png';
import circleNo from 'Assets/images/no-circulo.png';
import squareYes from 'Assets/images/si-cuadrado.png';
import squareNo from 'Assets/images/no-cuadrado.png';


export const CircleStamp = ({ completed, isNumeric }) => (
  <CircleImg alt="Completed Icon" src={completed ? circleYes : circleNo} isNumeric={isNumeric} rotation={Math.random() * (0 - (-5)) + (-5)} />
)

export const SquareStamp = ({ completed }) => (
  <SquareImg alt="Completed Icon" src={completed ? squareYes : squareNo} rotation={Math.random() * (0 - (-5)) + (-5)} />
)

const CircleImg = styled('img')`
  position: absolute;
  top: ${props => props.isNumeric ? '-17px' : '-14px'};
  height: 55px;
  transform: rotate(${props => props.rotation}deg)
`

const SquareImg = styled('img')`
  position: absolute;
  left: 10px;
  height: 33px;
  top: -10px;
  transform: rotate(${props => props.rotation}deg)
`
