import React, { useContext, useState, useEffect } from 'react'

import { OptionGroupButton } from 'Components'
import { ModalFormHowToMeasure } from 'Fragments'
import {
  UPDATE_OBJECTIVE_HOW,
  OBJECTIVE,
  OPEN_OBJECTIVE,
  UPDATE_OBJECTIVE_HOW_WITH_ADJUSTS,
} from 'Queries'
import {
  GlobalContext,
  ObjectiveContext,
  getMutationVars,
  getObjectiveIdVars,
  plurals,
} from 'Utils'

import { get, isEmpty } from 'lodash'
import { useMutation } from '@apollo/client'

export const OptionGroupButtonHow = ({ formOptions, objective }) => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { resetKeepAliveTimer, setObjective, setRecalculating } = context

  const [updateHow] = useMutation(
    get(objective, 'medida.id') === 'A' ? UPDATE_OBJECTIVE_HOW : UPDATE_OBJECTIVE_HOW_WITH_ADJUSTS,
    getMutationVars(getObjectiveIdVars(context), get(objective, 'medida.id') === 'A' ? OPEN_OBJECTIVE : OBJECTIVE, {
      onCompleted: async ({ objective }) => {
        resetKeepAliveTimer()
        setObjective(objective)
        setRecalculating(false)
      },
      onError: () => {
        setRecalculating(false)
      }
    }),
  )

  const [showModal, setShowModal] = useState(null)
  const [measureType, setMeasureType] = useState(
    get(objective, 'tipoValorRef.id') || '',
  )
  const [refValue, setRefValue] = useState(get(objective, 'tipoValorRef.id') === 'E' ? (get(objective, 'inctvMinCaja') || '') :
    (get(objective, 'valorRef.valor') || ''),
  )
  const [refValueType, setRefValueType] = useState(
    get(objective, 'valorRef.tipoDeUnidad') || 'cantidad',
  )
  const [valueDistribution, setValueDistribution] = useState(
    get(objective, 'distribucionValorRef.id') || '',
  )
  const [refAno, setRefAno] = useState(
    get(objective, 'refAno') || null,
  )

  const measureId = get(objective, 'medida.id') || ''
  const measureUd = get(objective, 'medida.unidad')
  const [nArtGroup, setNArtGroup] = useState(
    get(objective, 'nArtGroup') || 2,
  )

  const [canales, setCanales] = useState(get(objective, "canales") || []);

  const [euroTypeSettable, setEuroTypeSettable] = useState(get(objective, 'minEuroType'), false)
  const [euroTypeMinValue, setEuroTypeMinValue] = useState(get(objective, 'valorRef.valor'), '')
  const [euroTypeMinPayment, setEuroTypeMinPayment] = useState(get(objective, 'pagoIncentivo'), false)

  const yearInit = new Date().getFullYear()-2;
  const euroTypeValue = `€/${plurals(measureUd)}`
  let euroTypeDesc = `Se obtiene un incentivo de ${refValue} ${euroTypeValue}`
  euroTypeDesc += !euroTypeSettable ? '.' : ` a partir de la ${euroTypeMinValue}ª ${plurals(measureUd)} \n y se pagará desde ${!euroTypeMinPayment ? 'la cantidad mínima' : 'la primera unidad'}.`
  //reset form values on medidaId changed and when modal has closed/open
  useEffect(() => {
    setMeasureType(
      !get(objective, 'tipoValorRef.id') && get(objective, 'medida.id') === 'A' ? 'C' :
        !get(objective, 'tipoValorRef.id') && get(objective, 'medida.id') === 'S' ? 'SPG' :
          get(objective, 'tipoValorRef.id') ? get(objective, 'tipoValorRef.id') : '')
    setRefValue(get(objective, 'tipoValorRef.id') === 'E' ? (get(objective, 'inctvMinCaja') || '') :
      (get(objective, 'valorRef.valor') || ''))
    setRefValueType(get(objective, 'valorRef.tipoDeUnidad') || 'cantidad')
    setValueDistribution(get(objective, 'distribucionValorRef.id') || '')
    setRefAno(get(objective, 'refAno') || (get(objective, 'ano') !== 0 ? `${get(objective, 'ano') - 1}` : yearInit))
    setEuroTypeSettable(get(objective, 'minEuroType') || false)
    setEuroTypeMinValue(get(objective, 'valorRef.valor') || '')
    setEuroTypeMinPayment(get(objective, 'pagoIncentivo') || false)
    setNArtGroup(get(objective, 'nArtGroup') || 2)
    setCanales(get(objective, 'canales') || [])
  }, [measureId, showModal, objective])

  const sublabel = objective.distribucionValorRef
    ? `${objective.distribucionValorRef.nota}. ${objective.distribucionValorRef.desc}.`
    : 'SELECCIONAR'

  const showWarning =
    !objective.draft &&
    !isEmpty(objective.tipoValorRef) &&
    (objective.tipoValorRef.id !== measureType ||
      ((measureId !== 'A' || !(measureType === 'E' && !euroTypeSettable)) && objective.valorRef !== null && (
        objective.valorRef.valor.toString().replace('.', ',') !==
        refValue.toString() ||
        objective.valorRef.tipoDeUnidad !== refValueType)) ||
      objective.distribucionValorRef.id !== valueDistribution ||
      (objective.tipoValorRef.id === 'IA' && objective.refAno !== refAno))

  const modalProps = {
    visible: showModal ? 1 : 0,
    title: '¿Cómo quieres medirlo?',
    okButtonProps: {
      disabled:
        (isEmpty(measureType)) || (measureId !== 'A' && (isNaN(parseInt(refValue.toString().replace(',', '.')))))
        || isEmpty(valueDistribution) || ((measureType === 'E' && euroTypeSettable) && isNaN(parseInt(euroTypeMinValue)))
        || (measureId === 'S' && isNaN(parseInt(nArtGroup))),
    },
    okText: 'Seleccionar',
    cancelText: 'Cancelar',
    onCancel: () => {
      setShowModal(false)
    },
    onOk: () => {
      setShowModal(false)
      const sendValue = (measureType === 'E' && euroTypeSettable) ? euroTypeMinValue : refValue
      setRecalculating(true)
      updateHow({
        variables: {
          tipoValorRefId: measureType,
          valorRef: (measureId === 'A' || (measureType === 'E' && !euroTypeSettable)) ? null : {
            valor: sendValue.toString().replace(',', '.'),
            tipoDeUnidad: refValueType,
          },
          distribucionValorRefId: valueDistribution,
          recalcularAjustes: false,
          refAno,
          inctvMinCaja: measureType === 'E' ? refValue.toString().replace(',', '.') : null,
          pagoIncentivo: (measureType === 'E' && euroTypeSettable) ? euroTypeMinPayment : null,
          nArtGroup: measureId === 'S' ? parseInt(nArtGroup) : null,
        },
      })
    },
    width: 450,
  }

  return (
    <OptionGroupButton
      label="¿Cómo quieres medirlo?"
      sublabel={sublabel}
      selected={!!objective.distribucionValorRef}
      disabled={measureId === ''}
      onClick={() => measureId !== '' && setShowModal(true)}
      formView={() => (
        <ModalFormHowToMeasure
          showWarning={showWarning}
          options={formOptions}
          measureType={measureType}
          setMeasureType={setMeasureType}
          refValue={refValue}
          setRefValue={setRefValue}
          refValueType={refValueType}
          setRefValueType={setRefValueType}
          valueDistribution={valueDistribution}
          setValueDistribution={setValueDistribution}
          refAno={refAno}
          setRefAno={setRefAno}
          measureId={measureId}
          measureUd={measureUd}
          canales={canales}
          euroTypeValue={euroTypeValue}
          euroTypeDesc={euroTypeDesc}
          euroTypeSettable={euroTypeSettable}
          setEuroTypeSettable={setEuroTypeSettable}
          euroTypeMinValue={euroTypeMinValue}
          setEuroTypeMinValue={setEuroTypeMinValue}
          euroTypeMinPayment={euroTypeMinPayment}
          setEuroTypeMinPayment={setEuroTypeMinPayment}
          nArtGroup={nArtGroup}
          setNArtGroup={setNArtGroup}
        ></ModalFormHowToMeasure>
      )}
      modalProps={modalProps}
    ></OptionGroupButton>
  )
}
