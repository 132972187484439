import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'
import logoClave from 'Assets/logo/logo-clave.svg'

export const showConsentModal = callback => {
  Modal.warning({
    title: (
      <ConfirmTitle>
        <img alt="Logotipo Clave X" src={logoClave} />
      </ConfirmTitle>
    ),
    content: (
      <div>
        <h1>¡Bienvenido a CLAVE!</h1>
        <Text>
          Utilizamos cookies propias y de terceros para mejorar nuestros
          servicios y, además, elaborar información estadística y analizar tus
          hábitos de navegación con el fin de mejorar la experiencia de usuario.
          Pulsando ENTRAR estarás aceptando su instalación. Para obtener más
          información sobre el uso de estas cookies y tus derechos, accede a
          nuestra{' '}
          <a href="#/cookies" target="_blank">
            Política de Cookies
          </a>
          .
        </Text>
      </div>
    ),
    centered: true,
    icon: null,
    okText: 'Entrar',
    onOk: callback,
    width: 550,
  })
}

const ConfirmTitle = styled.div`
  color: ${colors.black};
  display: flex;
  justify-content: flex-end;
  height: 75px;
`
const Text = styled.p`
  font-size: 16px;
  height: 150px;
`
