import React, { useState, useEffect } from 'react'
import { Input as AntInput, Row, Col } from 'antd'
import styled from '@emotion/styled'
import { formatNumber } from 'Utils'

export const OpCellInput = ({
  paramtr = { signo: false, titulo: "", unidad: "%", valor: 0 },
  value,
  onChange,
  onlyPositive = false,
  updateOnChange = false,
  isDisabled
}) => {
  const [inputValue, setInputValue] = useState(value || '')

  const percentage = paramtr !== null ? (paramtr.unidad === '%') : false
  const suffix = paramtr !== null ? (paramtr.unidad === '%' ? '%' : '€') : '€'

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const onBlur = e => {
    const newValue = parseFloat(e.target.value.replace(',', '.'))
    if (!isNaN(newValue) && newValue !== value) {
      onChange(newValue)
    } else {
      setInputValue(value)
    }
  }

  return (
    <Inner>
      <Row>
        <Col span={11} style={{ marginTop: '5px' }}>
          {paramtr !== null ? paramtr.titulo : ''}
        </Col>
        <Col span={13}>
          <StyledInput
            disabled={isDisabled}
            value={inputValue.toString().replace('.', ',')}
            onChange={e => {
              const value = formatNumber(e.target.value, percentage, onlyPositive)
              setInputValue(value)
              if (updateOnChange) onChange(value)
            }}
            onBlur={onBlur}
            suffix={suffix}
          />
        </Col>
      </Row>
    </Inner>
  )
}

const Inner = styled.div`
  margin-bottom: 7px;
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    font-sizae 13px;
  }
  .ant-input-affix-wrapper .ant-input-suffix {
    right: 8px;
  }
`

const StyledInput = styled(AntInput)`
.ant-input .ant-input-affix-wrapper {
    height: 27px;
  }
  .ant-input .ant-input-affix-wrapper .ant-input-suffix {
    height: 27px;
  }
`
