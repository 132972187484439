import React, { useState, useEffect } from 'react'
import { Input } from 'antd'

export const OpCellInputDesc = ({ value, onChange, isDisabled }) => {

  const [inputValue, setInputValue] = useState(value || '')

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const onBlur = e => {
    onChange(e.target.value)
  }
  return (
    <Input.TextArea
      disabled={isDisabled}
      autoSize={{ minRows: 4, maxRows: 4 }}
      maxLength={250}
      value={inputValue}
      onChange={e => {
        setInputValue(e.target.value)
      }}
      onBlur={onBlur}
    />
  )
}

