import React, { useState } from 'react'
import { Form, Select } from 'antd'
import styled from '@emotion/styled'
import { Modal, WarningMessage } from 'Components'

import { isEmpty } from 'lodash'

export const MoveEmployeeForm = ({ props, setProps }) => {
  const [codeToMove, setCodeToMove] = useState(null)

  const onSubmit = () => {
    const variables = {
      codPadre: props.codPadre,
      codEmpleado: props.codEmpleado,
      codNuevoPadre: parseInt(codeToMove),
    }
    props.submit({ variables }).then(() => {
      setProps({ ...props, visible: false })
      setCodeToMove(null)
    })
  }

  const disableSubmit = isEmpty(codeToMove)

  return (
    <Modal
      title={props.title}
      okButtonProps={{ disabled: disableSubmit }}
      subtitle={props.subtitle}
      visible={props.visible}
      onCancel={() => {
        setProps({
          title: props.title,
          subtitle: props.subtitle,
          okText: props.okText,
          visible: false,
        })
        setCodeToMove(null)
      }}
      onOk={onSubmit}
      okText={props.okText}
    >
      <Form layout="vertical">
        <FormItem label="Mover a">
          <Select
            mode="default"
            allowClear
            size="default"
            value={codeToMove}
            onChange={val => setCodeToMove(val)}
            style={{ width: '100%' }}
            showArrow={true}
          >
            {!isEmpty(props.availableEmployees) &&
              props.availableEmployees.map(({ codMiembro, nombre }) => (
                <Select.Option key={codMiembro}>{nombre}</Select.Option>
              ))}
          </Select>
        </FormItem>
      </Form>
      {props.warning && (
        <WarningMessage>
          <span>
            Cualquier variación en la estructura de la jerarquía afectará a los
            objetivos existentes
          </span>
        </WarningMessage>
      )}
    </Modal>
  )
}

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    padding-bottom: 0px;
    margin-bottom: 24px;
  }
`
