import { get, isNil, isEmpty } from 'lodash'
import { compareSaturationArrays } from './commun'

/* * * * * * * * * *
*  OBJETIVO COMUN  *
* * * * * * * * * */

// Paso 1 - DEFINICION

const definitionStep = [{
  title: 'Definición',
  route: 'definition',
  isValid: () => {
    return true
  },
}]

// Paso 2 - ARTICULOS

const articlesStep = [{
  title: 'Artículos',
  route: 'articles',
  isValid: objective => {
    let valid = !objective.recalculating
    const requiredFields = [
      'medida.id',
      'distribucionValorRef.id',
      'tipoValorRef.id',
      'cuandoEvaluar.id',
    ]
    requiredFields.forEach(field => {
      if (isNil(get(objective, field))) {
        valid = false
      }
    })
    if (!objective.nombre || objective.nombre.trim().length <= 0) {
      valid = false
    }
    // TODO: This is repetitive. Thing of a cleaner way of validating
    // all previous steps
    return objective.draft
      ? valid && newSteps[0].isValid(objective)
      : valid
  },
}]

const articlesAndExceptionsStep = [{
  title: 'Artículos',
  route: 'articles-and-exceptions',
  isValid: objective => {
    let valid = !objective.recalculating
    const requiredFields = [
      'medida.id',
      'distribucionValorRef.id',
      'tipoValorRef.id',
      'cuandoEvaluar.id',
    ]
    requiredFields.forEach(field => {
      if (isNil(get(objective, field))) {
        valid = false
      }
    })
    if (!objective.nombre || objective.nombre.trim().length <= 0) {
      valid = false
    }
    // TODO: This is repetitive. Thing of a cleaner way of validating
    // all previous steps
    return objective.draft
      ? valid && newSteps[0].isValid(objective)
      : valid
  },
}]

// Paso 3 - COMERCIALES

const comercialsStep = [{
  title: 'Comerciales',
  route: 'commercials',
  isValid: objective => {
    return (
      newSteps[1].isValid(objective) &&
      (objective.hasArticles || objective.hasArticles === undefined) &&
      (
        (objective.medida.id !== 'S' &&
          (!objective.paraEspecialistas || !!objective.categoriasSeleccionadasArticulo.categorias.length)
        ) || (objective.medida.id === 'S' &&
          objective.categoriasSeleccionadasArticulo.saturacion.length >= objective.nArtGroup &&
          compareSaturationArrays('hasArticles', objective.categoriasSeleccionadasArticulo.saturacion, objective.categoriasSeleccionadasArticulo.saturacion.length) &&
          compareSaturationArrays('grupo', objective.categoriasSeleccionadasArticulo.categorias, objective.categoriasSeleccionadasArticulo.saturacion.length)
        )
      ) &&
      (!objective.paraEspecialistas ||
        !!objective.categoriasSeleccionadasArticulo.categorias.length ||
        !!objective.categoriasSeleccionadasExcepciones.categorias.length) &&
      (!objective.withException || (objective.withException && ((objective.hasExceptions || objective.hasExceptions === undefined) &&
        (!!objective.categoriasSeleccionadasArticulo.categorias.length && !!objective.categoriasSeleccionadasExcepciones.categorias.length)
      )))
    )
  },
}]

// Paso 4 - CLIENTES

const clientsStep = [{
  title: 'Clientes',
  route: 'clients',
  isValid: objective => {
    let valid = !objective.recalculating
    let comercialesSeleccionados = get(objective, 'comercialesSeleccionados')
    if (
      isNil(comercialesSeleccionados) ||
      isEmpty(comercialesSeleccionados)
    ) {
      valid = false
    }
    return valid && newSteps[2].isValid(objective)
  },
}]

// Paso 5 - AJUSTES

const adjustmentsStep = [{
  title: 'Ajustes',
  route: 'adjustments',
  isValid: objective => {
    return (
      !objective.recalculating
      && (objective.hasClients || objective.hasClients === undefined)
      && newSteps[3].isValid(objective)
    )
  },
}]

// Paso 6 - INCENTIVOS

const incentivesStep = [{
  title: 'Incentivos',
  route: 'incentives',
  isValid: objective => {
    return (
      !objective.recalculating
      && objective.ajuste
      && newSteps[4].isValid(objective)
    )
  },
}]


/* * * * * * * * * * *
*  OBJETIVO ABIERTO  *
* * * * * * * * * * */

const stepsOpenOptions = [
  {
    title: 'Definición',
    route: 'definition',
    isValid: () => {
      return true
    },
  },
  {
    title: 'Comerciales',
    route: 'commercials',
    isValid: objective => {
      let valid = !objective.recalculating
      const requiredFields = [
        'medida.id',
        'distribucionValorRef.id',
        'tipoValorRef.id',
        'cuandoEvaluar.id',
      ]
      requiredFields.forEach(field => {
        if (isNil(get(objective, field))) {
          valid = false
        }
      })
      if (!objective.nombre || objective.nombre.trim().length <= 0) {
        valid = false
      }
      // TODO: This is repetitive. Thing of a cleaner way of validating
      // all previous steps
      return objective.draft
        ? valid && stepsOpenOptions[0].isValid(objective)
        : valid
    },
  },
  {
    title: 'Ajustes/Incentivos',
    route: 'openAdjustments',
    isValid: objective => {
      let valid = !objective.recalculating
      let comercialesSeleccionados = get(objective, 'comercialesSeleccionados')
      if (
        isNil(comercialesSeleccionados) ||
        isEmpty(comercialesSeleccionados)
      ) {
        valid = false
      }
      return valid && stepsOpenOptions[1].isValid(objective)
    },
  }
]

let newSteps = [...definitionStep, ...articlesStep, ...comercialsStep, ...clientsStep, ...adjustmentsStep, ...incentivesStep]

export const getStepsOption = (idMedida, tipoValorRef, minimum, withException) => {
  newSteps = []

  if (idMedida === 'A') {
    newSteps = stepsOpenOptions;
  } else {
    newSteps = newSteps.concat(definitionStep);

    if (withException) {
      newSteps = [...newSteps, ...articlesAndExceptionsStep]
    } else {
      newSteps = [...newSteps, ...articlesStep]
    }

    newSteps = [...newSteps, ...comercialsStep, ...clientsStep,]

    if (tipoValorRef === 'E' && minimum) {
      newSteps = [...newSteps, ...adjustmentsStep]
    }
    if (tipoValorRef !== 'E') {
      newSteps = [...newSteps, ...adjustmentsStep, ...incentivesStep]
    }
  }

  return newSteps;
}
