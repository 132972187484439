import React, { useContext, useEffect } from 'react'
import { Breadcrumb, BreadcrumbHome, Checkbox, HorizontalLine } from './shared'

import { GlobalContext, ObjectiveContext } from 'Utils'
import { useQuery } from '@apollo/client'
import { get, isEmpty, findIndex } from 'lodash'
import { ResourceWrapper } from 'Components'
import { getObjectiveIdVars } from 'Utils'
import { Spin } from 'antd'
import { cloneDeep } from 'lodash'

export const ObjectiveCategoryValueList = ({
  selectedCategorieHash,
  selectedCategory,
  selectedCategories,
  query,
  setSelectedCategory,
  addAllCategoryValues,
  removeAllCategoryValues,
  addCategoryValue,
  removeCategoryValue,
  showAlert = false,
  isLoading = false,
  idGrupo = undefined,
  objGrupoId = undefined,
  paraProveedor,
}) => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const variables = {
    ...getObjectiveIdVars(context),
    idCategoria: selectedCategory.id,
    idGrupo,
    objGrupoId
  }

  const resource = useQuery(query, {
    fetchPolicy: 'network-only',
    variables,
    onCompleted: context.resetKeepAliveTimer,
  })

  useEffect(() => {
    resource.refetch()
  }, [selectedCategorieHash])

  let valoresCategoria = get(resource, 'data.valoresCategoria') || []
  const categoryId = selectedCategory.id

  const [selectedCategoriesInFamilyA] = selectedCategories.filter(
    category => category.categoria.id === categoryId,
  )

  let selectedCategoriesInFamily = cloneDeep(selectedCategoriesInFamilyA)

  if (
    selectedCategoriesInFamily &&
    !isEmpty(selectedCategoriesInFamily.valores)
  ) {
    valoresCategoria = valoresCategoria.map(category => {
      let index = findIndex(
        selectedCategoriesInFamily.valores,
        o => o.nombre.split("###")[0] === category.nombre.split("###")[0],
      )
      if (index >= 0 && category.nombre.split("###")[1] !== undefined) {
        let nombrefinal = selectedCategoriesInFamily.valores[index].nombre.split("###")[0] + "###" + category.nombre.split("###")[1]
        selectedCategoriesInFamily.valores[index].nombre = nombrefinal
      }
      return index >= 0
        ? selectedCategoriesInFamily.valores[index]
        : { ...category, seleccionado: false }
    })
  } else {
    valoresCategoria = valoresCategoria.map(category => ({
      ...category,
      seleccionado: false,
    }))
  }

  const allChecked = valoresCategoria
    .filter(value => value.activado)
    .every(({ seleccionado }) => seleccionado)


  const calcHeight = (idGrupo !== undefined && showAlert) ? 'calc(100vh - 410px)' : (idGrupo !== undefined && !showAlert) ? 'calc(100vh - 330px)' : 'calc(100vh - 280px)'

  return (
    <ResourceWrapper resource={resource}>
      <Breadcrumb>
        <BreadcrumbHome onClick={() => setSelectedCategory(null)}>
          Categorías
        </BreadcrumbHome>
        {` / ${selectedCategory.desc}`}
      </Breadcrumb>
      <Checkbox
        checked={allChecked}
        disabled={isLoading}
        onChange={() => {
          if (!allChecked) {
            addAllCategoryValues(
              categoryId,
              valoresCategoria
                .filter(value => value.activado)
                .map(value => value.nombre.split("###")[0]),
            )
          } else {
            removeAllCategoryValues(categoryId)
          }
        }}
      >
        Seleccionar todos los valores
      </Checkbox>
      <HorizontalLine />
      <div style={{ height: calcHeight, overflowY: 'auto' }}>
        <Spin indicator={<span style={{ opacity: 0 }} />} spinning={isLoading}>
          {valoresCategoria
            .filter(value => value.nombre.split("###")[0].trim() != "" && value.nombre != "null###null")
            .map((value, index) => (
              <Checkbox
                disabled={!value.activado || ((paraProveedor !== null && paraProveedor !== '') && paraProveedor === value.nombre.split("###")[0])}
                key={index}
                checked={value.seleccionado}
                onChange={() => {
                  if (value.seleccionado) {
                    removeCategoryValue(categoryId, value.nombre.split("###")[0])
                  } else {
                    addCategoryValue(categoryId, value.nombre.split("###")[0])
                  }
                }}
              >
                {value.nombre.split("###")[0]} {value.nombre.split("###")[1]}
              </Checkbox>
            ))}
        </Spin>
      </div>
    </ResourceWrapper>
  )
}
