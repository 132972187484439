import React, { useContext, useState, useEffect } from 'react'

import {
  ObjectiveOpenAdjustmentTable,
  ResourceWrapper,
  ObjectiveOpenIncentives,
  ObjectiveOpenDescription,
} from 'Components'
import {
  RECALCULATE_OPEN_OBJETIVE,
  UPDATE_OBJETIVE_OPEN_ADJUSTS,
  UPDATE_OBJETIVE_OPEN_COLUMN_ADJUSTS,
  UPDATE_OBJETIVE_OPEN_ADJUSTS_ALL,
  OPEN_OBJECTIVE,
  EDIT_OPEN_INCENTIVES_MATRIX,
  DELETE_OPEN_INCENTIVES_MATRIX
} from 'Queries'
import {
  colors,
  GlobalContext,
  ObjectiveContext,
  getMutationVars,
  getObjectiveIdVars,
} from 'Utils'
import { ObjectiveAdjustmentsMock } from 'Mockups'

import styled from '@emotion/styled'
import { useMutation, useLazyQuery } from '@apollo/client'
import { get, isNil } from 'lodash'
import moment from 'moment'

export const ObjectiveOpenAdjustments = () => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { resetKeepAliveTimer, setObjective, isDev, isLoading, setIsLoading } = context

  const objective = isDev
    ? ObjectiveAdjustmentsMock.data.objective
    : context.objective

  const [incentiveMatrix, setIncentiveMatrix] = useState(get(objective, 'incentivoMatrizCumplimiento') || [])
  const [generalIncentive, setGeneralIncentive] = useState(0)

  const [descIsLoading, setDescIsLoading] = useState(false)
  const [incIsLoading, setIncIsLoading] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
  })

  const onCompleted = ({ objective,
    objective: { incentivoMatrizCumplimiento }
  }) => {
    resetKeepAliveTimer()
    setIncentiveMatrix(incentivoMatrizCumplimiento)
    setObjective(objective)
    !descIsLoading || setDescIsLoading(false)
    !incIsLoading || setIncIsLoading(false)
  }
  const [getObjective, queryResource] = useLazyQuery(OPEN_OBJECTIVE, {
    fetchPolicy: 'network-only',
    skip: isDev,
    ...getMutationVars(getObjectiveIdVars(context), OPEN_OBJECTIVE, {
      onCompleted,
    }),
  })

  const [recalculateOpenAdjusts, mutacionResource] = useMutation(
    RECALCULATE_OPEN_OBJETIVE,
    getMutationVars(getObjectiveIdVars(context), OPEN_OBJECTIVE, {
      onCompleted,
    }),
  )

  const [updateOpenMutacion] = useMutation(
    UPDATE_OBJETIVE_OPEN_ADJUSTS,
    getMutationVars(getObjectiveIdVars(context), OPEN_OBJECTIVE, {
      onCompleted,
    }),
  )

  const [updateOpenColumnMutacion] = useMutation(
    UPDATE_OBJETIVE_OPEN_COLUMN_ADJUSTS,
    getMutationVars(getObjectiveIdVars(context), OPEN_OBJECTIVE, {
      onCompleted,
    }),
  )

  const [updateOpenApplyAllMutacion] = useMutation(
    UPDATE_OBJETIVE_OPEN_ADJUSTS_ALL,
    getMutationVars(getObjectiveIdVars(context), OPEN_OBJECTIVE, {
      onCompleted,
    }),
  )

  const distribucionValorRefId = get(objective, 'distribucionValorRef.id')
  const totalIncentives = get(objective, 'ajusteAbierto.cabeceraInctvTotal.total')
  const totalMonthIncentives = get(objective, 'ajusteAbierto.cabeceraInctvTotal.totalMeses') || []
  const monthValues = get(objective, 'ajusteAbierto.valoresCeldaPorEmpleadoMes') || []


  let startMonth = moment
    .utc(get(objective, 'periodoVigencia.fechaInicio'))
    .month()
  const endMonth = moment
    .utc(get(objective, 'periodoVigencia.fechaFin'))
    .month()
  const year = moment.utc(get(objective, 'periodoVigencia.fechaInicio')).year()

  const cuandoEvaluarId = get(objective, 'cuandoEvaluar.id')

  if (cuandoEvaluarId === 'F') {
    startMonth = endMonth
  }

  const [editIncentivesMatrix] = useMutation(
    EDIT_OPEN_INCENTIVES_MATRIX,
    getMutationVars(getObjectiveIdVars(context), OPEN_OBJECTIVE, {
      onCompleted: ({ objective }) => {
        setIncentiveMatrix(get(objective, 'incentivoMatrizCumplimiento'))
        resetKeepAliveTimer()
        setObjective(objective)
      },
    }),
  )

  const [deleteIncentivesMatrix] = useMutation(
    DELETE_OPEN_INCENTIVES_MATRIX,
    getMutationVars(getObjectiveIdVars(context), OPEN_OBJECTIVE, {
      onCompleted: ({ objective }) => {
        setIncentiveMatrix(get(objective, 'incentivoMatrizCumplimiento'))
        resetKeepAliveTimer()
        setObjective(objective)
      },
    }),
  )

  useEffect(() => {
    if (!isDev) {
      const hasYear = !isNil(get(objective, 'ano'))
      const hasAdjustments = !isNil(get(objective, 'ajusteAbierto'))
      if (hasYear) {
        if (!hasAdjustments) recalculateOpenAdjusts()
      } else getObjective()
    }
  }, [])

  const updateAdjusts = (mes, codigoEmpleado, params) => {
    return updateOpenMutacion({
      variables: {
        mes,
        codigoEmpleado,
        description: params.description,
        incentivoValor: params.inctv,
        percentage: params.percentage
      },
    })
  }

  const updateAdjustsColumn = (mes, percentage) => {
    return updateOpenColumnMutacion({
      variables: {
        mes,
        percentage
      },
    })
  }

  const tableData = get(objective, 'ajusteAbierto') || []

  const applyAll = (description, incentivoValor) => {
    return updateOpenApplyAllMutacion({
      variables: {
        description,
        incentivoValor
      },
    })
  }

  const resource = {
    loading:
      queryResource.loading ||
      mutacionResource.loading,
    error:
      queryResource.error ||
      mutacionResource.error,
  }

  return (
    <Wrapper>
      <PageTitle>Ajustes del objetivo</PageTitle>
      <Inner>
        <ResourceWrapper resource={{ ...resource, data: objective }}>
          {tableData && (
            <>
              <TopPannel>
                <ObjectiveOpenDescription
                  isCommon={distribucionValorRefId === 'D'}
                  dataSource={monthValues}
                  applyAll={applyAll}
                  isLoading={descIsLoading}
                  setLoading={setDescIsLoading}
                />

                <ObjectiveOpenIncentives
                  incentiveMatrix={incentiveMatrix}
                  setIncentiveMatrix={setIncentiveMatrix}
                  totalIncentives={totalIncentives}
                  totalMonthIncentives={totalMonthIncentives}
                  startMonth={startMonth}
                  endMonth={endMonth}
                  year={year}
                  editIncentivesMatrix={editIncentivesMatrix}
                  deleteIncentivesMatrix={deleteIncentivesMatrix}
                  generalIncentive={generalIncentive}
                  setGeneralIncentive={setGeneralIncentive}
                  applyAll={applyAll}
                  isLoading={incIsLoading}
                  setLoading={setIncIsLoading}
                />
              </TopPannel>

              <ObjectiveOpenAdjustmentTable
                isCommon={distribucionValorRefId === 'D'}
                dataSource={tableData}
                onChange={updateAdjusts}
                onChangeColumn={updateAdjustsColumn}
                showPeriod={get(objective, 'cuandoEvaluar.id') === 'F'}
                periodDate={objective.periodoVigencia}
              />
            </>
          )}
        </ResourceWrapper>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`
const Inner = styled.div`
  width: 100%;
  height: calc(100vh - 225px);
  padding: 25px 30px;
  overflow: auto;
`

const TopPannel = styled.div`
  position: relative;
`

const PageTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.11;
  letter-spacing: -0.27px;
  color: ${colors.black};
  height: 43px;
  padding: 13px 0 0 20px;
  width: 100%;
  border-bottom: 1px solid #dfdfdf
`
