import gql from 'graphql-tag'
import {
  OBJECTIVE_FRAGMENT,
  OBJECTIVE_ADJUSTS_FRAGMENT,
} from 'Queries/ObjectiveFragments'

export const OBJECTIVE_INCENTIVES_FRAGMENT = gql`
  fragment objectiveIncentivesFragment on Objetivo {
    incentivo {
      cabeceraTotales {
        total
        totalMeses {
          mes
          valor
        }
      }
      empleados {
        nivelAnidacion
        empleado {
          codMiembro
          titulo
        }
        total
        celdas {
          mes
          valor
        }
      }
    }
    incentivoMatrizCumplimiento {
      desde
      hasta
      limite
      lineal
      orden
      secuencia
      valor
    }
    ajuste {
      ...objectiveAdjustsFragment
    }
    ajusteJerarquico {
      ...objectiveAdjustsFragment
    }
  }
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const OBJECTIVE_INCENTIVES_ONLY_FRAGMENT = gql`
  fragment objectiveIncentivesFragment on Objetivo {
    incentivo {
      cabeceraTotales {
        total
        totalMeses {
          mes
          valor
        }
      }
      empleados {
        nivelAnidacion
        empleado {
          codMiembro
          titulo
        }
        total
        celdas {
          mes
          valor
        }
      }
    }
    incentivoMatrizCumplimiento {
      desde
      hasta
      limite
      lineal
      orden
      secuencia
      valor
    }
  }
`

export const RECALCULATE_OBJECTIVE_INCENTIVES = gql`
  mutation recalculateObjectiveIncentives($id: ObjetivoIdInput!, $valor: Int) {
    objective: recalcularObjetivoIncentivo(id: $id, valor: $valor) {
      ...objectiveFragment
      ...objectiveIncentivesFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_INCENTIVES_FRAGMENT}
`


export const RECALCULATE_OBJECTIVE_FRAGMENTS = gql`
  mutation recalculateObjectiveIncentives($id: ObjetivoIdInput!, $valor: Int) {
    objective: recalcularObjetivoIncentivo(id: $id, valor: $valor) {
      ...objectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`


export const RECALCULATE_OBJECTIVE_INCENTIVES_FRAGMENTS = gql`
  mutation recalculateObjectiveIncentives($id: ObjetivoIdInput!, $valor: Int) {
    objective: recalcularObjetivoIncentivo(id: $id, valor: $valor) {
      ...objectiveIncentivesFragment
    }
  }
  ${OBJECTIVE_INCENTIVES_ONLY_FRAGMENT}
`

export const UPDATE_OBJECTIVE_INCENTIVES = gql`
  mutation updateObjectiveIncentives(
    $id: ObjetivoIdInput!
    $codMiembro: Long!
    $mes: Int!
    $valorIncentivo: Int!
  ) {
    objective: actualizarObjetivoIncentivo(
      id: $id
      codMiembro: $codMiembro
      mes: $mes
      valorIncentivo: $valorIncentivo
    ) {
      ...objectiveFragment
      ...objectiveIncentivesFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_INCENTIVES_FRAGMENT}
`

export const EDIT_INCENTIVES_MATRIX = gql`
  mutation editIncentivesMatrix(
    $id: ObjetivoIdInput!
    $secuencia: Int
    $desde: Float
    $hasta: Float
    $valor: Float
    $lineal: Boolean
  ) {
    objective: actualizarObjetivoIncentivoMatrizCumplimiento(
      id: $id
      secuencia: $secuencia
      desde: $desde
      hasta: $hasta
      valor: $valor
      lineal: $lineal
    ) {
      ...objectiveFragment
      ...objectiveIncentivesFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_INCENTIVES_FRAGMENT}
`

export const DELETE_INCENTIVES_MATRIX = gql`
  mutation deleteIncentivesMatrix($id: ObjetivoIdInput!, $secuencia: Int!) {
    objective: eliminarObjetivoIncentivoFilaMatrizCumplimiento(
      id: $id
      secuencia: $secuencia
    ) {
      ...objectiveFragment
      ...objectiveIncentivesFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_INCENTIVES_FRAGMENT}
`
