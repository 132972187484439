import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { Button, Icon } from 'Components'
import { DoubleLeftOutlined, DoubleRightOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Select, DatePicker } from 'antd'
import { colors, agrupacionList, estadoList, periodoList, dateIconStyle } from 'Utils'
import moment from 'moment'

const toggleFilter = (filter, element) =>
  filter && filter.length
    ? filter.includes(element)
      ? filter.filter(elem => elem !== element)
      : [...filter, element]
    : [element]

export const SummaryLegend = ({
  filter,
  filter: { tipo, cuando, fechas, idMedida, empleado, nivel, estado },
  empleados,
  niveles,
  setFilter,
  clearFilter,
  isLoading,
  callNewKpis = () => { },
  resetList = () => { },
}) => {

  const [ipadResolution, setIpadResolution] = useState()
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState(null);
  const [showThisLabel, setShowThisLabel] = useState('');

  useEffect(() => {
    setTimeout(() => {
      updateResolution()
    }, 100)
    window.addEventListener('resize', updateResolution)
    return () => {
      window.removeEventListener('resize', updateResolution)
    }
  }, [])

  const updateResolution = () => {
    setIpadResolution(document.querySelector('#contentWidth').offsetWidth < 1100)
  }

  const setMyDay = (mom) => {
    let d = new Date(Date.UTC(mom.format('YYYY'), parseInt(mom.format('MM')) - 1, mom.format('DD'), 0, 0, 0));
    return d.getTime();
  }

  function disabledDate(current) {

    const index = dates === null ? -1 : dates
      .map((value) => { return value !== null })
      .indexOf(true);

    if (!dates || index === -1) {
      const my2018 = moment('2019-01-01', 'YYYY-MM-DD');
      return !my2018.isBefore(current);
    }

    if (dates && index !== -1) {
      const datte = dates[index].format('YYYY-MM-DD')

      const yearStart = moment(moment(datte, 'YYYY-MM-DD')).startOf('year');
      const yearEnd = moment(moment(datte, 'YYYY-MM-DD')).endOf('year');
      return !(yearStart.isSameOrBefore(current) && yearEnd.isAfter(current));
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return !!tooEarly || !!tooLate;

  }

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };



  const overShow = (tipo) => {
    setShowThisLabel(tipo);
  }

  return (
    <>
      <IconsWrapper >
        <IconFilter
          disabled={isLoading}
          selected={idMedida && idMedida.includes('S')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'S') })}
          onMouseOver={() => overShow('S')}
        >
          <Icon type="saturacion" size={30} />
          {(showThisLabel === 'S') && <Label>Saturación</Label>}
        </IconFilter>
        <IconFilter
          disabled={isLoading}
          selected={idMedida && idMedida.includes('A')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'A') })}
          onMouseOver={() => overShow('A')}
        >
          <Icon type="hand" size={26} />
          {(showThisLabel === 'A') && <Label>Obj. Libre</Label>}
        </IconFilter>
        <IconFilter
          disabled={isLoading}
          selected={idMedida && idMedida.includes('B')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'B') })}
          onMouseOver={() => overShow('B')}
        >
          <Icon type="equivalentPackage" size={32} />
          {(showThisLabel === 'B') && <Label>Bulto Eq.</Label>}
        </IconFilter>
        <IconFilter
          disabled={isLoading}
          selected={idMedida && idMedida.includes('F')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'F') })}
          onMouseOver={() => overShow('F')}
        >
          <Icon type="euro" size={32} />
          {(showThisLabel === 'F') && <Label>Facturación</Label>}
        </IconFilter>
        <IconFilter
          disabled={isLoading}
          selected={idMedida && idMedida.includes('M')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'M') })}
          onMouseOver={() => overShow('M')}
        >
          <Icon type="margin" size={32} />
          {(showThisLabel === 'M') && <Label>Margen</Label>}
        </IconFilter>
        <IconFilter
          disabled={isLoading}
          selected={idMedida && idMedida.includes('N')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'N') })}
          onMouseOver={() => overShow('N')}
        >
          <Icon type="bar" size={32} />
          {(showThisLabel === 'N') && <Label>Numérica</Label>}
        </IconFilter>
        <IconFilter
          disabled={isLoading}
          selected={idMedida && idMedida.includes('U')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'U') })}
          onMouseOver={() => overShow('U')}
        >
          <Icon type="box" size={32} />
          {(showThisLabel === 'U') && <Label>Unidades</Label>}
        </IconFilter>
        <IconFilter
          disabled={isLoading}
          selected={idMedida && idMedida.includes('V')}
          onClick={() => setFilter({ idMedida: toggleFilter(idMedida, 'V') })}
          onMouseOver={() => overShow('V')}
        >
          <Icon type="drop" size={32} />
          {(showThisLabel === 'V') && <Label>Volumen</Label>}
        </IconFilter>
      </IconsWrapper>
      <FilterWrapper >
        <Select
          disabled={isLoading || !fechas}
          mode="default"
          size="small"
          placeholder="Rol"
          value={niveles.length ? nivel : null}
          onChange={nivel => setFilter({ nivel, empleado: undefined })}
          style={{ width: 130, minWidth: 120 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          <Select.Option key={undefined} value={undefined}>
            ...
          </Select.Option>
          {niveles.map(({ id, desc }) => (
            <Select.Option key={id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={isLoading || !nivel}
          mode="default"
          size="small"
          placeholder="Empleado"
          value={empleados.length ? empleado : null}
          onChange={empleado => setFilter({ empleado })}
          style={{ width: 170, minWidth: 120 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {empleados.map(({ codEmpleado, nombreEmpleado }) => (
            <Select.Option key={codEmpleado} value={codEmpleado}>
              {nombreEmpleado}
            </Select.Option>
          ))}
        </Select>

        <Select
          disabled={isLoading}
          mode="default"
          size="small"
          placeholder="Agrupación"
          value={tipo}
          onChange={tipo => setFilter({ tipo })}
          style={{ width: 120, minWidth: 120 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {agrupacionList.map(({ id, desc }) => (
            <Select.Option key={id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={isLoading}
          mode="default"
          size="small"
          placeholder="Estado"
          value={estado}
          onChange={estado => setFilter({ estado })}
          style={{ width: 100, minWidth: 100 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {estadoList.map(({ id, desc }) => (
            <Select.Option key={id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={isLoading}
          mode="default"
          size="small"
          placeholder="Tipo período"
          value={cuando}
          onChange={cuando => setFilter({ cuando })}
          style={{ width: 130, minWidth: 120 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {periodoList.map(({ id, desc }) => (
            <Select.Option key={id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <DatePicker.RangePicker
          disabled={isLoading}
          size="small"
          value={hackValue || fechas}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => {
            setFilter({ fechas: val })
            resetList()
            callNewKpis(setMyDay(val[0]), setMyDay(val[1]))
          }}
          onOpenChange={onOpenChange}
          ranges={{
            'Año anterior': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Mes anterior': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
            'Año actual': [moment().startOf('year'), moment().endOf('year')],
            'Mes actual': [moment().startOf('month'), moment().endOf('month')],
          }}

          superPrevIcon={<DoubleLeftOutlined style={dateIconStyle} />}
          prevIcon={<LeftOutlined style={dateIconStyle} />}
          nextIcon={<RightOutlined style={dateIconStyle} />}
          superNextIcon={<DoubleRightOutlined style={dateIconStyle} />}
          style={{ width: ipadResolution ? 170 : 195, minWidth: 110 }}
          format={ipadResolution ? "YY/MM/DD" : "YYYY/MM/DD"}
          placeholder={['F. Inical', 'F. Final']}
        />
        <Button
          disabled={
            !Object.values(filter).some(theFilter =>
              Array.isArray(theFilter) ? theFilter.length : theFilter,
            )
          }
          type="link"
          size="large"
          onClick={clearFilter}
        >
          Resetear filtros
        </Button>
      </FilterWrapper>
    </>
  )
}

const IconFilter = styled.div`
  border: ${({ selected }) =>
    selected ? `1px dashed ${colors.nastyGreen}` : 'none'};
  border-radius: 5px;
  padding: 3px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled && '40%')};
  &:hover {
    cursor: pointer;
  }
`
const Label = styled.span`
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${colors.black};
  margin: 0 10px 0 6px;
`
const IconsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  right: 20px;
  top: 10px;
  min-width: 500px;
  > div {
    margin: 0 5px;
  }
`

const FilterWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  right: 0px;
  top: 65px;
  > div {
    margin: 0 5px;
  }
`