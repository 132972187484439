import React from 'react'
import { Row as AntRow } from 'antd'
import styled from '@emotion/styled'
import moment from 'moment'
import {
  OpCellDescription,
  OpHeaderCellWrapper,
  OpCellWrapper,
  OpCellInput,
} from 'Components'

export const OpenTable = ({
  headerValues,
  dataSource,
  onChange,
  onChangeColumn,
  isCommon,
  showPeriod,
  periodDate,
}) => (
  <Inner>
    <DataRow>
      {headerValues.map(mes => (
        <OpHeaderCellWrapper
          isDetail
          key={mes}
          isAbsolut={showPeriod}
        >
          {mes}
          {showPeriod && (
            <>
              <br />
              <PeriodInfo>
                {`${moment(periodDate.fechaInicio).format('DD/MM/YY')} - ${moment(periodDate.fechaFin).format('DD/MM/YY')}`}
              </PeriodInfo>
            </>
          )}
        </OpHeaderCellWrapper>
      ))}
    </DataRow>
    {dataSource.map(({ empleado: { codMiembro }, celdas, nivelAnidacion }, i) => (
      <DataRow type="flex" key={i}>
        {celdas &&
          celdas.map(({ mes, inctvTotal, inctv, percentage }, index) => (
            <OpCellWrapper
              isDisabled={nivelAnidacion !== 0 && (dataSource[i - 1].empleado.codMiembro === codMiembro || dataSource[0].empleado.codMiembro === codMiembro)}
              isDetail
              isAbsolut={showPeriod}
              key={index}
            >
              {/* Incentivo */}
              {nivelAnidacion === 0 ? (
                <OpCellDescription mBot dataSource={inctv} />
              ) : (
                <OpCellInput
                  isDisabled={nivelAnidacion !== 0 && (dataSource[i - 1].empleado.codMiembro === codMiembro || dataSource[0].empleado.codMiembro === codMiembro)}
                  paramtr={inctv}
                  value={inctv !== null ? inctv.valor : 0}
                  onChange={value => onChange(mes, codMiembro, { description: null, inctv: value, percentage: null })}
                ></OpCellInput>
              )}

              {/* % conseguido */}
              {(nivelAnidacion === 0 && isCommon) ? (
                <OpCellInput
                  isDisabled={nivelAnidacion !== 0 && (dataSource[i - 1].empleado.codMiembro === codMiembro || dataSource[0].empleado.codMiembro === codMiembro)}
                  paramtr={percentage}
                  value={percentage !== null ? percentage.valor : 0}
                  onChange={value => onChangeColumn(mes, value)}
                ></OpCellInput>
              ) : (nivelAnidacion !== 0 && isCommon) ? (
                <OpCellDescription mBot dataSource={percentage} />
              ) : (nivelAnidacion !== 0 && !isCommon) ? (
                <OpCellInput
                  isDisabled={nivelAnidacion !== 0 && (dataSource[i - 1].empleado.codMiembro === codMiembro || dataSource[0].empleado.codMiembro === codMiembro)}
                  paramtr={percentage}
                  value={percentage !== null ? percentage.valor : 0}
                  onChange={value => onChange(mes, codMiembro, { description: null, inctv: null, percentage: value })}
                ></OpCellInput>
              ) : (<></>)}

              {/* Incentivo total */}
              <OpCellDescription dataSource={inctvTotal} />
            </OpCellWrapper>
          ))}
      </DataRow>
    ))}
  </Inner>
)

const Inner = styled.div({
  width: 'max-content',
})
const DataRow = styled(AntRow)({
  display: 'flex',
  backgroung: 'green',
  ' > :last-child': {
    marginRight: 0,
  },
})

const PeriodInfo = styled.span(props => {
  return {
    fontSize: 13,
    color: 'black'
  }
})
