import gql from 'graphql-tag'
import {
  OBJECTIVE_FRAGMENT,
  OPEN_OBJETIVE_FRAGMENT,
} from 'Queries/ObjectiveFragments'

export const OPEN_OBJETIVE_INCENTIVES_FRAGMENT = gql`
  fragment objectiveIncentivesFragment on Objetivo {
    incentivoMatrizCumplimiento {
      desde
      hasta
      limite
      lineal
      orden
      secuencia
      valor
    }
    ajusteAbierto {
      ...objectiveOpenFragment
    }
  }
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const RECALCULATE_OPEN_OBJECTIVE_INCENTIVES = gql`
  mutation recalculateObjectiveIncentives($id: ObjetivoIdInput!, $valor: Int) {
    objective: recalcularObjetivoIncentivo(id: $id, valor: $valor) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const EDIT_OPEN_INCENTIVES_MATRIX = gql`
  mutation editIncentivesMatrix(
    $id: ObjetivoIdInput!
    $secuencia: Int
    $desde: Float
    $hasta: Float
    $valor: Float
    $lineal: Boolean
  ) {
    objective: actualizarObjetivoIncentivoMatrizCumplimiento(
      id: $id
      secuencia: $secuencia
      desde: $desde
      hasta: $hasta
      valor: $valor
      lineal: $lineal
    ) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`

export const DELETE_OPEN_INCENTIVES_MATRIX = gql`
  mutation deleteIncentivesMatrix($id: ObjetivoIdInput!, $secuencia: Int!) {
    objective: eliminarObjetivoIncentivoFilaMatrizCumplimiento(
      id: $id
      secuencia: $secuencia
    ) {
      ...objectiveFragment
      incentivoMatrizCumplimiento {
        desde
        hasta
        limite
        lineal
        orden
        secuencia
        valor
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
`
