import React, { useEffect, useState } from 'react';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import axios from 'axios';
import { PageErrorWarning } from 'Components';

export const ReportEmbed = ({ currentUrl, dashboardId, hSize = 90, params }) => {
  const [posts, setPosts] = useState('');

  const containerDiv = React.useRef();

  useEffect(() => {
    const headers = {
      'Authorization': localStorage.getItem("token"),
      'Content-type': 'application/json',
    };

    const dashboardUrl = `${currentUrl}?dashboardid=${dashboardId}`;
    const dashboardParams = params ? {
      pano: params.ano,
      pcoddistribuidor: params.codDistribuidor,
      pcodobjetivo: params.codObjetivo,
    } : null;

    axios.get(dashboardUrl, { headers })
      .then(response => {
        setPosts('OK');
        const options = {
          url: response.data,
          parameters: dashboardParams,
          container: containerDiv.current,
          scrolling: "no",
          iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit,
          // to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
          width: '100%',
          height: '100%',
          border: 'none',
          locale: "es-ES",
          footerPaddingEnabled: true,
          sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls
          undoRedoDisabled: false, // use this option to disable undo and redo buttons
          resetDisabled: false // use this option to disable reset button
        };
        QuickSightEmbedding.embedDashboard(options);
      })
      .catch(error => {
        setPosts('NOK');
        console.error('There was an error!', error);
      });
  }, []);

  return (
    <div ref={containerDiv} style={{ height: posts === 'OK' ? `calc(100vh - ${hSize}px` : 150 }}>
      {posts === 'NOK' && PageErrorWarning('No es posible ver el informe en estos momentos', 'Perdone las molestias')}
    </div>
  )
}
