import React from 'react'
import { TableHeaderTitle, TableHeaderSubtitle } from './styled'

export const TableHeader = () => (
  <>
    <TableHeaderTitle>
      <div>Cumplimiento del objetivo (%)</div>
      <div>Retribución (% del incentivo)</div>
    </TableHeaderTitle>
    <TableHeaderSubtitle>
      <div>Desde</div>
      <div>Hasta</div>
    </TableHeaderSubtitle>
  </>
)
