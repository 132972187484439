import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { Table as AntTable, Empty, Popover } from 'antd'
import { Icon } from '@ant-design/compatible'
import { colors, colorList } from 'Utils';

export const SaturationTable = ({
  headerList,
  dataList,
  isLoading,
  orderList,
  totalElements,
  pager,
  sortBy,
  sortDir,
}) => {

  const columnColor = (id) => {
    return colorList[headerList.map(grp => grp.groupId).indexOf(id)]
  }

  const popHeader = (name, articulos = []) => {
    const content = (
      <div style={{ maxHeight: 400, overflowY: 'auto' }}>
        {articulos.map(art => { return (<p key={art.codArticulo} style={{ fontSize: 13 }} >{art.codArticulo} - {art.nombre}</p>) })}
      </div>
    );

    return (
      <Popover content={content} placement="bottomRight" title={name}>
        <span>{name.length < 35 ? name : `${name.substring(0, 35)}...`}</span>
        {/* <span>{name}</span> */}
      </Popover>
    )
  }

  const getColumns = () => {
    let colsList = headerList.map(grp => {
      return {
        title: popHeader(grp.groupName, grp.articulos),
        dataIndex: `Grupo${grp.groupId}`,
        key: `Grupo${grp.groupId}`,
        align: 'center',
        // sorter: true,
        // sortDirections: ['descend', 'ascend', 'descend'],
        render: (text, row) => {
          const stateColor = (row.groupsId.find(pdvGr => pdvGr === grp.groupId) !== undefined);
          const obj = {
            children: (
              <span>
                {stateColor && <Icon type="check-square" style={{ color: columnColor(grp.groupId), fontSize: 19 }} theme="filled" />}
              </span>
            ),
          };
          return obj;
        },
      }
    })

    const successCol = {
      dataIndex: 'countGroup',
      align: 'center',
      width: 50,
      fixed: 'left',
      sorter: true,
      // defaultSortOrder: sortBy === 'countGroup' ? (sortDir === 'desc' ? 'descend' : 'ascend') : undefined,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (text, row) => {
        const obj = {
          children: (
            <span>
              {row.success ? (<Icon type="check" style={{ color: colors.trueGreen, fontSize: '17px' }} />)
                : (<Icon type="close" style={{ color: colors.redCruz, fontSize: '17px' }} />)}
              <span style={{ fontSize: 17, fontWeight: 600, marginLeft: 5 }}>
                {row.countGroup}
              </span>
            </span>
          ),
        };
        return obj;
      },
    }

    const idCol = {
      title: 'Código PdV',
      dataIndex: 'pdvId',
      key: 'nameKey',
      align: 'center',
      width: 110,
      fixed: 'left',
      sorter: true,
      // defaultSortOrder: sortBy === 'pdvId' ? (sortDir === 'desc' ? 'descend' : 'ascend') : undefined,
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (value) => {
        const obj = {
          children: (value),
          props: { style: { background: "#D4EFDF" } },
        };
        return obj;
      },
    }

    colsList.splice(0, 0, successCol, idCol, nameCol)

    return colsList;
  }

  const nameCol = {
    title: 'Nombre PdV',
    dataIndex: 'pdv',
    align: 'left',
    width: 210,
    fixed: 'left',
    ellipsis: true,
    sorter: true,
    // defaultSortOrder: sortBy === 'pdv' ? (sortDir === 'desc' ? 'descend' : 'ascend') : undefined,
    sortDirections: ['descend', 'ascend', 'descend'],
    render: (value) => {
      const obj = {
        children: (
          <Popover content={(<span>{value}</span>)} placement="topLeft">
            <Cell> {value} </Cell>
          </Popover>
        ),
        props: { style: { background: "#D4EFDF" } },
      };
      return obj;
    },
  }

  const [showScroll, setShowScroll] = useState(
    !document.querySelector('#satTable') ? true : false,
  )

  useEffect(() => {
    setTimeout(() => {
      updateScrollable()
    }, 100)
    window.addEventListener('resize', updateScrollable)
    return () => {
      window.removeEventListener('resize', updateScrollable)
    }
  }, [])

  const updateScrollable = () => {
    document.querySelector('#satTable') && console.log(document.querySelector('#satTable').offsetWidth);
    setShowScroll(
      document.querySelector('#satTable') && document.querySelector('#satTable').offsetWidth < 1200,
    )
  }

  return (
    <StyledTable
      id="satTable"
      rowKey="nameKey"
      size={"small"}
      dataSource={isLoading ? [] : dataList}
      pagination={{ pageSize: 100, total: totalElements, current: pager }}
      scroll={{ y: 'calc(100vh - 335px)', x: showScroll ? 1200 : '' }}
      columns={getColumns()}
      locale={{ emptyText: 'No hay pdv para la búsqueda' }}
      onChange={(pagination, __, { field, order }) => {
        orderList({ field, order }, pagination.current)
      }}
    />
  )
}

const StyledTable = styled(AntTable)(({ cursor }) => ({
  cursor: cursor ? 'pointer' : 'initial',
  '& .ant-table-thead > tr > th': {
    fontSize: 14,
    fontWeight: 600,
    background: '#FFFFFF !important',
    color: '#205527',
  },
  '& .ant-table-small > .ant-table-content > .ant-table-body': {
    fontSize: '13px',
  },
  '& .ant-table-tbody > tr > td': {
    background: '#F3FAF3',
    borderTop: '1px solid darkGray',
    borderBottom: 'none'
  }
}))


const Cell = styled.span`
  display: inline-block;
  width: 193px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`