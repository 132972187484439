import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { notification } from "antd";
import { commonError } from "./session";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'same-origin',
});

const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

let networkModalOpened = false;

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  assumeImmutableResults: false,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          notification.error({
            message: 'Error de datos', //[GraphQL error]
            duration: 6,
            description: `${commonError('GraphQL', message)}${path !== undefined ? ' Path: ' + path : ''}`, 
          }),
        )
      if (networkError && !networkModalOpened) {
        networkModalOpened = true;
        notification.error({
          message: 'Error de red', //[Network error]
          duration: 6,
          description: `${commonError('Network', networkError.message)}`,
          onClose: () => {
            networkModalOpened = false;
          }
        })
      }
    }),
    authLink.concat(httpLink),
  ]),
  defaultOptions: defaultOptions
});

export const getMutationVars = (variables, query, options) => ({
  variables,
  update: (cache, { data }) => {
    cache.writeQuery({
      query,
      variables,
      data: { ...data },
    })
  },
  ...options,
})
