import React from 'react'

import { rounded } from 'Utils'

import styled from '@emotion/styled'

export const ObjectiveBoxIncentive = ({ kpi }) => {
  const { idMedida, incentivoDia, incentivoTeorico, porcCumplimientoMedidaRealDia, inctvMinCaja } = kpi
  if (
    (idMedida !== 'A' && !((inctvMinCaja === null && incentivoTeorico > 0) || (inctvMinCaja !== null && incentivoDia > 0)))
    || (idMedida === 'A' && porcCumplimientoMedidaRealDia <= 0)) return null
  return (
    <Footer>
      <FooterInfo>Incentivo </FooterInfo>
      {rounded(incentivoDia)} eur{' '}
      {inctvMinCaja === null &&
        <FooterPerDayInfo>
          ({rounded(incentivoTeorico)} eur)
        </FooterPerDayInfo>}
    </Footer>
  )
}
const Footer = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: var(--black);
  text-align: right;
  min-height: 27px;
  overflow: hidden;
`
const FooterInfo = styled.span`
  font-size: 15px;
  line-height: 0.94;
  letter-spacing: 0.2px;
  font-weight: normal;
  color: var(--warm-grey);
`
const FooterPerDayInfo = styled.span`
  font-size: 15px;
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: 0.2px;
  color: #555555;
`
