import React from 'react'
import { Layout } from 'antd'
import styled from '@emotion/styled'

const ContentWrapper = styled(Layout.Content)(({ collapsed, nosider }) => ({
  height: '100vh ',
  width: '100vw !important',
  transition: 'all 0.2s',
  background: '#F9FAF9',
  marginLeft: nosider ? 0 : collapsed ? 80 : 218,
  padding: '81px 0 0 0',
}))

const Inner = styled.div(({ fullwidth }) => ({
  width: '100%',
  height: '100%',
  paddingTop: fullwidth === 'A' && 15,
  paddingRight: fullwidth === 'A' ? 70 : fullwidth === 'B' ? 5 : 0,
  paddingLeft: fullwidth === 'A' ? 70 : fullwidth === 'B' ? 5 : 0,
  overflow: fullwidth === 'A' ? 'auto' : 'hidden',
  overflowX: 'auto'
}))

export const Content = ({ children, collapsed, fullwidth, nosider }) => (
  <ContentWrapper collapsed={collapsed} nosider={nosider}>
    <Inner fullwidth={fullwidth}>
      {children}
    </Inner>
  </ContentWrapper>
)
