import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { Button } from 'Components'
import { Input, Select } from 'antd'
import { minOkList } from 'Utils'

export const SaturationLegend = ({
  filter,
  filter: { cliente, minOk, nGroups, empleado, nivel },
  empleados,
  niveles,
  handleOnChange,
  gruposSat,
  setFilter,
  clearFilter,
  isLoading,
}) => {

  const [inputValue, setInputValue] = useState(cliente)

  useEffect(() => {
    setInputValue(filter.cliente)
  }, [filter.cliente])

  return (
    <>
      <Wrapper>
        <Input
          disabled={isLoading}
          mode="default"
          size="small"
          placeholder="Cliente"
          // value={searchTerm}
          // onChange={handleOnChange}
          value={inputValue}
          onChange={e => {
            handleOnChange(e);
            setFilter({ cliente: e.target.value })
            setInputValue(e.target.value);
          }}
          style={{ width: 160, minWidth: 120 }}
        />

        <Select
          disabled={isLoading}
          mode="default"
          size="small"
          placeholder="Rol"
          value={niveles.length ? nivel : null}
          onChange={nivel => setFilter({ nivel, empleado: undefined })}
          style={{ width: 180, minWidth: 120 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {niveles.map(({ id, desc }) => (
            <Select.Option key={id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={isLoading || !nivel}
          mode="default"
          size="small"
          placeholder="Empleado"
          value={empleados.length ? empleado : null}
          onChange={empleado => setFilter({ empleado })}
          style={{ width: 200, minWidth: 120 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {empleados.map(({ codEmpleado, nombreEmpleado }) => (
            <Select.Option key={codEmpleado} value={codEmpleado}>
              {nombreEmpleado}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={isLoading}
          mode="default"
          size="small"
          placeholder="Nº grupo"
          value={nGroups}
          onChange={nGroups => setFilter({ nGroups })}
          style={{ width: 120 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          <Select.Option key={-1} value={undefined}>
            ...
          </Select.Option>
          <Select.Option key={'grp0'} value={'grp0'}>
            0 conseguidos
          </Select.Option>
          {gruposSat.map((value, index) => (
            <Select.Option key={`grp${index + 1}`} value={`grp${index + 1}`}>
              {index + 1} conseguido{index !== 1 && 's'}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={isLoading}
          mode="default"
          size="small"
          placeholder="Conseguido"
          value={minOk}
          onChange={minOk => setFilter({ minOk })}
          style={{ width: 130, minWidth: 120 }}
          dropdownStyle={{ zIndex: 999 }}
          showArrow={true}
        >
          {minOkList.map(({ id, desc }) => (
            <Select.Option key={!id ? -1 : id} value={id}>
              {desc}
            </Select.Option>
          ))}
        </Select>
        <Button
          disabled={
            !Object.values(filter).some(theFilter =>
              Array.isArray(theFilter) ? theFilter.length : theFilter,
            )
          }
          type="link"
          size="large"
          onClick={clearFilter}
        >
          Resetear filtros
        </Button>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div {
    margin: 0 5px;
  }
`
