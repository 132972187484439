import React from 'react'
import moment from 'moment'
import {
  FlexCol,
  FlexRow,
  DescriptionInner,
  ObjectiveDetailLabel,
  ObjectiveDetailCategories,
  ObjectiveDetailSubtitle,
  ObjectiveDetailDescription,
  ObjectiveDetail,
} from './shared'

import { ExceptionsLabel } from 'Components/Objectives/ExceptionsEllipse'

import {
  Collapse,
  CollapseHeader,
  ExceptionsEllipse,
  ObjectiveButtonList,
  ObjectiveWarning,
  Panel,
} from '.'
import {
  showObjectiveDeleteConfirm,
  showObjectiveCopyConfirm,
} from 'Components/Modals'
import { useMutation } from '@apollo/client'
import { DELETE_OBJECTIVE } from 'Queries'
import { Modal } from 'antd'

const getCategoriesString = arr => {
  const newArr = arr.slice(0, 10)
    .map(({ valores }) => {
      return valores.map(({ nombre }) => nombre).join(', ')
    })
    .join(', ')
  return `${newArr} ${arr.length === 11 ? '...' : ''}`
}

const getExceptionsString = arr => {
  const newArr = arr.slice(0, 10).map(({ desc }) => desc).join(', ')
  return `${newArr} ${arr.length === 11 ? '...' : ''}`
}

export const Objective = ({
  objective,
  codDistribuidor,
  refetchObjectives,
  setIsRefetch,
  navigate,
}) => {
  const {
    ano,
    codObjetivo,
    estado,
    nombre,
    modifEn,
    medida,
    cuandoEvaluar,
    periodoVigencia,
    categoriasSeleccionadasArticulo,
    categoriasSeleccionadasExcepciones,
    categoriasSeleccionadasCliente,
    idDesc = 'not received',
    comoDesc = 'not received',
    ajustado = true,
    withException = false,
    withExceptionDesc,
  } = objective

  const objectiveDataSource = [
    { label: 'ID', description: idDesc },
    {
      label: 'Qué', description: (
        <div>
          <DescriptionInner>{`${medida.desc} (${medida.unidad})`}</DescriptionInner>
          {withException && (
            <DescriptionInner style={{ fontSize: 14 }}>
              {withExceptionDesc}
            </DescriptionInner>
          )}
        </div>
      ),
    },
    {
      label: 'Cómo',
      description: (
        <div>
          <DescriptionInner>{comoDesc}</DescriptionInner>
          {ajustado && <ObjectiveWarning />}
        </div>
      ),
    },
    {
      label: 'Cuándo',
      description: `Desde ${moment(periodoVigencia.fechaInicio).format(
        'DD MMMM YYYY',
      )} hasta ${moment(periodoVigencia.fechaFin).format('DD MMMM YYYY')}. ${cuandoEvaluar.desc
        }`,
    },
  ]

  const variables = {
    id: {
      codDistribuidor,
      ano,
      codObjetivo,
    },
  }

  const [deleteObjective] = useMutation(
    DELETE_OBJECTIVE, 
  {
    variables,
    onCompleted: () => {
      Modal.destroyAll();
      //setIsRefetch(true); // se muestra el spinner
      setIsRefetch(false); // no se muestra el spinner
      refetchObjectives();
      //navigate('/dashboard/objectives/gestion');
      //window.location.reload(true);
    },
  })

  const copyObjective = () =>
    navigate(`/objective/copy/${codObjetivo}/${ano}/definition`)

  return (
    <Collapse expandIconPosition="end" onChange={() => { }}>
      <Panel header={<CollapseHeader title={nombre} date={modifEn} estado={estado} medida={medida} />}>
        <FlexRow>
          <FlexCol span={12} style={{ borderRight: 'solid 1px #eaeaea' }}>
            {objectiveDataSource.map(({ label, description }, index) => (
              <ObjectiveDetail key={index}>
                <ObjectiveDetailLabel>{label}</ObjectiveDetailLabel>
                <ObjectiveDetailDescription>
                  {description}
                </ObjectiveDetailDescription>
              </ObjectiveDetail>
            ))}
          </FlexCol>
          <FlexCol span={12}>
            {/* artículos */}
            <ObjectiveDetailSubtitle>
              <ObjectiveDetailLabel>Artículos</ObjectiveDetailLabel>
            </ObjectiveDetailSubtitle>

            <ObjectiveDetailCategories>
              <ExceptionsEllipse collapsed>
                {getCategoriesString(
                  categoriasSeleccionadasArticulo.categorias,
                )}
              </ExceptionsEllipse>
            </ObjectiveDetailCategories>

            <ExceptionsLabel>Deseleccionados:</ExceptionsLabel>
            <ExceptionsEllipse collapsed>
              {getExceptionsString(categoriasSeleccionadasArticulo.excepciones)}
            </ExceptionsEllipse>

            {/* Excepciones */}
            {withException && (
              <>
                <ObjectiveDetailSubtitle>
                  <ObjectiveDetailLabel>Excepciones</ObjectiveDetailLabel>
                </ObjectiveDetailSubtitle>

                <ObjectiveDetailCategories>
                  <ExceptionsEllipse collapsed>
                    {getCategoriesString(
                      categoriasSeleccionadasExcepciones.categorias,
                    )}
                  </ExceptionsEllipse>
                </ObjectiveDetailCategories>

                <ExceptionsLabel>Deseleccionados:</ExceptionsLabel>
                <ExceptionsEllipse collapsed>
                  {getExceptionsString(categoriasSeleccionadasExcepciones.excepciones)}
                </ExceptionsEllipse>
              </>
            )}

            {/* Clientes */}
            <ObjectiveDetailSubtitle>
              <ObjectiveDetailLabel>Clientes</ObjectiveDetailLabel>
            </ObjectiveDetailSubtitle>

            <ObjectiveDetailCategories>
              <ExceptionsEllipse collapsed>
                {getCategoriesString(categoriasSeleccionadasCliente.categorias)}
              </ExceptionsEllipse>
            </ObjectiveDetailCategories>

            <ExceptionsLabel>Deseleccionados:</ExceptionsLabel>
            <ExceptionsEllipse collapsed>
              {getExceptionsString(categoriasSeleccionadasCliente.excepciones)}
            </ExceptionsEllipse>
          </FlexCol>
        </FlexRow>
        <ObjectiveButtonList
          onDelete={() => 
            [
              showObjectiveDeleteConfirm(nombre, deleteObjective, flag => {
                if (flag) {
                  setIsRefetch(true);
                  deleteObjective();
                }
              }),
            ]
          }
          onCopy={() =>
            ajustado
              ? showObjectiveCopyConfirm(nombre, copyObjective)
              : copyObjective()
          }
          onEdit={() =>
            navigate(`/objective/edit/${codObjetivo}/${ano}/definition`)
          }
        />
      </Panel>
    </Collapse>
  )
}
