import gql from 'graphql-tag'
import {
  OBJECTIVE_KPI_FRAGMENT,
  OBJECTIVE_KPI_VALUES_FRAGMENT,
  OBJECTIVE_KPI_GLOBAL_FRAGMENT,
} from 'Queries/ObjectivesKpiFragments'

export const OBJECTIVE_KPI = gql`
  query objectiveKpi($codDistribuidor: Long!, $ano: Int, $codEmpleado: Long, $nivel: String) {
    objetivoKpi(codDistribuidor: $codDistribuidor, ano: $ano, codEmpleado: $codEmpleado, nivel: $nivel) {
      ...objectiveKpiFragment
    }
  }
  ${OBJECTIVE_KPI_FRAGMENT}
`

export const OBJECTIVE_KPI_VALUES = gql`
  query ObjectiveKpiValues($id: ObjetivoIdInput, $codEmpleado: Long, $nivel: String) {
    objetivoKpiValores(id: $id, codEmpleado: $codEmpleado, nivel: $nivel) {
      ...objectiveKpiValuesFragment
    }
  }
  ${OBJECTIVE_KPI_VALUES_FRAGMENT}
`

export const OBJECTIVE_KPI_GLOBAL = gql`
  query ObjectiveKpiGlobal($codDistribuidor: Long!, $ano: Int!, $fechaDesde: Long, $fechaHasta: Long, $codObjetivos: [Int]!) {
    objetivoKpiGlobal(codDistribuidor: $codDistribuidor, ano: $ano, fechaDesde: $fechaDesde, fechaHasta: $fechaHasta, codObjetivos:$codObjetivos) {
      ...objectiveKpiGlobalFragment
    }
  }
  ${OBJECTIVE_KPI_GLOBAL_FRAGMENT}
`

export const OBJECTIVE_KPI_LIST_GLOBAL = gql`
  query ObjetivoKpiListGlobal(
    $codDistribuidor: Long!,
    $ano: Int!,
    $fechaDesde: Long,
    $fechaHasta: Long
  ) {
    objetivoKpiListGlobal(
      codDistribuidor: $codDistribuidor, 
      ano: $ano, 
      fechaDesde: $fechaDesde, 
      fechaHasta: $fechaHasta
    ) 
  }
`

export const OBJECTIVE_KPI_MONTH = gql`
  query objectiveKpiMonth($id: ObjetivoIdInput, $codEmpleado: Long, $nivel: String) {
    objetivoKpiMes(id: $id, codEmpleado: $codEmpleado, nivel: $nivel) {
      ...objectiveKpiValuesFragment
      mes
    }
  }
  ${OBJECTIVE_KPI_VALUES_FRAGMENT}
`

export const UPDATE_OBJECTIVE_KPI_TAGS = gql`
  mutation ActualizarEtiquetas(
    $codDistribuidor: Long!
    $currentLabel: String!
    $newLabel: String!
  ) {
    actualizarEtiquetaObjetivos(
      codDistribuidor: $codDistribuidor
      currentLabel: $currentLabel
      newLabel: $newLabel
    )
  }
`
