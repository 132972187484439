import styled from '@emotion/styled'

export const ObjectiveLeftCol = styled.div`
  display: inline-block;
  height: calc(100vh - 205px);
  width: 400px;
  padding: 0px 10px 0px 30px;
  vertical-align: top;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: solid 1px #b1b1b1;
`
