import { colors } from 'Utils'
import styled from '@emotion/styled'

export const HeaderCellWrapper = styled.div(({ isDetail, condensed, isAbsolut }) => ({
  display: 'inline-block',
  userSelect: 'none',
  padding: isAbsolut ? '10px 5px 10px 10px' : '10px 20px',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.25,
  color: colors.pine,
  width: isAbsolut ? 125 : isDetail ? 90 : condensed ? 90 : 110,
  marginRight: condensed ? 5 : 0,
  boxShadow: condensed ? '0 4px 12px 0 rgba(200, 198, 198, 0.5)' : 'none',
  height: isAbsolut ? 55 : 36,
  background: isDetail ? 'white' : '#e3f0c8',
  textAlign: isAbsolut && 'left'
}))
