import React, { useContext, useState } from 'react'

import {
  EmployeeTree,
  Row,
  ResourceWrapper,
  PageTitle,
  EmployeeList,
  AddEmployeeForm,
  MoveEmployeeForm
} from 'Components'
import {
  GET_COMMERCIAL_HIERARCHY,
  DELETE_EMPLOYEE,
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  MOVE_EMPLOYEE,
  SIGN_UP_EMPLOYEE,
  DROP_EMPLOYEE,
  REACTIVATE_EMPLOYEE,
} from 'Queries'
import { GlobalContext } from 'Utils'

import { Button } from 'antd'
import { Icon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { useQuery, useMutation } from '@apollo/client'
import { get, isEmpty } from 'lodash'
import { ReactComponent as AddWhiteIcon } from 'Assets/icons/add-white-icon.svg'

export const CommercialHierarchy = () => {
  const context = useContext(GlobalContext)
  const { codDistribuidor, name, email } = get(context, 'currentUser')
  const [isListVisible, setIsListVisible] = useState('')

  const [modalProps, setModalProps] = useState({
    visible: false,
    copyable: false,
    okText: 'Añadir',
  })
  const [moveModalProps, setMoveModalProps] = useState({
    visible: false,
  })

  const variables = {
    codDistribuidor,
  }
  const queryObject = {
    query: GET_COMMERCIAL_HIERARCHY,
    variables,
  }

  const mutationVars = {
    variables,
    update: (cache, { data }) => {
      const { categoriasEspecialistas } = cache.readQuery(queryObject)
      cache.writeQuery({
        ...queryObject,
        data: { categoriasEspecialistas, ...data },
      })
    },
    onCompleted: context.refetchUser,
  }

  const [deleteEmployee] = useMutation(DELETE_EMPLOYEE, mutationVars)
  const [editEmployee] = useMutation(EDIT_EMPLOYEE, mutationVars)
  const [addEmployee] = useMutation(ADD_EMPLOYEE, mutationVars)
  const [moveEmployee] = useMutation(MOVE_EMPLOYEE, mutationVars)
  const [signUpEmployee] = useMutation(SIGN_UP_EMPLOYEE, {
    ...mutationVars,
    onCompleted: null,
  })
  const [dropEmployee] = useMutation(DROP_EMPLOYEE, {
    ...mutationVars,
    onCompleted: null,
  })
  const [reactivateEmployee] = useMutation(REACTIVATE_EMPLOYEE, {
    ...mutationVars,
    onCompleted: null,
  })

  const resource = useQuery(GET_COMMERCIAL_HIERARCHY, {
    fetchPolicy: 'cache-and-network',
    variables,
    onCompleted: () => {
      context.resetKeepAliveTimer()
    },
  })

  const { comercialesSinAsignar, comercialesActivos, jerarquia } =
    get(resource, 'data.jerarquiaComercial') || {}
  const specialistCategories =
    get(resource, 'data.categoriasEspecialistas') || []

  return (
    <ResourceWrapper resource={resource}>
      <EmployeeList
        dataSource={eval(isListVisible)}
        onClose={() => setIsListVisible('')}
        isOpen={!!isListVisible}
      />
      <Row type="flex" justify="space-between">
        <PageTitle>Jerarquía comercial</PageTitle>
        <Buttons>
          {!isEmpty(comercialesActivos) && (
            <Button onClick={() => setIsListVisible('comercialesActivos')}>
              {comercialesActivos.length} usuarios activos
              <IconWrapper type="right"></IconWrapper>
            </Button>
          )}
          {!isEmpty(comercialesSinAsignar) && (
            <Button onClick={() => setIsListVisible('comercialesSinAsignar')}>
              {comercialesSinAsignar.length} comerciales sin asignar
              <IconWrapper type="right"></IconWrapper>
            </Button>
          )}
          {isEmpty(comercialesSinAsignar) && (
            <EmptyEmployeeList>
              Todos los comerciales están asignados
            </EmptyEmployeeList>
          )}
        </Buttons>
      </Row>
      {!jerarquia && (
        <Button
          type="primary"
          onClick={() => {
            setModalProps({
              ...modalProps,
              name,
              email,
              copyable: false,
              showProfileTypeSelection: false,
              showAddComercial: false,
              title: 'Añadir perfil',
              subtitle: 'Gerente',
              visible: true,
              profileType: 'gerente',
              submit: addEmployee,
              okText: 'Añadir',
            })
          }}
        >
          <Icon style={{ verticalAlign: 'bottom' }} component={AddWhiteIcon} />
          Añadir gerente
        </Button>
      )}
      <div style={{ padding: 20, height: 'calc(100vh - 160px)', overflowY: 'auto' }}>
        <EmployeeTree
          dataSource={jerarquia}
          actions={{
            delete: deleteEmployee,
            showEditPopup: props =>
              setModalProps({
                ...modalProps,
                ...props,
                specialistCategories,
                isEdit: true,
                title: 'Actualizar perfil',
                okText: 'Actualizar',
                visible: true,
                submit: editEmployee,
                signUp: props.activo !== null ? dropEmployee : signUpEmployee,
              }),
            showAddPopup: props =>
              setModalProps({
                ...modalProps,
                ...props,
                comercialesSinAsignar,
                specialistCategories,
                title: 'Añadir perfil',
                okText: 'Añadir',
                visible: true,
                isEdit: false,
                submit: addEmployee,
              }),
            showMoveModal: props =>
              setMoveModalProps({
                ...props,
                visible: true,
                warning: true,
                submit: moveEmployee,
                title: 'Mover perfil',
                okText: 'Mover',
              }),
            lockUnlock: props =>
              props.activo
                ? dropEmployee({
                  variables: {
                    codEmpleado: props.codMiembro,
                    nivel: props.nivel,
                    drop: false,
                  },
                })
                : reactivateEmployee({
                  variables: { codEmpleado: props.codMiembro },
                }),
          }}
        />
      </div>
      <AddEmployeeForm props={modalProps} setProps={setModalProps} />
      <MoveEmployeeForm props={moveModalProps} setProps={setMoveModalProps} />
    </ResourceWrapper>
  )
}

const IconWrapper = styled(Icon)`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.65);
`
const EmptyEmployeeList = styled.span`
  font-size: 14px;
  line-height: 34px;
  color: #989898;
`
const Buttons = styled.div`
  margin-right: 16px;
  button:nth-child(2) {
    margin-top: 16px;
    margin-left: 10px;
  }
`
