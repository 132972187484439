import React, { useContext, useState, useEffect } from 'react'

import { DetailsPageTitleSection, PageErrorWarning, ReportEmbed, SaturationReport, Spinner, SummaryReport, ReportWrapper } from 'Components'
import { ActionPlan, ObjectiveSummary } from 'Fragments'
import { GET_REPORT_URL, OBJECTIVE_HEADER } from 'Queries'
import { GlobalContext, checkRolePermit, ObjectiveContextProvider } from 'Utils'

import { Tabs as AntTabs, Button, Layout as AntLayout } from 'antd'
import styled from '@emotion/styled'
import { get, isEmpty } from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/client'

const { TabPane } = AntTabs

export const ObjectiveReport = ({
  navigate,
  id: codObjetivo,
  year,
  month = null,
}) => {
  const context = useContext(GlobalContext)
  const { isDev } = context
  const codDistribuidor = get(context, 'currentUser.infoHesa') !== null ? get(context, 'currentUser.infoHesa.codDistribuidor') : get(context, 'currentUser.codDistribuidor')
  const profileId = get(context, 'currentUser.profile.id') || null
  const roles = get(context, 'currentUser.roles')
  const isHesa = get(context, 'currentUser.infoHesa.isHesa') || false

  const [objective, setObjective] = useState({})
  const [resource, setResource] = useState({})
  const [littleTabs, setLittleTabs] = useState(false)

  const canCallKpis = (profileId !== null && codDistribuidor !== null) || (isHesa && codDistribuidor !== null)

  const hasActionPlan =
    isDev ||
    checkRolePermit(roles, 'SeguimientoObjetivoPlanAccionResumen') ||
    checkRolePermit(roles, 'SeguimientoObjetivoPlanAccionPdvs')
  const isDesktop = checkRolePermit(roles, 'Desktop')
  const seguimiento = checkRolePermit(
    roles,
    'SeguimientoObjetivoGerenciaComercialSAC',
  )

  const variables = {
    id: {
      codDistribuidor,
      ano: Number(year),
      codObjetivo: Number(codObjetivo),
    },
    mes: Number(month),
  }

  const [getObjReport] = useLazyQuery(GET_REPORT_URL, {
    fetchPolicy: 'network-only',
    skip: isDev || !canCallKpis,
    onCompleted: (data) => {
      setResource(data)
      context.resetKeepAliveTimer()
    }
  })

  useQuery(OBJECTIVE_HEADER, {
    fetchPolicy: 'network-only',
    skip: isDev || !canCallKpis,
    variables: {
      id: variables.id,
    },
    onCompleted: ({ objective }) => {
      setObjective(objective)
      context.resetKeepAliveTimer()
    },
  })

  const checkTabWidth = () => {
    setTimeout(() => {
      const availableWidth = document.querySelector('.wrapper').offsetWidth
      const tabsWidth = 550
      const dropDownWidth = 520
      setLittleTabs(availableWidth - tabsWidth < dropDownWidth)
    }, 300)
  }

  useEffect(() => {
    checkTabWidth()
    window.addEventListener('resize', checkTabWidth)
    return () => window.removeEventListener('resize', checkTabWidth)
  }, [])

  if (isDev) {
    const { mapeoObjetivoSACRes } = require('Mockups/ActionPlan')
    resource.data = mapeoObjetivoSACRes.data
    resource.error = null
  }

  const goBack = () => {
    const d = new Date();
    let time = d.getTime();
    if (objective.periodoVigencia.fechaFin < time) {
      navigate(`/dashboard/objectives/follow-up/inactive/${year}`)
    } else {
      navigate('/dashboard/objectives/follow-up/active')
    }
  }

  const nombre = get(objective, 'nombre') || ''
  const idMedida = get(objective, 'medida.id') || ''
  const idtipoValorRef = get(objective, 'tipoValorRef.id') || ''
  const canales = get(objective, 'canales') || ''

  const urlGerencia = get(resource, 'mapeoObjetivoSAC.urlGerencia') || ''
  const urlComercial = get(resource, 'mapeoObjetivoSAC.urlComercial') || ''
  const mapeoObjetivoSAC = get(resource, 'mapeoObjetivoSAC')
  const {
    fechaDesde = null,
    fechaHasta = null,
    comerciales = [],
    pdvSituacion = [],
  } = mapeoObjetivoSAC || {}

  const notFollowTabs = (idtipoValorRef !== '' && idtipoValorRef !== 'E') && (idMedida !== '' && (idMedida !== 'N' && idMedida !== 'A' && idMedida !== 'S'))
  const notActionTabs = idMedida === 'A' || idMedida === 'S'

  useEffect(() => {
    if ((idMedida !== '' && idMedida !== 'A' && idMedida !== 'S') && isEmpty(resource)) {
      getObjReport({ variables })
    }
  }, [idMedida])

  return (
    <Layout>
      <AntLayout.Content>
        <Wrapper className="wrapper">
          {!canCallKpis ? PageErrorWarning(
            'No tiene acceso a esta página actualmente',
            (<> Diríjase a <a style={{ fontWeight: 'bold' }} href="/">Seguimiento</a> y seleccione un distribuidor para poder visualizar el contenido</>)
          ) : (<div style={{ height: '100%' }}>
            <DetailsPageTitleSection onBack={goBack} title={nombre || ''}>
              {((idMedida !== '' && idMedida !== 'A' && idMedida !== 'S') && month) && (
                <Button
                  type="primary"
                  style={{ position: "absolute", top: 10, right: 128 }}
                  onClick={() =>
                    navigate(
                      `/dashboard/objectives/follow-up/monthly/${codObjetivo}/${year}`,
                    )
                  }
                >
                  Histórico
                </Button>
              )}
            </DetailsPageTitleSection>
            {idMedida === '' && <Spinner />}
            {(idMedida !== '' && !isEmpty(resource)) && (
              <StyledTabs size="small" defaultActiveKey="2" className="parentTabs">
                {isDesktop && seguimiento && (urlGerencia || urlComercial) && notFollowTabs && canales.length === 0 &&(
                  <TabPane
                    tab={littleTabs ? 'SEG. OBJ.' : 'SEGUIMIENTO OBJETIVO'}
                    key="1"
                  >
                    <ReportWrapper reportHeight={170}>
                      <ReportEmbed
                        currentUrl={process.env.REACT_APP_DASHBOARD_URI}
                        dashboardId={process.env.REACT_APP_DASHBOARD_ID_GOAL_TRACKING}
                        hSize={200}
                        params={variables.id}
                      />
                    </ReportWrapper>
                  </TabPane>
                )}
                {hasActionPlan && mapeoObjetivoSAC && (
                  <TabPane
                    tab={littleTabs ? 'PLAN ACCIÓN' : 'PLAN DE ACCIÓN'}
                    key="2"
                  >
                    <ActionPlan
                      codDistribuidor={codDistribuidor}
                      idObjetivo={variables.id}
                      fechaDesde={fechaDesde}
                      fechaHasta={fechaHasta}
                      comerciales={comerciales}
                      pdvSituacion={pdvSituacion}
                      idMedida={idMedida}
                      month={month}
                      year={year}
                    />
                  </TabPane>
                )}
                <TabPane
                  tab={littleTabs ? 'RES. OBJ.' : 'RESUMEN OBJETIVO'}
                  key="3"
                >
                  <SummaryReport
                    codDistribuidor={codDistribuidor}
                    codObjetivo={codObjetivo}
                    year={year}
                    isHesa={isHesa}
                  />
                </TabPane>
              </StyledTabs>
            )}
            {(idMedida !== '' && isEmpty(resource) && notActionTabs) && (
              <StyledTabs size="small" className="parentTabs">
                {idMedida === 'S' && (<TabPane
                  tab={littleTabs ? 'RES. SAT.' : 'RESUMEN SATURACIÓN'}
                  key="1"
                >
                  <SaturationReport
                    codDistribuidor={codDistribuidor}
                    codObjetivo={codObjetivo}
                    year={year}
                  />
                </TabPane>)}
                <TabPane
                  tab={littleTabs ? 'RES. OBJ.' : 'RESUMEN OBJETIVO'}
                  key="3"
                >
                  <SummaryReport
                    codDistribuidor={codDistribuidor}
                    codObjetivo={codObjetivo}
                    year={year}
                    isHesa={isHesa}
                  />
                </TabPane>
              </StyledTabs>)}
          </div>)}
        </Wrapper>
      </AntLayout.Content>
      <ObjectiveContextProvider value={{ objective }}>
        <ObjectiveSummary readOnly />
      </ObjectiveContextProvider>
    </Layout>
  )
}

const Wrapper = styled.div`
  min-width: 868px;
  padding: 0;
`
const Layout = styled(AntLayout)`
  position: relative;
  height: 100%;  
  width: 100%;  
  background: none;
  .ant-layout-content {
    padding: 0;
  }
`
const StyledTabs = styled(AntTabs)`
  .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-left: 15px;
  }
  .ant-tabs-tab {
    padding: 10px 15px;
    font-size: 17px;
  }
  .ant-tabs-nav {
    margin: 0;
  }
`