import React, { useState } from 'react'

import { Content } from 'Components'
import { Header, Sider } from 'Fragments'

import { Layout } from 'antd'

export const Dashboard = ({ children, isFetching }) => {
  const [collapsed, setCollapsed] = useState(false)
  const fullwidth = window.location.href.includes('management-dashboard') ? 'B' : 'C'

  return (
    <Layout>
      <Header />
      <Sider
        collapsed={collapsed ? 1 : 0}
        setCollapsed={setCollapsed}
        isFetching={isFetching}
      />
      <Content collapsed={collapsed ? 1 : 0} fullwidth={fullwidth}>
        {children}
      </Content>
    </Layout>
  )
}
