import styled from '@emotion/styled'
import { colors } from 'Utils'

export const OpCellWrapper = styled.div(
  ({ isDetail, isDisabled, background, isAbsolut }) => ({
    fontsize: 13,
    userSelect: 'none',
    padding: isAbsolut ? '10px 25px 10px 15px' : '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: isAbsolut ? 160 : !isDetail ? 240 : 140,
    height: 115,
    background: (background || isDisabled) ? background : isDetail ? 'white' : '#e3f0c8',
    borderBottom: `1px solid ${colors.lime}`,
    boxShadow: (isDetail || isDisabled) ? 'none' : '0 4px 12px 0 rgba(200, 198, 198, 0.5)',
  }),
)
