import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import styled from '@emotion/styled'
import { formatNumber } from 'Utils'

export const CellInput = ({
  value = 0,
  percentage,
  onChange,
  suffix = '',
  onlyPositive = false,
  updateOnChange = false,
  paValue = 1,
  doWholeNumber = false
}) => {
  const [inputValue, setInputValue] = useState(value || '')

  useEffect(() => {
    setInputValue(value % 1 !== 0 ? value.toFixed(2) : value)
  }, [value])

  const onBlur = e => {
    const newValue = parseFloat(e.target.value.replace(',', '.'))
    if (!isNaN(newValue) && newValue !== value) {
      onChange(newValue)
    } else {
      setInputValue(value)
    }
  }

  function isWholeNumber(valorRef = 0, pa) {
    let newValue = parseFloat(valorRef.toString().replace(',', '.'));
    let inc = Math.ceil((newValue * pa) / 100);
    let percInc = (inc * 100) / (pa === 0 ? 1 : pa)

    var with2Decimals = percInc.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]

    return parseFloat(with2Decimals);
  }

  return (
    <Inner>
      <Input
        value={inputValue.toString().replace('.', ',')}
        onChange={e => {
          const value = formatNumber(e.target.value, percentage, onlyPositive)
          setInputValue(value)
          if (updateOnChange) onChange(value)
        }}
        onBlur={e => {
          if (doWholeNumber) { e.target.value = isWholeNumber(e.target.value, paValue) }
          onBlur(e)
        }
        }
        suffix={suffix}
      />
    </Inner>
  )
}

const Inner = styled.div`
  margin-bottom: 7px;
  font-size: 13px;
  .ant-input-affix-wrapper {
    padding: 4px 4px;
  }
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding: 0 10px 0 8px;
  }
  .ant-input-affix-wrapper .ant-input-suffix {
    right: 8px;
  }
`
