import React from 'react'
import styled from '@emotion/styled'
import { Progress as ProgressAnt } from 'antd'
import { ReactComponent as ProgressIndicator } from 'Assets/icons/progress-indicator.svg'

export const ProgressBar = ({ completed, target, showIndicator }) => {
  return (
    <Inner>
      <Progress
        percent={completed}
        size="default"
        strokeLinecap="square"
        strokeColor={completed >= target ? '#3e9a3e' : '#de4d55'}
        showInfo={false}
      />
      {showIndicator && completed < 100 && (
        <Indicator percent={target}></Indicator>
      )}
    </Inner>
  )
}

const Inner = styled.div`
  position: relative;
  max-width: 272px;
  height: 30px;
`
const Indicator = styled(ProgressIndicator)`
  position: absolute;
  top: 0;
  left: ${props => (props.percent * 272) / 100 - 8.5}px;
`
const Progress = styled(ProgressAnt)`
  .ant-progress-inner {
    border-radius: initial;
    background-color: #d6d6d6;
  }
`
