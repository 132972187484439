import React, { useContext, useState, useEffect } from 'react'

import {
  ObjectiveCategoryList,
  ObjectiveCategoryValueList,
  ObjectiveLeftCol,
  ObjectiveRightCol,
  SelectionTag,
  TagContainer,
  showAdjustmentsRecalcConf,
} from 'Components'
import { ClientsTable } from 'Fragments'
import {
  GET_OBJECTIVE_CATEGORY_CLIENT_VALUES,
  GET_OBJECTIVE_CLIENT_CATEGORIES,
  UPDATE_OBJECTIVE_CLIENTE_CATEGORIES,
  UPDATE_OBJECTIVE_CLIENTE_EXCEPTIONS,
  OBJECTIVE,
  GET_CLIENTS,
} from 'Queries'
import {
  GlobalContext,
  ObjectiveContext,
  ObjectiveContextConsumer,
  getMutationVars,
  getObjectiveIdVars,
  removeValueFromCategories,
  removeCategoryFromCategories,
  addValueToCategories,
  addCategoryToCategories,
  categoriesCount,
} from 'Utils'

import { isEmpty, isNull, get, without, concat, cloneDeep } from 'lodash'
import { useMutation } from '@apollo/client'

export const ObjectiveClients = () => (
  <ObjectiveContextConsumer>
    {({ objective }) =>
      !Number.isFinite(objective.codObjetivo) ? null : (
        <Clients objective={objective} />
      )
    }
  </ObjectiveContextConsumer>
)

const Clients = ({ objective }) => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { resetKeepAliveTimer, setObjective, setHasClients, setIsLoading } = context

  const selectedCategories =
    get(objective, 'categoriasSeleccionadasCliente.categorias') || []
  const exceptions =
    get(objective, 'categoriasSeleccionadasCliente.excepciones') || []

  const [extraExceptionHash, setExtraExceptionHash] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [numClients, setClients] = useState(0)
  const [clientList, setClientsList] = useState([])

  useEffect(() => {
    setHasClients(numClients - exceptions.length > 0)
  }, [numClients, exceptions])

  const onCompleted = ({ objective }) => {
    resetKeepAliveTimer()
    setObjective(objective)
    setIsLoading(false)
  }

  const [selectCategory] = useMutation(
    UPDATE_OBJECTIVE_CLIENTE_CATEGORIES,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  )

  const [recalcularAjustes, setRecalcularAjustes] = useState(null)

  const updateSelectedCategory = (selectedCategories, recalcularAjustes) => {
    let formattedCategories = cloneDeep(selectedCategories);
    if (formattedCategories.length) {
      formattedCategories[0].valoresSeleccionados.forEach((value, index) => {
        formattedCategories[0].valoresSeleccionados[index] = value.split("###")[0].trim();
      })
    }
    selectCategory({
      variables: {
        selectedCategories: formattedCategories,
        recalcularAjustes,
      },
    })
  }

  const showSelectedCategoryConfirm = selectedCategories => {
    if (!objective.draft && objective.ajustado && isNull(recalcularAjustes)) {
      showAdjustmentsRecalcConf(flag => {
        setRecalcularAjustes(!flag)
        updateSelectedCategory(selectedCategories, !flag)
      })
    } else {
      updateSelectedCategory(selectedCategories, recalcularAjustes || false)
    }
  }

  const removeCategoryValue = (id, value) =>
    showSelectedCategoryConfirm(
      removeValueFromCategories(selectedCategories, id, value),
    )

  const removeAllCategoryValues = id =>
    showSelectedCategoryConfirm(
      removeCategoryFromCategories(selectedCategories, id),
    )

  const removeAllCategories = () => showSelectedCategoryConfirm([])

  const addCategoryValue = (id, value) =>
    showSelectedCategoryConfirm(
      addValueToCategories(selectedCategories, id, value),
    )

  const addAllCategoryValues = (id, values) =>
    showSelectedCategoryConfirm(
      addCategoryToCategories(selectedCategories, id, values),
    )

  const removeAllExceptions = () => updateSelectedException(true)

  const addAllExceptions = () => updateSelectedException(false)

  const updateSelectedException = (selected) => {
    let newList = []

    if (!selected) newList = clientList.map(cli => cli.codCliente)

    return setExceptions({
      variables: {
        excepcionesSeleccionadas: selected
          ? []
          : newList,
        recalcularAjustes: true
      },
    }).then(() => {
      if (selected) setExtraExceptionHash(extraExceptionHash + '#')
    })
  }

  const [setExceptions] = useMutation(
    UPDATE_OBJECTIVE_CLIENTE_EXCEPTIONS,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  )

  const selectionIds = exceptions.map(exception => exception.codigo)

  const onSelectionChange = (record, selected, recalcularAjustes) => {
    return setExceptions({
      variables: {
        excepcionesSeleccionadas: selected
          ? without(selectionIds, record.codCliente)
          : concat(selectionIds, record.codCliente),
        recalcularAjustes,
      },
    }).then(() => {
      if (selected) setExtraExceptionHash(extraExceptionHash + '#')
    })
  }

  const showSelectionChangeConfirm = (record, selected) => {
    if (!objective.draft && objective.ajustado && isNull(recalcularAjustes)) {
      showAdjustmentsRecalcConf(flag => {
        setRecalcularAjustes(!flag)
        onSelectionChange(record, selected, !flag)
      })
    } else {
      onSelectionChange(record, selected, recalcularAjustes || false)
    }
  }

  const selectedCategorieHash =
    selectedCategories
      .map(
        ({ categoria, valores }) =>
          categoria.id + valores.map(({ nombre }) => nombre).join(''),
      )
      .join('') + extraExceptionHash

  return (
    <div style={{ paddingTop: 23, width: '100vw' }}>
      <ObjectiveLeftCol>
        {!selectedCategory && (
          <ObjectiveCategoryList
            onChange={setSelectedCategory}
            query={GET_OBJECTIVE_CLIENT_CATEGORIES}
          />
        )}

        {selectedCategory && (
          <ObjectiveCategoryValueList
            selectedCategory={selectedCategory}
            selectedCategories={selectedCategories}
            setSelectedCategory={setSelectedCategory}
            query={GET_OBJECTIVE_CATEGORY_CLIENT_VALUES}
            addCategoryValue={addCategoryValue}
            removeCategoryValue={removeCategoryValue}
            removeAllCategoryValues={removeAllCategoryValues}
            addAllCategoryValues={addAllCategoryValues}
            selectedCategorieHash={selectedCategorieHash}
          />
        )}
      </ObjectiveLeftCol>
      <ObjectiveRightCol>
        <TagContainer
          title={`Categorías seleccionadas (${categoriesCount(selectedCategories)})`}
          deleteAll={!isEmpty(selectedCategories)}
          onDeleteAll={removeAllCategories}
        >
          <SelectionTag>Clientes de los comerciales</SelectionTag>{' '}
          {selectedCategories.map(category =>
            category.valores.map(value => (
              <SelectionTag
                key={category.categoria.id + '_' + value.nombre}
                onDelete={() =>
                  removeCategoryValue(category.categoria.id, value.nombre)
                }
              >
                {value.nombre.split("###")[0]} {value.nombre.split("###")[1]}
              </SelectionTag>
            )),
          )}
        </TagContainer>
        <TagContainer
          title={`Deseleccionados (${exceptions.length})`}
          deleteAll={!isEmpty(exceptions)}
          onDeleteAll={removeAllExceptions}
        >
          {!isEmpty(exceptions) &&
            exceptions.map(({ desc, codigo }) => (
              <SelectionTag
                key={codigo}
                isException
                onDelete={() =>
                  showSelectionChangeConfirm({ codCliente: codigo }, true)
                }
              >
                {desc}
              </SelectionTag>
            ))}
        </TagContainer>
        <ClientsTable
          query={GET_CLIENTS}
          objectiveId={getObjectiveIdVars(context)}
          onSelectionChange={showSelectionChangeConfirm}
          selectedCategorieHash={selectedCategorieHash}
          selectionIds={selectionIds}
          showSearch={true}
          sortBy="nombre"
          sortDir="asc"
          showDate={false}
          expandable={true}
          searchPlaceholder="Buscar en clientes seleccionados"
          onQueryCompleted={setClients}
          exceptions={exceptions}
          removeAllExceptions={removeAllExceptions}
          addAllExceptions={addAllExceptions}
          numClients={numClients}
          clientList={clientList}
          setClientsList={setClientsList}
        />
      </ObjectiveRightCol>
    </div>
  )
}
