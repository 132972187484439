export const getNameInitials = s => {
  if (!s) return 'A'

  // This isn't working in safari ...
  // return [...s.matchAll(/(\S)[^\s]*/g)].map(arr => arr[1]).join('')
  return (s || 'A')
    .split(' ')
    .filter(s => s) // remove empty
    .map(s => s.charAt(0).toUpperCase())
    .splice(0, 3)
    .join('')
}

export const sentenceCase = s => {
  return s[0].toUpperCase() + s.substr(1).toLowerCase()
}

export const ellipsis = (string, length) => {
  if (!string) return ''
  if (!length) return string
  return `${string.substring(0, length)}...`
}
