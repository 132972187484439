import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Radio, Select } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { FormLabel } from './'
import { Modal, ColapsableText } from 'Components'
import { isEmpty } from 'lodash'

// eslint-disable-next-line
const emailRE = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const AddEmployeeForm = ({ props, setProps }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [isCopy, setIsCopy] = useState(false)
  const [profileType, setProfileType] = useState(null)
  const [specialistCategories, setSpecialistCategories] = useState([])
  const [codComercial, setCodeComercial] = useState(null)
  const [isSubmitDisabled, setSubmitDisabled] = useState(false)

  const isCommercial = profileType === 'comercial'
  const isGerente = profileType === 'gerente'

  useEffect(() => {
    setName(props.name || '')
    setEmail(props.email || '')
    setPhone(props.phone || '')
    setSpecialistCategories(props.categorias || [])
    setProfileType(props.profileType || null)
  }, [props])

  const afterSubmit = () => {
    setProps({ visible: false })
    setIsCopy(false)
    setName('')
    setEmail('')
    setPhone('')
    setProfileType(null)
    setSpecialistCategories([])
    setCodeComercial(null)
    setSubmitDisabled(false)
  }

  const onSubmit = (_, closeModal = true) => {
    setSubmitDisabled(true)
    const variables = {
      nombre: name,
      email,
      telefono: phone,
      codEmpleado: props.codEmpleado || codComercial || null,
      categoriasEspecialistas: specialistCategories,
      tipoPerfil: profileType,
      codPadre: props.parentCode || null,
    }
    if (isCopy) {
      variables.codEmpleado = props.codEmpleadoCopy
    }
    return props
      .submit({ variables })
      .then(() => {
        if (closeModal) afterSubmit()
      })
      .catch(() => setSubmitDisabled(false))
  }

  const onSignUp = async () => {
    const variables = {
      codEmpleado: props.codEmpleado || codComercial || null,
      nivel: props.activo !== null ? undefined : profileType,
      drop: props.activo !== null ? true : undefined,
    }
    await onSubmit(null, false)
    props
      .signUp({ variables })
      .then(afterSubmit)
      .catch(() => setSubmitDisabled(false))
  }

  const disableSignIn = isSubmitDisabled || !name || !emailRE.test(email)

  const disableSubmit =
    isSubmitDisabled ||
    (isEmpty(name) && profileType !== 'comercial') ||
    isEmpty(profileType) ||
    (profileType === 'especialista' && isEmpty(specialistCategories)) ||
    (isCommercial && isEmpty(codComercial) && !props.readonlyName)

  return (
    <Modal
      title={props.title}
      subtitle={props.subtitle}
      visible={props.visible}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setProps({
              title: props.title,
              subtitle: props.subtitle,
              okText: props.okText,
              visible: false,
            })
            setIsCopy(false)
            setCodeComercial(null)
          }}
        >
          Cancelar
        </Button>,
        props.isEdit && !isGerente && !isCopy && !props.copiaGerente && (
          <SuscribeButton
            key="signup"
            icon={<UserAddOutlined />}
            disabled={disableSignIn}
            title={disableSignIn ? 'email y nombre son obligatorios' : ''}
            onClick={onSignUp}
          >
            Dar de {props.activo !== null ? 'baja' : 'alta'}
          </SuscribeButton>
        ),
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={disableSubmit}
        >
          {props.okText}
        </Button>,
      ].filter(btn => btn)}
    >
      <Form layout="vertical">
        {props.showAddComercial && (
          <>
            <FormItem label="Asignar comercial a">
              <FormItemInfo>{`${props.parentName} (${props.parentLevel})`}</FormItemInfo>
            </FormItem>
            <FormItem label="Comercial">
              <FormItemInfo2>
                Selecciona el perfil del comercial que quieres asignar al jefe
                de equipo.
              </FormItemInfo2>
              <Select
                mode="default"
                getPopupContainer={triggerNode => triggerNode.parentNode}
                size="default"
                value={codComercial}
                onChange={val => setCodeComercial(val)}
                style={{ width: '100%' }}
                showArrow={true}
              >
                {!isEmpty(props.availableEmployees) &&
                  props.availableEmployees.map(({ codMiembro, nombre }) => (
                    <Select.Option key={codMiembro}>{nombre}</Select.Option>
                  ))}
              </Select>
              <ColapsableText
                title="No encuentro el perfil en esta lista"
                subtitle="Estos perfiles provienen de los datos cargados en CLAVE, si no aparece
        el nombre que quieres añadir deberás importar datos en CLAVE que
        incluyan su perfil."
              />
            </FormItem>
          </>
        )}

        {!props.showAddComercial && (
          <FormItem label="Nombre">
            <Input
              disabled={
                isGerente || isCopy || props.copiaGerente || props.readonlyName
              }
              value={name}
              onChange={event => {
                setIsCopy(false)
                setName(event.currentTarget.value)
              }}
              onKeyUp={event =>
                event.keyCode === 13 && !disableSubmit && onSubmit()
              }
            />
            {props.copyable && (
              <FillPerfilButton
                type="link"
                style={{ color: isCopy ? 'red' : 'green' }}
                onClick={() => {
                  setIsCopy(!isCopy)
                  if (
                    !isCopy &&
                    (profileType === 'especialista' || profileType === null)
                  ) {
                    setProfileType('jefe_equipo')
                  }
                  if (isCopy && profileType === 'jefe_equipo')
                    setProfileType(null)
                  setName(isCopy ? '' : props.nameCopy)
                  setEmail(isCopy ? '' : props.emailCopy)
                  setPhone(isCopy ? '' : props.phoneCopy)
                }}
              >
                {isCopy ? 'Desasignar perfil' : 'Asignar perfil a gerente'}
              </FillPerfilButton>
            )}
          </FormItem>
        )}
        {props.showProfileTypeSelection && (
          <FormItem
            label={<FormLabel title="Tipo de perfil" optional={false} />}
          >
            <Radio.Group
              disabled={isCopy}
              onChange={event => setProfileType(event.target.value)}
              value={profileType}
            >
              <Radio value="jefe_equipo" style={radioStyle}>
                Jefe de equipo
              </Radio>
              <Radio value="especialista" style={radioStyle}>
                Especialista
              </Radio>
            </Radio.Group>
          </FormItem>
        )}
        {profileType === 'especialista' && (
          <FormItem label={<FormLabel title="Categorias" optional={false} />}>
            <Select
              mode="multiple"
              allowClear
              size="default"
              disabled={props.isEdit}
              defaultValue={specialistCategories}
              onChange={categories => setSpecialistCategories(categories)}
              style={{ width: '100%' }}
              showArrow={true}
            >
              {props.specialistCategories &&
                props.specialistCategories.map(category => (
                  <Select.Option key={category}>{category}</Select.Option>
                ))}
            </Select>
          </FormItem>
        )}
        <FormItem
          label={
            <FormLabel
              title="Email"
              optional={!(isGerente || isCopy)}
              optionalText={
                !isGerente ? 'obligatorio sólo para dar de alta' : undefined
              }
            />
          }
        >
          <Input
            disabled={
              props.copiaGerente ||
              isCopy ||
              isGerente ||
              (props.edit && props.activo !== null)
            }
            value={email}
            onChange={event => {
              setIsCopy(false)
              setEmail(event.currentTarget.value)
            }}
          />
        </FormItem>
        <FormItem label={<FormLabel title="Teléfono" optional={true} />}>
          <Input
            disabled={isCopy}
            value={phone}
            onChange={event => {
              setIsCopy(false)
              setPhone(event.currentTarget.value)
            }}
          />
        </FormItem>
      </Form>
    </Modal>
  )
}

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    padding-bottom: 0px;
    margin-bottom: 24px;
  }
`
const FillPerfilButton = styled(Button)`
  color: #417505;
  font-size: 12px;
  text-align: right;
  float: right;
  height: 0;
  margin-top: 4px;
`
const SuscribeButton = styled(Button)`
  color: #417505;
`
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}
const FormItemInfo = styled.div`
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #aeaeae;
  margin-top: -3px;
`
const FormItemInfo2 = styled.div`
  font-size: 12px;
  color: #a4a4a4;
  margin-top: -10px;
  margin-bottom: 7px;
`
