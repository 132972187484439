import React from 'react'
import styled from '@emotion/styled'

import { Button as AntButton } from 'antd'
import { Icon } from '@ant-design/compatible'
import { Avatar } from 'Components'

export const Button = ({ size, icon, text, avatar, children, ...rest }) => {
  return (
    <ButtonWrapper size={size} avatar={avatar ? 1 : 0} {...rest}>
      {icon && (
        <Icon
          type={icon}
          size="small"
          style={{ color: 'white', marginRight: 0 }}
        ></Icon>
      )}
      <ButtonText avatar={avatar}>{text || children}</ButtonText>
      {avatar && <Avatar>{avatar}</Avatar>}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled(AntButton)(props => ({
  display: 'flex',
  alignItems: 'center',
  height: 40,
  padding: '0 25px',
}))

const ButtonText = styled.span(props => {
  return {
    margin: 'auto',
    fontSize: 16,
  }
})
