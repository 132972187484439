import React from 'react'
import { Avatar as AntAvatar } from 'antd'
import { colors, levelColors, getNameInitials } from 'Utils'
import styled from '@emotion/styled'

export const UserInfoCell = ({ empleado, nivel, hierarchied = false, isPercentage }) => {
  return (
    <UserInfoCol cellWidth={138} nivel={nivel} hierarchied={hierarchied} isPercentage={isPercentage}>
      {(empleado.codMiembro && (
        <Avatar size={36}>{getNameInitials(empleado.titulo)}</Avatar>
      )) ||
        null}
      <Title withAvatar={empleado.codMiembro && true}>{empleado.titulo}</Title>
      <Subtitle>{empleado.subtitulo}</Subtitle>
    </UserInfoCol>
  )
}

export const Col = styled.div({
  background: 'white',
  userSelect: 'none',
  padding: '11px 10px 0 10px',
})

const Avatar = styled(AntAvatar)({
  margin: '0 0 5px 0',
  '> .anticon': {
    marginRight: 0,
  },
})

const levelWidth = [60, 40, 20, 0]

const UserInfoCol = styled(Col)(({ nivel, hierarchied, isPercentage }) => ({
  width: hierarchied ? `calc(140px + ${levelWidth[nivel]}px)` : 140,
  borderBottom: `1px solid ${colors.lime}`,
  height: isPercentage ? '100px' : '90px',
  background: hierarchied ? colors[levelColors[nivel]] : 'white',
  textAlign: 'left',
}))

const Title = styled.p(({ withAvatar }) => ({
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
  color: colors.pine,
  fontSize: 14,
  lineHeight: 1.14,
  margin: withAvatar ? 0 : '10px 0 0 0',
}))

const Subtitle = styled.p({
  color: '#757575',
  fontSize: 14,
  lineHeight: 1.14,
  margin: 0,
})
