export const formatNumber = (s, decimals, onlyPositive = false) => {
  if (s.indexOf('-') === 0 && !onlyPositive) {
    s = '-' + s.substr(1).replace('-', '')
  } else {
    s = s.replace('-', '')
  }

  s = s.replace('.', ',')

  if (decimals) {
    const decimalSepIndex = s.indexOf(',')
    if (decimalSepIndex > -1) {
      s =
        s.substr(0, decimalSepIndex + 1) +
        s.substr(decimalSepIndex + 1, 2).replace(',', '')
    }
    return s.replace(/[^\d-,]/g, '')
  } else {
    s = s.split(',')[0]
  }
  return s.replace(/[^\d-]/g, '')
}

export function formatNumberWithThousands(
  amount,
  withDec = false,
  decimalCount = getDecimalsNumber(amount),
  decimal = ',',
  thousands = '.',
) {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    )

    let k = parseFloat(amount);
    if (k < 10000 && !withDec) {
      i = Math.ceil(k);
    }

    let abrev = ''

    if (i.toString().length > 10) {
      i = Math.round(i / 1000000000)
      abrev = 'bn'
      decimalCount = 0
    } else if (i.toString().length > 7) {
      i = Math.round(i / 1000000)
      abrev = 'M'
      decimalCount = 0
    }
    i = i.toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (withDec && decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : '') +
      abrev
    )

  } catch (e) {
    console.error(e)
  }
}

const getDecimalsNumber = n => {
  return Math.min(((n && n.toString().split('.')[1]) || '').length, 2)
}

export const rounded = (value, round = false, decs = 2) => {

  const amount = `${(Math.round(Math.floor(value) * 100) / 100).toLocaleString()}`;
  const decimals = String(Math.floor(value * 100)).slice(0 - decs)

  return `${amount}${(parseInt(decimals) !== 0 && !round) ? ',' + decimals : ''}`
}