import React, { useContext } from 'react';

import { Button } from "Components";
import { GlobalContext, getNameInitials, checkRolePermit } from 'Utils'

import { Col, Layout, Row, Menu as AntMenu, Dropdown as AntDropdown } from 'antd';
import styled from '@emotion/styled'
import { get } from 'lodash'
import { Link } from "@reach/router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import moment from 'moment';

import logoClave from 'Assets/logo/logo-clave.svg'
import tabShape from 'Assets/images/HeaderTabShape.svg'

const hash = document.location.hash.replace('#', '')
const excludingWarns = ['/cookies', '/privacidad', '/legal'].includes(hash)

const menu = (signOut, roles, currentTime, isSuperUser) => {

  const currentList = [
    {
      key: '1',
      label: (
        <Link to={`/dashboard/calendar/${moment(currentTime).year()}`} >
          Calendario de trabajo
        </Link>
      ),
      disabled: !checkRolePermit(roles, 'Calendario'),
    },
    {
      key: '2',
      label: (
        <Link to={'/dashboard/commercial-hierarchy'} >
          Jerarquía comercial
        </Link>
      ),
      disabled: !checkRolePermit(roles, 'JerarquiaComercial'),
    },
    {
      key: '3',
      label: (
        <Link to={'/dashboard/provider-hierarchy'} >
          Jerarquía proveedores
        </Link>
      ),
      disabled: !checkRolePermit(roles, 'JerarquiaComercial'),
    },
    {
      key: '4',
      label: (
        <Link to={'/dashboard/fuerza-consents'} >
          Permisos FuerzaBar
        </Link>
      ),
      disabled: !checkRolePermit(roles, 'JerarquiaComercial'),
    }
  ]

  const superList = [
    {
      key: '1',
      label: (
        <Link to={'/dashboard/dealer-management'} >
          Gestión distribuidores
        </Link>
      ),
    },
  ]

  const commonList = [
    {
      key: '5',
      label: (<a href="#/cookies" target="_blank"> Política de cookies </a>),
    },
    {
      key: '6',
      label: (<a href="#/privacidad" target="_blank"> Política de privacidad </a>),
    },
    {
      key: '7',
      label: (<a href="#/legal" target="_blank"> Aviso legal </a>),
    },
    {
      key: '8',
      label: (<a onClick={signOut}> Salir de la aplicación </a>),
    },
  ]

  const headerList = isSuperUser ? superList : currentList;

  return (<AntMenu
    items={headerList.concat(commonList)}
  />
  )
};

export const Header = ({ onlyLogo = false }) => {
  const { signOut } = useAuthenticator();
  const context = useContext(GlobalContext)
  const roles = get(context, 'currentUser.roles') || []
  const userName = get(context, 'currentUser.name') || ''
  const currentTime = get(context, 'currentUser.currentTime') || ''
  const isSuperUser = get(context, 'currentUser.isSuperUser') || false

  return (
    <HeaderStyled className="header">
      <RowBg type="flex" justify="space-between">
        <Col>
          <LogoWrapper>
            {onlyLogo ? (
              <img alt="Logotipo Clave X" src={logoClave} />
            ) : (
              <Link to="/">
                <img alt="Logotipo Clave X" src={logoClave} />
              </Link>
            )}
          </LogoWrapper>
        </Col>
        <Col>
          {!excludingWarns && <Menu>
            <MenuStyled overlay={menu(signOut, roles, currentTime, isSuperUser)}>
              <Button
                avatar={getNameInitials(userName)}
                size="large"
                text={userName}
              />
            </MenuStyled>
          </Menu>}
        </Col>
      </RowBg>
    </HeaderStyled>
  );
};

const HeaderStyled = styled(Layout.Header)({
  position: 'fixed',
  zIndex: 1000,
  width: '100%',
  height: 81,
  padding: 0,
  backgroundImage: 'linear-gradient(-27deg, #008200 0%, #205527 100%)',
  boxShadow: '0 2px 6px 0 rgba(32,85,39,0.61)',
})

const Menu = styled.div`
  display: flex;
`
const MenuStyled = styled(AntDropdown)`
  margin: 25px 20px 1px;
`

const RowBg = styled(Row)({
  backgroundImage: `url(${tabShape})`,
  width: '100%',
  height: 71,
})
const LogoWrapper = styled.div({
  paddingLeft: 30,
})