import React, { useState, useEffect } from "react";
import { Form, Input, Button, Col } from "antd";
import styled from "@emotion/styled";
import { FormLabel } from ".";
import { Modal } from "Components";

// eslint-disable-next-line
const emailRE =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const formFilds = {
  user: "",
  password: "",
};

export const DealerCredentialsForm = ({ props, setProps }) => {
  const [credentialsForm, setCredentialsForm] = useState(formFilds);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  const afterSubmit = () => {
    setProps({ visible: false });
    setSubmitDisabled(false);
  };

  useEffect(() => {
    setCredentialsForm(
      props.credentials !== undefined ? props.credentials : formFilds
    );
  }, [props]);

  const onSubmit = (_, closeModal = true) => {
    setSubmitDisabled(true);
    const variables = {
      user: credentialsForm.user,
      password: credentialsForm.password,
    };
    if (closeModal) afterSubmit();
    return props.submit({ variables });
  };

  const disableSubmit =
    isSubmitDisabled ||
    !credentialsForm.password ||
    credentialsForm.password === "" ||
    !credentialsForm.user ||
    credentialsForm.user === "";

  return (
    <Modal
      style={{
        borderLeft: "20px solid #ffffff",
        borderRight: "5px solid #ffffff",
        borderImage: "linear-gradient(to bottom, #008200 0%, #03d503 100%) 1",
      }}
      title={props.title || 'Credenciales de transmisión'}
      visible={props.visible}
      width={500}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setProps({
              ...props,
              visible: false,
            });
          }}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={disableSubmit}
        >
          {props.okText || 'Confirmar'}
        </Button>,
      ].filter((btn) => btn)}
    >
      <Form layout="vertical">
        <Col xs={24} md={24}>
          <FormItem label={<FormLabel title="Usuario" />}>
            <Input
              value={credentialsForm.user}
              onChange={(event) => {
                setCredentialsForm({
                  ...credentialsForm,
                  user: event.currentTarget.value,
                });
              }}
            />
          </FormItem>
          <FormItem label={<FormLabel title="Contraseña" />}>
            <Input.Password
              value={credentialsForm.password}
              onChange={(event) => {
                setCredentialsForm({
                  ...credentialsForm,
                  password: event.currentTarget.value,
                });
              }}
            />
          </FormItem>
        </Col>
      </Form>
    </Modal>
  );
};

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    padding: 5px 30px;
  }
`;
