import React from 'react'
import { Tree as AntTree } from 'antd'
import { EmployeeRow } from 'Components'
import styled from '@emotion/styled'
import { colors } from 'Utils'

export const EmployeeTree = ({ dataSource, actions }) => {

  const keyColor = [
    colors.trueGreen,
    '#e4e4e4',
    '#f4f4f4',
    colors.white
  ]

  const generateSubTree = (ds, actions, key) => {
    const deletable = !ds.hijos || (ds.hijos && ds.hijos.length < 1)
    const nodeKey = `${key}-${ds.miembro.codMiembro}`
    const keyNodes = nodeKey.split('-');
    const newEmployee = {
      title: <EmployeeRow dataSource={{ ...ds.miembro, deletable }} actions={actions} />,
      key: nodeKey,
      style: { background: keyColor[keyNodes.length - 1], borderBottom: `solid 1px ${colors.whiteTwo}`, width: '100%' },
      children: ds.hijos && ds.hijos.map(hijo => generateSubTree(hijo, actions, nodeKey)),
    }

    return newEmployee;
  }

  const generateNewTree = (ds, actions) => {
    let newTree = []
    const deletable = !ds.hijos || (ds.hijos && ds.hijos.length < 1)
    const nodeKey = ds.miembro.codMiembro

    const newEmployee = {
      title: <EmployeeRow dataSource={{ ...ds.miembro, deletable }} actions={actions} />,
      key: nodeKey,
      style: { background: colors.trueGreen, width: '100%' },
      children: ds.hijos && ds.hijos.map(hijo => generateSubTree(hijo, actions, nodeKey)),
    }
    newTree.push(newEmployee)

    return newTree;
  }

  return ((dataSource && (
    <Inner>
      <StyledTree
        defaultExpandAll
        treeData={generateNewTree(dataSource, actions)}
      />
    </Inner>
  )) ||
    null)
}

const StyledTree = styled(AntTree)`
  .ant-tree-switcher-icon {
    padding: 20px 10px;
  }
  .ant-tree-node-content-wrapper {
    width: 100%
  }
  .ant-tree-node-content-wrapper:hover {
    background-color: unset;
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: unset;
  }
`

const Inner = styled.div`
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
`
