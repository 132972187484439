import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'

const errorTitle = [
  'Este perfil no está vacío',
  'Este perfil está Activo',
  'Este perfil tiene objetivos asociados'
]

const errorContent = [
  `Necesitas borrar todos los perfiles contenidos en este perfil antes de poder borrarlo.`,
  `No es posible eliminar de la jerarquía a un usuario con acceso al sistema. Debe darlo de baja previamente.`,
  `Debe desasignar o borrar todos los objetivos que tenga asociados este proveedor para poder eliminarlo`
]

export const showCantDeleteConfirm = (errorMesage = 0) => {
  Modal.info({
    title: <ConfirmTitle> {errorTitle[errorMesage]} </ConfirmTitle>,
    content: <>{errorContent[errorMesage]}</>,
    centered: true,
    icon: null,
    okText: 'OK',
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
