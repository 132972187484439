import { colors } from "./colors";

const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


export const sort = (arr, property) =>
  arr.sort((a, b) => {
    if (property) {
      a = a[property]
      b = b[property]
    }
    if (a > b) return 1
    if (a < b) return -1
    return 0
  })

export const reverseSort = (arr, property) =>
  arr.sort((a, b) => {
    if (property) {
      a = a[property]
      b = b[property]
    }
    if (a < b) return 1
    if (a > b) return -1
    return 0
  })

export const checkRolePermit = (roles, page) =>
  roles.filter(({ name, read }) => name === page && read).length > 0


export const checkRolePermits = (roles, pages) =>
  pages.reduce((acc, page) => acc && checkRolePermit(roles, page), true)

export const checkRoleAnyPermits = (roles, pages) =>
  pages.some(page => checkRolePermit(roles, page))

export const diveObj = (theObject, paths) =>
  paths.split(' + ').map(path =>
    path.split('.').reduce((obj, prop) => {
      return obj && typeof obj === 'object' && obj[prop]
    }, theObject),
  )

export const findSalesMen = (hierarchy, specialists = true, salesmen = []) => {
  if (hierarchy && hierarchy.hijos) {
    hierarchy.hijos.forEach(hijo => {
      if (hijo.miembro.nivel === 'comercial') {
        salesmen.push(hijo)
      } else {
        if (specialists || hijo.miembro.nivel !== 'especialista')
          findSalesMen(hijo, salesmen, specialists)
      }
    })
  }
  return salesmen
}

export const plurals = (valor, isPlural = false) => {
  let newValor = valor
  if (valor !== undefined && valor !== null && valor.length > 0 && !isPlural) newValor = valor.slice(0, -1);
  if (!isPlural && valor === "unidades") newValor = valor.slice(0, -2);
  if (valor === 'euros') newValor = '€'
  if (valor === 'be') return valor

  return `${newValor}`
}

export const compareSaturationArrays = (filter, list, satLength) => {
  if (filter === "grupo") {
    let catGroups = []

    list.forEach(cat => cat.valores.map(val => val.groupsId.map(grp => { catGroups.push({ idCat: cat.categoria.id, grupo: `grupo${grp}` }) })))
    return Object.keys(groupBy(catGroups, filter)).length === satLength
  }
  if (filter === "hasArticles") {
    return list.filter(sat => sat.hasArticles).length === satLength
  }
  return false
}

export const categoriesCount = (selectedCategories) => {
  let cats = 0;
  selectedCategories.map(cat => cats += cat.valores.length)
  return cats
}

export const checkLevel = (level) => {
  if (level === 'Gerente') { return 'GE' }
  if (level === 'Jefe de ventas') { return 'JV' }
  if (level === 'Jefe de equipo') { return 'JE' }
  if (level === 'Especialista') { return 'ES' }
  if (level === 'Comercial') { return 'CO' }
  return null
}

export const dateIconStyle = { fontSize: 17, color: colors.pine, padding: '0 10px' }