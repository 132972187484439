import React, { useContext, useState } from 'react'

import {
  AddDealerForm,
  Content,
  PageTitle,
  ResourceWrapper,
  NoDataForList,
  Spinner,
  Dealer,
} from 'Components'
import { Header } from 'Fragments'
import { ALL_DISTRIBUTORS, CREATE_DISTRIBUTORS, EDIT_DISTRIBUTORS } from 'Queries'
import { GlobalContext, reverseSort } from 'Utils'

import { Layout, Row, Button as AntButton } from 'antd'
import styled from '@emotion/styled'
import { isEmpty } from 'lodash'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'

export const DealerManagement = () => {
  const context = useContext(GlobalContext)

  const [dealers, setDealers] = useState([])
  const [isRefetch, setIsRefetch] = useState(false)
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: 'Añadir distribuidor',
    okText: 'Añadir',
  })

  const onCompleted = ({ Distributor }) => {
    const list = Distributor.length > 1 ? reverseSort(Distributor, 'modifEn') : Distributor;
    setDealers(list);
    setIsRefetch(false);
    context.resetKeepAliveTimer();
  }

  const resource = useQuery(ALL_DISTRIBUTORS, {
    fetchPolicy: 'network-only',
    onCompleted
  })

  const [createDealer, { loading: createLoading }] = useMutation(CREATE_DISTRIBUTORS, { onCompleted })
  const [editDealer, { loading: editLoading }] = useMutation(EDIT_DISTRIBUTORS, { onCompleted })

  const client = useApolloClient()
  client.addResolvers()

  const isLoading = isRefetch || editLoading || createLoading

  return (
    <Layout>
      <Header onlyLogo />
      <Content nosider={1}>
        {isLoading ? <Spinner /> : (
          <ResourceWrapper resource={resource}>
            <Row justify="space-between" type="flex" style={{ padding: '15px 70px 0 70px' }}>
              <PageTitle>distribuidores</PageTitle>
              <Button
                size="large"
                onClick={() => setModalProps({
                  title: 'Añadir distribuidor',
                  okText: 'Añadir',
                  visible: true,
                  submit: createDealer
                })}
              >
                Nuevo distribuidor
              </Button>
            </Row>
            <ListDiv>
              {dealers.map((dealer, index) => (
                <Dealer
                  key={'1' + index}
                  dealer={dealer}
                  refetchObjectives={resource.refetch}
                  setIsRefetch={setIsRefetch}
                  props={modalProps}
                  setProps={setModalProps}
                  editDealer={editDealer}
                />
              ))}
              {isEmpty(dealers) && (
                <NoDataForList text="No hay distribuidores que estén activos"></NoDataForList>
              )}
            </ListDiv>
          </ResourceWrapper>
        )}
      </Content>
      <AddDealerForm props={modalProps} setProps={setModalProps} isLoading={false} />
    </Layout>)
}

const Button = styled(AntButton)`
  background: #008200;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  margin-top: 10px;
  &:hover: {
    background: #008200;
    color: white;
  }
`

const ListDiv = styled.div`
  height: calc(100vh - 170px);
  width: 100%;
  overflow-y: auto;
  padding: 0 70px;
`