import { useAuthenticator } from "@aws-amplify/ui-react";
import { Login, MainHome } from "Pages";
import { useState } from "react";
import { useEffect } from "react";
import './App.less';

function App() {
  const { user } = useAuthenticator();

  const [userLogin, setUserLogin] = useState(null)

  useEffect(() => {
    setUserLogin(user)
  }, [user])

  if (userLogin !== null && userLogin === undefined) {
    return <Login />;
  } else if (userLogin !== undefined) {
    return <MainHome />
  }
  return false;
}

export default App;