import React from 'react'
import { formatNumberWithThousands } from 'Utils'
import styled from '@emotion/styled'

export const OpCellDescription = ({ dataSource, mBot }) => {
  if (!dataSource) return null
  const { valor, unidad } = dataSource

  return (
    <CellInfo mBot={mBot}>
      <TextSpan>
        {`${dataSource.titulo} ${dataSource.signo ? '+' : ''}`}
      </TextSpan>

      <NumberSpan>
        {formatNumberWithThousands(valor)} {unidad || '€'}
      </NumberSpan>
    </CellInfo>
  )
}

const CellInfo = styled.p(({ mBot }) => ({
  fontSize: 14,
  lineHeight: 1.25,
  marginBottom: mBot ? 10 : '0px',
}))

const TextSpan = styled.span({
  width: 50,
  float: 'left',
  marginRight: 14
})

const NumberSpan = styled.span({
  fontWeight: 600
})
