import React, { useState } from 'react'
import { Input, Button as AntButton } from 'antd'
import styled from '@emotion/styled'
import { IncentivesTotalTable } from 'Components'
import { IncentiveMatrixModal } from 'Fragments'
import { colors } from 'Utils'

export const ObjectiveOpenIncentives = ({
  incentiveMatrix,
  setIncentiveMatrix,
  totalIncentives,
  totalMonthIncentives,
  startMonth,
  endMonth,
  year,
  editIncentivesMatrix,
  deleteIncentivesMatrix,
  generalIncentive,
  setGeneralIncentive,
  applyAll,
  isLoading,
  setLoading,
}) => {

  const [showModal, setShowModal] = useState(false)
  const [editedRow, setEditedRow] = useState(null)

  return (
    <Wrapper>
      <Inner>
        <IncentivesTotalTable
          total={totalIncentives}
          montly={totalMonthIncentives}
          startMonth={startMonth}
          endMonth={endMonth}
          isOpen
        />
        <Row>
          <Input
            size="large"
            style={{ width: 72, marginRight: 4 }}
            type="number"
            value={generalIncentive}
            onChange={e => setGeneralIncentive(parseInt(e.currentTarget.value))}
          />
          <span style={{ marginRight: 10 }}>€</span>
          <Button
            size="large"
            loading={isLoading}
            onClick={() => {
              setLoading(true)
              applyAll(null, generalIncentive)
            }}
          >
            Aplicar a todos
          </Button>
          <Button
            size="large"
            type="link"
            style={{ float: 'right' }}
            onClick={() => setShowModal(true)}
          >
            Ver matriz cumplimiento
          </Button>
        </Row>
      </Inner>
      <IncentiveMatrixModal
        title="Matriz de cumplimiento"
        subtitle={year}
        show={showModal}
        dataSource={incentiveMatrix}
        onCancel={() => setShowModal(false)}
        editIncentivesMatrix={editIncentivesMatrix}
        deleteIncentivesMatrix={deleteIncentivesMatrix}
        addNewRow={(index, row) => {
          const newIncentiveMatrix = incentiveMatrix.slice()
          newIncentiveMatrix.splice(index, 0, row)
          setIncentiveMatrix(newIncentiveMatrix)
          setEditedRow(index)
        }}
        removeNewRow={index => {
          const newIncentiveMatrix = incentiveMatrix.slice()
          newIncentiveMatrix.splice(index, 1)
          setIncentiveMatrix(newIncentiveMatrix)
        }}
        onRowEdit={id => setEditedRow(id)}
        editedRow={editedRow}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  left: 485px;
  top: 0px;
  width: 740px;
  background: #e3f0c8;
  box-shadow: 0px 0px 10px 1px ${colors.lime};
`
const Inner = styled.div`
  width: 740px;
  height: 220px;
  box-shadow: 0 4px 12px 0 rgb(200 198 198 / 50%);
`
const Row = styled.div`
  margin-left: 25px;
  margin-right: 15px;
`
const Button = styled(AntButton)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--true-green);
  text-transform: uppercase;
`
