import React, { useState, useEffect, useContext } from 'react'

import { NoDataForList, SummaryLegend, SummaryTable, PageErrorWarning } from 'Components'
import { OBJECTIVE_KPI_GLOBAL, OBJECTIVE_KPI_LIST_GLOBAL } from 'Queries'
import {
  GlobalContext,
  DashboardContextConsumer,
  sort, groupListBy,
  DownloadButton,
  summaryHeader,
  getEmpleadosAndNiveles
} from 'Utils'

import { Tabs as AntTabs, Alert as AntAlert } from 'antd'
import styled from '@emotion/styled'
import { get } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'

const { TabPane } = AntTabs

export const ObjetivesTable = props => (
  <DashboardContextConsumer>
    {dashboardProps => <ResumeTables {...dashboardProps} {...props} />}
  </DashboardContextConsumer>
)

export const ResumeTables = ({
  navigate,
  type,
  year: ano,
  filter,
  setFilter,
}) => {
  const context = useContext(GlobalContext)
  const isDev = context.isDev
  const codDistribuidor = get(context, 'currentUser.infoHesa') !== null ? get(context, 'currentUser.infoHesa.codDistribuidor') : get(context, 'currentUser.codDistribuidor')
  const avisos = get(context, 'currentUser.avisos') || []
  const isHesa = get(context, 'currentUser.infoHesa.isHesa') || false

  const warnedYears = avisos
    .filter(({ tipo }) => tipo === 'calendario')
    .map(({ context }) => context.ano)
  const isWarnedByHierarchy = avisos.some(({ tipo }) => tipo === 'jerarquia')
  const isComercial = isDev ? false : !context.currentUser.display
  const year = moment(context.currentUser.currentTime).year()
  const [empleadosGlobal, setEmpleadosGlobal] = useState([])
  const [niveles, setNiveles] = useState([])
  const [fecha, setFecha] = useState([])
  const [activeObjectives, setActiveObjectives] = useState([])
  const [summaryList, setSummaryList] = useState([])
  const [remainingList, setRemainingList] = useState([])
  const divisor = 1
  const MAX_RETRIES = 3;

  const [stopper, setStopper] = useState(false)
  const [pauser, setPauser] = useState(false)
  const [retryRefetchSummaryList, setRetryRefetchSummaryList] = useState(0)
  const [retrySelectedObjetives, setRetrySelectedObjetives] = useState(0)

  const resetList = () => {
    setRemainingList([])
    setSummaryList([])
    setActiveObjectives([])
  }

  const callNewKpis = (desde, hasta) => {
    setFecha([desde, hasta]);
  }

  const controlGlobal = (remainingList) => {
    selectedObjetives({
      variables: {
        codDistribuidor,
        ano: filter.fechas ? filter.fechas[0].year() : ano !== undefined ? ano : year,
        fechaDesde: filter.fechas && filter.fechas[0].format('x'),
        fechaHasta: filter.fechas && filter.fechas[1].format('x'),
        codObjetivos: remainingList.slice(0, divisor),
      }
    })
  }

  const [selectedObjetives, { loading, refetch: refetchSelectedObjectives }] = useLazyQuery(OBJECTIVE_KPI_GLOBAL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ objetivoKpiGlobal }) => {
      setRetrySelectedObjetives(0);
      if (summaryList.length > 0 && remainingList.length > 0 && !pauser) {
        if (!stopper) {
          remainingList.splice(0, divisor)
        } else {
          setPauser(true)
        }
        const newList = [...activeObjectives, ...objetivoKpiGlobal];

        setActiveObjectives(sort(newList, 'desc',).filter(
          ({ empleadosGlobal }) =>
            !filter.empleado ||
            empleadosGlobal.some(({ codEmpleado }) => codEmpleado === filter.empleado),
        ))

        if (remainingList.length > 0 && !stopper) {
          controlGlobal(remainingList);
        }
        context.resetKeepAliveTimer()
      }
    },
    onError: () => {
      if (retrySelectedObjetives < MAX_RETRIES) {
        setRetrySelectedObjetives(retrySelectedObjetives + 1)
        refetchSelectedObjectives()
      }
    }
  })

  const [getSummaryList, { error, refetch: refetchSummaryList }] = useLazyQuery(OBJECTIVE_KPI_LIST_GLOBAL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ objetivoKpiListGlobal }) => {
      setRetryRefetchSummaryList(0);

      if (objetivoKpiListGlobal.length > 0 && summaryList.length === 0 && stopper === false && pauser === false) {
        setSummaryList([...objetivoKpiListGlobal]);
        setRemainingList([...objetivoKpiListGlobal]);
        selectedObjetives({
          variables: {
            codDistribuidor,
            ano: filter.fechas ? filter.fechas[0].year() : ano !== undefined ? ano : year,
            fechaDesde: filter.fechas && filter.fechas[0].format('x'),
            fechaHasta: filter.fechas && filter.fechas[1].format('x'),
            codObjetivos: objetivoKpiListGlobal.slice(0, divisor),
          }
        })
      }
    },
    onError: () => {
      if (retryRefetchSummaryList < MAX_RETRIES) {
        setRetryRefetchSummaryList(retryRefetchSummaryList + 1)
        refetchSummaryList()
      }
    }
  })

  const empleadosYniveles = getEmpleadosAndNiveles(activeObjectives)
  const objetivesList = groupListBy(activeObjectives, 'desc', filter)
  const comercialsList = groupListBy(activeObjectives, 'nombreEmpleado', filter)

  const takeBackCall = () => {
    remainingList.splice(0, divisor)
    if (remainingList.length > 0) {
      selectedObjetives({
        variables: {
          codDistribuidor,
          ano: filter.fechas ? filter.fechas[0].year() : ano !== undefined ? ano : year,
          fechaDesde: filter.fechas && filter.fechas[0].format('x'),
          fechaHasta: filter.fechas && filter.fechas[1].format('x'),
          codObjetivos: remainingList.slice(0, divisor),
        }
      })
    }
  }

  useEffect(() => {
    const employees = Object.values(empleadosYniveles.empleadosGlobal)
    if (!empleadosGlobal.length && employees.length) {
      const empleadosGlobal = sort(employees, 'nombreEmpleado')
      setEmpleadosGlobal(empleadosGlobal)
    }
  }, [empleadosYniveles.empleadosGlobal])

  useEffect(() => {
    const empleadosGlobal = sort(
      Object.values(
        getEmpleadosAndNiveles(
          activeObjectives,
          filter.nivel,
        ).empleadosGlobal,
      ),
      'nombreEmpleado',
    )
    setEmpleadosGlobal(empleadosGlobal)
  }, [filter.nivel])

  useEffect(() => {
    const levels = Object.values(empleadosYniveles.niveles)
    if (!niveles.length && levels.length) {
      const niveles = sort(levels, 'desc')
      setNiveles(niveles)
    }
  }, [empleadosYniveles.niveles])

  useEffect(() => {
    if (summaryList.length === 0 && filter.fechas) {
      resetList()
      setStopper(false)
      setPauser(false)
      getSummaryList({
        variables: {
          codDistribuidor,
          ano: filter.fechas ? filter.fechas[0].year() : year,
          fechaDesde: filter.fechas && filter.fechas[0].format('x'),
          fechaHasta: filter.fechas && filter.fechas[1].format('x'),
        }
      });
    }
  }, [filter.fechas]);

  const navigateByKPIs = (key) => {
    navigate(`/dashboard/objectives/table-objetives/${key}`,)
  }

  return (
    <div id="contentWidth" style={{ position: 'relative', minWidth: '830px' }}>
      {isHesa && codDistribuidor === null ? PageErrorWarning(
        'No tiene acceso a esta página actualmente',
        (<> Diríjase a <a style={{ fontWeight: 'bold' }} href="/">Seguimiento</a> y seleccione un distribuidor para poder visualizar el contenido</>)
      ) :
        (isWarnedByHierarchy ||
          warnedYears.includes(year) ||
          warnedYears.includes(year - 1)) ? (
          <NoDataForList
            text={`Para poder realizar seguimiento 
                ${isComercial
                ? ' deberán estar configurados correctamente los calendarios y/o la jerarquía comercial'
                : ' resuelva antes los avisos'
              }
                `}
          />
        ) : (
          <>
            {(loading) && (<Warning
              message="&nbsp; Es posible que la carga completa de los datos lleve algunos minutos"
              type="warning"
              showIcon
              style={{ width: "545px" }}
            />)}

            {!filter.fechas && (<Warning
              message="&nbsp; Seleccione un rango de fechas para comenzar la busqueda de objetivos"
              type="warning"
              showIcon
              style={{ width: "570px" }}
            />)}

            <SummaryLegendWrapper>
              <SummaryLegend
                filter={filter}
                empleados={empleadosGlobal}
                niveles={niveles}
                setFilter={newFilter => {
                  setFilter({ ...filter, ...newFilter })
                }}
                clearFilter={() => {
                  if (fecha.length > 0) {
                    resetList()
                    setFecha([])
                  }
                  setFilter({})
                }}
                isLoading={loading}
                resetList={resetList}
                callNewKpis={callNewKpis}
              />
            </SummaryLegendWrapper>
            <FollowTabs
              props={{ isLoaded: loading }}
              activeKey={type}
              onTabClick={key => {
                navigateByKPIs(key)
              }}
            >
              <TabPane
                tab={'OBJETIVOS'}
                key="objetivos"
              >
                <DownloadButton
                  header={summaryHeader("objetivos", isHesa)}
                  body={objetivesList}
                  loading={loading}
                  setPauser={setPauser}
                  setStopper={setStopper}
                  stopper={stopper}
                  remaining={remainingList.length - divisor}
                  call={() => takeBackCall()}
                />
                <div style={{ marginTop: 20, padding: '0 20px 20px 20px', height: 'calc(100vh - 250px)', overflowY: 'auto' }}>
                  <SummaryTable
                    customList={objetivesList}
                    firstCol={[{ id: 'desc', text: 'Objetivo' }, { id: 'nombreEmpleado', text: 'Comercial' }]}
                    isLoaded={loading}
                    isHesa={isHesa}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={'COMERCIALES'}
                key="comerciales"
              >
                <DownloadButton
                  header={summaryHeader("comerciales", isHesa)}
                  body={objetivesList}
                  loading={loading}
                  setPauser={setPauser}
                  setStopper={setStopper}
                  stopper={stopper}
                  remaining={remainingList.length - divisor}
                  call={() => takeBackCall()}
                />
                <div style={{ marginTop: 36, padding: '0 20px 20px 20px', height: 'calc(100vh - 264px)', overflowY: 'auto' }}>
                  <SummaryTable
                    customList={comercialsList}
                    firstCol={[{ id: 'nombreEmpleado', text: 'Comercial' }, { id: 'desc', text: 'Objetivo' }]}
                    isLoaded={loading}
                    isHesa={isHesa}
                  />
                </div>
              </TabPane>
            </FollowTabs>
            <br />
          </>
        )}
    </div>
  )
}

const FollowTabs = styled(AntTabs)`
  .ant-tabs-nav{
    margin: 15px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    z-index: 105;
    padding: 12px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
  }
`

const Warning = styled(AntAlert)`
  font-size: 17px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 110px auto;
  height: 50px;
`

const SummaryLegendWrapper = styled.div`
  padding: 16px;
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 100;
`
