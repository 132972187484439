import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Input, Col, Alert } from 'antd'
import { Icon } from '@ant-design/compatible'
import { Row, PageTitle, showWarningNotification } from 'Components'
import { useQuery } from '@apollo/client'
import { useDebounce } from 'use-debounce'
import { GlobalContext } from 'Utils'
import styled from '@emotion/styled'
import { get } from 'lodash'

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  cursor: ${props => (props.expandable ? 'pointer' : 'inicial')};
`

let totalNumberOfElements = 0

export const TableQuery = ({
  searchPlaceholder = 'Buscar',
  onQueryCompleted = null,
  children,
  expandable,
  querySeachParams,
  query,
  objectiveId,
  queryVariables,
  queryPath,
  idKey,
  title,
  disableSearch,
  showSearch,
  onSelectionChange,
  selectionIds,
  selectedCategorieHash,
  queryExtraParams,
  contentList = [],
  objGrupoId = undefined,
  setExceptionTab = () => { },
  setContentList = () => { },
  idCodSatGroup = undefined,
  showWarning = false
}) => {
  const context = useContext(GlobalContext)
  const codDistribuidor = get(context, 'currentUser.infoHesa') !== null ? get(context, 'currentUser.infoHesa.codDistribuidor') : (get(context, 'currentUser.codDistribuidor') || null)

  const [search, setSearch] = useState(querySeachParams || '')
  const [searchTerm] = useDebounce(search, 1000)

  const [expanded, setExpanded] = useState(true)

  if (!queryVariables) {
    queryVariables = {}
  }

  const [{ sortBy, sortDir }, setSort] = useState({
    sortBy: queryVariables.sortBy || '',
    sortDir: queryVariables.sortDir || 'desc',
  })

  let variables = {
    codDistribuidor,
    size: 100,
    page: 0,
    searchTerm: searchTerm.length > 2 ? searchTerm : '',
    sortBy,
    sortDir,
    objGrupoId,
    idCodSatGroup
  }
  if (objectiveId) {
    variables.idObjetivo = objectiveId.id
  }
  if (queryExtraParams) {
    delete variables.codDistribuidor
    variables = { ...variables, ...queryExtraParams }
  }

  const queryExtraParamsHash = JSON.stringify(queryExtraParams)

  const { error, data, loading, fetchMore } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables,
    onCompleted: () => {
      context.resetKeepAliveTimer()
      context.setIsLoading(false)
    },
    skip: context.isDev,
  })

  useEffect(() => {
    if ((data && data[queryPath] && data[queryPath].content)) {
      setContentList(data[queryPath].content)
      if (showWarning && data[queryPath].content.length === 0) {
        showWarningNotification('El proveedor que se ha seleccionado no comparte ningún artículo con el especialista. \n Por favor revise la definición del objetivo')
      }
    }
  }, [data])

  if (searchTerm.length <= 2)
    totalNumberOfElements =
      get(data, queryPath + '.pagination.totalElements') || 0

  useEffect(() => {
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) =>
        fetchMoreResult ? fetchMoreResult : prev,
    })
  }, [selectedCategorieHash, queryExtraParamsHash])

  useEffect(() => {
    setSort({ ...queryVariables })
  }, [queryVariables.sortDir])

  if (error) {
    const message = error.message || 'Algo ha fallado.'
    return <Alert message="Error" description={message} type="error" showIcon />
  }

  const content = (data && data[queryPath] && data[queryPath].content) || []

  if (contentList.length !== content.length) {
    setContentList(content)
    setExceptionTab(objGrupoId === 'E' ? true : false)
  }

  const totalElements =
    (data &&
      data[queryPath] &&
      data[queryPath].pagination &&
      data[queryPath].pagination.totalElements) ||
    0

  let rowSelection = null

  const selectedRowKeys =
    idKey &&
    content
      .map((row, index) => ({ ...row, index }))
      .filter(row => selectionIds.indexOf(row[idKey]) === -1)
      .map(row => row.index)

  if (onSelectionChange) {
    rowSelection = {
      onSelect: onSelectionChange,
      columnTitle: ' ',
      selectedRowKeys,
    }
  }

  if (onQueryCompleted) {
    onQueryCompleted(totalNumberOfElements)
  }

  return (
    <Fragment>
      {!disableSearch && (
        <Fragment>
          <Row>
            <Col>
              {title && (
                <Title
                  expandable={expandable}
                  onClick={() => expandable && setExpanded(!expanded)}
                >
                  {title} ({totalElements}){' '}
                  {expandable && (
                    <Icon
                      style={{ fontSize: 12 }}
                      type={expanded ? 'up' : 'down'}
                    />
                  )}
                </Title>
              )}
            </Col>
          </Row>
          {expanded && showSearch && (
            <Row gutter={8}>
              <Col span={24}>
                <Input
                  value={search}
                  placeholder={searchPlaceholder}
                  onChange={e => {
                    setSearch(e.target.value);
                  }}
                />
              </Col>
            </Row>
          )}
        </Fragment>
      )}
      {disableSearch && (
        <Fragment>
          <Row>
            <Col>
              <PageTitle>{title}</PageTitle>
            </Col>
          </Row>
        </Fragment>
      )}
      {expanded && (
        <>
          {React.cloneElement(children, {
            updateQueryPath: queryPath,
            content,
            totalElements,
            fetchMore,
            onSort: ({ sortBy, sortDir }) => {
              const mappedSortBy = {
                codPtoVenta: 'codPdv',
                'rutaPrev.id': 'codRuta',
                valorMedidaDia: 'medidaAct',
                valorMedidaAA: 'medidaAA',
              }
              setSort({
                sortBy: mappedSortBy[sortBy] || sortBy,
                sortDir,
              })
            },
            searchTerm,
            sortBy,
            sortDir,
            rowSelection,
            loading,
          })}
        </>
      )}
    </Fragment>
  )
}
