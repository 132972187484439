import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'

export const NoDataForList = ({ text = 'No hay datos', minHeight = 400 }) => {
  return (
    <Wrapper minHeight={minHeight}>
      <Text>{text}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: ${props => props.minHeight}px;
  align-items: center;
  justify-content: center;
`

const Text = styled.h4`
  font-size: 32px;
  color: ${colors.whiteTwo};
`
