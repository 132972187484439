import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'

export const sessionExpireConfirm = (seconds, onOk) => {
  Modal.info({
    title: <ConfirmTitle>Caducidad de la sesión</ConfirmTitle>,
    content: `Para mantener viva la sesión, haga clic en Seguir`,
    icon: null,
    centered: true,
    okText: 'SEGUIR',
    okButtonProps: {
      type: 'danger',
    },
    onOk,
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
