import React, { useContext, useState, useEffect } from 'react'

import { OptionGroupButton } from 'Components'
import { ModalFormWhenToMeasure } from 'Fragments'
import {
  UPDATE_OBJECTIVE_WHEN,
  UPDATE_OPEN_OBJECTIVE_WHEN,
  OBJECTIVE,
  OPEN_OBJECTIVE,
  CHECK_CALENDAR_WARNINGS,
} from 'Queries'
import {
  GlobalContext,
  ObjectiveContext,
  getMutationVars,
  sentenceCase,
  colors,
  getObjectiveIdVars,
} from 'Utils'

import { Icon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { get, isEmpty } from 'lodash'
import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'

const getWhenOptionValue = (start, end, arr) => {
  return (
    (!isEmpty(arr) &&
      arr.indexOf(
        arr.filter(v => v.fechaInicio === start && v.fechaFin === end).shift(),
      ) + 1) ||
    undefined
  )
}

const getCustomPeriod = (start, end, arr) => {
  return (
    (start &&
      isEmpty(
        arr.filter(v => v.fechaInicio === start && v.fechaFin === end),
      ) && [
        {
          desc:
            sentenceCase(moment.unix(start / 1000).format('DD MMMM YY')) +
            ' - ' +
            sentenceCase(moment.unix(end / 1000).format('DD MMMM YY')),
          fechaInicio: start,
          fechaFin: end,
        },
      ]) ||
    []
  )
}

export const OptionGroupButtonWhen = ({ formOptions, objective }) => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const {
    resetKeepAliveTimer,
    setObjective,
    setRecalculating,
    changeURLParams,
    empleados
  } = context

  const fechaInicio = get(objective, 'periodoVigencia.fechaInicio')
  const fechaFin = get(objective, 'periodoVigencia.fechaFin')
  const ano =
    (fechaInicio && moment.unix(fechaInicio / 1000).year()) || moment().year()
  const [showModal, setShowModal] = useState(false)
  const [startDate, setStartDate] = useState(fechaInicio)
  const [endDate, setEndDate] = useState(fechaFin)
  const [whenValue, setWhenValue] = useState(
    get(objective, 'cuandoEvaluar.id') || null,
  )
  const medidaId = get(objective, 'medida.id') || null
  const tvrId = get(objective, 'tipoValorRef.id') || null
  const [showWhenCalendar, setShowWhenCalendar] = useState(false)
  const [year, setYear] = useState(ano)
  const [customPeriod, setCustomPeriod] = useState(
    getCustomPeriod(
      fechaInicio,
      fechaFin,
      formOptions.objetivoCuandoOpcionesPeriodosVigencia,
    ),
  )

  let {
    objetivoCuandoEvaluar = 0,
    objetivoCuandoEvaluarFinPeriodo = [],
    objetivoCuandoOpcionesPeriodosVigencia = [],
  } = formOptions

  objetivoCuandoOpcionesPeriodosVigencia = [
    ...objetivoCuandoOpcionesPeriodosVigencia,
    ...customPeriod,
  ]

  const [whenOptionValue, setWhenOptionValue] = useState(
    getWhenOptionValue(
      fechaInicio,
      fechaFin,
      objetivoCuandoOpcionesPeriodosVigencia,
    ),
  )

  const onCompleted = ({ objective }) => {
    resetKeepAliveTimer()
    setObjective(objective)
    setRecalculating(false)
  }

  const [updateWhenQuery] = useMutation(
    get(objective, 'medida.id') === 'A' ? UPDATE_OPEN_OBJECTIVE_WHEN : UPDATE_OBJECTIVE_WHEN,
    getMutationVars(getObjectiveIdVars(context), get(objective, 'medida.id') === 'A' ? OPEN_OBJECTIVE : OBJECTIVE, {
      onCompleted,
      onError: () => {
        setRecalculating(false)
      }
    }),
  )

  useEffect(() => {
    if (showModal && whenOptionValue) {
      const selectedPeriod =
        objetivoCuandoOpcionesPeriodosVigencia[whenOptionValue - 1]
      if (selectedPeriod) {
        setStartDate(Number(selectedPeriod.fechaInicio))
        setEndDate(Number(selectedPeriod.fechaFin))
        setYear(moment(Number(selectedPeriod.fechaInicio)).year())
      }
    }
  }, [showModal, whenOptionValue])

  useEffect(() => {
    const { ano, codObjetivo } = objective
    changeURLParams({ anyo: ano, id: codObjetivo })
  }, [objective.ano])

  const {
    data: { verificarCalendarioObjetivo: calendarWarnings } = {
      verificarCalendarioObjetivo: [],
    },
  } = useQuery(CHECK_CALENDAR_WARNINGS, {
    skip: !whenOptionValue,
    fetchPolicy: 'network-only',
    variables: {
      ano: year,
    },
  })

  const updateWhen = () => {
    const oldCuandoEvaluarId = get(objective, 'cuandoEvaluar.id')
    const oldFechaInicio = get(objective, 'periodoVigencia.fechaInicio')
    const oldFechaFin = get(objective, 'periodoVigencia.fechaFin')
    const { fechaInicio, fechaFin } = objetivoCuandoOpcionesPeriodosVigencia[
      whenOptionValue - 1
    ]
    const cuandoEvaluarId = medidaId !== 'N' && (whenValue === 'P' || whenValue === 'A') ? 'F' : whenValue

    if (
      oldCuandoEvaluarId !== cuandoEvaluarId ||
      moment.utc(oldFechaInicio).format('DD/MM/YYYY') !==
      moment.utc(parseInt(fechaInicio)).format('DD/MM/YYYY') ||
      moment.utc(oldFechaFin).format('DD/MM/YYYY') !==
      moment.utc(parseInt(fechaFin)).format('DD/MM/YYYY')
    ) {
      setRecalculating(true)
      return updateWhenQuery({
        variables: {
          cuandoEvaluarId,
          fechaInicio,
          fechaFin,
        },
      })
    }
  }

  const sublabel =
    objective.cuandoEvaluar && objective.periodoVigencia
      ? `Desde ${sentenceCase(
        moment(new Date(objective.periodoVigencia.fechaInicio)).format(
          'DD MMMM YY',
        ),
      )} hasta ${sentenceCase(
        moment(new Date(objective.periodoVigencia.fechaFin)).format(
          'DD MMMM YY',
        ),
      )}. ${objective.cuandoEvaluar.desc}.`
      : 'SELECCIONAR'

  const showWarning =
    !objective.draft &&
    !isEmpty(objective.cuandoEvaluar) &&
    (objective.cuandoEvaluar.id !== whenValue ||
      moment(objective.periodoVigencia.fechaInicio).format('MMYY') !==
      moment(Number(fechaInicio)).format('MMYY') ||
      moment(objective.periodoVigencia.fechaFin).format('MMYY') !==
      moment(Number(fechaFin))
        .utc()
        .format('MMYY'))

  const newPosition = (desde, hasta, arr) => {
    const val = arr.find(dat => dat.fechaInicio === desde && dat.fechaFin === hasta)
    if (val) {
      return (arr.map(row => row.desc).indexOf(val.desc) + 1);
    }
    return 5
  }

  const modalProps = {
    visible: showModal ? 1 : 0,
    title: showWhenCalendar ? (
      <span>
        <BackIcon
          type="arrow-left"
          onClick={() => {
            const [{ fechaInicio, fechaFin }] = (!isEmpty(customPeriod) &&
              customPeriod) || [
                {
                  fechaInicio: null,
                  fechaFin: null,
                },
              ]
            const year =
              (fechaInicio && moment.unix(fechaInicio / 1000).year()) ||
              moment().year()

            setShowWhenCalendar(false)
            if (fechaInicio) {
              setStartDate(fechaInicio)
              setEndDate(fechaFin)
              setYear(year)
            }
          }}
        />
        Periodo de vigencia del objetivo
      </span>
    ) : (
      <span>¿Cuándo quieres medirlo?</span>
    ),
    okButtonProps: {
      disabled: showWhenCalendar
        ? !endDate || !startDate
        : !whenOptionValue || !whenValue || (medidaId === 'N' && whenValue === 'F'),
    },
    okText: showWhenCalendar ? 'Seleccionar vigencia' : 'Seleccionar',
    cancelText: showWhenCalendar ? <span /> : 'Cancelar',
    onCancel: () => {
      const year =
        (fechaInicio && moment.unix(fechaInicio / 1000).year()) ||
        moment().year()
      const localCustomPeriod = getCustomPeriod(
        fechaInicio,
        fechaFin,
        formOptions.objetivoCuandoOpcionesPeriodosVigencia,
      )
      const localWhenOptionValue = getWhenOptionValue(
        fechaInicio,
        fechaFin,
        formOptions.objetivoCuandoOpcionesPeriodosVigencia.concat(
          localCustomPeriod,
        ),
      )
      const resetCustomValues =
        localWhenOptionValue <=
        formOptions.objetivoCuandoOpcionesPeriodosVigencia.length

      setShowModal(false)
      setShowWhenCalendar(false)
      setStartDate(resetCustomValues ? null : fechaInicio)
      setEndDate(resetCustomValues ? null : fechaFin)
      setWhenValue(resetCustomValues ? null : whenValue)
      setCustomPeriod(resetCustomValues ? [] : localCustomPeriod)
      setYear(year)

      setWhenOptionValue(localWhenOptionValue)
    },
    onOk: async () => {
      if (showWhenCalendar) {
        setCustomPeriod(
          getCustomPeriod(
            startDate,
            endDate,
            formOptions.objetivoCuandoOpcionesPeriodosVigencia,
          ),
        )
        setWhenOptionValue(
          newPosition(startDate, endDate, formOptions.objetivoCuandoOpcionesPeriodosVigencia)
        )
        setShowWhenCalendar(false)
      } else {
        setShowModal(null)
        await updateWhen()
        if (
          whenOptionValue <=
          formOptions.objetivoCuandoOpcionesPeriodosVigencia.length
        ) {
          setCustomPeriod([])
          setStartDate(null)
          setEndDate(null)
          setYear(moment().year())
        }
      }
    },
  }

  let block = objective.paraEspecialistas && empleados && !empleados.length;
  let style;
  if (block) {
    style = {
      "background-image": "none",
      "box-shadow": "none",
      "background-color": "	#DCDCDC"
    }
  }

  return (
    <OptionGroupButton
      label="¿Cuándo quieres medirlo?"
      sublabel={sublabel}
      blocked={block}
      onClick={() => {
        if (!block || ((medidaId !== null && tvrId !== null) || (tvrId === null && whenValue !== null))) {
          setShowModal(true)
        }
      }}
      selected={!!objective.cuandoEvaluar}
      disabled={block || !((medidaId !== null && tvrId !== null) || (tvrId === null && whenValue !== null))}

      style={style}
      formView={() => (
        <ModalFormWhenToMeasure
          options={{
            objetivoCuandoEvaluar,
            objetivoCuandoEvaluarFinPeriodo,
            objetivoCuandoOpcionesPeriodosVigencia,
          }}
          showWhenCalendar={showWhenCalendar}
          showWarning={showWarning}
          calendarWarnings={calendarWarnings.map(({ context: { ano } }) => ano)}
          setShowWhenCalendar={setShowWhenCalendar}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          whenOptionValue={whenOptionValue}
          setWhenOptionValue={setWhenOptionValue}
          whenValue={whenValue}
          setWhenValue={setWhenValue}
          year={year}
          setYear={setYear}
          medidaId={medidaId}
        ></ModalFormWhenToMeasure>
      )}
      modalProps={modalProps}
    ></OptionGroupButton>
  )
}

const BackIcon = styled(Icon)`
  vertical-align: middle;
  color: ${colors.trueGreen};
  padding-right: 10px;
  cursor: pointer;
`
