import React from 'react'
import { Radio as AntRadio } from 'antd'
import { colors } from 'Utils'
import styled from '@emotion/styled'

export const RadioGroup = ({
  options,
  type = 'list',
  onChange,
  value,
  optionValueKey = 'id',
  label,
}) => {
  const optionsView = options.map((option, index) => {
    if (type === 'list') {
      return (
        <Radio
          value={option[optionValueKey]}
          key={index}
          style={{ display: 'block' }}
        >
          <RadioLabel>{label(option)}</RadioLabel>
        </Radio>
      )
    }
    if (type === 'buttons') {
      return (
        <RadioButton key={index} value={option[optionValueKey]}>
          {label(option)}
        </RadioButton>
      )
    }
    return (
      <>
      </>
    )
  })

  return <AntRadio.Group onChange={onChange} value={value}>{optionsView}</AntRadio.Group>
}

export const Radio = styled(AntRadio)({
  color: 'grey',
  marginBottom: 5,
})

const RadioButton = styled(AntRadio.Button)({
  '&.ant-radio-button-wrapper-checked': {
    background: colors.fernGreen,
    color: colors.white,
  },
})

export const RadioLabel = styled.span({
  paddingLeft: 10,
})
