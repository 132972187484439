import React, { useContext, useEffect, useState } from 'react'

import {
  ComercialList,
  EmployeeTree,
  ObjectiveLeftCol,
  ObjectivePageTitle,
  ObjectiveRightCol,
  ResourceWrapper,
} from 'Components'
import {
  GET_COMMERCIAL_HIERARCHY,
  OBJECTIVE,
  OPEN_OBJECTIVE,
  GET_AVAILABLE_SALESMEN,
  ADD_SALESMEN_TO_OPEN_OBJECTIVE,
  ADD_SALESMEN_TO_OBJECTIVE_LIGHT,
} from 'Queries'
import {
  GlobalContext,
  ObjectiveContext,
  getMutationVars,
  findSalesMen,
} from 'Utils'

import styled from '@emotion/styled'
import { cloneDeep, get, isNil } from 'lodash'
import { useMutation, useQuery } from '@apollo/client'

const filterHierarchy = (hierarchy, selectedSalesMen, specialists) => {
  let filteredHierarchy
  if (hierarchy) {
    filteredHierarchy = cloneDeep(hierarchy)
    filteredHierarchy.hijos = filteredHierarchy.hijos
      .map(({ miembro, hijos }) => ({
        miembro,
        hijos: hijos
          .map(salesman => ({
            miembro: salesman.miembro,
            hijos: salesman.hijos.filter(
              salesman =>
                selectedSalesMen &&
                selectedSalesMen.indexOf(salesman.miembro.codMiembro) > -1,
            ),
          }))
          .filter(
            ({ hijos, miembro: { nivel } }) =>
              hijos.length && (specialists || nivel !== 'especialista'),
          ),
      }))
      .filter(({ hijos }) => hijos.length)
  }
  return filteredHierarchy
}

export const ObjectiveCommercials = () => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { resetKeepAliveTimer, objective, setObjective } = context
  const [selectedComercials, setSelected] = useState(
    objective.comercialesSeleccionados,
  )
  const [salesmenAvailable, setSalesmenAvailable] = useState([])
  const [filteredHierarchy, setHierarchy] = useState(null)

  const codDistribuidor = get(context, 'currentUser.codDistribuidor')
  const variables = {
    id: {
      codDistribuidor,
      ano: objective.ano,
      codObjetivo: objective.codObjetivo,
    },
  }

  const [addSalesmenToObjective] = useMutation(
    get(objective, 'medida.id') === 'A' ? ADD_SALESMEN_TO_OPEN_OBJECTIVE : ADD_SALESMEN_TO_OBJECTIVE_LIGHT,
    getMutationVars(variables, get(objective, 'medida.id') === 'A' ? OPEN_OBJECTIVE : OBJECTIVE, {
      onCompleted: (response) => {
        resetKeepAliveTimer()

        // remove ajuste from objective to force reloading in step 5
        const { ajuste, ...rest } = objective;
        setObjective({...rest, ...response.objective})
        setSelected(response.objective.comercialesSeleccionados)
      },
    }),
  )
  const resource = useQuery(GET_COMMERCIAL_HIERARCHY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      codDistribuidor,
    },

    onCompleted: resetKeepAliveTimer,

  })
  const jerarquia = get(resource, 'data.jerarquiaComercial.jerarquia')

  const empleadosResource = useQuery(GET_AVAILABLE_SALESMEN, {
    fetchPolicy: 'network-only',
    skip: isNil(objective.codObjetivo),
    variables,
  })
  const empleados = get(empleadosResource, 'data.empleados')

  const setSelectedComercials = comercials =>
    addSalesmenToObjective({
      variables: {
        salesmen: comercials,
      },
    })

  useEffect(() => {
    if (empleados && empleados.length) {
      setHierarchy(
        filterHierarchy(
          jerarquia,
          empleados.map(({ codEmpleado }) => codEmpleado),
          objective.paraEspecialistas,
        ),
      )
    }
  }, [empleados, jerarquia])

  useEffect(() => {
    if (filteredHierarchy) {
      const salesmen = findSalesMen(
        filteredHierarchy,
        objective.paraEspecialistas,
      )
        .filter(({ miembro }) => miembro.nivel === 'comercial')
        .map(({ miembro }) => miembro.codMiembro)

      setSalesmenAvailable(
        empleados.filter(({ codEmpleado }) => salesmen.includes(codEmpleado)),
      )
    }
  }, [filteredHierarchy])

  useEffect(() => {
    if (salesmenAvailable.length) {
      const salesmen = salesmenAvailable.map(({ codEmpleado }) => codEmpleado)
      if (!selectedComercials.length) {
        setSelectedComercials(salesmen)
      } else {
        setSelected(selectedComercials.filter(id => salesmen.includes(id)))
      }
    }
  }, [salesmenAvailable])

  const selectedHierarchy = filterHierarchy(
    jerarquia,
    selectedComercials,
    objective.paraEspecialistas,
  )

  return (
    <div style={{ paddingTop: 23, width: '100vw' }}>
      <ResourceWrapper resource={resource}>
        <ObjectiveLeftCol>
          <ComercialList
            dataSource={salesmenAvailable}
            selectedComercials={selectedComercials || []}
            setSelectedComercials={setSelectedComercials}
          />
        </ObjectiveLeftCol>
        <ObjectiveRightCol>
          <ObjectiveRightColInner>
            <ObjectivePageTitle>Comerciales seleccionados</ObjectivePageTitle>
            {selectedHierarchy && <EmployeeTree dataSource={selectedHierarchy} />}
          </ObjectiveRightColInner>
        </ObjectiveRightCol>
      </ResourceWrapper>
    </div>
  )
}

const ObjectiveRightColInner = styled.div`
  min-width: 600px;
  padding-right: 30px;
`
