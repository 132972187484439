import React, { useState } from 'react'
import styled from '@emotion/styled'
import { UserInfoCell, TotalRowEmployees, AdjustesTable } from 'Components'
import { colors } from 'Utils'
import { Empty } from 'antd';

export const ObjectiveAdjustmentTable = ({
  dataSource,
  onChange,
  hierarchied,
  showPeriod,
  periodDate,
  isNumeric,
}) => {
  const [showDetails, setShowDetails] = useState(false)

  const {
    cabeceraMeses = [],
    valoresCeldaPorEmpleadoMes = [],
    valoresTotalPorEmpleado = [],
    valoresTotalPorMes = [],
    cabeceraTotal = '',
  } = dataSource

  const isPercentage = valoresCeldaPorEmpleadoMes.length > 0 ? (
    valoresCeldaPorEmpleadoMes[0].celdas[0].valorRef &&
    valoresCeldaPorEmpleadoMes[0].celdas[0].valorRef.unidad === '%') : false

  const isData = valoresCeldaPorEmpleadoMes.length > 0 ? (
    valoresCeldaPorEmpleadoMes[0].celdas[0].valorRef &&
    (valoresCeldaPorEmpleadoMes[0].celdas[0].valorRef.valor ||
      valoresCeldaPorEmpleadoMes[0].celdas[0].valorRef.valor === 0)) : false

  return (
    isData ? (
      <>
        <Wrapper>
          <AdjustesColumn shadow isAbsolut={showPeriod}>
            {valoresCeldaPorEmpleadoMes.map(({ empleado, nivelAnidacion }, index) => (
              <UserInfoCell
                key={`${empleado.codMiembro}_${index}`}
                empleado={empleado}
                nivel={nivelAnidacion}
                hierarchied={hierarchied}
                isPercentage={isPercentage}
              />
            ))}
          </AdjustesColumn>
          {cabeceraTotal && valoresTotalPorEmpleado && (
            <AdjustesColumn showDetails={showDetails} isAbsolut={showPeriod}>
              <TotalRowEmployees
                condensed={!cabeceraTotal}
                title={cabeceraTotal}
                dataSource={valoresTotalPorEmpleado}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
                isPercentage={isPercentage}
              />
            </AdjustesColumn>
          )}
          {(!cabeceraTotal || showDetails) && (
            <AdjustesColumn shadow isAbsolut={showPeriod}>
              <AdjustesTable
                condensed={!cabeceraTotal}
                headerValues={cabeceraMeses}
                dataSource={valoresCeldaPorEmpleadoMes}
                footerValues={valoresTotalPorMes}
                onChange={onChange}
                hierarchied={hierarchied}
                periodDate={periodDate}
                showPeriod={showPeriod}
                isNumeric={isNumeric}
                isPercentage={isPercentage}
              />
            </AdjustesColumn>
          )}
        </Wrapper>
      </>
    ) : (
      <>
        <br />
        <Empty description={'No hay datos que mostrar'} />
      </>
    )

  )
}

const Wrapper = styled.div({
  display: 'flex',
  width: 'fit-content',
})

export const AdjustesColumn = styled.div(({ shadow, showDetails, isAbsolut }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  '>div': {
    boxShadow: shadow
      ? '1px 1px 20px 0 rgba(200, 198, 198, 0.5)'
      : showDetails
        ? `0px 0px 10px 1px ${colors.lime}`
        : 'none',
  },
  zIndex: showDetails && 10,
  margin: '20px 0 20px 0',
  ':first-of-type': {
    height: 'fit-content',
    margin: isAbsolut ? '75px 0 20px 20px' : '56px 0 20px 20px',
  },
  ':last-child': {
    margin: '20px 30px 20px 0',
    width: 'min-content',
  },
}))
