import React, { useState, useEffect } from 'react'
import { Form, Input, Button } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { FormLabel } from './'
import { Modal, confirmHesaSubmit, confirmHesaSignUp, hesaWarning } from 'Components'
import { isEmpty } from 'lodash'
import { CHECK_COMERCIAL_EMAIL, CHECK_DROP_HESA } from 'Queries'
import { useLazyQuery } from '@apollo/client'

// eslint-disable-next-line
const emailRE = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const AddUserHesaForm = ({ props, setProps, isLoading }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [checkData, setCheckData] = useState({ name: '', phone: '' })
  const [codEmpleado, setCodEmpleado] = useState(undefined)
  const [checkEmail, setCheckEmail] = useState(false)
  const [waitEmail, setWaitEmail] = useState(false)
  const [checkUser, setCheckUser] = useState(false)
  const [hasData, setHasData] = useState(false)
  const altaBaja = props.activo !== null ? 'baja' : 'alta'

  const [isSubmitDisabled, setSubmitDisabled] = useState(false)

  const [checkComercilEmail, { loading }] = useLazyQuery(CHECK_COMERCIAL_EMAIL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ existeEmpleadoHesaModal }) => {
      if (waitEmail) {
        if (existeEmpleadoHesaModal !== null) {
          if (existeEmpleadoHesaModal.codDistribuidor !== null) {
            hesaWarning(existeEmpleadoHesaModal.codDistribuidor)
          } else {
            setName(existeEmpleadoHesaModal.nombre)
            setPhone(existeEmpleadoHesaModal.telefono)
            setCodEmpleado(existeEmpleadoHesaModal.codEmpleado)
            setCheckData({ name: existeEmpleadoHesaModal.nombre, phone: existeEmpleadoHesaModal.telefono })
            setHasData(true)
          }
        } else {
          setName('')
          setPhone('')
          setCodEmpleado('')
          setCheckData({ name: '', phone: '' })
          setHasData(false)
        }
        setWaitEmail(false)
        setCheckEmail(true)
      }
    },
  })


  // SignUp

  const onSignUp = async () => {
    const variables = {
      codDistribuidor: props.codDistribuidor,
      codEmpleado: props.codEmpleado,
      nivel: !props.activo ? 'comercial_hesa' : undefined,
    }
    // await onSubmit(null, false)
    props
      .signUp({ variables })
      .then(afterSubmit)
      .catch(() => setSubmitDisabled(false))
  }

  const [checkDeleteUser] = useLazyQuery(CHECK_DROP_HESA, {
    fetchPolicy: 'network-only',
    onCompleted: ({ comprobarBorrarBajaEmpleadoHesa }) => {
      if (checkUser) {
        confirmHesaSignUp(props.activo, altaBaja, onSignUp, comprobarBorrarBajaEmpleadoHesa);
        setCheckUser(false)
      }
    },
  })

  // Submit 

  const onSubmitFn = props && (props.isEdit || (!props.isEdit && hasData)) ? props.editSubmit : props.createSubmit

  const onSubmit = (_, closeModal = true) => {
    setSubmitDisabled(true)
    const variables = {
      codDistribuidor: props.codDistribuidor,
      codEmpleadoGerente: props.codGerente,
      codProveedor: props.parentCode,
      codEmpleado: (props.isEdit || hasData) ? codEmpleado : undefined,
      nombre: name,
      email: (!props.isEdit && !hasData) ? email : undefined,
      telefono: phone,
      tipoPerfil: 'comercial_hesa',
    }

    return onSubmitFn({ variables })
      .then(() => {
        if (closeModal) afterSubmit()
      })
      .catch(() => setSubmitDisabled(false))
  }

  useEffect(() => {
    if (!props.isEdit && emailRE.test(email)) {
      setCheckData({ name: '', phone: '' })
      setCheckEmail(false)
      const timeoutId = setTimeout(() => {
        setWaitEmail(true)
        checkComercilEmail({
          variables: {
            codDistribuidor: props.codDistribuidor,
            email,
          }
        })
      }, 1500)
      return () => clearTimeout(timeoutId);
    }
  }, [email]);

  useEffect(() => {
    setName(props.name || '')
    setEmail(props.email || '')
    setPhone(props.phone || '')
    setCodEmpleado(props.codEmpleado || '')
    setHasData((!props.isEdit && props.name) ? true : false)
    setCheckData({ name: '', phone: '' })
    setCheckEmail(false)
    setCheckUser(false)
  }, [props])

  const afterSubmit = () => {
    setProps({ visible: false })
    setName('')
    setEmail('')
    setPhone('')
    setCodEmpleado('')
    setCheckData({ name: '', phone: '' })
    setCheckEmail(false)
    setCheckUser(false)
    setSubmitDisabled(false)
  }

  const disableSignIn = isSubmitDisabled || !name || !emailRE.test(email)
  const disableSubmit = isSubmitDisabled || isEmpty(name)

  return (
    <Modal
      title={props.title}
      subtitle={props.subtitle}
      visible={props.visible}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setProps({
              title: props.title,
              subtitle: props.subtitle,
              okText: props.okText,
              visible: false,
            })
          }}
        >
          Cancelar
        </Button>,
        props.isEdit && (
          <SuscribeButton
            key="signup"
            icon={<UserAddOutlined />}
            disabled={disableSignIn}
            title={disableSignIn ? 'email y nombre son obligatorios' : ''}
            onClick={() => {
              setCheckUser(true);
              checkDeleteUser({ variables: { codEmpleado: props.codEmpleado } });
            }}
          >
            Dar de {altaBaja}
            {/* Dar de alta */}
          </SuscribeButton>
        ),
        <Button
          loading={isLoading}
          key="submit"
          type="primary"
          onClick={() => {
            if (!props.isEdit && hasData &&
              ((checkData.name !== '' && checkData.name !== name) || (checkData.phone !== '' && checkData.phone !== phone))) { confirmHesaSubmit(onSubmit) }
            else { onSubmit() }
          }}
          disabled={disableSubmit}
        >
          {props.okText}
        </Button>,
      ].filter(btn => btn)}
    >
      <Form layout="vertical">
        <FormItem label="E-mail">
          <Input
            disabled={props.isEdit}
            value={email}
            onChange={e => { setEmail(e.currentTarget.value) }}
          />
        </FormItem>

        <FormItem label="Nombre">
          <Input
            disabled={!props.isEdit && (loading || !checkEmail)}
            value={name}
            onChange={e => { setName(e.currentTarget.value) }}
          />
        </FormItem>

        <FormItem label={<FormLabel title="Teléfono" optional={true} />}>
          <Input
            disabled={!props.isEdit && (loading || !checkEmail)}
            value={phone}
            onChange={e => { setPhone(e.currentTarget.value) }}
          />
        </FormItem>
      </Form>
    </Modal>
  )
}

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    padding-bottom: 0px;
    margin-bottom: 24px;
  }
`

const SuscribeButton = styled(Button)`
  color: #417505;
`