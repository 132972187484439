import React from 'react'
import styled from '@emotion/styled'
import {
  OpCellInputDesc,
  OpHeaderCellWrapper,
  OpCellWrapper,
} from 'Components'

export const OpDescriptionRow = ({
  title,
  dataSource,
  onChange,
  showPeriod,
}) => {
  return (
    <Inner>
      <OpHeaderCellWrapper isAbsolut={showPeriod}>{title}</OpHeaderCellWrapper>
      {dataSource.map(({ empleado: { codMiembro }, description, nivelAnidacion }, index) => (
        <OpCellWrapper >
          <OpCellInputDesc
            isDisabled={nivelAnidacion !== 0 && (dataSource[index - 1].empleado.codMiembro === codMiembro || dataSource[0].empleado.codMiembro === codMiembro)}
            value={description}
            onChange={value => onChange(0, codMiembro, { description: value, inctv: null, percentage: null })}
          />
        </OpCellWrapper>
      ))}
    </Inner>
  )
}

const Inner = styled.div({
  position: 'relative',
  width: 'min-content',
})
