import gql from "graphql-tag";

export const DISTRIBUTOR_FRAGMENT = gql`
  fragment distributorFragment on Distributor {
    codigoDistribuidor
    codigoEmpleado
    codigoProveedorHesa
    cif
    poblacion
    provincia
    razonSocial
    direccionFiscal
    codigoPostal
    gerente
    email
    erp
    modifEn
    user
    password
  }
`;

export const ALL_DISTRIBUTORS = gql`
  query allDistributors {
    Distributor: allDistributors {
      ...distributorFragment
    }
  }
  ${DISTRIBUTOR_FRAGMENT}
`;

export const CREATE_DISTRIBUTORS = gql`
  mutation createDistributor(
    $codigoDistribuidor: Long!
    $codigoProveedorHesa: String
    $cif: String
    $poblacion: String
    $provincia: String
    $razonSocial: String
    $direccionFiscal: String
    $codigoPostal: String
    $gerente: String
    $email: String!
    $erp: String
    $user: String
    $password: String
  ) {
    Distributor: createDistributor(
      codigoDistribuidor: $codigoDistribuidor
      cif: $cif
      poblacion: $poblacion
      provincia: $provincia
      razonSocial: $razonSocial
      direccionFiscal: $direccionFiscal
      codigoPostal: $codigoPostal
      codigoProveedorHesa: $codigoProveedorHesa
      gerente: $gerente
      email: $email
      erp: $erp
      user: $user
      password: $password
    ) {
      ...distributorFragment
    }
  }
  ${DISTRIBUTOR_FRAGMENT}
`;

export const EDIT_DISTRIBUTORS = gql`
  mutation editDistributor(
    $codigoDistribuidor: Long!
    $codigoEmpleado: Long!
    $codigoProveedorHesa: String
    $cif: String
    $poblacion: String
    $provincia: String
    $razonSocial: String
    $direccionFiscal: String
    $codigoPostal: String
    $gerente: String
    $email: String!
    $erp: String
    $user: String
    $password: String
  ) {
    Distributor: editDistributor(
      codigoDistribuidor: $codigoDistribuidor
      codigoEmpleado: $codigoEmpleado
      codigoProveedorHesa: $codigoProveedorHesa
      cif: $cif
      poblacion: $poblacion
      provincia: $provincia
      razonSocial: $razonSocial
      direccionFiscal: $direccionFiscal
      codigoPostal: $codigoPostal
      gerente: $gerente
      email: $email
      erp: $erp
      user: $user
      password: $password
    ) {
      ...distributorFragment
    }
  }
  ${DISTRIBUTOR_FRAGMENT}
`;
