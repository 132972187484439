import React from 'react'

import { Content } from 'Components'
import { Header } from 'Fragments'

import { Layout } from 'antd'

export const Legal = () => (
  <Layout>
    <Header onlyLogo />
    <Content nosider={1} fullwidth={'A'}>
      <h1>Aviso Legal</h1>
      <p>&nbsp;</p>
      <div>
        <ol>
          <li>
            <strong>INFORMACIÓN GENERAL</strong>
          </li>
        </ol>
        <p>
          Las presentes condiciones regulan el acceso, descarga y uso de la
          página web y/o aplicación y/o plataforma en la que está navegando (en
          adelante, de manera conjunta, el “<strong>Sitio Web</strong>”),
          propiedad de <strong>Heineken España, S.A.</strong> (en adelante, “
          <strong>Heineken</strong>”). Estas condiciones regulan asimismo el
          acceso, descarga y uso de cualquier otra información, texto, gráficos,
          fotos, imágenes, música, etc. a los que se pueda acceder desde el
          Sitio Web (en adelante, el “<strong>Contenido</strong>”).
        </p>
        <p>
          En cumplimiento de lo dispuesto en el artículo 10 de la Ley 34/2002,
          de 11 de julio, de servicios de la sociedad de la información y de
          comercio electrónico, Heineken, titular del Sitio Web y de su
          Contenido, pone a disposición de los usuarios de este Sitio Web con
          residencia o acceso desde el territorio español, la siguiente
          información de carácter general:
        </p>
        <p>
          <ul>
            <li>
              <strong>Denominación social:</strong> Heineken España, S.A.
            </li>
            <li>
              <strong>Domicilio social:</strong> Avenida de Andalucía 1, 41007,
              Sevilla (España
            </li>
            <li>
              <strong>Contacto:</strong> Para cualquier consulta pueden ponerse
              en contacto con Heineken España, S.A. a través de los siguientes
              teléfonos: Sevilla (+34) 954 97 99 99 / Madrid (+34) 91 714 92 00
            </li>
            <li>
              <strong>Datos de inscripción en el Registro Oficial:</strong>{' '}
              Datos de inscripción en el Registro Mercantil: Inscrita en el
              Registro Mercantil de la provincia de Sevilla R, al Tomo 3.063,
              Folio 217 vuelto, Hoja SE-40.424.
            </li>
            <li>
              <strong>Número de identificación Fiscal:</strong> A-28006013
            </li>
          </ul>
        </p>
        <p>&nbsp;</p>
        <ol start="2">
          <li>
            <strong>OBJETO </strong>
          </li>
        </ol>
        <p>
          El acceso y el uso del Sitio Web se regirán y quedarán sujetos a las
          presentes condiciones de uso. La utilización del Sitio Web atribuye la
          condición de “<string>Usuario</string>”.
        </p>
        <p>
          No obstante, Heineken se reserva el derecho a modificar en cualquier
          momento las presentes condiciones, por lo que el Usuario debe leer
          detenidamente los mismos cada vez que acceda al Sitio Web para ser
          conocedor del alcance y/o cualquier modificación que se haya
          producido. Al acceder al Sitio Web y el Contenido con posterioridad a
          la publicación de dichas modificaciones, alteraciones o
          actualizaciones, el Usuario acepta cumplir con las nuevas condiciones.
        </p>
        <p>
          Cualquier disposición de las presentes condiciones que sea o devengue
          nula, anulable, ilegal o inexigible será excluida y considerada
          inaplicable, siendo sustituida por otra similar a la anterior, pero
          que no afecte o perjudique a las restantes disposiciones que
          permanecerán al margen y, por el contrario, plenamente vigentes.
        </p>
        <p>&nbsp;</p>
        <ol start="3">
          <li>
            <strong>CONTENIDO DEL SITIO WEB </strong>
          </li>
        </ol>
        <p>
          Heineken se reserva, sin necesidad de previo aviso y en cualquier
          momento, el derecho a suspender con carácter temporal o definitivo el
          acceso al Sitio Web, así como a todo o parte de su Contenido y a
          efectuar las modificaciones que considere oportunas en el mismo.
        </p>
        <p>
          En relación con el Contenido ofrecido por Heineken de buena fe, dada
          la gran cantidad de información que se ofrece, algunas de estas
          informaciones pueden no ser totalmente exactas o estar
          desactualizadas, si bien Heineken hace importantes esfuerzos para que
          no sea así.
        </p>
        <p>
          Por último, Heineken dispone su Contenido en el ámbito territorial de
          España. Dada la naturaleza inherente a Internet, Heineken no garantiza
          que el Contenido sea apto o disponible fuera del territorio de España.
          Si alguno o todo el Contenido alojado en el Sitio Web fuera
          considerado ilegal en otro país, queda prohibido el acceso al mismo,
          así como su utilización. Y, en su caso, su utilización será
          exclusivamente bajo la responsabilidad del Usuario, estando éste
          obligados al cumplimiento y observancia de las leyes aplicables de
          dicho país.
        </p>
        <p>&nbsp;</p>
        <ol start="4">
          <li>
            <strong>UTILIZACIÓN DEL SITIO WEB </strong>
          </li>
        </ol>
        <p>
          El Usuario se compromete a utilizar el Sitio Web de conformidad con la
          legislación vigente y con las presentes condiciones y a no incurrir en
          actuaciones que infrinjan los derechos de Heineken o terceros, o que
          puedan atentar contra la moral, las buenas costumbres o el orden
          público.{' '}
        </p>
        <p>
          Asimismo, a título meramente enunciativo, y no exhaustivo, se
          considera terminantemente prohibido el uso del Sitio Web con los
          siguientes fines o consecuencias:{' '}
        </p>
        <p>
          <ol type="a">
            <li>
              Difundir contenidos o propaganda de carácter político, racista,
              xenófobo, pornográfico, contrario a los derechos humanos o que
              haga apología del terrorismo.
            </li>
            <li>
              Destruir, alterar, inutilizar o de cualquier otra forma dañar los
              datos, programas o documentos electrónicos de Heineken o terceros.
            </li>
            <li>
              Obstaculizar la entrada de otros usuarios al servicio de acceso
              mediante el consumo masivo de los recursos informáticos a través
              de los cuales Heineken presta el servicio, así como realizar
              acciones que dañen interrumpan o generen errores en dichos
              sistemas.
            </li>
            <li>
              Provocar daños en los sistemas informáticos de Heineken, de sus
              proveedores o de terceros y/o introducir o difundir virus
              informáticos, programas, macros, applets, controles Active X o
              cualquier otro tipo de sistemas físicos o lógicos que causen o
              puedan causar daños en sistemas informáticos.
            </li>
            <li>
              Realizar actos contrarios a los derechos de Propiedad Intelectual
              y/o Industrial de Heineken o de otros terceros legítimos
              titulares.
            </li>
            <li>
              Reproducir o copiar, distribuir, permitir el acceso del público a
              través de cualquier modalidad de comunicación pública o
              transformar el Contenido a menos que se cuente con la autorización
              del titular de los correspondientes derechos o ello resulte
              legalmente permitido.
            </li>
            <li>
              Emplear el Contenido y, en particular, la información de cualquier
              clase obtenida a través del Sitio Web para remitir publicidad,
              comunicaciones con fines de venta directa o cualquier otra clase
              de finalidad comercial, mensajes no solicitados dirigidos a una
              pluralidad de personal con independencia de su finalidad, o
              comercializar o divulgar de cualquier modo dicha información.
            </li>
          </ol>
        </p>
        <p>
          El Usuario responderá de los daños y perjuicios de toda naturaleza que
          Heineken o terceros puedan sufrir, directa o indirectamente, como
          consecuencia del incumplimiento de cualquiera de las obligaciones
          derivadas de las presentes condiciones.
        </p>
        <p>
          Los contenidos de cualquier índole no relacionados con la temática del
          Sitio Web podrán ser eliminados así como todos aquellos comentarios
          que no respeten las presentes condiciones ni las buenas costumbres,
          sean comentarios de carácter ilícito, vandálico, peyorativo, racista,
          que inciten a actos violentos, tengan carácter publicitario ajeno a
          los productos de Heineken y/o infrinjan derechos de terceros,
          incluidos menores, grupos sociales débiles, personas afectadas por
          minusvalías y/o minorías, o tengan un contenido religioso, sexual y/o
          político.
        </p>
        <p>
          Asimismo, Heineken se reserva el derecho a denegar discrecionalmente
          en cualquier momento y sin necesidad de preaviso el acceso de
          cualquier Usuario a este Sitio Web o a alguna parte del mismo cuando
          concurra alguna de las conductas no permitidas de acuerdo con las
          presentes condiciones.
        </p>
        <p>&nbsp;</p>
        <ol start="5">
          <li>
            <strong>PROPIEDAD INTELECTUAL E INDUSTRIAL</strong>
          </li>
        </ol>
        <p>
          El Usuario reconoce los derechos de propiedad intelectual e Industrial
          de Heineken o, en su caso, de tercero, sobre el Sitio Web y los
          elementos que la constituyen (entre otros: fotografías, dibujos,
          textos, audios, videos, softwares, diseños, signos identificativos, el
          código HTML, los applets de JAVA o java Script, controles ActiveX,
          etc.).
        </p>
        <p>
          Heineken se reserva los derechos de distribución, reproducción,
          comunicación pública o transformación así como cualquier otro derecho
          de propiedad intelectual que pueda corresponderle sobre el Contenido
          del Sitio Web. Igualmente, están protegidos las marcas, dominios,
          nombres comerciales o cualquier otro signo distintivo sometido a
          propiedad industrial que sea titularidad de Heineken, o signos de
          terceras empresas que pudieran estar incluidas en el Sitio Web sobre
          los que ésta sociedad pudiera ostentar algún tipo de derecho, siendo
          la anterior relación meramente enunciativa y en ningún caso
          limitativa.
        </p>
        <p>
          En ningún caso el acceso al Sitio Web y su uso, aunque ambos fuesen
          autorizados por Heineken, implica cesión, licencia o cualquier tipo de
          renuncia, ni total ni parcial de derechos de Propiedad Intelectual y/o
          Industrial por parte de Heineken o sus legítimos titulares. Asimismo,
          del acceso al Sitio Web no se deriva ningún tipo de derecho salvo que
          exista autorización previa y por escrito de Heineken o de los terceros
          titulares de dichos derechos. Por su parte, toda la información del
          Usuario que se reciba en este Sitio Web, a través de textos, imágenes,
          sonidos, o cualquier forma de software, se considerará cedida a
          Heineken a título gratuito.
        </p>
        <p>
          El Usuario únicamente podrá obtener una copia privada siempre y cuando
          dicha copia sea única y exclusivamente para su uso personal y privado,
          quedando terminantemente prohibida su utilización para fines
          comerciales y no pudiendo, en ningún caso, modificar o suprimir la
          información que, en su caso, exista sobre los derechos de Heineken o
          terceros.
        </p>
        <p>
          El Usuario únicamente podrá acceder a los contenidos mediante los
          medios o procedimientos que se hayan puesto a disposición a este
          efecto en el Sitio Web o se utilicen habitualmente en aplicaciones
          electrónicas para ese fin, siempre que no impliquen violación de los
          derechos de Propiedad Intelectual o Industrial algún tipo de daño para
          el Sitio Web y/o su información y servicios ofrecidos.
        </p>
        <p>
          Las reclamaciones que pudieran interponerse por el Usuario en relación
          con posibles incumplimientos de los derechos de propiedad intelectual
          o industrial sobre cualesquiera de los contenidos del Sitio Web
          deberán dirigirse a los datos de contacto indicados en el apartado
          Información General.
        </p>
        <p>&nbsp;</p>
        <ol start="6">
          <li>
            <strong>RESPONSABILIDAD</strong>
          </li>
        </ol>
        <p>
          6.1 El uso que pueda hacerse del Contenido que aparecen en el Sitio
          Web se realizará bajo la exclusiva responsabilidad del Usuario. Es
          decir, el Usuario será el único responsable de las infracciones en que
          pueda incurrir o de los perjuicios que pueda causar por el uso del
          Sitio Web, quedando Heineken exonerada de cualquier responsabilidad.
          El Usuario es el único responsable frente a cualquier reclamación o
          acción legal, judicial o extrajudicial iniciada por terceras personas
          contra Heineken, o bien contra él mismo, basada en su utilización del
          Sitio Web. El Usuario asume cuantos gastos, costes e indemnizaciones
          sean ocasionados a Heineken con motivo de tales reclamaciones o
          acciones legales. No obstante, en su caso, en materia de
          responsabilidad por el Contenido, el Usuario podrá interponer sus
          reclamaciones a través de los datos de contacto indicados en el
          apartado Información General.
        </p>
        <p>
          6.2 Heineken no será responsable en ningún caso de los contenidos,
          productos y/o servicios alojados, transmitidos, ofertados y/o
          prestados por terceros ajenos a Heineken a través del Sitio Web. No
          obstante lo anterior, Heineken garantiza que en caso de conocer, en
          virtud de resolución dictada por el organismo público competente, la
          ilicitud de alguno de los contenidos, productos y/o servicios
          ofertados por terceros a través del Sitio Web, pondrá todos los medios
          necesarios para la supresión de los mismos a la mayor brevedad
          posible.
        </p>
        <p>
          6.3 Heineken no garantiza que los contenidos del Sitio Web sean aptos
          o disponibles en el país desde el que el Usuario acceda a la misma. En
          el supuesto de que todo o parte de los contenidos del Sitio Web sean
          considerados ilegales en virtud de la normativa aplicable al país
          desde el que acceda el Usuario, queda prohibido el acceso a los mismos
          y en el caso de que este se produzca, será exclusivamente bajo su
          responsabilidad, estando obligado al cumplimiento de las leyes
          nacionales de aplicación.
        </p>
        <p>
          6.4 Heineken no será responsable en caso de que existan interrupciones
          del servicio, demoras, errores, mal funcionamiento del mismo y, en
          general, demás inconvenientes para el acceso del Usuario al Sitio Web.
        </p>
        <p>
          6.5 Heineken pone a disposición del Usuario una serie de links,
          banners y otro tipo de enlaces. El acceso a otras páginas web de
          terceros a través de dichas conexiones o links se realizará bajo la
          exclusiva responsabilidad de aquellos que efectúen este tipo de actos,
          no siendo Heineken responsable, en ningún caso, de los daños o
          perjuicios que puedan derivarse de los mismos.
        </p>
        <p>
          6.6 Asimismo, Heineken no asume ninguna responsabilidad por cualquier
          daño o perjuicio en el software o hardware del Usuario que se derive
          del acceso al Sitio Web o del uso de información en ella contenidas,
          siendo obligación del Usuario disponer de medios actualizados que
          protejan adecuadamente su dispositivo y de programas con licencia de
          uso autorizada y vigente que permita la compatibilidad de su equipo
          con contenidos susceptibles de divulgación en Internet.{' '}
        </p>
        <p>&nbsp;</p>
        <ol start="7">
          <li>
            <strong>LEY APLICABLE Y JURISDICCIÓN</strong>
          </li>
        </ol>
        <p>
          El acceso y uso del Sitio Web y de su Contenido, están sometidos a la
          legislación española. Mediante el acceso a este Sitio Web, el Usuario
          somete expresamente cualquier controversia que pudiera derivarse en
          relación con la interpretación de las presentes condiciones de uso, o
          de su acceso y uso de la misma, a la legislación española y los
          juzgados y tribunales competentes.
        </p>
      </div>
    </Content>
  </Layout>
)
