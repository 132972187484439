import React from 'react'
import { formatNumberWithThousands } from 'Utils'
import styled from '@emotion/styled'

export const OpCellTitle = ({ dataSource }) => {
  if (!dataSource) return null
  const { valor, unidad } = dataSource

  return (
    <RefNumber>
      {formatNumberWithThousands(valor)}
      {unidad || ''}
    </RefNumber>
  )
}

const RefNumber = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  margin: 10px 0;
`
