import moment from 'moment'
import 'moment/locale/es'

moment.defineLocale('es-hk', {
  parentLocale: 'es',
  weekdaysMin: 'D_L_M_X_J_V_S'.split('_'),
})

const sessionKey = 'HKKT_session'

const storage = window.sessionStorage

export const initSession = () => {
  storage.setItem(sessionKey, JSON.stringify({}))
}

const getStorageObject = () => JSON.parse(storage.getItem(sessionKey))

export const getItem = key => {
  const storageObject = getStorageObject()
  return storageObject[key]
}

export const setItem = (key, value) => {
  const storageObject = getStorageObject()
  storageObject[key] = value
  storage.setItem(sessionKey, JSON.stringify(storageObject))
}

export function setUserToSession(user) {
  setItem('currentUser', user)
}

export function getUserFromSession() {
  getItem('currentUser')
}

export const resetTimeout = (cb, interval, delay) => {
  if (interval) {
    clearTimeout(interval)
  }
  return setTimeout(cb, delay)
}

export const commonError = (type, errorMessage) => {
  if (type === 'Network' && errorMessage.includes('Failed to fetch')) {
    return 'Ha ocurrido un problema al cargar los datos.\n Refresque la página o acceda de nuevo y sea paciente.'
  }
  if (type === 'GraphQL' && errorMessage.includes('Exception while fetching data')) {
    return 'Ha ocurrido un problema al obtener los datos.\n Espere un momento y recargue la página o acceda de nuevo.'
  }
  return errorMessage
}