import React, { useState, useContext } from 'react'
import { Breadcrumb, CategoryList, Category } from './shared'
import GlobalContext from 'Utils/context'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { ResourceWrapper } from 'Components'
import { Spin } from 'antd'

export const ObjectiveCategoryList = ({ onChange, query, isLoading = false }) => {
  const [allChecked] = useState(false)

  const context = useContext(GlobalContext)
  const codDistribuidor = get(context, 'currentUser.codDistribuidor')

  const variables = {
    codDistribuidor,
  }

  const resource = useQuery(query, {
    variables,
    onCompleted: context.resetKeepAliveTimer,
  })

  const categories = get(resource, 'data.categories') || []

  return (
    <ResourceWrapper resource={resource}>
      <Breadcrumb>Categorías</Breadcrumb>
      <Spin indicator={<span style={{ opacity: 0 }} />} spinning={isLoading}>
        <CategoryList>
          {categories.map((category, index) => (
            <Category
              onClick={() => !allChecked && onChange(category)}
              enabled={!allChecked}
              key={index}
            >
              {category.desc}
            </Category>
          ))}
        </CategoryList>
      </Spin>
    </ResourceWrapper>
  )
}
