import React, { useState } from 'react'
import { Checkbox, } from 'antd'
import { Icon as AntIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { showCantDeleteConfirm, showDeleteConfirm } from 'Components'
import { ReactComponent as RecicleBinIcon } from 'Assets/icons/recycle-bin-icon.svg'
import { ReactComponent as EditIcon } from 'Assets/icons/edit-icon.svg'
import { ReactComponent as AddWhiteIcon } from 'Assets/icons/add-white-icon.svg'
import { ReactComponent as AddGreenIcon } from 'Assets/icons/add-green-icon.svg'
import { CHECK_DROP_HESA } from 'Queries'
import { useLazyQuery } from '@apollo/client'

export const ProviderActions = ({ dataSource, actions, nodeKey }) => {
  const isProveedor = dataSource.nivel === 'proveedor'
  const isHesa = dataSource.nivel === 'comercial_hesa'
  const isComercial = dataSource.nivel === 'comercial'

  const [checkUser, setCheckUser] = useState(false)
  const deleteAction = isProveedor ? actions.deleteProvider : actions.deleteComercial;

  const onDeleteOk = () => {
    return deleteAction({
      variables: {
        codDistribuidor: parseInt(nodeKey.split("-")[0]),
        codProveedor: isProveedor ? dataSource.id : nodeKey.split("-")[1],
        codEmpleado: !isProveedor ? dataSource.id : undefined,
      },
    })
  }

  const onDelete = event => {
    event.stopPropagation()

    if ((isHesa && dataSource.username === null) || (isProveedor && dataSource.deletable)) {
      showDeleteConfirm(dataSource.nombre, onDeleteOk, true)
    } else {
      const errorMesage = (dataSource.infoObjetivos && dataSource.infoObjetivos.length > 0) ? 2 : 0;
      showCantDeleteConfirm(errorMesage)
    }
  }

  const [checkDeleteUser] = useLazyQuery(CHECK_DROP_HESA, {
    fetchPolicy: 'network-only',
    onCompleted: ({ comprobarBorrarBajaEmpleadoHesa }) => {
      if (checkUser) {
        if (comprobarBorrarBajaEmpleadoHesa > 1) {
          showDeleteConfirm(dataSource.nombre, onDeleteOk, true)
        } else {
          showCantDeleteConfirm(1)
        }
        setCheckUser(false)
      }
    },
  })

  function onGetIndeterminate(){
    const comercialCodes = dataSource.comercials?.map (item => item.codComercial);
    const comFilters = dataSource.comercialesVisibles.filter (sublist => comercialCodes.includes(sublist));

    return comercialCodes.length !== comFilters.length && comFilters.length > 0
  }

  function onGetChecked() {
    const comercialCodes = dataSource.comercials?.map (item => item.codComercial);
    const comFilters = dataSource.comercialesVisibles.filter (sublist => comercialCodes.includes(sublist));

    return comercialCodes.length === comFilters.length;
  }


  const onShow = (checked, nodes, all = false) => {
    const codes = nodes.split("-")

    actions.changeComercial({
      variables: {
        codDistribuidor: parseInt(codes[0]),
        codProveedor: codes[1],
        codEmpleado: parseInt(codes[2]),
        codComercial: all ? dataSource.comercials.map(({ codComercial }) => codComercial) : parseInt(codes[3]),
        status: !checked,
      },
    });
  }

  const onEdit = event => {
    event.stopPropagation()
    let popupProps = {
      subtitle: 'Usuario HESA',
      profileType: dataSource.nivel,
      name: dataSource.nombre,
      email: dataSource.email,
      phone: dataSource.telefono,
      activo: dataSource.activo,
    }

    actions.showEditPopup({
      ...popupProps,
      parentCode: nodeKey.split("-")[1],
      codEmpleado: dataSource.id
    })
  }

  const onAdd = event => {
    event.stopPropagation()
    let popupProps = {
      subtitle: 'Usuario HESA',
      profileType: 'comercial_hesa',
      name: '',
      email: '',
      phone: '',
    }

    actions.showAddPopup({
      ...popupProps,
      parentCode: dataSource.id,
      edit: actions.EditComercial,
      create: actions.createComercial
    })
  }

  const onModify = event => {
    event.stopPropagation()

    actions.lockUnlock({
      variables: {
        codDistribuidor: parseInt(nodeKey.split("-")[0]),
        codEmpleado: dataSource.id
      },
    });
  }

  return (
    <Inner isProveedor={isProveedor}>

      {isComercial ? (
        <Checkbox
          style={{ marginRight: 15 }}
          checked={dataSource.comercialesVisibles && dataSource.comercialesVisibles.find(com => com === dataSource.id) !== undefined}
          onChange={e => onShow(!e.target.checked, nodeKey)}
        />
      ) : (
        <>
          {(dataSource.activo !== null && isHesa) && (
            <AntIcon
              type={dataSource.activo ? 'unlock' : 'lock'}
              title={
                dataSource.activo ? 'pulse para desactivar' : 'pulse para activar'
              }
              theme="twoTone"
              twoToneColor={dataSource.activo ? colors.fernGreen : colors.scarlet}
              onClick={onModify}
            />
          )}
          <AntIcon component={RecicleBinIcon} onClick={(e) => {
            if (isHesa && dataSource.username !== null) {
              setCheckUser(true)
              checkDeleteUser({ variables: { codEmpleado: dataSource.id } })
            }
            else { onDelete(e) }
          }} />
          {!isProveedor && (
            <>
              <AntIcon component={EditIcon} onClick={onEdit} />
              <Checkbox
                style={{ margin: '0 15px' }}
                indeterminate = {onGetIndeterminate()}
                checked= {onGetChecked()}
                onChange={e => onShow(!e.target.checked, nodeKey, true)}
              />
            </>
          )}
          {isProveedor && (
            <AntIcon component={dataSource.codPadre ? AddGreenIcon : AddWhiteIcon} onClick={onAdd} />
          )}
        </>
      )}

    </Inner>
  )
}

const Inner = styled.div`
  width: 200px;
  text-align: right;
  .anticon {
    color: ${props => props.isProveedor ? colors.white : colors.fernGreen};
    padding: 0 16px;
    font-size: 16px;
  }
`
