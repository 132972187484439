import React, { useContext, useState, useEffect } from 'react'

import {
  ObjectiveCategoryList,
  ObjectiveGroupList,
  ObjectiveCategoryValueList,
  ObjectiveLeftCol,
  ObjectiveRightCol,
  SelectionTag,
  TagContainer,
  WarningMessage,
  showWarningNotification
} from 'Components'
import { ArticlesTable } from 'Fragments'
import {
  GET_OBJECTIVE_ARTICLE_CATEGORIES,
  GET_OBJECTIVE_CATEGORY_ARTICLE_VALUES,
  UPDATE_OBJECTIVE_ARTICLE_CATEGORIES,
  UPDATE_OBJECTIVE_ARTICLE_EXCEPTIONS,
  OBJECTIVE,
  GET_ARTICLES,
  GET_AVAILABLE_SALESMEN,
  UPDATE_OBJECTIVE_SATURATION_GROUP,
  DELETE_OBJECTIVE_SATURATION_GROUP,
  UPDATE_OBJECTIVE_SATURATION_ARTICLES,
} from 'Queries'
import {
  GlobalContext,
  ObjectiveContextConsumer,
  ObjectiveContext,
  getMutationVars,
  getObjectiveIdVars,
  removeValueFromCategories,
  removeCategoryFromCategories,
  addValueToCategories,
  addCategoryToCategories,
  categoriesCount,
} from 'Utils'

import { Alert, Spin } from 'antd';
import styled from '@emotion/styled'
import { get, isEmpty, without, concat, cloneDeep } from 'lodash'
import { useMutation, useLazyQuery } from '@apollo/client'

export const ObjectiveArticles = () => (
  <ObjectiveContextConsumer>
    {({ objective }) =>
      !Number.isFinite(objective.codObjetivo) ? null : (
        <Articles objective={objective} />
      )
    }
  </ObjectiveContextConsumer>
)

const Articles = ({ objective }) => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { resetKeepAliveTimer, setObjective, setHasArticles, empleados, setEmpleados } = context

  const categoriesList =
    get(objective, 'categoriasSeleccionadasArticulo.categorias') || []
  const exceptionsList =
    get(objective, 'categoriasSeleccionadasArticulo.excepciones') || []
  const groupList =
    get(objective, 'categoriasSeleccionadasArticulo.saturacion') || []

  const tipoValorRefId = get(objective, 'tipoValorRef.id') || ''
  const [extraExceptionHash, setExtraExceptionHash] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [numArticles, setArticles] = useState(0)
  const [articleList, setArticleList] = useState([])

  const paraProveedor = get(objective, 'paraProveedor') || ''
  const paraEspecialistas = get(objective, 'paraEspecialistas') || false
  const nArtGroup = get(objective, 'nArtGroup') || 2
  const [groupIndex, setGroupIndex] = useState(tipoValorRefId !== 'SPG' ? undefined : (get(objective, 'categoriasSeleccionadasArticulo.saturacion[0].id') || 0))
  const [showGroupList, setShowGroupList] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [groupModify, setGroupModify] = useState(-1)
  const [editRow, setEditRow] = useState(-1)
  const [totalArt, setTotalArt] = useState(null)

  const selectedCategories = tipoValorRefId !== 'SPG' ? categoriesList :
    (categoriesList.length > 0 && (categoriesList[0].valores[0].groupsId !== undefined && categoriesList[0].valores[0].groupsId !== null))
      ? categoriesList.map(({ categoria, valores }) => {
        return {
          categoria,
          valores: valores.filter(value => value.groupsId.includes(`${groupIndex}`))
        }
      }).filter(cat => cat.valores && cat.valores.length > 0) : [];

  const exceptions = tipoValorRefId !== 'SPG' ? exceptionsList :
    (exceptionsList.length > 0 && (exceptionsList[0].groupsId !== undefined && exceptionsList[0].groupsId !== null))
      ? exceptionsList.filter(value => value.groupsId.includes(`${groupIndex}`))
      : [];

  useEffect(() => {
    let newTotal = numArticles - exceptions.length
    if (newTotal !== totalArt) {
      setTotalArt(newTotal)
      if (newTotal > 0) {
        getSalesmen()
        setHasArticles(true)
      } else {
        setHasArticles(false)
      }
    }
  }, [numArticles, exceptions])

  useEffect(() => {
    if (groupList.length === 0 && tipoValorRefId === 'SPG') {
      setGroupModify(0);
      updateSaturationGroup({
        variables: {
          grupo: { id: 0, nombre: 'Grupo 1' },
        },
      });
    }
  }, [groupList])

  const packageCategories = (idx, catList = null) => {
    const newCatList = (catList === null) ? categoriesList : catList
    return (newCatList.length > 0 && (newCatList[0].valores[0].groupsId !== undefined && newCatList[0].valores[0].groupsId !== null)) ?
      newCatList.map(({ categoria, valores }) => {
        return {
          idCategoria: categoria.id,
          valoresSeleccionados: valores.filter(cat => cat.groupsId.includes(`${idx}`)).map(cat => cat.nombre)
        }
      }).filter(cat => cat.valoresSeleccionados.length > 0) : [];
  }

  const onCompleted = ({ objective }) => {
    setEditRow(-1)
    resetKeepAliveTimer()
    setObjective(objective)
  }

  const [updateArticlesSat, { loading: updateArticlesSatLoading }] = useMutation(
    UPDATE_OBJECTIVE_SATURATION_ARTICLES,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: ({ objective }) => {
        if (objective.validateMsg !== null && objective.validateMsg !== '') {
          showWarningNotification(`Se han modificado los siguientes grupos: ${objective.validateMsg}`)
        }
        setEditRow(-1)
        resetKeepAliveTimer()
        setObjective(objective)
      },
    }),
  )

  const [selectCategory, { loading: catLoading }] = useMutation(
    UPDATE_OBJECTIVE_ARTICLE_CATEGORIES,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      // onCompleted: tipoValorRefId === 'SPG' ? onCompletedSat(objective) : onCompleted(objective),
      onCompleted: ({ objective }) => {
        if (tipoValorRefId === 'SPG') {
          let hasCategory = packageCategories(groupIndex, objective.categoriasSeleccionadasArticulo.categorias).length > 0
          let excepcionesSeleccionadas = (objective.categoriasSeleccionadasArticulo.excepciones.length > 0 &&
            (objective.categoriasSeleccionadasArticulo.excepciones[0].groupsId !== undefined &&
              objective.categoriasSeleccionadasArticulo.excepciones[0].groupsId !== null))
            ? objective.categoriasSeleccionadasArticulo.excepciones.filter(value => value.groupsId.includes(`${groupIndex}`)).map(exc => exc.codigo)
            : []
          updateArticlesSat({
            variables: {
              idGrupoSat: groupIndex,
              hasCategory,
              excepcionesSeleccionadas,
            },
          })
        } else {
          onCompleted({ objective })
        }
      },
    }),
  )

  const [getSalesmen, salesmenResource] = useLazyQuery(GET_AVAILABLE_SALESMEN, {
    fetchPolicy: 'network-only',
    skip: !objective.paraEspecialistas,
    variables: { ...getObjectiveIdVars(context) },
  })

  const newEmpleados = get(salesmenResource, 'data.empleados')

  useEffect(() => {
    if (JSON.stringify(empleados) !== JSON.stringify(newEmpleados)) setEmpleados(newEmpleados)
  })


  const [setExceptions, { loading: ExceptionLoading }] = useMutation(
    UPDATE_OBJECTIVE_ARTICLE_EXCEPTIONS,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: ({ objective }) => {
        if (tipoValorRefId === 'SPG') {
          let hasCategory = packageCategories(groupIndex, objective.categoriasSeleccionadasArticulo.categorias).length > 0
          let excepcionesSeleccionadas = (objective.categoriasSeleccionadasArticulo.excepciones.length > 0 &&
            (objective.categoriasSeleccionadasArticulo.excepciones[0].groupsId !== undefined &&
              objective.categoriasSeleccionadasArticulo.excepciones[0].groupsId !== null))
            ? objective.categoriasSeleccionadasArticulo.excepciones.filter(value => value.groupsId.includes(`${groupIndex}`)).map(exc => exc.codigo)
            : []
          updateArticlesSat({
            variables: {
              idGrupoSat: groupIndex,
              hasCategory,
              excepcionesSeleccionadas,
            },
          })
        } else {
          onCompleted({ objective })
        }
      },
    }),
  )

  const [updateSaturationGroup, { loading: updateLoading }] = useMutation(
    UPDATE_OBJECTIVE_SATURATION_GROUP,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: ({ objective: newObjective }) => {
        if (groupModify === 1) {
          const max = Math.max.apply(null, groupList.map(obj => obj.id));
          setGroupIndex(max + 1)
          setGroupName('')
          setGroupModify(-1)
          setSelectedCategory(false)
        }
        setEditRow(-1)

        if (newObjective.paraEspecialistas || groupModify === 0) {
          setGroupModify(-1)
          let hasCategory = packageCategories(groupIndex, objective.categoriasSeleccionadasArticulo.categorias).length > 0
          let excepcionesSeleccionadas = (objective.categoriasSeleccionadasArticulo.excepciones.length > 0 &&
            (objective.categoriasSeleccionadasArticulo.excepciones[0].groupsId !== undefined &&
              objective.categoriasSeleccionadasArticulo.excepciones[0].groupsId !== null))
            ? objective.categoriasSeleccionadasArticulo.excepciones.filter(value => value.groupsId.includes(`${groupIndex}`)).map(exc => exc.codigo)
            : []

          updateArticlesSat({
            variables: {
              idGrupoSat: groupIndex,
              hasCategory,
              excepcionesSeleccionadas,
            },
          })
        } else {
          resetKeepAliveTimer()
          setObjective(newObjective)
        }

      },
    }),
  )

  const [deleteSaturationGroup, { loading: deleteLoading }] = useMutation(
    DELETE_OBJECTIVE_SATURATION_GROUP,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: ({ eliminarGrupoSaturacion }) => {
        if (eliminarGrupoSaturacion.validateMsg !== null && eliminarGrupoSaturacion.validateMsg !== '') {
          showWarningNotification(`Se han modificado los siguientes grupos: ${eliminarGrupoSaturacion.validateMsg}`)
        }
        setGroupModify(-1);
        resetKeepAliveTimer()
        setObjective(eliminarGrupoSaturacion)
      },
    }),
  )

  // Categorias

  const updateSelectedCategory = selectedCategories => {
    // formatear valoresSeleccionados para enviarlos únicamente con el número para que devuelva todo correcto
    let formattedCategories = cloneDeep(selectedCategories);
    if (formattedCategories.length) {
      formattedCategories.forEach((category, indexCat) => {
        if (category.idCategoria === 'PRO') {
          formattedCategories[indexCat].valoresSeleccionados.forEach((value, indexVal) => {
            formattedCategories[indexCat].valoresSeleccionados[indexVal] = value.split("###")[0].trim();
          })
        }
      })
    }
    if ((objective.paraProveedor !== null && objective.paraProveedor !== '') && formattedCategories.find(cats => cats.idCategoria === 'PRO') === undefined) {
      formattedCategories.push({ idCategoria: "PRO", valoresSeleccionados: [objective.paraProveedor] })
    }
    selectCategory({
      variables: {
        selectedCategories: formattedCategories,
        idGrupoSat: groupIndex,
        objGrupoId: "A",
      },
    })
  }

  const removeCategoryValue = (id, value) => {
    return updateSelectedCategory(removeValueFromCategories(selectedCategories, id, value))
  }

  const removeAllCategoryValues = id =>
    updateSelectedCategory(removeCategoryFromCategories(selectedCategories, id))

  const removeAllCategories = () => updateSelectedCategory([])

  const addCategoryValue = (id, value) =>
    updateSelectedCategory(addValueToCategories(selectedCategories, id, value))

  const addAllCategoryValues = (id, values) =>
    updateSelectedCategory(
      addCategoryToCategories(selectedCategories, id, values),
    )

  // Grupos

  const updateGroup = (param) => {
    updateSaturationGroup({
      variables: {
        grupo: {
          id: editRow,
          nombre: param,
        },
      },
    })
  }

  const addGroup = () => {
    const max = Math.max.apply(null, groupList.map(obj => obj.id));
    setGroupModify(1);
    updateSaturationGroup({
      variables: {
        grupo: {
          id: max + 1,
          nombre: groupName,
        },
      },
    })
  }

  const deleteGroup = (idx) => {
    if (groupList.length > 1) {
      if (idx === groupIndex) {
        const position = groupList.findIndex(grp => grp.id === idx)
        const newId = groupList[position === 0 ? 1 : position - 1].id

        setGroupIndex(newId)
      }
      setGroupModify(1);

      deleteSaturationGroup({
        variables: {
          idGrupo: idx
        },
      })

    }
  }

  const changeGroup = (idx) => {
    setGroupIndex(idx);
  }

  // Articulos

  const removeAllExceptions = () => updateSelectedException(true)

  const addAllExceptions = () => updateSelectedException(false)

  const updateSelectedException = (selected) => {
    let newList = []

    if (!selected) newList = articleList.map(art => art.codArticulo)

    return setExceptions({
      variables: {
        groupIdSat: groupIndex,
        excepcionesSeleccionadas: selected
          ? []
          : newList,
        objGrupoId: "A",
      },
    }).then(() => {
      if (selected) setExtraExceptionHash(extraExceptionHash + '#')
    })
  }

  const selectionIds = exceptions.map(exception => exception.codigo)

  const onSelectionChange = (record, selected) => {
    return setExceptions({
      variables: {
        groupIdSat: groupIndex,
        excepcionesSeleccionadas: selected
          ? without(selectionIds, record.codArticulo)
          : concat(selectionIds, record.codArticulo),
        objGrupoId: "A",
      },
    }).then(() => {
      if (selected) setExtraExceptionHash(extraExceptionHash + '#')
    })
  }

  const selectedCategorieHash =
    selectedCategories
      .map(
        ({ categoria, valores }) =>
          categoria.id + valores.map(({ nombre }) => nombre).join(''),
      )
      .join('') + extraExceptionHash

  const objIsLoading = catLoading || (ExceptionLoading || (updateLoading || deleteLoading || updateArticlesSatLoading));

  const showAlert = tipoValorRefId === 'SPG' && !objIsLoading && groupList.length < nArtGroup

  const MinAlert = () => (
    <Alert
      message={
        `No se está alcanzando el mínimo de ${(tipoValorRefId === 'SPG' && groupList.length < nArtGroup) ? "grupos" : "artículos"} 
        necesario para este objetivo`}
      type="warning"
      showIcon
      style={{ fontSize: '15px', marginTop: 10, maxWidth: 470 }}
    />
  )

  return (
    <div style={{ paddingTop: 23, width: '100vw' }}>
      <ObjectiveLeftCol>
        {tipoValorRefId === 'SPG' && (<ObjectiveGroupList
          showGroupList={showGroupList}
          setShowGroupList={setShowGroupList}
          groupList={groupList}
          groupName={groupName}
          setGroupName={setGroupName}
          groupIndex={groupIndex}
          deleteGroup={deleteGroup}
          addGroup={addGroup}
          changeGroup={changeGroup}
          updateGroup={updateGroup}
          editRow={editRow}
          setEditRow={setEditRow}
          isLoading={objIsLoading}
          isDisabled={objIsLoading}
        />)}

        {!selectedCategory && (
          <ObjectiveCategoryList
            onChange={setSelectedCategory}
            query={GET_OBJECTIVE_ARTICLE_CATEGORIES}
            isLoading={objIsLoading}
          />
        )}

        {selectedCategory && (
          <ObjectiveCategoryValueList
            selectedCategory={selectedCategory}
            selectedCategories={selectedCategories}
            setSelectedCategory={setSelectedCategory}
            query={GET_OBJECTIVE_CATEGORY_ARTICLE_VALUES}
            addCategoryValue={addCategoryValue}
            removeCategoryValue={removeCategoryValue}
            removeAllCategoryValues={removeAllCategoryValues}
            addAllCategoryValues={addAllCategoryValues}
            selectedCategorieHash={selectedCategorieHash}
            isLoading={objIsLoading}
            idGrupo={groupIndex}
            showAlert={showAlert}
            paraProveedor={objective.paraProveedor}
          />
        )}

        {showAlert && <MinAlert />}
      </ObjectiveLeftCol>
      <ObjectiveRightCol>
        <Spin spinning={objIsLoading}>
          {objective.paraEspecialistas && newEmpleados && !newEmpleados.length && (
            <Warning>
              <WarningMessage>
                No existe ningún especialista para el conjunto de categorías seleccionadas
                {tipoValorRefId === 'SPG' && ' en el conjunto de grupos'}
              </WarningMessage>
            </Warning>
          )}
          <TagContainer
            title={`Categorías seleccionadas (${categoriesCount(selectedCategories)})`}
            deleteAll={!isEmpty(selectedCategories)}
            onDeleteAll={removeAllCategories}
          >
            {(isEmpty(selectedCategories) && tipoValorRefId !== 'SPG') ? (
              <SelectionTag>Todas las categorías</SelectionTag>
            ) :
              (isEmpty(selectedCategories) && tipoValorRefId === 'SPG') ? (
                <SelectionTag warning={true}>Seleccione al menos una categoria</SelectionTag>
              ) : (
                selectedCategories.map(category =>
                  category.valores.map(value => (
                    <SelectionTag
                      key={category.categoria.id + '_' + value.nombre}
                      isProvider={objective.paraProveedor === value.nombre.split("###")[0]}
                      onDelete={() =>
                        removeCategoryValue(category.categoria.id, value.nombre)
                      }
                    >
                      {value.nombre.split("###")[0]} {value.nombre.split("###")[1]}
                    </SelectionTag>
                  )),
                )
              )}
          </TagContainer>
          <TagContainer
            title={`Deseleccionados (${exceptions.length})`}
            deleteAll={!isEmpty(exceptions)}
            onDeleteAll={removeAllExceptions}
          >
            {!isEmpty(exceptions) &&
              exceptions.map(({ desc, codigo }) => (
                <SelectionTag
                  key={codigo}
                  isException
                  onDelete={() =>
                    onSelectionChange({ codArticulo: codigo }, true)
                  }
                >
                  {desc}
                </SelectionTag>
              ))}
          </TagContainer>
          {groupModify === -1 && <ArticlesTable
            query={GET_ARTICLES}
            objectiveId={getObjectiveIdVars(context)}
            selectedCategorieHash={selectedCategorieHash}
            onSelectionChange={onSelectionChange}
            selectionIds={selectionIds}
            showSearch={true}
            sortBy="nombre"
            sortDir="asc"
            showDate={false}
            expandable={true}
            searchPlaceholder="Buscar en artículos seleccionados"
            onQueryCompleted={setArticles}
            exceptions={exceptions}
            removeAllExceptions={removeAllExceptions}
            addAllExceptions={addAllExceptions}
            numArticles={numArticles}
            articleList={articleList}
            setArticleList={setArticleList}
            idCodSatGroup={groupIndex}
            showWarning={(objective.paraProveedor !== null && paraProveedor !== '') && paraEspecialistas}
          />}
        </Spin>
      </ObjectiveRightCol>
    </div>
  )
}

const Warning = styled.div`
  float: right;
  width: 375px;
  margin-top: 24px;
  > div {
    line-height: 1.2;
  }
`
