import gql from 'graphql-tag'
import {
  OBJECTIVE_FRAGMENT,
  OPEN_OBJETIVE_FRAGMENT,
  OBJECTIVE_ADJUSTS_FRAGMENT,
} from 'Queries/ObjectiveFragments'
import { OBJECTIVE_INCENTIVES_FRAGMENT } from 'Queries/ObjectiveIncentives'

export const GET_OBJECTIVES = gql`
  query getObjectives($codDistribuidor: Long!) {
    objetivos(codDistribuidor: $codDistribuidor) {
      ...objectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`
export const DELETE_OBJECTIVE = gql`
  mutation deleteObjective($id: ObjetivoIdInput!) {
    objetivos: eliminarObjetivo(id: $id)
  }
`

export const CREATE_DRAFT_OBJECTIVE = gql`
  mutation createDraftObjective($codDistribuidor: Long!) {
    objective: crearObjetivoDraft(codDistribuidor: $codDistribuidor) {
      ...objectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`

export const COPY_OBJECTIVE = gql`
  mutation copyObjective($id: ObjetivoIdInput!) {
    objective: copiarObjetivo(id: $id) {
      ...objectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`

export const SAVE_DRAFT_OBJECTIVE = gql`
  mutation SaveDraftObjective(
    $id: ObjetivoIdInput! 
    $estado: String!
  ) {
    objective: finalizarObjetivoDraft(
      id: $id 
      estado: $estado
    ) {
      codObjetivo
    }
  }
`

export const CHANGE_OBJECTIVE_STATE = gql`
  mutation ChangeObjectiveState(
    $id: ObjetivoIdInput! 
    $estado: String!
  ) {
    objective: cambiarEstadoObjetivo(
      id: $id 
      estado: $estado
    ) {
      codObjetivo
    }
  }
`

export const OBJECTIVE = gql`
  query objetivo($id: ObjetivoIdInput!) {
    objective: objetivo(id: $id) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const OBJECTIVE_WITH_ADJUSTS = gql`
  query objetivo($id: ObjetivoIdInput!) {
    objective: objetivo(id: $id) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`
export const OBJECTIVE_HIERARCHICAL_ADJUSTS = gql`
  query objetivo($id: ObjetivoIdInput!) {
    objective: objetivo(id: $id) {
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const OBJECTIVE_HEADER = gql`
  query objetivo($id: ObjetivoIdInput!) {
    objective: objetivo(id: $id) {
      ...objectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`

export const OBJECTIVE_WITH_INCENTIVES = gql`
  query objetivo($id: ObjetivoIdInput!) {
    objective: objetivo(id: $id) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
      ...objectiveIncentivesFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
  ${OBJECTIVE_INCENTIVES_FRAGMENT}
`

export const OBJECTIVE_WITH_DATA = gql`
  query objetivo($id: ObjetivoIdInput!) {
    objective: objetivo(id: $id) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
      ajusteAbierto {
        ...objectiveOpenFragment
      }
      ...objectiveIncentivesFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
  ${OPEN_OBJETIVE_FRAGMENT}
  ${OBJECTIVE_INCENTIVES_FRAGMENT}
`

export const GET_OBJECTIVE_ARTICLE_CATEGORIES = gql`
  query getObjectiveArticleCategories($codDistribuidor: Long!) {
    categories: objetivoCategoriasArticulo(codDistribuidor: $codDistribuidor) {
      id
      desc
    }
  }
`

export const GET_OBJECTIVE_CATEGORY_ARTICLE_VALUES = gql`
  query getObjectiveArticleCategoryValues(
    $id: ObjetivoIdInput!
    $idCategoria: String!
    $idGrupo: Int
    $objGrupoId: String
  ) {
    valoresCategoria: objetivoCategoriaValoresArticulo(
      id: $id
      idCategoria: $idCategoria
      idGrupo: $idGrupo
      objGrupoId: $objGrupoId
    ) {
      activado
      nombre
      seleccionado
    }
  }
`

export const GET_OBJECTIVE_CLIENT_CATEGORIES = gql`
  query getObjectiveClientCategories($codDistribuidor: Long!) {
    categories: objetivoCategoriasCliente(codDistribuidor: $codDistribuidor) {
      id
      desc
    }
  }
`

export const GET_OBJECTIVE_CATEGORY_CLIENT_VALUES = gql`
  query getObjectiveClientCategoryValues(
    $id: ObjetivoIdInput!
    $idCategoria: String!
  ) {
    valoresCategoria: objetivoCategoriaValoresCliente(
      id: $id
      idCategoria: $idCategoria
    ) {
      activado
      nombre
      seleccionado
    }
  }
`

export const ADD_SALESMEN_TO_OBJECTIVE = gql`
  mutation asignSalemenToObjetive($id: ObjetivoIdInput!, $salesmen: [Long]) {
    objective: actualizarObjetivoComercialesSeleccionados(
      id: $id
      comercialesSeleccionados: $salesmen
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const ADD_SALESMEN_TO_OBJECTIVE_LIGHT = gql`
  mutation asignSalemenToObjetive($id: ObjetivoIdInput!, $salesmen: [Long]) {
    objective: actualizarObjetivoComercialesSeleccionados(
      id: $id
      comercialesSeleccionados: $salesmen
    ) {
      comercialesSeleccionados
    }
  }
`

export const UPDATE_OBJECTIVE_ARTICLE_CATEGORIES = gql`
  mutation updateObjectiveArticleCategories(
    $id: ObjetivoIdInput!
    $selectedCategories: [ObjetivoValoresSeleccionadosCategoriaInput]
    $idGrupoSat: Int
    $objGrupoId: String
  ) {
    objective: actualizarObjetivoArticuloCategoriasSeleccionadas(
      id: $id
      categoriasSeleccionadas: $selectedCategories
      idGrupoSat: $idGrupoSat
      objGrupoId: $objGrupoId
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_ARTICLE_EXCEPTIONS = gql`
  mutation updateObjectiveArticleExceptions(
    $id: ObjetivoIdInput!
    $excepcionesSeleccionadas: [String]
    $objGrupoId: String
    $groupIdSat: Int
  ) {
    objective: actualizarObjetivoArticuloExcepcionesSeleccionadas(
      id: $id
      excepcionesSeleccionadas: $excepcionesSeleccionadas
      objGrupoId: $objGrupoId
      groupIdSat: $groupIdSat
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_CLIENTE_CATEGORIES = gql`
  mutation updateObjectiveClienteCategories(
    $id: ObjetivoIdInput!
    $selectedCategories: [ObjetivoValoresSeleccionadosCategoriaInput]
    $recalcularAjustes: Boolean
  ) {
    objective: actualizarObjetivoClienteCategoriasSeleccionadas(
      id: $id
      categoriasSeleccionadas: $selectedCategories
      recalcularAjustes: $recalcularAjustes
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_CLIENTE_EXCEPTIONS = gql`
  mutation updateObjectiveClienteExceptions(
    $id: ObjetivoIdInput!
    $excepcionesSeleccionadas: [String]
    $recalcularAjustes: Boolean
  ) {
    objective: actualizarObjetivoClienteExcepcionesSeleccionadas(
      id: $id
      excepcionesSeleccionadas: $excepcionesSeleccionadas
      recalcularAjustes: $recalcularAjustes
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const RECALCULATE_OBJECTIVE_ADJUSTS = gql`
  mutation recalcularObjetivoAjuste($id: ObjetivoIdInput!) {
    objective: recalcularObjetivoAjuste(id: $id) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_ADJUSTS = gql`
  mutation actualizarObjetivoAjusteCelda(
    $id: ObjetivoIdInput!
    $mes: Int!
    $codMiembro: Long!
    $valorRef: Float!
  ) {
    objective: actualizarObjetivoAjusteCelda(
      id: $id
      mes: $mes
      codMiembro: $codMiembro
      valorRef: $valorRef
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_SATURATION_GROUP = gql`
  mutation saveGrupoSaturacion(
    $id: ObjetivoIdInput!
    $grupo: ObjetivoGrupoCmd
  ) {
    objective: guardarObjetivoGrupoSeleccionado(
      id: $id
      grupo: $grupo
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const DELETE_OBJECTIVE_SATURATION_GROUP = gql`
  mutation eliminarGrupoSaturacion(
    $id: ObjetivoIdInput!
    $idGrupo: Int!
  ) {
    eliminarGrupoSaturacion(
      id: $id
     idGrupo: $idGrupo
    ) {
      ...objectiveFragment
      ajuste {
        ...objectiveAdjustsFragment
      }
      ajusteJerarquico {
        ...objectiveAdjustsFragment
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
  ${OBJECTIVE_ADJUSTS_FRAGMENT}
`

export const UPDATE_OBJECTIVE_SATURATION_ARTICLES = gql`
mutation actualizarListaArticulosSaturacion(
  $id: ObjetivoIdInput!
  $idGrupoSat: Int!
  $hasCategory: Boolean!
  $excepcionesSeleccionadas: [String]
) {
  objective: actualizarListaArticulosSaturacion(
    id: $id
    idGrupoSat: $idGrupoSat
    hasCategory: $hasCategory
    excepcionesSeleccionadas: $excepcionesSeleccionadas
  ) {
    ...objectiveFragment
    ajuste {
      ...objectiveAdjustsFragment
    }
    ajusteJerarquico {
      ...objectiveAdjustsFragment
    }
  }
}
${OBJECTIVE_FRAGMENT}
${OBJECTIVE_ADJUSTS_FRAGMENT}
`
