import gql from 'graphql-tag'

export const GET_REPORT_URL = gql`
  query getReportUrl($id: ObjetivoIdInput!, $mes: Int) {
    mapeoObjetivoSAC(id: $id, mes: $mes) {
      idMedida
      urlGerencia
      urlComercial
      urlIncentivo
      nombre
      fechaDesde
      fechaHasta
      comerciales {
        codEmpleado
        nombreEmpleado
      }
      pdvSituacion {
        id
        desc
      }
    }
  }
`

export const GET_EMPLEADOS_DETALLE_SEGUIMIENTO = gql`
  query getEmpleadosDetalleSeguimiento($id: ObjetivoIdInput!) {
    empleadosDetalleSeguimientoObjetivo(id: $id) {
      codEmpleado
      nombreEmpleado
      nivelEmpleado {
        id
        desc
      }
      aplicaJefeEquipo
      aplicaEspecialista
    }
  }
`

export const GET_SATURATION_TABLE_STRUCTURE = gql`
  query tableListSatGroup($id: ObjetivoIdInput!) {
    tableListSatGroup(objetivoIdInput: $id) {
      empleados {
        codEmpleado
        nombreEmpleado
        nivelEmpleado {
          id
          desc
        }
        aplicaJefeEquipo
        aplicaEspecialista
      }
      gruposSat {
        groupId
        groupName
        articulos {
          codArticulo
          nombre
        }
      }
    }
  }
`

export const GET_SATURATION_TABLE_PDVS = gql`
  query getTablaObjetivoSat(
    $id: ObjetivoIdInput!
    $codEmpleado: Long
    $nivel: String
    $page: Int
    $size: Int 
    $searchTerm: String 
    $sortBy: String 
    $sortDir: SortDir = asc
    $countGroup: Int
    $status: Boolean
  ) {
    getTablaObjetivoSat(
      objetivoIdInput: $id
      codEmpleado: $codEmpleado
      nivel: $nivel
      page: $page
      size: $size
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      countGroup: $countGroup
      status: $status
    ) {
      pagination {
        numberOfElements
        totalElements
      }
      content {
        pdvId
        pdvName
        success
        groupsId
      }
    }
  }
`

