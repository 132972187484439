import React from 'react'

import { ActionPlanTable } from './ActionPlanTable'

import styled from '@emotion/styled'

const measureMap = {
  V: {
    t1: 'Volumen (l)',
    t2row2: 'Vol Act - Vol AA',
    t2row3: '% Var. Lit',
  },
  F: {
    t1: 'Facturación (€)',
    t2row2: 'Fct Act - Fct AA',
    t2row3: '% Var. Eur',
  },
  U: {
    t1: 'Unidades',
    t2row2: 'Uds Act - Uds AA',
    t2row3: '% Var. Uds',
  },
  N: {
    t1: 'Unidades',
    t2row2: 'Uds Act - Uds AA',
    t2row3: '% Var. Uds',
  },
  M: {
    t1: 'Margen (€)',
    t2row2: 'Mg Act - Mg AA',
    t2row3: '% Var. Eur',
  },
  B: {
    t1: 'Bultos Equivalentes (BE)',
    t2row2: 'BE Act - BE AA',
    t2row3: '% Var. BE',
  },
}

export const ActionPlanTables = ({ planAccionPdvResumen, idMedida }) => {
  const {
    tablaResumenMedida,
    tablaResumenMedidaPdvSituacion,
  } = planAccionPdvResumen
  return (
    <>
      <Row style={{ marginBottom: 40 }}>
        <ActionPlanTable
          dataSource={tablaResumenMedida}
          backgroundColors={['#fedb4e91', '#fedb4e91', '#d8ecd9', '#e6f2cf']}
          headers={['Act', 'AA', 'Dif', 'Dif %']}
          rowHeaders={[
            'PdV Numérica',
            measureMap[idMedida].t1,
            'Rotación punto de venta estable',
            'Por numérica',
          ]}
          boldRows={[0, 1]}
        />
      </Row>
      <Row>
        <ActionPlanTable
          dataSource={tablaResumenMedidaPdvSituacion.slice(0, 4)}
          backgroundColors={['#d8ecd9', '#d8ecd9', '#d8ecd9', '#b1d8b1']}
          headers={[
            'PdV',
            measureMap[idMedida].t2row2,
            measureMap[idMedida].t2row3,
          ]}
          rowHeaders={[
            'PdV Crecen',
            'PdV Mantiene',
            'PdV Decrecen',
            'Rotación punto de venta estable',
          ]}
          boldRows={[3]}
        />
      </Row>
      <Row>
        <ActionPlanTable
          dataSource={tablaResumenMedidaPdvSituacion.slice(4)}
          header={false}
          backgroundColors={['#d8ecd9', '#d8ecd9', '#d2e8a7']}
          headers={['PdV', 'Vol Act - Vol AA', '% Var. Lit']}
          rowHeaders={['PdV Nuevos', 'PdV Perdidos', 'Por numérica']}
          boldRows={[2]}
        />
      </Row>
    </>
  )
}
const Row = styled.div`
  margin-bottom: 20px;
  display: flex;
`
