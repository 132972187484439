import React, { Fragment, useContext, useState, useEffect } from 'react'

import { Row, CalendarDay, Spinner, DayPicker, YearPicker, PageTitle, SectionTitle } from 'Components'
import { SET_PRESALE_WEEK, GET_CALENDAR_DATA, UPDATE_HOLIDAYS } from 'Queries'
import { GlobalContext, days } from 'Utils'

import { Calendar as Month, Col, Card, Alert } from 'antd'
import styled from '@emotion/styled'
import { useQuery, useMutation } from '@apollo/client'
import { times, find, get, filter, includes, without } from 'lodash'
import moment from 'moment'
import calendarHeaderShape from 'Assets/images/CalendarHeaderShape.svg'

const months = []

times(12, i => {
  months.push(i)
})

const isHoliday = (holidays, moment) => {
  return find(holidays, holiday => {
    return moment.isSame(holiday, 'day')
  })
}

const isPresale = (presaleWeek, moment) => {
  // isoWeek is 1 indexed where as an Array is 0 indexed so I need to take one from
  // isoWeekday to get the day
  const day = days[moment.isoWeekday() - 1]
  return includes(presaleWeek, day)
}

const parseDates = timestampArray =>
  timestampArray.map(timestamp => moment(timestamp))

export const Calendar = ({ navigate, year, uri }) => {
  const context = useContext(GlobalContext)
  const codDistribuidor = get(context, 'currentUser.codDistribuidor')
  const now = get(context, 'currentUser.currentTime')

  const [holidays, setHolidays] = useState()
  const [presaleWeek, setPresaleWeek] = useState([])
  const [firstCall, setFirstCall] = useState(true)

  const variables = {
    codDistribuidor,
    ano: year,
  }

  const mutationOptions = {
    variables,
    update: (cache, { data }) => {
      context.setEditing(true)
      const cachedData = cache.readQuery({
        query: GET_CALENDAR_DATA,
        variables,
      })
      cache.writeQuery({
        query: GET_CALENDAR_DATA,
        variables,
        data: {
          ...cachedData,
          ...data,
        },
      })
    },
    onCompleted: () =>
      setTimeout(() => {
        context.setEditing(false);
        refetch(variables);
      }, 200),
  }

  const { error, data, loading, refetch } = useQuery(GET_CALENDAR_DATA, {
    variables,
    onCompleted: () => {
      context.setEditing(false)
      context.resetKeepAliveTimer()
    },
  })

  const [mutatePresaleWeek] = useMutation(SET_PRESALE_WEEK, {
    variables,
    onCompleted: () => {
      refetch(variables)
      context.refetchUser()
      setTimeout(() => {
        context.setEditing(false)
      }, 200)
    },
  })

  const [updateHolidays] = useMutation(UPDATE_HOLIDAYS, mutationOptions)

  const diasPreventa = get(data, 'semanaPreventa.dias') || []

  useEffect(() => {
    if (diasPreventa && presaleWeek.length !== diasPreventa.length) {
      setPresaleWeek(diasPreventa)
    }
  }, [diasPreventa])

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setHolidays(parseDates(get(data, 'calendarioFestivos.festivos')))
    }
  }, [data])

  useEffect(() => {
    if (presaleWeek.length !== diasPreventa.length && !firstCall) {
      mutatePresaleWeek({ variables: { ...variables, dias: presaleWeek } })
    }
    if (firstCall) { setFirstCall(false) }
  }, [presaleWeek])

  if (loading) return <Spinner />

  if (error) {
    const message = error.message || 'Algo ha fallado.'
    return <Alert message="Error" description={message} type="error" showIcon />
  }

  if (!presaleWeek || !holidays) return null

  const workingDays = get(data, 'calendarioFestivos.laborables')
  const isYearPast = false //year < moment().year()

  const onSelectDay = day => {
    context.setEditing(true)
    setPresaleWeek(prevDays => {
      let newDaysSelection = [...prevDays]
      if (includes(presaleWeek, day)) {
        newDaysSelection = without(presaleWeek, day)
      } else {
        newDaysSelection.push(day)
      }
      return newDaysSelection
    })
  }

  return (
    <Fragment>
      <PageTitle>CALENDARIO LABORAL</PageTitle>
      <Panel style={{ height: 'calc(100vh - 150px)', overflowY: 'auto' }}>
        <Row spacer={8}>
          <Col span={16}>
            <DayPicker
              isYearPast={isYearPast}
              onSelectDay={onSelectDay}
              days={days}
              currentSelection={presaleWeek}
              disabled={context.isFetching}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Fragment>
            <SectionTitle>Calendario anual</SectionTitle>
            <YearPicker
              disabled={context.isFetching}
              year={year}
              onIncrement={() => {
                navigate(`${uri.replace(year, Number(year) + 1)}`)
              }}
              onDecrement={() => {
                navigate(`${uri.replace(year, Number(year) - 1)}`)
              }}
            ></YearPicker>
          </Fragment>
          <Legend>
            <LegendRow>
              <CalendarDay
                isHoliday
                cellDate={moment(now)}
                legend
              ></CalendarDay>
              <LegendText>Festivos</LegendText>
            </LegendRow>
            <LegendRow>
              <CalendarDay
                isPresale
                cellDate={moment(now)}
                legend
              ></CalendarDay>
              <LegendText>Preventa</LegendText>
            </LegendRow>
          </Legend>
        </Row>
        <Row type="flex" justify="space-between">
          {months.map(month => {
            const workingDaysForMonth = workingDays[month]
            const calendarValue = moment(now)
            calendarValue.month(month)
            calendarValue.year(year)
            return (
              <CalendarCard key={month}>
                <Month
                  value={calendarValue}
                  style={{ opacity: isYearPast ? 0.8 : 1 }}
                  fullscreen={false}
                  mode="month"
                  dateFullCellRender={cellDate => {
                    return (
                      <CalendarDay
                        cellDate={cellDate}
                        isOutOfCurrentMonth={cellDate.month() !== month}
                        isHoliday={isHoliday(holidays, cellDate)}
                        isPresale={isPresale(presaleWeek, cellDate)}
                        isYearPast={isYearPast}
                        isToday={cellDate.isSame(moment(now), 'day')}
                        onClick={() => {
                          // Can't do anything with dates in years past
                          if (isYearPast) return

                          let newSelection = [...holidays]
                          const dateInHolidays = find(newSelection, holiday => {
                              return holiday.isSame(cellDate, 'day')
                          })
                          if (!dateInHolidays) {
                            newSelection.push(cellDate)
                          } else {
                            newSelection = filter(newSelection, day => {
                              return !day.isSame(cellDate, 'day')
                            })
                          }

                          updateHolidays({
                            variables: {
                              codDistribuidor,
                              ano: year,
                              festivos: newSelection.map((holiday) => {
                                return holiday.unix() * 1000;
                              }),
                              dias: presaleWeek,
                            },
                          })
                        }}
                      ></CalendarDay>
                    )
                  }}
                  headerRender={({ value }) => {
                    return (
                      <MonthHeader>
                        <MonthLabel>{value.format('MMMM')}</MonthLabel>
                        <WorkingDaysCount>
                          <WorkingDaysNumber>
                            {workingDaysForMonth}
                          </WorkingDaysNumber>
                          <WorkingDaysLabel>días laborables</WorkingDaysLabel>
                        </WorkingDaysCount>
                      </MonthHeader>
                    )
                  }}
                />
              </CalendarCard>
            )
          })}
        </Row>
      </Panel>
    </Fragment>
  )
}

const Panel = styled.div({
  borderRadius: 4,
  boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.15)',
  padding: 40,
})

const CalendarCard = styled(Card)({
  margin: '20px 0',
  width: 245,
  ' .ant-card-body': {
    padding: 0,
  },
  ' .ant-fullcalendar': {
    borderTop: 'none',
  },
  ' .ant-fullcalendar-calendar-body': {
    padding: 0,
  },
  ' table': {
    height: 'auto',
    borderSpacing: 0,
    cellSpacing: 0,
    borderWidth: 0,
    borderCollapse: 'collapse',
  },
  ' td, tr': {
    padding: 0,
    cellSpacing: 0,
  },
  ' .ant-fullcalendar-column-header': {
    lineHeight: '40px',
  },
})

const Legend = styled.div({
  padding: 10,
  border: '1px solid green',
  borderRadius: 3,
})

const LegendRow = styled.div({
  display: 'flex',
  alignItems: 'baseline',
})

const LegendText = styled.legend({
  fontSize: 14,
  width: 'auto',
  paddingLeft: 10,
  margin: 0,
})

const MonthHeader = styled.div({
  height: 30,
  display: 'flex',
  justifyContent: 'space-between',
  backgroundImage: `url(${calendarHeaderShape})`,
  backgroundPositionX: '-50px',
  backgroundColor: 'white',
})

const MonthLabel = styled.h3({
  color: 'white',
  fontSize: 14,
  lineHeight: '30px',
  fontWeight: 'bold',
  letterSpacing: 0.5,
  margin: 0,
  textTransform: 'uppercase',
  textAlign: 'center',
  width: 95,
  marginLeft: 5,
})

const WorkingDaysCount = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  paddingTop: 5,
  paddingRight: 10,
})

const WorkingDaysNumber = styled.div({
  fontSize: 20,
  marginRight: 5,
  color: '#549608',
})

const WorkingDaysLabel = styled.div({
  fontSize: 12,
  color: '#4a4a4a',
})
