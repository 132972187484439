import React, { useEffect, useState } from 'react'

import {
  CommercialHierarchy,
  ProviderHierarchy,
  Calendar,
  Cookies,
  Legal,
  Objectives,
  ObjectivesFollowUp,
  ObjectiveNew,
  ObjectiveDefinition,
  ObjectiveClients,
  ObjectiveCommercials,
  ObjectiveAdjustments,
  ObjectiveOpenAdjustments,
  ObjectiveIncentives,
  ObjectiveArticlesAndExceptions,
  ObjectiveArticles,
  ObjetivesTable,
  Activities,
  ObjectiveMonthlyFollowUp,
  ObjectiveReport,
  Privacy,
  ReportsDataQuality,
  ReportsSales,
  ReportsEvolution,
  ReportsTableAnalysis,
  OutOfService,
  DealerManagement,
  FuerzaConsents,
} from 'Pages'
import {
  sessionExpireConfirm,
  showConsentModal,
  showNoUserCodeModal,
  showNotificationsModal,
  modalNotification,
  showFuerzaBarModal,
} from 'Components'
import { Dashboard } from 'Fragments'
import { GET_CURRENT_USER, SET_CONSENT } from 'Queries'
import {
  GlobalContextProvider,
  DashboardContextProvider,
  checkRolePermit,
  resetTimeout,
} from 'Utils'

import {
  Redirect,
  createHistory,
  LocationProvider,
  Router,
} from '@reach/router'
import createHashSource from 'hash-source'
import { useQuery, useMutation } from '@apollo/client'
import { get } from 'lodash'
import UAParser from 'ua-parser-js'
import ReactGA from "react-ga4";
import styled from '@emotion/styled'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'

import logoNext from 'Assets/logo/logo-next.png'
import porDia from 'Assets/images/quicksight/por-dia.png'
import porMes from 'Assets/images/quicksight/por-mes.png'
import porAno from 'Assets/images/quicksight/por-anno.png'
import comer from 'Assets/images/quicksight/comercial.png'
import espec from 'Assets/images/quicksight/especialista.png'
import jefeEq from 'Assets/images/quicksight/jefe-de-equipo.png'
import jefeVe from 'Assets/images/quicksight/jefe-de-ventas.png'

const isDev = false
const source = createHashSource()
const history = createHistory(source)
const url = window.location.href
let showSessionExpireConfirm = true
let sendAnalytics = true

history.listen(({ location }) => {
  ReactGA.send({ 
    hitType: "pageview", page: location.pathname, title: "Page m" 
  });
})

let avoidAutomaticWarning = false;

const delay = 155 * 60 * 1000;
let interval = null;
const startTime = 15 * 60;

const DashboardRoute = ({ children }) => {
  const [filter, setFilter] = useState({})

  return (
    <DashboardContextProvider
      value={{
        filter,
        setFilter,
      }}
    >
      {children}
    </DashboardContextProvider>
  )
}

const EmptyRoute = ({ children }) => <span>{children}</span>

export const MainHome = () => {
  const { user: authUser, signOut } = useAuthenticator();
  const parser = new UAParser()
  const isDesktop = typeof parser.getDevice().type === 'undefined'
  const hash = document.location.hash.replace('#', '')
  const excludingWarns = ['/cookies', '/privacidad'].includes(hash)
  const historyHashArray = [hash]

  const resetKeepAliveTimer = () => {
    interval = resetTimeout(showModal, interval, delay)
  }

  async function checkUserLogged() {
    return Auth.currentAuthenticatedUser().then(() => {} ).catch(() => signOut());
  }

  const [fetching, setFetching] = useState([])
  const [userConsent, setUserConsent] = useState(false)
  const [fuerzaConsent, setFuerzaConsent] = useState(false)
  const [fuerzaRepeat, setFuerzaRepeat] = useState(false)
  let [isLoading, setIsLoading] = useState(true)
  let [empleados, setEmpleados] = useState(null);

  const [timer, setTimer] = useState(startTime);

  const showModal = () => {
    if (!url.includes('/login') && showSessionExpireConfirm) {
      showSessionExpireConfirm = false
      sessionExpireConfirm(300, () => {
        showSessionExpireConfirm = true
        const currentSession = Auth.currentSession();
        Auth.currentAuthenticatedUser().then(
          (cognitoUser) => {
            localStorage.setItem("token", cognitoUser.signInUserSession.refreshToken.token)
          }, (e) => {
            signOut();
          }
        )
      })
    }
  }

  const refetchUser = () => {
    resource.refetch({})
  }

  if (timer === 0) {
    showModal();
  }

  const setEditing = value =>
    setFetching(prev => (value ? [...prev, value] : prev.slice(0, -1)))


  useEffect(() => {
    const myInterval = setInterval(() => {
      checkUserLogged();
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
    const resetTimeout = () => {
      setTimer(startTime);
    };
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });

  useEffect(() => {
    if (authUser !== undefined) {
      localStorage.setItem("token", authUser.signInUserSession.idToken.jwtToken)
    }
  })

  const resource = useQuery(GET_CURRENT_USER, {
    skip: localStorage.getItem("token") === undefined,
    fetchPolicy: 'network-only',
    variables: { tipo: 0 },
  })

  useEffect(() => {
    if (resource.data === undefined) {
      setFuerzaRepeat(true)
    }
  }, [resource])

  const [setConsent] = useMutation(SET_CONSENT)

  if (resource.loading || !resource.data) {
    return null
  }

  if (window) {
    window.onpopstate = () => {
      if (excludingWarns) refetchUser()
      historyHashArray.push(document.location.hash.replace('#', ''))
      if (avoidAutomaticWarning && modalNotification) {
        modalNotification.destroy()
      }
    }
  }

  const user = get(resource, 'data.currentUser')
  const isComercial = isDev ? false : !user.display
  const roles = user.roles || []

  roles.push({ name: 'Desktop', read: isDesktop, write: isDesktop })
  user.roles = roles
  
  const consent = excludingWarns || userConsent
  const fConsent = excludingWarns || fuerzaConsent

  if (user) {
    if (!userConsent && user.respuesta) {
      setUserConsent(true)
    } else {
      if (sendAnalytics) {
        ReactGA.initialize([
          {
            trackingId: process.env.REACT_APP_ENV_GA4_TRACKER_ID,
            gtagOptions: {
              NivelUsuario: user.profile != null ? user.profile.id : 'Hesa',
              CodigoDistribuidor: String(user.codDistribuidor),
            }
          }
        ]);
      }
      sendAnalytics = false;
      
      if (!user.codDistribuidor && !user.isSuperUser) {
        showNoUserCodeModal(() => {
          if (window) {
            window.location.href = '/logout.html'
          }
        })
        return null
      }
      if (!consent) {
        setUserConsent(true)
        showConsentModal(() => {
          setConsent({ variables: { tipo: 0, respuesta: true } }).then(() => {
            refetchUser()
          })
        })
      } else {
        if (
          !excludingWarns &&
          !isComercial &&
          !avoidAutomaticWarning &&
          user.avisos &&
          user.avisos.length &&
          !user.isSuperUser
        ) {
          showNotificationsModal(user.avisos)
          setTimeout(() => {
            avoidAutomaticWarning = true
          }, 1000)
        }
      }
    }
    if (!fuerzaConsent && user.respuestaFuerzaBar && !user.isSuperUser) {
      setFuerzaConsent(true)
    } else {
      if (userConsent && user.respuesta && !fConsent) {
        if (fuerzaRepeat && user.profile && user.profile.id === "GE" && (user.respuestaFuerzaBar === undefined || user.respuestaFuerzaBar === null)) {
          showFuerzaBarModal(
            () => {
              setFuerzaConsent(true)
              setConsent({ variables: { tipo: 1, respuesta: true } }).then(() => {
                refetchUser()
              })
            }, () => {
              setFuerzaConsent(true)
              setConsent({ variables: { tipo: 1, respuesta: false } }).then(() => {
                refetchUser()
              })
            }
          )
          setFuerzaRepeat(false)
        }
      }
    }
  }

  const isFetching = !!fetching.length

  const contextValue = {
    currentUser: user,
    history,
    resetKeepAliveTimer,
    refetchUser,
    historyHashArray,
    isDev,
    isFetching,
    setEditing,
    isLoading,
    setIsLoading,
    empleados,
    setEmpleados
  }

  const dayView = checkRolePermit(roles, 'InformesDashboardsGdia')
  const monthView = checkRolePermit(roles, 'InformesDashboardsGmes')
  const analysisView = checkRolePermit(roles, 'InformesDashboardsTanalisis')
  const dashboardsView = dayView || monthView || analysisView

  return (
    <GlobalContextProvider value={contextValue}>
      <img alt="Logotipo Clave X" src={logoNext} style={{ height: 20, position: 'absolute', zIndex: 10000, margin: 4 }} />
      <QsImg alt="por dia" src={porDia} />
      <QsImg alt="por mes" src={porMes} />
      <QsImg alt="por ano" src={porAno} />
      <QsImg alt="comercial" src={comer} />
      <QsImg alt="especialista" src={espec} />
      <QsImg alt="jefe equipo" src={jefeEq} />
      <QsImg alt="jefe venta" src={jefeVe} />
      <Wrapper consent={consent}>
        <LocationProvider history={history}>
          {user && user.isSuperUser ?
            <Router primary={false}>
              <Cookies path="cookies" />
              <Privacy path="privacidad" />
              <Legal path="legal" />
              <DealerManagement path="dealer-management" />
              <Redirect noThrow from="*" to="/dealer-management" />
            </Router>
            :
            <Router primary={false}>
              <Cookies path="cookies" />
              <Privacy path="privacidad" />
              <Legal path="legal" />
              <OutOfService path="/out-of-service" />
              {checkRolePermit(roles, 'GestionObjetivo') && (
                <EmptyRoute path="/objective">
                  <ObjectiveNew path="/:type/:id/:year">
                    <ObjectiveDefinition path="/definition" />
                    <ObjectiveArticlesAndExceptions path="/articles-and-exceptions" />
                    <ObjectiveArticles path="/articles" />
                    <ObjectiveCommercials path="/commercials" />
                    <ObjectiveClients path="/clients" />
                    <ObjectiveAdjustments path="/adjustments" />
                    <ObjectiveOpenAdjustments path="/openAdjustments" />
                    <ObjectiveIncentives path="/incentives" />
                    <Redirect noThrow from="*" to="/definition" />
                  </ObjectiveNew>
                </EmptyRoute>
              )}
              <Dashboard path="/dashboard" isFetching={isFetching}>
                {checkRolePermit(roles, 'RegistroActividad') && (
                  <Activities path="/activities" />
                )}
                {checkRolePermit(roles, 'JerarquiaComercial') && (
                  <CommercialHierarchy path="/commercial-hierarchy" />
                )}
                {checkRolePermit(roles, 'JerarquiaComercial') && (
                  <ProviderHierarchy path="/provider-hierarchy" />
                )}
                {checkRolePermit(roles, 'JerarquiaComercial') && (
                  <FuerzaConsents path="/fuerza-consents" />
                )}
                {checkRolePermit(roles, 'Calendario') && (
                  <Calendar path="/calendar/:year" />
                )}
                {checkRolePermit(roles, 'SeguimientoObjetivo') && (
                  <DashboardRoute path="/objectives/follow-up">
                    <ObjectiveMonthlyFollowUp path="/:type/:id/:year" />
                    <ObjectivesFollowUp path="/:type" />
                    <ObjectivesFollowUp path="/:type/:year" />
                    <ObjectiveReport path="/reports/:id/:year/:month" />
                    <ObjectiveReport path="/reports/:id/:year" />
                  </DashboardRoute>
                )}
                {checkRolePermit(roles, 'GestionObjetivo') && (
                  <Objectives path="/objectives/gestion" />
                )}
                <DashboardRoute path="/objectives/table-objetives">
                  <ObjetivesTable path="/:type" />
                </DashboardRoute>
                {dashboardsView && (
                  <EmptyRoute path="/management-dashboard">
                    {(checkRolePermit(roles, 'InformesIncentivos') && checkRolePermit(roles, 'InformesCalidad')) && (
                      <ReportsDataQuality
                        path="/data-quality"
                        url={process.env.REACT_APP_DASHBOARD_URI}
                        dashboardIdArticles={process.env.REACT_APP_DASHBOARD_ID_QUALITY_ARTICLES}
                        dashboardIdClients={process.env.REACT_APP_DASHBOARD_ID_QUALITY_CLIENTS}
                      />
                    )}
                    {(checkRolePermit(roles, 'InformesIncentivos') && checkRolePermit(roles, 'InformesVentas')) && (
                      <ReportsSales
                        path="/sales"
                        url={process.env.REACT_APP_DASHBOARD_URI}
                        dashboardId={process.env.REACT_APP_DASHBOARD_ID_SALES}
                      />
                    )}
                    {dayView && (
                      <ReportsEvolution
                        path="/evolution"
                        url={process.env.REACT_APP_DASHBOARD_URI}
                        dashboardId={process.env.REACT_APP_DASHBOARD_ID_EVOLUTION}
                      />
                    )}
                    {analysisView && (
                      <ReportsTableAnalysis
                        path="/table-analysis"
                        url={process.env.REACT_APP_DASHBOARD_URI}
                        dashboardId={process.env.REACT_APP_DASHBOARD_ID_ANALYSIS}
                      />
                    )}
                  </EmptyRoute>
                )}
                <Redirect noThrow from="*" to="/objectives/follow-up/active" />
              </Dashboard>
              <Redirect
                noThrow
                from="*"
                to="/dashboard/objectives/follow-up/active"
              />
            </Router>
          }
        </LocationProvider>
      </Wrapper>
    </GlobalContextProvider>
  )
}

const Wrapper = styled.div`
  filter: ${({ consent }) => (consent ? 'none' : 'blur(5px)')};
`
const QsImg = styled.img`
  height: 10px; 
  position: absolute; 
  margin: 4px;
  left: -100px
`
