import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'

export const showObjectiveCopyConfirm = (name, onOk) => {
  Modal.confirm({
    title: <ConfirmTitle>Copiar objetivo</ConfirmTitle>,
    content: (
      <>
        <p>{`El objetivo "${name}" ha sido ajustado. En este nuevo objetivo no se copiará dicho ajuste.`}</p>
      </>
    ),
    icon: null,
    centered: true,
    okText: 'COPIAR',
    cancelText: 'CANCELAR',
    cancelButtonProps: {
      type: 'link',
    },
    onOk,
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
