import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'

export const ModalTitle = ({ title, subtitle }) => (
  <>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </>
)

const Title = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
const Subtitle = styled.div`
  font-size: 14px;
  color: #6f6f6f;
`
