import gql from 'graphql-tag'

const HESA_FRAGMENT = gql`
  fragment hesaFragment on JerarquiaHesa {
    proveedores {
      codProveedor
      nombre
      infoObjetivos
      usuariosHesa {
        codEmpleado
        nombreEmpleado
        codDistribuidor
        username
        ultimoAcceso
        comercialesVisibles
        activo
        email
        telefono
      }
    }
  }
`

export const GET_HESA_HIERARCHY = gql`
  query jerarquiaHesa($codDistribuidor: Long!) {
    JerarquiaHesa: jerarquiaHesa(codDistribuidor: $codDistribuidor) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const GET_HESA_COMERCIAL_HIERARCHY = gql`
  query comerciales($codDistribuidor: Long!) {
    comerciales: comerciales(codDistribuidor: $codDistribuidor) {
      codComercial
      codEmpleado
      nombre
    }
  }
`

export const GET_PROVIDERS = gql`
  query obtenerProveedorHesa($codigoDistribuidor: Long!) {
    obtenerProveedorHesa(codigoDistribuidor: $codigoDistribuidor) {
      nombre
      codigoProveedor
    }
  }
`
export const GET_CANALES = gql`
    query getCanales{
        getCanales {
            codigoCanal
            descripcion
        }
    }
`;

export const GET_NO_ALTA_PROVIDERS = gql`
  query proveedoresNoAltaHesa($codigoDistribuidor: Long!) {
    proveedoresNoAltaHesa(codigoDistribuidor: $codigoDistribuidor) {
      nombre
      codigoProveedor
    }
  }
`

export const SAVE_HESA_PROVIDERS = gql`
  mutation guardarProveedorHesa(
    $codDistribuidor: Long!
    $codProveedor: String!
    $nombre: String!
  ) {
    JerarquiaHesa: guardarProveedorHesa(
      codDistribuidor: $codDistribuidor
      codProveedor: $codProveedor
      nombre: $nombre
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const CHANGE_COMERCIAL_STATUS = gql`
  mutation changeStatusVisibilityComercial(
    $codDistribuidor: Long!
    $codProveedor: String!
    $codEmpleado: Long!
    $codComercial: [Long]!
    $status: Boolean!
  ) {
    JerarquiaHesa: changeStatusVisibilityComercial(
      codDistribuidor: $codDistribuidor
      codProveedor: $codProveedor
      codEmpleado: $codEmpleado
      codComercial: $codComercial
      status: $status
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const CHECK_COMERCIAL_EMAIL = gql`
  query existeEmpleadoHesaModal(
    $codDistribuidor: Long!
    $email: String!
    ) {
    existeEmpleadoHesaModal(
      codDistribuidor: $codDistribuidor 
      email: $email 
    ) {
      email
      nombre
      telefono
      codEmpleado
      codDistribuidor
    }
  }
`

export const CREATE_COMERCIAL_HESA = gql`
  mutation crearEmpleadoHesa(
    $codDistribuidor: Long!
    $codEmpleadoGerente: Long
    $codProveedor: String
    $nombre: String
    $email: String
    $telefono: String
    $tipoPerfil: TipoPerfil!
  ) {
    JerarquiaHesa: crearEmpleadoHesa(
      codDistribuidor: $codDistribuidor
      codEmpleadoGerente: $codEmpleadoGerente
      codProveedor: $codProveedor
      nombre: $nombre
      email: $email
      telefono: $telefono
      tipoPerfil: $tipoPerfil
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const EDIT_COMERCIAL_HESA = gql`
  mutation editarEmpleadoHesa(
    $codDistribuidor: Long!
    $codEmpleadoGerente: Long!
    $codProveedor: String!
    $codEmpleado: Long!
    $nombre: String!
    $telefono: String
    $tipoPerfil: TipoPerfil!
  ) {
    JerarquiaHesa: editarEmpleadoHesa(
      codDistribuidor: $codDistribuidor
      codEmpleadoGerente: $codEmpleadoGerente
      codProveedor: $codProveedor
      codEmpleado: $codEmpleado
      nombre: $nombre
      telefono: $telefono
      tipoPerfil: $tipoPerfil
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const DELETE_COMERCIAL_HESA = gql`
  mutation eliminarEmpleadoHesa(
    $codDistribuidor: Long!
    $codProveedor: String!
    $codEmpleado: Long!
  ) {
    JerarquiaHesa: eliminarEmpleadoHesa(
      codDistribuidor: $codDistribuidor
      codProveedor: $codProveedor
      codEmpleado: $codEmpleado
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const DELETE_PROVIDER_HESA = gql`
  mutation eliminarProveedorHesa(
    $codDistribuidor: Long!
    $codProveedor: String!
  ) {
    JerarquiaHesa: eliminarProveedorHesa(
      codDistribuidor: $codDistribuidor
      codProveedor: $codProveedor
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const CHECK_DROP_HESA = gql`
  query comprobarBorrarBajaEmpleadoHesa($codEmpleado: Long!) {
      comprobarBorrarBajaEmpleadoHesa( codEmpleado: $codEmpleado ) 
  }
`

export const SIGN_UP_HESA = gql`
  mutation crearCuentaUsuarioHesa(
    $codDistribuidor: Long!
    $codEmpleado: Long!
    $nivel: String!
  ) {
    JerarquiaHesa: crearCuentaUsuarioHesa(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
      nivel: $nivel
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const DROP_HESA = gql`
  mutation eliminarCuentaUsuarioHesa(
    $codDistribuidor: Long!
    $codEmpleado: Long!
  ) {
    JerarquiaHesa: eliminarCuentaUsuarioHesa(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`

export const MODIFY_HESA = gql`
  mutation modificarEstadoCuentaUsuarioHesa(
    $codDistribuidor: Long!
    $codEmpleado: Long!
  ) {
    JerarquiaHesa: modificarEstadoCuentaUsuarioHesa(
      codDistribuidor: $codDistribuidor
      codEmpleado: $codEmpleado
    ) {
      ...hesaFragment
    }
  }
  ${HESA_FRAGMENT}
`
