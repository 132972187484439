import React from 'react'

import { Avatar as AntAvatar } from 'antd'
import styled from '@emotion/styled'

export const Avatar = props => <StyledAvatar {...props}></StyledAvatar>

const StyledAvatar = styled(AntAvatar)({
  margin: '0 5px',
  '> .anticon': {
    marginRight: 0,
  },
})
