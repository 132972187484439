import React from 'react'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'

import { Table as AntTable, Empty, Popover } from 'antd'
import { Icon } from '@ant-design/compatible'
import { colors } from 'Utils';
import { Spinner } from 'Components'

export const SummaryTableShort = ({
  customList,
  firstCol,
  isLoaded,
  isHesa,
}) => {

  const getColumns = listLength => {

    const base = [
      {
        dataIndex: firstCol[0].id,
        key: firstCol[0].id,
        align: 'center',
        width: 120,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {
              style: { background: "#D4EFDF", fontSize: "14px" },
              rowSpan: index === 0 ? listLength : 0,
            },
          };
          return obj;
        },
      },
      {
        title: 'Nivel',
        dataIndex: 'rolEmpleado',
        key: 'rolEmpleado',
        align: 'center',
        width: 70,
      },
      {
        title: firstCol[1].text,
        dataIndex: firstCol[1].id,
        key: firstCol[1].id,
        align: 'center',
        width: 190,
        ellipsis: true,
        render: (value) => {
          const obj = {
            children: (
              <Popover content={(<span>{value}</span>)} placement="topLeft">
                <Cell> {value} </Cell>
              </Popover>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Tipo de Objetivo',
        dataIndex: 'objetivoUnidad',
        key: 'objetivoUnidad',
        align: 'center',
        width: isHesa ? 120 : 80,
      },
      {
        title: 'Fecha Periodo',
        dataIndex: 'periodo',
        key: 'periodo',
        align: 'center',
        width: 135,
      },

      {
        title: 'Cantidad Objetivo',
        dataIndex: 'objAbs',
        key: 'objAbs',
        align: 'center',
        width: 90,
        render: (value) => {
          return (
            <Cell negative={parseInt(value) < 0}>  {value} </Cell>
          );
        },
      },
      {
        title: 'Cantidad Actual',
        dataIndex: 'objetivoRealDia',
        key: 'objetivoRealDia',
        align: 'center',
        width: 90,
        render: (value) => {
          return (
            <Cell negative={parseInt(value) < 0}>  {value} </Cell>
          );
        },
      },
    ]

    const notHesa = [
      {
        title: '% Cumplimiento',
        dataIndex: 'porcCumplimientoMedidaRealDia',
        key: 'porcCumplimientoMedidaRealDia',
        align: 'center',
        width: 100,
        render: (value) => {
          const obj = {
            children: (
              <Cell negative={value !== '-' && parseInt(value) < 0}>
                {value}
              </Cell>
            ),
          };
          return obj;
        },
      },
      {
        title: 'Incentivo Teórico',
        dataIndex: 'incentivoTeorico',
        key: 'incentivoTeorico',
        align: 'center',
        width: 90,
        render: (value) => {
          return (
            <Cell negative={parseInt(value) < 0}>  {value}  </Cell>
          );
        },
      },
      {
        title: 'Incentivo Real',
        dataIndex: 'incentivoDia',
        key: 'incentivoDia',
        align: 'center',
        width: 90,
        render: (value) => {
          return (
            <Cell negative={parseInt(value) < 0}> {value}  </Cell>
          );
        },
      },
      {
        title: '% Incentivo',
        dataIndex: 'porcIncentivo',
        key: 'porcIncentivo',
        align: 'center',
        width: 90,
        render: (value) => {
          const obj = {
            children: (
              <Cell negative={parseInt(value) < 0}>  {value} </Cell>
            ),
          };
          return obj;
        },
      },
    ]

    const estado = [
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        align: 'center',
        width: 70,
        render: (value) => {
          const stateColor = value === 'VENCIDO' ? '#7D7D7D' : '#54AC29';
          const obj = {
            children: (
              <span>
                <Icon type="clock-circle" style={{ color: stateColor, fontSize: '17px' }} theme="filled" />
              </span>
            ),
          };
          return obj;
        },
      },
    ];

    return isHesa ? [...base, ...estado] : [...base, ...notHesa, ...estado]
  }


  return (
    <div style={{ width: '100%', display: 'inline-block', overflowX: 'auto' }}>
      {isLoaded && <Spinner />}
      {customList.map((objList, index) =>
        <StyledTable
          style={{ borderWidth: '0px', marginBottom: '-1px' }}
          rowKey="idObj"
          showHeader={index === 0}
          size={"small"}
          dataSource={objList}
          pagination={false}
          columns={getColumns(objList.length)}
        />
      )}
      <br />
      {(isEmpty(customList) && !isLoaded) && (
        <NoData description={`No hay ${firstCol[0].text}s para mostrar`} />
      )}
    </div>
  )
}

const StyledTable = styled(AntTable)(({ cursor }) => ({
  cursor: cursor ? 'pointer' : 'initial',
  '& .ant-table-thead > tr > th': {
    fontSize: 14,
    fontWeight: 600,
    background: '#FFFFFF !important',
    color: '#205527',
  },
  '& .ant-table-small > .ant-table-content > .ant-table-body': {
    margin: '0px',
    fontSize: '13px',
  },
  '& .ant-table-tbody > tr > td': {
    background: '#F3FAF3',
    borderTop: '1px solid darkGray',
    borderBottom: 'none',
  }
}))

const NoData = styled(Empty)`
  margin: 50px;
  font-size: 24px;
  color: ${colors.whiteTwo};
`
const Cell = styled.span`
  color: ${props => (props.negative ? 'rgba(255,0,0,.6)' : 'rgba(0,0,0,.6)')};
`