import React, { useContext, useState, useEffect } from 'react'

import { IncentivesTotalTable, ComercialIncetiveTable, Spinner } from 'Components'
import { IncentiveMatrixModal } from 'Fragments'
import {
  UPDATE_OBJECTIVE_INCENTIVES,
  EDIT_INCENTIVES_MATRIX,
  DELETE_INCENTIVES_MATRIX,
  OBJECTIVE,
  OBJECTIVE_WITH_INCENTIVES,
  RECALCULATE_OBJECTIVE_FRAGMENTS,
  RECALCULATE_OBJECTIVE_INCENTIVES_FRAGMENTS
} from 'Queries'
import {
  GlobalContext,
  ObjectiveContext,
  ObjectiveContextConsumer,
  getMutationVars,
  getObjectiveIdVars,
} from 'Utils'
import { ObjectiveIncetiveMock } from 'Mockups'

import { Input, Button as AntButton } from 'antd'
import styled from '@emotion/styled'
import { useMutation, useLazyQuery } from '@apollo/client'
import { get } from 'lodash'
import moment from 'moment'

export const ObjectiveIncentives = () => (
  <ObjectiveContextConsumer>
    {({ objective }) =>
      !Number.isFinite(objective.codObjetivo) ? null : (
        <ObjIncentives objective={objective} />
      )
    }
  </ObjectiveContextConsumer>
)

export const ObjIncentives = ({ objective }) => {
  const context = {
    ...useContext(GlobalContext),
    ...useContext(ObjectiveContext),
  }
  const { setObjective, resetKeepAliveTimer, isDev, isLoading, setIsLoading } = context
  objective = isDev ? ObjectiveIncetiveMock.data.objective : objective

  const [incentiveMatrix, setIncentiveMatrix] = useState([])
  const [incentivo, setIncentivo] = useState(isDev ? objective.incentivo : null)
  const [generalIncentive, setGeneralIncentive] = useState(0)
  const [openAllCollapses, setOpenAllCollapses] = useState(-1)

  const onCompleted = ({
    objective,
    objective: { incentivo, incentivoMatrizCumplimiento },
  }) => {
    // cuando carga los nombres en incentivos, bloquear botones hasta esto
    setIncentivo(incentivo)
    setIncentiveMatrix(incentivoMatrizCumplimiento)
    setOpenAllCollapses(openAllCollapses + 1)
    resetKeepAliveTimer()
    setObjective(objective)
    setIsLoading(false)
  }

  const onCompletedIncentive = (
    objectiveResponse
  ) => {
    const { incentivo, incentivoMatrizCumplimiento } = objectiveResponse.objective;
    // cuando carga los nombres en incentivos, bloquear botones hasta esto
    setIncentivo(incentivo)
    setIncentiveMatrix(incentivoMatrizCumplimiento)
    setOpenAllCollapses(openAllCollapses + 1)
    setIsLoading(false)
    setObjective({ ...objective, ...objectiveResponse.objective })

  }

  const onCompletedObjective = (
    objectiveResponse
  ) => {
    resetKeepAliveTimer();
    setObjective({ ...objective, ...objectiveResponse.objective });

    recalculateIncentives({ variables: { valor: generalIncentive } })

  }

  const [getObjective] = useLazyQuery(OBJECTIVE_WITH_INCENTIVES, {
    fetchPolicy: 'network-only',
    ...getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted,
    }),
  })

  const [recalculateObjective] = useMutation(
    RECALCULATE_OBJECTIVE_FRAGMENTS,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: onCompletedObjective,
    }),
  )

  const [recalculateIncentives] = useMutation(
    RECALCULATE_OBJECTIVE_INCENTIVES_FRAGMENTS,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: onCompletedIncentive,
    }),
  )


  const [updateIncentive] = useMutation(
    UPDATE_OBJECTIVE_INCENTIVES,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: ({ objective }) => {
        setIncentivo(objective.incentivo)
        resetKeepAliveTimer()
        setObjective(objective)
        setIsLoading(false)
      },
    }),
  )

  const [editIncentivesMatrix] = useMutation(
    EDIT_INCENTIVES_MATRIX,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: ({ objective }) => {
        setIncentiveMatrix(get(objective, 'incentivoMatrizCumplimiento'))
        resetKeepAliveTimer()
        setObjective(objective)
      },
    }),
  )

  const [deleteIncentivesMatrix] = useMutation(
    DELETE_INCENTIVES_MATRIX,
    getMutationVars(getObjectiveIdVars(context), OBJECTIVE, {
      onCompleted: ({ objective }) => {
        setIncentiveMatrix(get(objective, 'incentivoMatrizCumplimiento'))
        resetKeepAliveTimer()
        setObjective(objective)
      },
    }),
  )

  useEffect(() => {
    if (!isDev) {
      const ajustadoIncentivo = get(objective, 'ajustadoIncentivo')
      setIsLoading(true)
      if (!ajustadoIncentivo) {
        recalculateObjective({ variables: { valor: generalIncentive } })
      } else {
        getObjective()
      }
    }
  }, [])

  const totalIncentives = get(incentivo, 'cabeceraTotales.total')

  const montlyIncentives = get(incentivo, 'cabeceraTotales.totalMeses') || []

  const employees = get(incentivo, 'empleados') || []

  let startMonth = moment
    .utc(get(objective, 'periodoVigencia.fechaInicio'))
    .month()
  const endMonth = moment
    .utc(get(objective, 'periodoVigencia.fechaFin'))
    .month()
  const year = moment.utc(get(objective, 'periodoVigencia.fechaInicio')).year()

  if (get(objective, 'cuandoEvaluar.id') !== 'M') {
    startMonth = endMonth
  }

  const [showModal, setShowModal] = useState(false)
  const [editedRow, setEditedRow] = useState(null)

  return (
    <Wrapper>
      <Inner>
        <IncentivesTotalTable
          total={totalIncentives}
          montly={montlyIncentives}
          startMonth={startMonth}
          endMonth={endMonth}
        />
        <Row>
          <Input
            size="large"
            style={{ width: 72, marginRight: 4 }}
            type="number"
            value={generalIncentive}
            onChange={e => setGeneralIncentive(parseInt(e.currentTarget.value))}
          />
          <span style={{ marginRight: 10 }}>€</span>
          <Button
            size="large"
            onClick={() => {
              setIsLoading(true);
              recalculateObjective({ variables: { valor: generalIncentive } });
            }}
            disabled={isLoading}
          >
            Aplicar a todos
          </Button>
          <Button
            size="large"
            type="link"
            style={{ float: 'right' }}
            onClick={() => setShowModal(true)}
          >
            Ver matriz cumplimiento
          </Button>
        </Row>
        {isLoading ? <Spinner /> : employees.map(
          ({
            total,
            empleado: { titulo, codMiembro },
            nivelAnidacion,
            celdas,
          }) => (
            <ComercialIncetiveTable
              open={openAllCollapses > 0}
              onColapse={() => setOpenAllCollapses(0)}
              key={codMiembro}
              startMonth={startMonth}
              endMonth={endMonth}
              name={titulo}
              incentive={total}
              montly={celdas}
              nivel={nivelAnidacion}
              onChange={(mes, valorIncentivo) => {
                // setIsLoading(true)
                return updateIncentive({
                  variables: { mes, valorIncentivo, codMiembro },
                })
              }
              }
            />
          ),
        )}
      </Inner>
      <IncentiveMatrixModal
        title="Matriz de cumplimiento"
        subtitle={year}
        show={showModal}
        dataSource={incentiveMatrix}
        onCancel={() => setShowModal(false)}
        editIncentivesMatrix={editIncentivesMatrix}
        deleteIncentivesMatrix={deleteIncentivesMatrix}
        addNewRow={(index, row) => {
          const newIncentiveMatrix = incentiveMatrix.slice()
          newIncentiveMatrix.splice(index, 0, row)
          setIncentiveMatrix(newIncentiveMatrix)
          setEditedRow(index)
        }}
        removeNewRow={index => {
          const newIncentiveMatrix = incentiveMatrix.slice()
          newIncentiveMatrix.splice(index, 1)
          setIncentiveMatrix(newIncentiveMatrix)
        }}
        onRowEdit={id => setEditedRow(id)}
        editedRow={editedRow}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 182px);
  overflow: auto;
`
const Inner = styled.div`
  width: 1064px;
  padding: 0 20px;
  margin: auto;
`
const Row = styled.div`
  margin-bottom: 36px;
`

const Button = styled(AntButton)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--true-green);
  text-transform: uppercase;
`
