import React, { useContext } from 'react'

import {
  ObjectiveMonthlyBox,
  ResourceWrapper,
  DetailsPageTitleSection,
} from 'Components'
import { OBJECTIVE_KPI_MONTH } from 'Queries'
import { GlobalContext, DashboardContext, checkLevel } from 'Utils'

import { get, isEmpty } from 'lodash'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'

export const ObjectiveMonthlyFollowUp = ({ navigate, id, year }) => {
  const context = useContext(GlobalContext)
  const { filter } = useContext(DashboardContext)

  const profileId = get(context, 'currentUser.profile.id') || null
  const codDistribuidor = get(context, 'currentUser.infoHesa') !== null ?
    (get(context, 'currentUser.infoHesa.codDistribuidor') || null) : (get(context, 'currentUser.codDistribuidor') || null)
  const isHesa = get(context, 'currentUser.infoHesa.isHesa') || false
  const codEmpleadoInfoHesa = get(context, 'currentUser.infoHesa.codEmpleado') || null
  const nivelInfoHesa = checkLevel(get(context, 'currentUser.infoHesa.nivel'))
  const canCallKpis = (profileId !== null && codDistribuidor !== null) || (isHesa && codDistribuidor !== null)

  const variables = {
    id: {
      codDistribuidor,
      ano: year,
      codObjetivo: id,
    },
    codEmpleado: isHesa ? codEmpleadoInfoHesa : filter.empleado,
    nivel: isHesa ? nivelInfoHesa : filter.empleado ? filter.nivel : undefined,
  }
  const resource = useQuery(OBJECTIVE_KPI_MONTH, {
    skip: !canCallKpis,
    fetchPolicy: 'network-only',
    variables,
    onCompleted: context.resetKeepAliveTimer,
  })

  const objectives = get(resource, 'data.objetivoKpiMes')

  const title = (!isEmpty(objectives) && objectives[0].desc) || ''

  const goBack = () => {
    if (!isEmpty(objectives) && objectives[0].mes) {
      const meses = objectives.sort((a, b) => b.mes - a.mes)
      navigate(`/dashboard/objectives/follow-up/reports/${id}/${year}/${meses[0].mes}`)
    } else {
      navigate('/dashboard/objectives/follow-up/active')
    }
  }

  return (
    <ResourceWrapper resource={resource}>
      {!isEmpty(objectives) && (
        <>
          <DetailsPageTitleSection onBack={goBack} title={title} />

          <Inner>
            <FlexContiner>
              {objectives
                .sort((a, b) => b.mes - a.mes)
                .map((kpi, index) => {
                  const isKpiComercial =
                    get(kpi, 'distribucionValorRef.id') === 'C'

                  return (
                    <ObjectiveMonthlyBox
                      key={'1' + index}
                      kpi={kpi}
                      year={year}
                      onClick={() =>
                        navigate(
                          `/dashboard/objectives/follow-up/reports/${id}/${year}/${kpi.mes}`,
                        )
                      }
                      isNotClickable={!!filter.empleado && !isKpiComercial}
                    />
                  )
                })}
            </FlexContiner>
          </Inner>
        </>
      )}
    </ResourceWrapper>
  )
}
const Inner = styled.div`
  height: calc(100vh - 132px);
  margin: 0;
  padding: 0 20px;
  overflow-y: auto;
`

const FlexContiner = styled.div`
  display: flex;
  flex-wrap: wrap;
`
