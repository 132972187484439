import React from 'react'
import styled from '@emotion/styled'
import { TextButton } from 'Components'

export const ObjectiveButtonList = ({ onDelete, onEdit, onCopy }) => {
  return (
    <Wrapper>
      {onDelete && <TextButton type="danger" onClick={onDelete}> borrar</TextButton>}
      {onCopy && <TextButton onClick={onCopy}>COPIAR</TextButton>}
      {onEdit && <TextButton onClick={onEdit}>EDITAR</TextButton>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  float: right;
  margin-bottom: 17px;
`
