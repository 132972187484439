import React, { useContext, useEffect, useState } from 'react'

import {
  ProviderTree,
  Row,
  ResourceWrapper,
  PageTitle,
  EmployeeList,
  AddUserHesaForm,
  AddProviderForm
} from 'Components'
import {
  GET_HESA_HIERARCHY,
  GET_HESA_COMERCIAL_HIERARCHY,
  GET_NO_ALTA_PROVIDERS,
  SAVE_HESA_PROVIDERS,
  CHANGE_COMERCIAL_STATUS,
  CREATE_COMERCIAL_HESA,
  EDIT_COMERCIAL_HESA,
  DELETE_COMERCIAL_HESA,
  DELETE_PROVIDER_HESA,
  SIGN_UP_HESA,
  DROP_HESA,
  MODIFY_HESA,
} from 'Queries'
import { GlobalContext, sort } from 'Utils'

import { Button, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { get } from 'lodash'

export const ProviderHierarchy = () => {
  const context = useContext(GlobalContext)
  const { codDistribuidor, codEmpleado: codGerente } = get(context, 'currentUser')
  const [isListVisible, setIsListVisible] = useState('')

  const [jerarquia, setJerarquia] = useState([])
  const [proveedores, setProveedores] = useState([])
  const [comerciales, setComerciales] = useState([])
  const [isHierachySet, setIsHierachySet] = useState(false)

  const [modalProps, setModalProps] = useState({
    visible: false,
    copyable: false,
    okText: 'Añadir',
  })
  const [selectModalProps, setSelectModalProps] = useState({
    visible: false,
    proveedores,
    codDistribuidor,
  })

  const variables = {
    codDistribuidor,
  }

  const sortHierachy = (hierachy) => {
    let newJerarquia = []

    hierachy.forEach(prv => {
      newJerarquia.push({
        codProveedor: prv.codProveedor,
        nombre: prv.nombre,
        infoObjetivos: prv.infoObjetivos,
        usuariosHesa: sort(prv.usuariosHesa, 'codEmpleado')
      })
    });

    return sort(newJerarquia, 'codProveedor');
  }

  const onCompleted = ({ JerarquiaHesa }) => {
    setJerarquia(sortHierachy(JerarquiaHesa.proveedores))
    context.resetKeepAliveTimer()
  }

  const resourceHesaComercials = useQuery(GET_HESA_COMERCIAL_HIERARCHY, {
    variables,
    onCompleted: ({ comerciales }) => {
      setComerciales(comerciales)
      context.resetKeepAliveTimer()
    },
  })

  const [getProveedores, { loading: providersLoading }] = useLazyQuery(GET_NO_ALTA_PROVIDERS, {
    fetchPolicy: 'network-only',
    variables: { codigoDistribuidor: codDistribuidor },
    onCompleted: ({ proveedoresNoAltaHesa }) => {
      setProveedores(proveedoresNoAltaHesa)
      context.resetKeepAliveTimer()
    },
  })

  const [getHierachy, { loading: hierachyLoading }] = useLazyQuery(GET_HESA_HIERARCHY, {
    fetchPolicy: 'network-only',
    variables,
    onCompleted: ({ JerarquiaHesa }) => {
      if (!isHierachySet) {
        setIsHierachySet(true)
        setJerarquia(sortHierachy(JerarquiaHesa.proveedores))
        getProveedores()
      }
    },
  })

  useEffect(() => {
    // if (isEmpty(jerarquia) && isEmpty(proveedores)) {
    setIsHierachySet(false)
    getHierachy()
    // }
  }, [])

  const [saveProveedores] = useMutation(SAVE_HESA_PROVIDERS, { onCompleted })
  const [changeComercialStatus] = useMutation(CHANGE_COMERCIAL_STATUS, { onCompleted })
  const [createComercialHesa, { loading: createLoading }] = useMutation(CREATE_COMERCIAL_HESA, { onCompleted })
  const [EditComercialHesa, { loading: editLoading }] = useMutation(EDIT_COMERCIAL_HESA, { onCompleted })
  const [deleteComercialHesa] = useMutation(DELETE_COMERCIAL_HESA, { onCompleted })
  const [deleteProviderHesa] = useMutation(DELETE_PROVIDER_HESA, { onCompleted })
  const [signUpHesa] = useMutation(SIGN_UP_HESA, { onCompleted })
  const [dropHesa] = useMutation(DROP_HESA, { onCompleted })
  const [modifyHesa] = useMutation(MODIFY_HESA, { onCompleted })

  return (
    <ResourceWrapper resource={resourceHesaComercials}>
      <EmployeeList
        dataSource={eval(isListVisible)}
        onClose={() => setIsListVisible('')}
        isOpen={!!isListVisible}
      />
      <Row type="flex" justify="space-between">
        <PageTitle>Jerarquía PROVEEDORES</PageTitle>
        <Button
          style={{ width: 180, height: 35, margin: 16 }}
          onClick={() => setSelectModalProps({ visible: true, proveedores, codDistribuidor, submit: saveProveedores })}
        >
          <PlusOutlined style={{ paddingRight: 3 }} />
          <span style={{ fontSize: 14 }}>Dar de alta proveedor </span>
        </Button>
      </Row>
      <div style={{ padding: 20, height: 'calc(100vh - 160px)', overflowY: 'auto' }}>
        <Spin spinning={hierachyLoading || providersLoading} >
          <ProviderTree
            dataSource={jerarquia}
            comercials={comerciales}
            codDistribuidor={codDistribuidor}
            actions={{
              deleteComercial: deleteComercialHesa,
              deleteProvider: deleteProviderHesa,
              changeComercial: changeComercialStatus,
              EditComercial: EditComercialHesa,
              createComercial: createComercialHesa,
              showEditPopup: props =>
                setModalProps({
                  ...modalProps,
                  ...props,
                  title: 'Actualizar perfil',
                  okText: 'Actualizar',
                  codDistribuidor,
                  codGerente,
                  visible: true,
                  isEdit: true,
                  editSubmit: EditComercialHesa,
                  signUp: props.activo !== null ? dropHesa : signUpHesa,
                }),
              showAddPopup: props =>
                setModalProps({
                  ...modalProps,
                  ...props,
                  title: 'Añadir perfil',
                  okText: 'Añadir',
                  codDistribuidor,
                  codGerente,
                  visible: true,
                  isEdit: false,
                  createSubmit: createComercialHesa,
                  editSubmit: EditComercialHesa,
                }),
              lockUnlock: modifyHesa
            }}
          />
        </Spin>
      </div>
      <AddUserHesaForm props={modalProps} setProps={setModalProps} isLoading={editLoading || createLoading} />
      <AddProviderForm props={selectModalProps} setProps={setSelectModalProps} />
    </ResourceWrapper>
  )
}
