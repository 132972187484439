import styled from '@emotion/styled'
import { colors } from 'Utils'

export const CellWrapper = styled.div(
  ({ isDetail, isFooter = false, shadow, condensed, background, isAbsolut, isPercentage }) => ({
    userSelect: 'none',
    padding: isAbsolut ? '10px 25px 10px 15px' : '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: isAbsolut ? 125 : condensed ? 90 : isDetail ? 90 : 110,
    height: isFooter ? 'initial' : isPercentage ? 100 : 90,
    marginRight: condensed ? 5 : 0,
    background: background ? background : isDetail ? 'white' : '#e3f0c8',
    borderBottom: `1px solid ${colors.lime}`,
    boxShadow:
      shadow || condensed ? '0 4px 12px 0 rgba(200, 198, 198, 0.5)' : 'none',
  }),
)
