import React from 'react'
import { Table, clientColumns, dateColumn } from 'Components'
import { TableQuery } from 'Fragments'

export const ClientsTable = ({
  showDate = true,
  expandable = false,
  showSearch = true,
  onSelectionChange = null,
  selectionIds = [],
  objectiveId = null,
  selectedCategorieHash = '',
  onQueryCompleted = null,
  onRow,
  sortBy,
  sortDir,
  querySeachParams,
  query,
  searchPlaceholder,
  exceptions,
  removeAllExceptions,
  addAllExceptions,
  numClients,
  clientList,
  setClientsList,
}) => {
  const sortDirMap = {
    asc: 'ascend',
    desc: 'descend',
  }

  const columns = showDate ?
    [...clientColumns(onSelectionChange, exceptions, removeAllExceptions, addAllExceptions, numClients), dateColumn]
    : clientColumns(onSelectionChange, exceptions, removeAllExceptions, addAllExceptions, numClients)

  const columnsWithSort = columns.map(column => {
    return column.dataIndex !== sortBy
      ? column
      : { ...column, defaultSortOrder: sortDirMap[sortDir] }
  })

  return (
    <TableQuery
      showSearch={showSearch}
      searchPlaceholder={searchPlaceholder}
      querySeachParams={querySeachParams}
      // onSelectionChange={onSelectionChange}
      selectionIds={selectionIds}
      title="Clientes"
      expandable={expandable}
      query={query}
      objectiveId={objectiveId}
      selectedCategorieHash={selectedCategorieHash}
      queryVariables={{
        sortBy,
        sortDir,
      }}
      queryPath={'clientePage'}
      idKey={'codCliente'}
      onQueryCompleted={onQueryCompleted}
      contentList={clientList}
      setContentList={setClientsList}
    >
      <Table
        columns={columnsWithSort}
        locale={{ emptyText: 'No hay clientes para la búsqueda' }}
        onRow={onRow}
      />
    </TableQuery>
  )
}
