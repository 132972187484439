import { Menu as AntMenu } from 'antd'
import styled from '@emotion/styled'
import { colors } from 'Utils'


export const SideMenu = styled(AntMenu)`
  padding: 0 2px 0 0;
  background: transparent;
  color: #000000;
  .ant-menu-item, .ant-menu-submenu-title {
    background: transparent;
  }
  .ant-menu-item:hover, .ant-menu-submenu-title:hover {
    background: #CCE3C880;
    color: ${colors.pine};
    border-right: 0px ;
  }
  .ant-menu-selected,.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: ${colors.pine};
    background: transparent;
  }
  .ant-menu-submenu>.ant-menu {
    background: transparent;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 37px;
    line-height: 37px;
  }
  .ant-menu-item img {
    transform: translateY(-2px);
  }
  .ant-menu-item-selected img {
    filter: invert(92%) sepia(93%) saturate(0%) hue-rotate(202deg)
      brightness(106%) contrast(106%);
  }
  .ant-menu-item-selected label {
    color: white;
  }
  :not(.ant-menu-inline-collapsed) .ant-menu-item label,
  :not(.ant-menu-inline-collapsed) .ant-menu-submenu label {
    padding-left: 12px;
    opacity: 1;
    transition: padding-left 0.3s, opacity 0.3s;
  }
  .ant-menu-item label,
  .ant-menu-submenu label {
    padding-left: 36px;
    opacity: 0;
    transition: padding-left 0.3s, opacity 0.3s;
  }
  &.ant-menu-inline-collapsed > .ant-menu-item,
  &.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 27px !important;
  }
  .ant-menu-inline .ant-menu-item:after {
    border-right: 0px solid ${colors.pine};
  }
  .ant-menu-item:not(:last-child) {
    margin-bottom: 1px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}
  .ant-menu-item {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .ant-menu-item .ant-menu-item-icon,
   .ant-menu-item .anticon,
    .ant-menu-submenu-title .ant-menu-item-icon,
     .ant-menu-submenu-title .anticon {
      font-size: 18px;
      color: ${colors.pine}
     }

`
