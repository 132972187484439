import gql from 'graphql-tag'

export const GET_CALENDAR_DATA = gql`
  query GetCalendarData($codDistribuidor: Long!, $ano: Int!) {
    calendarioFestivos(codDistribuidor: $codDistribuidor, ano: $ano) {
      festivos
      laborables
    }
    semanaPreventa(codDistribuidor: $codDistribuidor, ano: $ano) {
      dias
    }
  }
`

export const SET_PRESALE_WEEK = gql`
  mutation setPresaleWeek($codDistribuidor: Long!, $dias: [Dia], $ano: Int) {
    semanaPreventa: actualizarSemanaPreventa(
      codDistribuidor: $codDistribuidor
      dias: $dias
      ano: $ano
    ) {
      dias
    }
  }
`

export const UPDATE_HOLIDAYS = gql`
  mutation UpdateHolidays(
    $codDistribuidor: Long!
    $ano: Int!
    $festivos: [Long]
    $dias: [Dia]
  ) {
    calendarioFestivos: actualizarCalendarioFestivos(
      codDistribuidor: $codDistribuidor
      ano: $ano
      festivos: $festivos
    ) {
      festivos
      laborables
    }
    semanaPreventa: actualizarSemanaPreventa(
      codDistribuidor: $codDistribuidor
      dias: $dias
      ano: $ano
    ) {
      dias
    }
  }
`;

export const CHECK_CALENDAR_WARNINGS = gql`
  query checkCalendarWarnings($ano: Int) {
    verificarCalendarioObjetivo(ano: $ano) {
      context {
        ano
      }
    }
  }
`
