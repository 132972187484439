import React from 'react'

import { ProgressBar, Icon, CircleStamp } from 'Components'
import { formatNumberWithThousands, rounded, colors, unitTypeMap, iconTypeMap } from 'Utils'

import { Icon as AntIcon } from '@ant-design/compatible'
import styled from '@emotion/styled'

export const ObjectiveBoxPercentual = ({ kpi, isHesa }) => {
  const {
    objetivoAbsTotal,
    objetivoAbsMes,
    objetivoRealDia,
    objetivoDia,
    objetivoPendiente,
    objetivoPorDiaPendiente,
    porcCumplimientoMedidaRealDia,
    diasLaborablesRestantes,
    idMedida,
    cuandoEvaluar,
    withException
  } = kpi

  const unit = unitTypeMap[idMedida]
  const icon = iconTypeMap[idMedida]

  const objAbs = cuandoEvaluar.id === 'M' ? objetivoAbsMes : objetivoAbsTotal;
  const achieved = objAbs <= objetivoRealDia || objetivoPendiente === 0

  return (
    <>
      <LeftFooter>
        <FooterInfo>Objetivo </FooterInfo>
        {rounded(objAbs)} {unit}
      </LeftFooter>
      <br />
      <Kpi success={porcCumplimientoMedidaRealDia >= 0}>
        <span>
          {porcCumplimientoMedidaRealDia >= 0 ? '+' : ''}
          {formatNumberWithThousands(
            Number(porcCumplimientoMedidaRealDia).toFixed(1), true
          )}
          <KpiPercent>%</KpiPercent>
        </span>
        <div style={{ position: "relative" }}>
          <Icon type={icon} size={60} />
          {withException && (
            <AntIcon
              type="stop"
              style={{ position: "absolute", top: 40, right: 40, fontSize: 22 }}
              theme="twoTone"
              twoToneColor={colors.scarlet}
            />
          )}
        </div>
      </Kpi>
      <ProgressBar
        showIndicator={diasLaborablesRestantes > 0}
        completed={(objetivoRealDia / (objAbs || 1)) * 100}
        target={(objetivoDia / (objAbs || 1)) * 100}
      />
      <Footer>
        <FooterInfo>Conseguido </FooterInfo>
        {rounded(objetivoRealDia)} {unit}
        <br />
        {(diasLaborablesRestantes > 0 && objetivoPendiente > 0) && (
          <>
            <FooterInfo>Restante </FooterInfo>
            {rounded(objetivoPendiente)} {unit}{' '}
            <FooterPerDayInfo>
              ({rounded(objetivoPorDiaPendiente)} {unit}
              /día)
            </FooterPerDayInfo>
          </>
        )}
      </Footer>
      <br />
      {(diasLaborablesRestantes <= 0 || objetivoPendiente <= 0) && (
        <div style={{ position: "relative" }}>
          <CircleStamp completed={achieved} />
        </div>
      )}
    </>
  )
}
const Kpi = styled.div`
  position: relative;
  font-size: 40px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  color: ${props => (props.success ? `${colors.trueGreen}` : `${colors.redCruz}`)};
`

const KpiPercent = styled.span`
  font-size: 15px;
`

const LeftFooter = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: var(--black);
  float: left;
  max-height: 20px;
  overflow: hidden;
`

const Footer = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: var(--black);
  float: right;
  max-height: 45px;
  overflow: hidden;
  text-align: right;
  margin-top: 5px;
`
const FooterInfo = styled.span`
  font-size: 15px;
  line-height: 0.94;
  letter-spacing: 0.2px;
  font-weight: normal;
  color: var(--warm-grey);
`
const FooterPerDayInfo = styled.span`
  font-size: 15px;
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: 0.2px;
  color: #555555;
`
