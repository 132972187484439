import React from 'react'

import { Content } from 'Components'
import { Header } from 'Fragments'

import { Layout } from 'antd'

export const Privacy = () => (
  <Layout>
    <Header onlyLogo />
    <Content nosider={1} fullwidth={'A'}>
      <h1>Política de Privacidad</h1>
      <p>&nbsp;</p>
      <div>
        <ol>
          <li>
            <strong>General</strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>La presente Política de Privacidad (en
          adelante, la “<strong>Política de Privacidad</strong>”) de{' '}
          <strong>Heineken España S.A.</strong> (“<strong>Heineken</strong>”/”
          <strong>nosotros</strong>”), con C.I.F. A-28006013 y situada en
          Avenida de Andalucía 1, 41007, Sevilla (España), aplica a todos los
          datos de carácter personal de nuestros consumidores que tratamos y que
          han sido aportados a través de nuestras webs y/o aplicaciones (en
          adelante, de manera conjunta, el “<strong>Sitio Web</strong>”).
        </p>
        <p>
          Por favor, lea detenidamente esta Política de Privacidad, ya que
          contiene información importante para ayudarle a comprender cuál es
          nuestra práctica respecto a la información de carácter personal que
          nos proporcione o que recopilemos de otro modo en el contexto del
          Sitio Web (en adelante, «<strong>Datos Personales</strong>«). Heineken
          es responsable del tratamiento de los Datos Personales tratados.
        </p>
        <p>
          Respetamos su privacidad y nos comprometemos a guardar sus Datos
          Personales de forma segura, así como a gestionarlos de acuerdo con
          nuestras obligaciones legales al amparo de la legislación aplicable en
          materia de protección de datos.
        </p>
        <p>&nbsp;</p>
        <ol start="2">
          <li>
            <strong>
              Qué datos personales recopilamos y cómo los utilizamos{' '}
            </strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>Puede utilizar la mayor parte de nuestro Sitio
          Web sin tener que proporcionarnos Datos Personales.
        </p>
        <p>
          No obstante, sí que será necesario para utilizar ciertos servicios o
          realizar solicitudes accesibles a través de la misma, a fin de que
          podamos atender a su consulta o solicitud, enviarle información
          comercial que pudiera ser de su interés o gestionar su participación
          en los correspondientes procesos de selección de HEINEKEN. Además de
          la información que debe proporcionarnos, le informamos que recogemos
          información aparejada a la mera navegación por nuestro Sitio Web.
        </p>
        <p>
          La información que le solicitamos en el Sitio Web y que aparece
          marcada con un asterisco es obligatoria. Si no nos la facilita, no
          podremos prestarle el servicio o proporcionarle el producto que
          necesite.
        </p>
        <p>
          A continuación detallamos los Datos Personales que tratamos, los fines
          para los que utilizamos tales Datos Personales y el plazo de
          conservación de los mismos:
        </p>
        <ol>
          <li>
            <strong>
              <em>registro y creación de una cuenta en nuestro Sitio Web</em>
            </strong>
            . Antes de utilizar la Plataforma, se le pedirá que cree una cuenta
            y nos proporcione un nombre de usuario y una contraseña para iniciar
            sesión (lo cual necesitamos para procesar su cuenta), así como una
            dirección de correo electrónico, nombre/apellidos y dirección de
            facturación. Usted mismo puede gestionar la información de su
            cuenta.
            <br />
            La creación de una cuenta es un trámite necesario para poder
            utilizar la plataforma, poder prestar el servicio y{' '}
            <strong>
              <u>cumplir el contrato que tenga con nosotros.</u>
            </strong>{' '}
            Conservaremos los datos de su cuenta mientras la utilice de forma
            activa. Los usuarios pueden borrar sus propias cuentas, salvo las
            cuentas de los gerentes que serán borradas por Heineken.
          </li>
          <li>
            <strong>
              <em>mantenimiento y optimización de nuestro Sitio Web</em>
            </strong>
            . Sus Datos Personales también se utilizarán para el mantenimiento y
            análisis de nuestro Sitio Web con el fin de resolver problemas de
            rendimiento, mejorar la disponibilidad y proteger el Sitio Web
            contra el fraude. El uso que hagamos de sus Datos Personales para
            estos fines es necesario en nuestro propio y{' '}
            <strong>
              <u>legítimo interés</u>
            </strong>{' '}
            y se conservará igualmente durante un período máximo de cinco años.
          </li>
          <li>
            <strong>
              <em>información sobre su visita y uso de nuestra Página Web</em>
            </strong>
            . Cuando visita Sitio Web recopilamos cierta información como su
            dirección IP, páginas web que visita, tipo de navegador de Internet,
            clics visualizaciones, código de distribuidor y nivel de usuario.
            Así mismo, se elabora información estadística y se analizan los
            hábitos de navegación, la información sobre su visita y uso del
            Sitio Web con el fin de mejorar la experiencia de usuario.
          </li>
        </ol>
        <p>
          Utilizamos estos Datos Personales en la medida en que es necesario en
          nuestro propio interés de cara a poder mejorar el servicio ofrecido.
        </p>
        <p>
          El uso que hagamos de sus Datos Personales para estos fines es
          necesario en nuestro propio y legítimo interés y se conservará
          igualmente durante un período máximo de cinco años.
        </p>
        <p>&nbsp;</p>
        <ol start="3">
          <li>
            <strong>Cómo compartimos sus Datos Personales</strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>Es posible que tengamos que compartir Datos
          Personales con terceros para que ello nos ayude a proporcionarle
          servicios y productos y para gestionar nuestro Sitio Web. Estos
          terceros son:
        </p>
        <ul>
          <li>
            Las empresas del Grupo Heineken, por fines administrativos, con el
            fin de almacenar los Datos Personales procesados a través del Sitio
            Web, mediante sistemas informáticos compartidos;
          </li>
          <li>
            proveedores de servicios, que actuarán como encargados del
            tratamiento, cuando ello sea necesario para prestarnos un servicio o
            para (ayudarnos a) prestar el servicio o entregar el producto pedido
            por usted en el Sitio Web (incluido nuestro proveedor de entregas a
            terceros) y para proporcionar servicios de análisis de datos;
          </li>
          <li>
            en caso de que Heineken venda todos los activos o acciones, o parte
            de ellos, a una empresa del Grupo Heineken desde la que se hayan
            transferido Datos Personales a un tercero, sus Datos Personales
            podrán facilitarse a dicho tercero.
          </li>
        </ul>
        <p>
          Estos terceros pueden estar situados en España, en otros países del
          Espacio Económico Europeo o en cualquier otro lugar del mundo. Cuando
          almacenamos Datos Personales fuera del Espacio Económico Europeo, solo
          lo hacemos en casos en los que existan garantías adecuadas de acuerdo
          con las exigencias en materia de protección de datos. Garantizamos un
          nivel adecuado de protección de los datos transferidos, exigiendo a
          los proveedores de servicios que adopten las medidas adecuadas para
          proteger la confidencialidad y seguridad de los Datos Personales.
        </p>
        <p>
          También es posible que tengamos que proporcionar Datos Personales a
          los organismos de aplicación de la ley con el fin de cumplir con
          cualquier obligación legal u orden judicial.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol start="4">
          <li>
            <strong>Seguridad de Datos Personales</strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>Tomaremos las medidas técnicas, físicas y
          organizativas apropiadas para proteger los Datos Personales
          recopilados a través del Sitio Web contra el mal uso o la destrucción,
          pérdida, alteración, divulgación, adquisición o acceso accidental,
          ilegal o no autorizado, que sean consistentes con las leyes y
          regulaciones aplicables en materia de privacidad y seguridad.
        </p>
        <p>
          Sin embargo, ningún sitio de Internet puede ser seguro al 100% y por
          ello no nos responsabilizamos del acceso no autorizado o no
          intencionado que esté fuera de nuestro control.
        </p>
        <p>
          Nuestro Sitio Web puede contener enlaces a otros sitios web. No somos
          responsables de las prácticas de privacidad, contenido o seguridad
          utilizados por esas otras páginas web, que no se atendrán por esta
          Política de Privacidad. Le aconsejamos que lea siempre atentamente las
          políticas de privacidad de cualquier sitio web ajeno a Heineken.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol start="5">
          <li>
            <strong>Conservar sus Datos Personales</strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>Conservaremos sus Datos Personales durante el
          tiempo que la ley exija o durante el tiempo que sea necesario para
          proporcionarle atender a las solicitudes realizadas por usted o para
          cualquiera de los demás fines mencionados en esta Política de
          Privacidad. En esta Política de Privacidad se menciona la duración
          concreta de la conservación de los datos para cada uno de los
          distintos fines. Tomaremos las medidas que sean razonables para
          destruir o anonimizar los Datos Personales que poseemos si ya no son
          necesarios para los fines establecidos anteriormente o después de que
          expire el plazo de conservación definido para ello.
        </p>
        <p>&nbsp;</p>
        <ol start="6">
          <li>
            <strong>Cookies</strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>Una gran parte de la información a la que se
          hace referencia en esta Política de Privacidad se recopila a través de
          nuestro uso de cookies y técnicas similares. Las cookies son pequeños
          archivos de texto que contienen pequeñas cantidades de información que
          se descargan y se pueden almacenar en su dispositivo de usuario, por
          ejemplo, en su ordenador, teléfono móvil o tablet.
        </p>
        <p>
          A veces estas cookies y técnicas similares son necesarias para
          recordar la configuración de su cuenta, el idioma y el país, y además
          nos permiten medir y analizar su comportamiento en nuestro Sitio Web y
          mostrarle anuncios personalizados en nuestro Sitio Web o en sitios web
          de terceros.
        </p>
        <p>
          Cuando sea necesario, se le pedirá su consentimiento para nuestro uso
          de cookies. Para ver más información sobre qué cookies usamos y cómo
          las usamos, revise nuestra{' '}
          <a href="#/cookies" target="_blank">
            <strong>
              <u>Política de Cookies</u>
            </strong>
          </a>
          .
        </p>
        <p>&nbsp;</p>
        <ol start="7">
          <li>
            <strong>
              Sus derechos de acceso, rectificación, supresión, limitación al
              tratamiento, oposición y portabilidad de datos{' '}
            </strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>Usted podrá ponerse en contacto con nosotros
          cuando así lo estime para ejercitar sus derechos de acceso,
          rectificación, supresión, limitación y a no ser objeto de decisiones
          automatizadas. También tiene derecho a la portabilidad, es decir, a
          recibir los Datos Personales que nos haya proporcionado en un formato
          estructurado, de uso común y legible por máquina, y si así lo
          solicita, en determinadas circunstancias facilitaremos sus Datos a
          otra persona responsable del tratamiento de los Datos Personales
          cuando ello sea técnicamente posible. Igualmente, tiene derecho, en
          determinadas circunstancias, a ejercitar su derecho a oponerse al
          tratamiento de sus Datos Personales en supuestos concretos, si bien
          podremos denegar dicha solicitud en caso de que tengamos motivos
          legítimos de peso de acuerdo con la normativa de protección de datos.
          Asimismo, si usted ha dado su consentimiento para que utilicemos sus
          Datos Personales, tendrá derecho a revocar dicho consentimiento sin
          que ello afecte la legalidad del uso que hagamos de tales Datos antes
          de su oposición.
        </p>
        <p>
          Para ejercitar los derechos anteriormente mencionados puede ponerse en
          contacto con nosotros dirigiéndose a Heineken España S.A. en Avenida
          de Andalucía 1, 41007, Sevilla (España), o a la siguiente dirección de
          correo electrónico:{' '}
          <a href="mailto:protecciondatos@heineken.es">
            protecciondatos@heineken.es
          </a>
          , adjuntando copia de su DNI o documentación acreditativa de su
          identidad.
        </p>
        <p>
          Tenga en cuenta que puede que las solicitudes que no cumplan los
          requisitos establecidos por la legislación aplicable tengan que volver
          a hacerse o ser finalmente denegadas y que, en ocasiones, debamos
          denegar dichas solicitudes de conformidad con las leyes de protección
          de datos u otra normativa aplicable.
        </p>
        <p>
          Por último, en el caso que Usted desee más información acerca de sus
          derechos en materia de protección de datos o precise presentar una
          reclamación, podrá dirigirse a la Agencia Española de Protección de
          Datos, con domicilio en Calle Jorge Juan, 6, 28001, Madrid, con el fin
          de salvaguardar sus derechos.
        </p>
        <p>&nbsp;</p>
        <ol start="8">
          <li>
            <strong>Actualizaciones</strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>Revisaremos esta Política de Privacidad y
          haremos actualizaciones de vez en cuando. Cualquier cambio que se
          produzca en esta Política de Privacidad se publicará en nuestro Sitio
          Web y, en la medida en que sea razonablemente posible, se lo
          comunicaremos.
        </p>
        <p>&nbsp;</p>
        <ol start="9">
          <li>
            <strong>Contacto</strong>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>Si tiene cualquier otra pregunta, desea
          oponerse a que usemos sus Datos Personales o tiene alguna queja sobre
          esta Política de Privacidad o el manejo que hacemos de sus Datos
          Personales, puede enviarnos un correo a{' '}
          <a href="mailto:protecciondatos@heineken.es">
            protecciondatos@heineken.es
          </a>
          .
        </p>
      </div>
    </Content>
  </Layout>
)
