import React from 'react'
import styled from '@emotion/styled'

import aimIcon from 'Assets/icons/aim.svg'
import editSquareIcon from 'Assets/icons/edit-square.svg'
import editIcon from 'Assets/icons/edit-icon.svg'
import fileTextIcon from 'Assets/icons/file-text.svg'
import giftIcon from 'Assets/icons/gift.svg'
import euroIcon from 'Assets/icons/euro-icon.svg'
import boxIcon from 'Assets/icons/box-icon.svg'
import dropIcon from 'Assets/icons/drop-icon.svg'
import barIcon from 'Assets/icons/bar-icon.svg'
import equivalentPackageIcon from 'Assets/icons/equivalent-package-icon.svg'
import marginIcon from 'Assets/icons/margin-icon.svg'
import handIcon from 'Assets/icons/hand-icon.svg'
import contractIcon from 'Assets/icons/contract-icon.svg'
import saturationIco from 'Assets/icons/saturation-icon.svg'

const iconMap = {
  euro: euroIcon,
  box: boxIcon,
  drop: dropIcon,
  bar: barIcon,
  aim: aimIcon,
  editSquare: editSquareIcon,
  edit: editIcon,
  fileText: fileTextIcon,
  gift: giftIcon,
  equivalentPackage: equivalentPackageIcon,
  margin: marginIcon,
  hand: handIcon,
  contract: contractIcon,
  saturacion: saturationIco,
}

export const Icon = ({ type, size }) => (
  <Img alt="Aim Icon" src={iconMap[type]} size={size} />
)

const Img = styled('img')`
  --size: ${props => props.size}px;
  width: var(--size);
  height: var(--size);
`
