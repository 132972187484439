import gql from 'graphql-tag'

export const OBJECTIVE_KPI_FRAGMENT = gql`
  fragment objectiveKpiFragment on ObjetivoKpi {
    codObjetivo
    etiqueta
    ano
    estado
    desc
    empleados {
      codEmpleado
      nombreEmpleado
      nivelEmpleado {
        id
        desc
      }
    }
  }
`

export const OBJECTIVE_KPI_VALUES_FRAGMENT = gql`
  fragment objectiveKpiValuesFragment on ObjetivoKpiValores {
    desc
    fechaDesde
    fechaHasta
    diasLaborablesRestantes
    cuandoEvaluar {
      id
    }
    distribucionValorRef {
      id
    }
    idMedida
    objetivoUnidad
    porcCumplimientoMedidaRealDia
    objetivoRealDia
    objetivoDia
    objetivoAbsTotal
    objetivoPendiente
    objetivoPorDiaPendiente
    incentivoDia
    incentivoTeorico
    objetivoAbs
    objetivoAbsMes
    countCo
    countJe
    description
    inctvMinCaja
    withException
    paraProveedor
    canales
  }
  `

export const OBJECTIVE_KPI_GLOBAL_FRAGMENT = gql`
  fragment objectiveKpiGlobalFragment on ObjetivoKpiGlobal {
    codObjetivo
    desc
    ano
    etiqueta
    estado
    inctvMinCaja
    empleadosGlobal {
      mes
      codEmpleado
      nombreEmpleado
      nivelEmpleado {
        id
        desc
      }
      porcCumplimientoMedidaRealDia
      objetivoFP
      objetivoMes
      objetivoRealDia
      incentivoDia
      incentivoTeorico
      fechaDesde
      fechaHasta
      idMedida
      objetivoUnidad
      cuandoEvaluar {
        id
      }
      distribucionValorRef{
        id
      }
    }
    withException
  }
`
