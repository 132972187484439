import React, { Fragment, useState } from 'react'

import { Table, ModalTitle } from 'Components'
import { TableQuery } from 'Fragments'
import { GET_ACTIVITIES } from 'Queries'
import { colors } from 'Utils'

import { Modal, Input, Button, Tooltip } from 'antd'
import { Icon } from '@ant-design/compatible'
import styled from '@emotion/styled'
import { capitalize } from 'lodash'
import Clipboard from 'react-clipboard.js'
import moment from 'moment'

export const phaseErrorToTextMap = {
  recepcion: 'Error en la transmision',
  analisis: 'Error al procesar los datos',
  autorizacion: 'Error de autorizacion',
  validacion: 'Error en el formato de los datos',
  guardado: 'Error al guardar los datos',
  invalidating: 'Datos guardados. Error al invalidar caché.',
  default: 'Error desconocido',
}

const ActivityUploadErrorCell = ({ phase, error, date }) => {
  const [showErrorDetail, setShowErrorDetail] = useState(false)
  const errorLabel = phaseErrorToTextMap[phase] || phaseErrorToTextMap.default
  return (
    <Fragment>
      <StyledModal
        title={<ModalTitle title={errorLabel} />}
        visible={showErrorDetail}
        centered
        onOk={() => setShowErrorDetail(false)}
        onCancel={() => setShowErrorDetail(false)}
      >
        <Label>Fecha/hora</Label>
        <Date>{capitalize(moment(date).calendar(null, calendarFormat))}</Date>
        <InRow>
          <Label>Traza de error</Label>
          <Tooltip placement="top" trigger="click" title={'Copiado'}>
            <Clipboard option-text={() => error} component={Button} type="link">
              Copiar error en el portapapeles
            </Clipboard>
          </Tooltip>
        </InRow>
        <Input.TextArea rows={11} value={error} />
      </StyledModal>
      <Error onClick={() => setShowErrorDetail(true)}>{errorLabel}</Error>
    </Fragment>
  )
}

const dateFormat = 'DD MMM YYYY, h:mm:ss a'
const calendarFormat = {
  sameDay: dateFormat,
  nextDay: dateFormat,
  lastDay: dateFormat,
  nextWeek: dateFormat,
  lastWeek: dateFormat,
  sameElse: dateFormat,
}

const Error = styled.div({
  color: 'red',
  cursor: 'pointer',
})

const Label = styled.div({
  fontSize: 14,
  letterSpacing: '0.25px',
  color: '#050606',
  marginBottom: '3px',
})

const Date = styled.span({
  fontSize: 14,
  color: '#aeaeae',
  letterSpacing: '0.25px',
  marginBottom: '4px',
})

const InRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '15px',
  '.ant-btn': {
    border: 'none',
    color: '#008200',
    height: '24px',
    ':after': {
      content: 'none',
    },
  },
})

const columns = [
  {
    title: 'Fecha/Hora',
    dataIndex: 'fecha',
    key: 'fecha',
    sorter: true,
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 185,
    render: value => {
      return (
        <span>{capitalize(moment(value).calendar(null, calendarFormat))}</span>
      )
    },
  },
  {
    title: 'Acción',
    dataIndex: 'tipo',
    key: 'tipo',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: value => {
      return <span>Subida de datos ({value})</span>
    },
  },
  {
    title: 'Resultado',
    dataIndex: 'resultado',
    key: 'resultado',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (value, { fase, error, fecha, invalidated }) => {
      const failureInvalidating = fase === 'guardado' && invalidated === false
      if (value === 'ok') {
        return <span>Completada con éxito</span>
      } else {
        return (
          <ActivityUploadErrorCell
            phase={failureInvalidating ? 'invalidating' : fase}
            date={fecha}
            error={error}
          ></ActivityUploadErrorCell>
        )
      }
    },
  },
  {
    title: '# Regs',
    dataIndex: 'numRegs',
    key: 'numRegs',
    width: 100,
    render: value => <span>{value || '-'}</span>,
  },
  {
    title: 'Inicio',
    dataIndex: 'fechaIni',
    key: 'fechaIni',
    width: 110,
    render: value => (
      <span>{value ? moment(value).format('DD/MM/YYYY') : '-'} </span>
    ),
  },
  {
    title: 'Fin',
    dataIndex: 'fechaFin',
    key: 'fechaFin',
    width: 110,
    render: value => (
      <span>{value ? moment(value).format('DD/MM/YYYY') : '-'}</span>
    ),
  },
  {
    title: 'Caché invalidada',
    dataIndex: 'invalidated',
    key: 'invalidated',
    render: (value, { invalidationTime }) => (
      <center>
        {value === null ? (
          '-'
        ) : (
          <>
            <Icon
              type={`${value ? 'check' : 'close'}-circle`}
              theme="twoTone"
              twoToneColor={colors[value ? 'trueGreen' : 'scarlet']}
              style={{ paddingRight: '5px' }}
            />
            {value && `${moment(invalidationTime).format('DD/MM/YY HH:mm')}h.`}
          </>
        )}
      </center>
    ),
  },
]

export const Activities = () => (
  <div style={{ padding: 20, height: 'calc(100vh - 85px)', overflowY: 'auto' }}>
    <TableQuery
      title="Ver actividad"
      disableSearch
      query={GET_ACTIVITIES}
      queryVariables={{
        sortBy: 'fecha',
        sortDir: 'desc',
      }}
      queryPath={'actividadPage'}
    >
      <Table
        cursor={false}
        columns={columns}
        onRow={() => {
          return () => { }
        }}
      />
    </TableQuery>
  </div>
)

const StyledModal = styled(Modal)`
  width: 460px;
  max-width: 460px;
  .ant-modal-close-x {
    display: none;
  }
  .ant-btn.ant-btn-primary {
    font-size: 12px;
    line-height: 1.83;
    color: ${colors.white};
    text-transform: uppercase;
  }
  .ant-btn.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.5);
  }

  .ant-modal-body {
    padding: 12px 32px;
    max-height: 356px;
    min-height: 356px;
    overflow: auto;
  }
  .ant-modal-header {
    padding: 24px 32px 16px;
  }
  .ant-modal-footer {
    padding: 10px 32px;

    .ant-btn:not(.ant-btn-primary) {
      display: none;
    }
  }
`
