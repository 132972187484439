import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Input, Button as AntButton } from 'antd'
import { colors } from 'Utils'

export const ObjectiveOpenDescription = ({
  isCommon,
  applyAll,
  dataSource,
  isLoading,
  setLoading,
}) => {

  const [commonDescr, setCommonDescr] = useState((!isCommon || (!dataSource || dataSource.length < 1)) ? '' : dataSource[0].description)

  return (
    <Inner>
      <Boxer>
        <Title>
          {isCommon ? 'Descripción común' : 'Descripción'}
        </Title>
        <div>
          <Input.TextArea
            allowClear
            style={{ width: 400, margin: '6px 0px' }}
            autoSize={{ minRows: 5, maxRows: 5 }}
            maxLength={250}
            value={commonDescr}
            onChange={e => setCommonDescr(e.target.value)}
          />
          <Button
            size="large"
            loading={isLoading}
            disabled={isCommon && (dataSource.length > 1 && dataSource[0].description === commonDescr)}
            onClick={() => {
              setLoading(true)
              applyAll(commonDescr, null)
            }}
          >
            {isCommon ? 'Aplicar' : 'Aplicar a todos'}
          </Button>
        </div>
      </Boxer>
    </Inner>
  )
}

const Inner = styled.div`
  width: 465px;
  margin-bottom: 25px;
  margin-top: 0px;
  background: #e3f0c8;
  box-shadow: 0px 0px 10px 1px ${colors.lime};
`

const Boxer = styled.div`
  padding: 10px 0px 20px 25px;  
  width: 465px;
  height: 235px;
  box-shadow: 0 4px 12px 0 rgb(200 198 198 / 50%);
`

const Title = styled.div`
  margin: 0px 30px 7px -5px;
  padding-left: 12px;
  font-size: 18px;
  font-weight: 700;
  color: #205527;
  border-bottom: 1px solid ${colors.lime};
`


const Button = styled(AntButton)`
  vertical-align: top;
  margin: 8px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--true-green);
  text-transform: uppercase;
`
