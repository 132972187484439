import gql from 'graphql-tag'
import {
  INFO_HESA_FRAGMENT,
} from 'Queries/HesaUser'

export const GET_CURRENT_USER = gql`
  query getCurrentUser($tipo: Long!) {
    currentUser(tipo: $tipo) {
      codDistribuidor
      codEmpleado
      name
      email
      roles {
        name
        read
        write
      }
      currentTime
      menu {
        subMenu
        url
      }
      avisos {
        tipo
        desc
        context {
          ano
        }
        url
      }
      display
      profile {
        id
        desc
      }
      respuesta
      fechaRespuesta
      infoHesa {
        ...infoHesaFragment
      }
      isSuperUser
      respuestaFuerzaBar
      fechaRespuestaFuerzaBar
    }
  }
  ${INFO_HESA_FRAGMENT}
`

export const SET_CONSENT = gql`
  mutation setConsent($tipo: Long!, $respuesta: Boolean!) {
    registrarRespuestaConsentimiento(tipo: $tipo, respuesta: $respuesta)
  }
`

export const CHANGE_FUERZA_CONSENTS = gql`
  mutation changeFuerzaConsent($respuesta: Boolean!) {
    cambiarConsentimientoFuerzaBar(respuesta: $respuesta)
  }
`
