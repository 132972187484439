import React from 'react'
import { isEmpty } from 'lodash'
import styled from '@emotion/styled'

import { Table as AntTable, Empty } from 'antd'
import { Icon } from '@ant-design/compatible'
import { colors } from 'Utils';
import { Spinner } from 'Components'

export const SummaryTable = ({
  customList,
  firstCol,
  isLoaded,
  isHesa = false,
}) => {

  const getColumns = listLength => {
    const base = [
      {
        dataIndex: firstCol[0].id,
        key: firstCol[0].id,
        // ellipsis: true,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {
              style: { background: "#D4EFDF", fontSize: "14px" },
              rowSpan: index === 0 ? listLength : 0,
            },
          };
          return obj;
        },
      },
      {
        title: 'Nivel',
        dataIndex: 'rolEmpleado',
        key: 'rolEmpleado',
        align: 'center',
        className: 'row-xs-header',
        render: (value) => {
          const obj = {
            children: value,
            props: {
              class: 'row-xs-body'
            },
          };
          return obj;
        },
      },
      {
        title: firstCol[1].text,
        dataIndex: firstCol[1].id,
        key: firstCol[1].id,
        ellipsis: true,
      },
      {
        title: 'Tipo de Objetivo',
        dataIndex: 'objetivoUnidad',
        key: 'objetivoUnidad',
        align: 'center',
        className: isHesa ? 'row-l-header' : 'row-s-header',
        render: (value) => {
          const obj = {
            children: value,
            props: {
              class: isHesa ? 'row-l-body' : 'row-s-body',
            },
          };
          return obj;
        },
      },
      {
        title: 'Fecha Periodo',
        dataIndex: 'periodo',
        key: 'periodo',
        align: 'center',
        className: 'row-l-header',
        render: (value) => {
          const obj = {
            children: value,
            props: {
              class: 'row-l-body'
            },
          };
          return obj;
        },
      },
      {
        title: 'Cantidad Objetivo',
        dataIndex: 'objAbs',
        key: 'objAbs',
        align: 'center',
        render: (value) => {
          return (
            <Cell negative={parseInt(value) < 0}>
              {value}
            </Cell>
          );
        },
      },
      {
        title: 'Cantidad Actual',
        dataIndex: 'objetivoRealDia',
        key: 'objetivoRealDia',
        align: 'center',
        render: (value) => {
          return (
            <Cell negative={parseInt(value) < 0}>
              {value}
            </Cell>
          );
        },
      },
    ]

    const notHesa = [
      {
        title: '% Cumplimiento',
        dataIndex: 'porcCumplimientoMedidaRealDia',
        key: 'porcCumplimientoMedidaRealDia',
        align: 'center',
        className: 'row-s-header',
        render: (value) => {
          const obj = {
            children: (
              <Cell negative={value !== '-' && parseInt(value) < 0}>
                {value}
              </Cell>
            ),
            props: {
              class: 'row-s-body'
            },
          };
          return obj;
        },
      },
      {
        title: 'Incentivo Teórico',
        dataIndex: 'incentivoTeorico',
        key: 'incentivoTeorico',
        align: 'center',
        render: (value) => {
          return (
            <Cell negative={parseInt(value) < 0}>
              {value}
            </Cell>
          );
        },
      },
      {
        title: 'Incentivo Real',
        dataIndex: 'incentivoDia',
        key: 'incentivoDia',
        align: 'center',
        render: (value) => {
          return (
            <Cell negative={parseInt(value) < 0}>
              {value}
            </Cell>
          );
        },
      },
      {
        title: '% Incentivo',
        dataIndex: 'porcIncentivo',
        key: 'porcIncentivo',
        align: 'center',
        className: 'row-s-header',
        render: (value) => {
          const obj = {
            children: (
              <Cell negative={parseInt(value) < 0}>
                {value}
              </Cell>
            ),
            props: {
              class: 'row-s-body'
            },
          };
          return obj;
        },
      },
    ]

    const estado = [
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        align: 'center',
        className: 'row-xs-header',
        render: (value) => {
          const stateColor = value === 'VENCIDO' ? '#7D7D7D' : '#54AC29';
          const obj = {
            children: (
              <span>
                <Icon type="clock-circle" style={{ color: stateColor, fontSize: '17px' }} theme="filled" />
              </span>
            ),
            props: {
              class: 'row-xs-body'
            },
          };
          return obj;
        },
      },
    ];

    return isHesa ? [...base, ...estado] : [...base, ...notHesa, ...estado]
  }

  return (
    <>
      <br />
      {customList.map((objList, index) =>
        <StyledTable
          rowKey="idObj"
          style={{ borderWidth: '0px', marginBottom: '-1px' }}
          showHeader={index === 0}
          size={"small"}
          dataSource={objList}
          pagination={false}
          columns={getColumns(objList.length)}
          isHesa={isHesa}
        />
      )}

      {(isEmpty(customList) && !isLoaded) && (
        <NoData description={`No hay ${firstCol[0].text}s para mostrar`} />
      )}
      {isLoaded && <Spinner />}
    </>
  )
}

const StyledTable = styled(AntTable)(({ cursor, isHesa }) => ({
  cursor: cursor ? 'pointer' : 'initial',
  boxShadow: '4px 4px 5px 0px rgba(150,150,150,1)',
  '& .ant-table-thead > tr > th': {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 600,
    color: '#205527',
  },
  '& .ant-table-small > .ant-table-content > .ant-table-body': {
    margin: '0px',
    fontSize: '13px',
  },
  '& .ant-table-tbody > tr > td': {
    textAlign: 'center',
    background: '#F3FAF3',
    borderTop: '1px solid darkGray',
    borderBottom: 'none'
  },
  '& th.row-l-header.ant-table-align-center': {
    width: '130px !important',
  },
  '& td.row-l-body': {
    width: '130px !important',
  },
  '& th.row-s-header.ant-table-align-center': {
    width: '100px !important',
  },
  '& td.row-s-body': {
    width: '100px !important',
  },
  '& th.row-xs-header.ant-table-align-center': {
    width: '70px !important',
  },
  '& td.row-xs-body': {
    width: '70px !important',
  },
  '& .ant-table-row-cell-ellipsis': {
    width: isHesa ? '240px !Important' : '130px !Important',
  },
}))

const NoData = styled(Empty)`
  margin: 50px;
  font-size: 24px;
  color: ${colors.whiteTwo};
`
const Cell = styled.span`
  color: ${props => (props.negative ? 'rgba(255,0,0,.6)' : 'rgba(0,0,0,.6)')};
`