import React from 'react'
import styled from '@emotion/styled'

export const TextButton = ({ children, onClick, type }) => {
  return (
    <TextButtonInner onClick={onClick} type={type}>
      {children}
    </TextButtonInner>
  )
}

const TextButtonInner = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${props => (props.type === 'danger' && '#cc0000') || '#008200'};
  min-width: 60px;
  margin-left: 7px;
  margin-right: 7px;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  display: inline-block;
`
