import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'Utils'
import { Modal } from 'antd'

export const showObjectiveDeleteConfirm = (name, onOk, callback) => {
  Modal.confirm({
    title: <ConfirmTitle>Borrar objetivo</ConfirmTitle>,
    content: (
      <>
        <p>{`El objetivo "${name}" será borrado de tu lista de objetivos.`}</p>
        <p>Esta acción no se puede deshacer.</p>
      </>
    ),
    icon: null,
    centered: true,
    okText: 'BORRAR',
    okButtonProps: {
      type: 'danger',
    },
    cancelText: 'CANCELAR',
    cancelButtonProps: {
      type: 'link',
    },
    onOk,

    onOk: () => callback(true),
    onCancel: () => callback(false)
  })
}

const ConfirmTitle = styled.div`
  font-size: 18px;
  color: ${colors.black};
`
