import React from 'react'
import { Drawer, Avatar as AntAvatar } from 'antd'
import { getNameInitials } from 'Utils'
import styled from '@emotion/styled'

// Use Collapse in stead of Select
export const ProviderList = ({ dataSource, isOpen, onClose }) => {
  if (!dataSource) return null
  return (
    <Drawer visible={isOpen} closable={false} onClose={onClose}>
      {dataSource.map(({ nombreComercial }, index) => (
        <ListItemWrapper key={index}>
          <Avatar>{getNameInitials(nombreComercial)}</Avatar>
          {nombreComercial}
        </ListItemWrapper>
      ))}
    </Drawer>
  )
}

const Avatar = styled(AntAvatar)({
  background: '#7ab800',
  marginRight: 10,
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.5px',
})

const ListItemWrapper = styled.div({
  padding: '10px 0',
  borderBottom: '1px solid #e8e8e8',
})
