import React, { useContext, useEffect, useState } from 'react'
import { get } from 'lodash'
import { SummaryTableShort, ReportWrapper } from 'Components'
import { OBJECTIVE_KPI_GLOBAL } from 'Queries/ObjectivesKpi'
import { useQuery } from '@apollo/client'
import { GlobalContext, sort, groupListBy, DownloadButton, summaryHeader, getEmpleadosAndNiveles } from 'Utils'
import moment from 'moment'
import styled from '@emotion/styled'

export const SummaryReport = ({
  codDistribuidor,
  codObjetivo,
  year,
  isHesa,
}) => {

  const context = useContext(GlobalContext)
  const { isDev } = context

  const [filter, setFilter] = useState({})
  const [empleadosGlobal, setEmpleadosGlobal] = useState([])
  const [niveles, setNiveles] = useState([])

  const activeObjectiveResource = useQuery(OBJECTIVE_KPI_GLOBAL, {
    fetchPolicy: 'network-only',
    variables: {
      codDistribuidor,
      ano: year,
      fechaDesde: moment(`${year}-01-01`, 'YYYY-MM-DD').format('x'),
      fechaHasta: moment(`${year}-12-31`, 'YYYY-MM-DD').format('x'),
      codObjetivos: [parseInt(codObjetivo)],
    },
    skip: isDev,
    onCompleted: context.resetKeepAliveTimer
  })

  const activeObjectives = get(activeObjectiveResource, 'data.objetivoKpiGlobal') || []
  const comercialsList = groupListBy(activeObjectives, 'nombreEmpleado', filter)
  const empleadosYniveles = getEmpleadosAndNiveles(activeObjectives)


  useEffect(() => {
    const employees = Object.values(empleadosYniveles.empleadosGlobal)
    if (!empleadosGlobal.length && employees.length) {
      const empleadosGlobal = sort(employees, 'nombreEmpleado')
      setEmpleadosGlobal(empleadosGlobal)
    }
  }, [empleadosYniveles.empleadosGlobal])

  useEffect(() => {
    const empleadosGlobal = sort(
      Object.values(
        getEmpleadosAndNiveles(
          activeObjectives,
          filter.nivel,
        ).empleadosGlobal,
      ),
      'nombreEmpleado',
    )
    setEmpleadosGlobal(empleadosGlobal)
  }, [filter.nivel])

  useEffect(() => {
    const levels = Object.values(empleadosYniveles.niveles)
    if (!niveles.length && levels.length) {
      const niveles = sort(levels, 'desc')
      setNiveles(niveles)
    }
  }, [empleadosYniveles.niveles])

  return (
    <ReportWrapper reportHeight={170}>
      <DownloadButton isShort header={summaryHeader("comerciales", isHesa)} body={comercialsList} loading={get(activeObjectiveResource, 'loading') || false} />
      <br />
      <SummaryTableShort
        customList={comercialsList}
        firstCol={[{ id: 'nombreEmpleado', text: 'Comercial' }, { id: 'desc', text: 'Objetivo' }]}
        isLoaded={get(activeObjectiveResource, 'loading') || false}
        isHesa={isHesa}
      />
      <br />
    </ReportWrapper>
  )
}
