export const colors = {
  white: '#ffffff',
  black: '#000000',
  greyish: '#b0b0b0',
  warmGrey: '#858585',
  greyishBrown: '#575756',
  blackTwo: '#222221',
  paleGrey: '#e6eaee',
  whiteTwo: '#d9d9d9',
  whiteThree: '#f5f5f5',
  whiteFour: '#eeecec',
  coolGrey: '#a8aab7',
  paleGreyTwo: '#dfe3e9',
  paleGreyThree: '#e9eff4',
  purpleGrey: '#7f7e7f',
  whiteFive: '#f7f7f7',
  whiteSix: '#eae9e9',
  scarlet: '#d0021b',
  silver: '#dfe2e5',
  pine: '#205527',
  fernGreen: '#467a3c',
  trueGreen: '#008200',
  nastyGreen: '#6cb33f',
  lime: '#BDDC7F',

  ligthRed: '#FFCACA',
  redCruz: '#D20B12',
  ligthGreen: '#CCE3C8',
  openBlue: '#379CD8',
}

export const levelColors = ['nastyGreen', 'whiteSix', 'whiteFive', 'white']
