import React from 'react'
import styled from '@emotion/styled'
import { formatNumberWithThousands } from 'Utils'

const getMounthValue = (mounths, index) =>
  formatNumberWithThousands(
    Object.keys(mounths).reduce((acc, key) => {
      return parseInt(mounths[key].mes) === index + 1
        ? parseInt(mounths[key].valor)
        : acc
    }, 0),
  )

export const IncentivesTotalTable = ({
  total,
  montly,
  startMonth,
  endMonth,
  isOpen,
}) => {
  return (
    <TableWrapper isOpen>
      <Total>{formatNumberWithThousands(parseInt(total))} €</Total>
      <Subtitle>Incentivos total año</Subtitle>
      <MonthsWrapper>
        {months.map((month, index) => (
          <Month
            isOpen
            key={index}
            disabled={!(startMonth <= index && index <= endMonth)}
          >
            <div>{month}</div>
            <MonthAmount>{getMounthValue(montly, index)} €</MonthAmount>
          </Month>
        ))}
      </MonthsWrapper>
    </TableWrapper>
  )
}
const TableWrapper = styled.div`
  margin: ${props => (props.isOpen ? '15px auto' : '30px auto')};
  width: ${props => (props.isOpen ? '710px' : '1024px')};
  height: 150px;
  padding-top: 16px;
  border-radius: ${props => (props.isOpen && '5px')};
  box-shadow: ${props => (props.isOpen ? '0 4px 13px 0 rgba(255, 255, 255, 0.7)' : '0 4px 12px 0 rgba(200, 198, 198, 0.5)')};
  background-color: var(--white);
`
const Total = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: var(--pine);
`
const Subtitle = styled.div`
  font-size: 16px;
  text-align: center;
  color: #757575;
`
const MonthsWrapper = styled.div`
  margin: 12px 12px;
  display: flex;
  > *:last-child {
    border-right: none;
  }
`
const Month = styled.div`
  display: inline-block;
  text-align: center;
  min-width: ${props => (props.isOpen ? '58px' : '84px')};
  min-height: 62px;
  font-size: 14px;
  line-height: 1.43;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  color: #555555;
  border-right: solid 1px #eaeaea;
`
const MonthAmount = styled.div`
  font-weight: 600;
  color: var(--pine);
`
const months = [
  'Ene.',
  'Feb.',
  'Mar.',
  'Abr.',
  'May.',
  'Jun.',
  'Jul.',
  'Ago.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
]
