import React from 'react'
import moment from 'moment'
import { Icon } from 'Components'
import { ObjectiveTitle, ObjectiveSubtitle, DraftSpan, IconTag } from './shared'
import { colors, colorTypeMap, iconTypeMap } from 'Utils'

export const CollapseHeader = ({ title, date, estado, medida }) => (

  <>
    <div>
      {medida && <IconTag borderColor={colorTypeMap[medida.id]}> < Icon type={iconTypeMap[medida.id]} size={24} /></IconTag>}
      {(estado && estado === "Draft") && <IconTag borderColor={colors.redCruz} style={{ marginLeft: -5 }} > <Icon type="contract" size={24} /></IconTag>}
      <ObjectiveTitle>{(estado && estado === "Draft") && <DraftSpan> [ BORRADOR ] </DraftSpan>}{title} </ObjectiveTitle>
    </div>

    <ObjectiveSubtitle>
      Editado por última vez, {moment(date).calendar(null, formatOptions)}
    </ObjectiveSubtitle>
  </>
)

export const formatOptions = {
  sameDay: '[Hoy, ]h:mm a',
  lastDay: '[Ayer, ]h:mm a',
  lastWeek: 'D MMM h:mm a',
  sameElse: 'D MMM h:mm a',
}
