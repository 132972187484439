import { Collapse as AntCollapse } from 'antd'
import styled from '@emotion/styled'

export const Panel = AntCollapse.Panel
export const Collapse = styled(AntCollapse)`
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.09);
  margin-bottom: 19px;
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 17px 28px 15px;
  }
  .ant-collapse-header {
    background-color: #f1f1f1;
    box-shadow: 0 2px 4px 0 rgba(233, 233, 233, 0.5);
    min-height: 76px;
  }
  .ant-collapse-header > .ant-collapse-header-text{
    width: 100%;
  }
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 22px 10px 30px;
  }
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 28px;
  }
`
